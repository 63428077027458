import { Flex } from "@springcare/sh-component-library";
import { ReactElement } from "react";

import CareAdvocateCard from "./CareAdvocateCard";
import CareConsultantCard from "./CareConsultantCard";
import WorkplaceConsultationCard from "./WorkplaceConsultationCard";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";

type SDOHCardsProps = {
  careAdvocateSupported: boolean;
  careConsultantSupported: boolean;
  customerId?: string;
  workplaceConsultationsSupported: boolean;
};

const SDOHCards = ({
  careAdvocateSupported,
  careConsultantSupported,
  customerId,
  workplaceConsultationsSupported,
}: SDOHCardsProps): ReactElement => {
  const showOnsiteClincianCard = useFeatureFlag(
    FLAGS.SHOW_ONSITE_CLINICIAN_FLOW,
  );
  return (
    <Flex
      flexDirection={["column", "column", "column", "row"]}
      overflowX="auto"
      overflowY="hidden"
    >
      {careAdvocateSupported && (
        <CareAdvocateCard customerId={customerId} data-testid="cca-card" />
      )}
      {careConsultantSupported && (
        <CareConsultantCard customerId={customerId} data-testid="wpmhc-card" />
      )}
      {showOnsiteClincianCard && workplaceConsultationsSupported && (
        <WorkplaceConsultationCard
          customerId={customerId}
          data-testid="wpc-card"
        />
      )}
    </Flex>
  );
};

export default SDOHCards;
