import { Heading, Text } from "@springcare/sh-component-library";
import { useTranslation } from "react-i18next";

type TeenAssessmentStartMessageProps = {
  memberFirstName: string;
  appointmentId?: string | string[];
};

export const TeenAssessmentStartMessage = ({
  memberFirstName,
  appointmentId,
}: TeenAssessmentStartMessageProps): React.ReactElement => {
  const { t } = useTranslation("assessments");
  return (
    <>
      <Heading as="h1" size="heading-medium" data-testid="confirmed-heading">
        {appointmentId
          ? t("teenAssessments.minorAppointmentDetails.confirmationheader", {
              ns: "assessments",
              memberFirstName: memberFirstName,
            })
          : t("teenAssessments.minorAppointmentDetails.supportText", {
              ns: "assessments",
              memberFirstName: memberFirstName,
            })}
      </Heading>
      <Text variant="body-medium">
        {t("teenAssessments.minorAppointmentDetails.autonomyText", {
          ns: "assessments",
          memberFirstName: memberFirstName,
        })}
      </Text>
    </>
  );
};
