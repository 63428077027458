import { Box } from "@springcare/sh-component-library";
import React from "react";
import { useOnRampToCareExperiment } from "../../hooks";
import { DefaultCompletionSteps } from "./DefaultCompletionSteps";
import { TreatmentUserCompletionSteps } from "./TreatmentUserCompletionSteps";
import { handleRedictToMoments } from "utils/routes";

export const MomentsCompletionContent = ({
  exerciseTitle,
  exerciseId,
  exerciseMediaType,
  exerciseCategory,
  onClose = () => {},
  onCloseVideoModal = () => {},
  feeling,
  setFeeling,
}) => {
  const { isTherapyTreatmentUser, isCoachingTreatmentUser } =
    useOnRampToCareExperiment();
  const handleOnClick = () => {
    onCloseVideoModal();
    onClose();
    setFeeling(null);
    handleRedictToMoments();
  };

  if (isTherapyTreatmentUser || isCoachingTreatmentUser) {
    return (
      <Box
        width="100%"
        sx={{
          ["& > *:not(style)"]: {
            marginTop: "v-none",
            paddingTop: "v-none",
          },
        }}
      >
        <TreatmentUserCompletionSteps
          {...{
            exerciseTitle,
            exerciseId,
            exerciseMediaType,
            exerciseCategory,
            feeling,
            setFeeling,
            handleOnClick,
          }}
        />
      </Box>
    );
  }

  return (
    <Box
      width="100%"
      sx={{
        ["& > *:not(style)"]: {
          marginTop: "v-none",
          paddingTop: "v-none",
        },
      }}
    >
      <DefaultCompletionSteps
        {...{
          exerciseTitle,
          exerciseId,
          exerciseMediaType,
          exerciseCategory,
          feeling,
          setFeeling,
          handleOnClick,
        }}
      />
    </Box>
  );
};
