// theme/index.js
import { extendTheme, theme } from "@springcare/sh-component-library";
// Foundation style overrides
import { base } from "./base";

const verdant = {
  semanticTokens: theme.mx.semanticTokens,
  colors: theme.mx.colors,
  sizes: theme.mx.sizes,
  borderWidths: theme.mx.borderWidths,
  radii: theme.mx.radii,
  space: theme.mx.space,
  fontSizes: theme.mx.fontSizes,
  lineHeights: theme.mx.lineHeights,
  fontWeights: theme.mx.fontWeights,
  textStyles: theme.mx.textStyles,
  components: {
    Avatar: theme.mx.components.Avatar,
    Button: theme.mx.components.Button,
    Badge: theme.mx.components.Badge,
    Text: theme.mx.components.Text,
    Heading: theme.mx.components.Heading,
    Modal: theme.mx.components.Modal,
    Link: theme.mx.components.Link,
    Card: theme.mx.components.Card,
    Input: theme.mx.components.Input,
    CompactPageHeader: theme.mx.components.PageHeader,
    Alert: theme.mx.components.Alert,
    Switch: theme.mx.components.Switch,
    MessageBubble: theme.cgx.components.MessageBubble,
    MessageBlock: theme.cgx.components.MessageBlock,
    TextArea: theme.mx.components.Textarea,
    ...theme.mx.components,
  },
};

const config = {
  initialColorMode: "light",
  useSystemColorMode: false,
};
// TODO: add our theme to '@springcare/sh-component-library';
// export const mxTheme = extendTheme(theme.mx, {
export const mxTheme = extendTheme(verdant, {
  config,
  ...base,
});

const direction = "rtl";
export const rtlTheme = extendTheme(verdant, {
  config,
  ...base,
  direction,
});
