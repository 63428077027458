import {
  Heading,
  ListItem,
  Text,
  UnorderedList,
  useMediaQuery,
  VFamiliesDuotoneSpot,
  VFamiliesIcon,
} from "@springcare/sh-component-library";
import { InformationFlyout } from "components";
import { Button, Call, Plus } from "design-system/index";
import Router from "next/router";
import { Trans, useTranslation } from "react-i18next";
import routes from "routes";

type DependentsFlyoutType = {
  callback?: () => void;
  isOpen: boolean;
  onClose: () => void;
  ctaText: string;
};

const DependentsFlyoutBody = () => {
  const { t } = useTranslation("t2Homepage", {
    keyPrefix: "dependentsFlyout",
  });

  return (
    <>
      <Heading size="heading-small" mb="v-8">
        {" "}
        {t("benefitsHeader")}
      </Heading>
      <UnorderedList mb={24}>
        <ListItem>{t("benefitsBody.benefit1")}</ListItem>
        <ListItem>{t("benefitsBody.benefit2")}</ListItem>
      </UnorderedList>
      <Heading size="heading-small" mb="v-8" mt="v-24">
        {" "}
        {t("whatToExpectHeader")}
      </Heading>
      <UnorderedList mb={72}>
        <ListItem>
          <Trans
            t={t}
            i18nKey="whatToExpectBody.item1"
            components={[<Text key="whatToExpectBody.concern1" />]}
          />
        </ListItem>{" "}
        <ListItem>
          <Trans
            t={t}
            i18nKey="whatToExpectBody.item2"
            components={[<Text key="whatToExpectBody.concern2" />]}
          />
        </ListItem>
      </UnorderedList>
    </>
  );
};

export const DependentsFlyout = ({
  callback,
  isOpen,
  onClose,
  ctaText,
}: DependentsFlyoutType) => {
  const { t } = useTranslation("t2Homepage", {
    keyPrefix: "dependentsFlyout",
  });
  const [isMobile] = useMediaQuery("(max-width: 450px)");

  return (
    <InformationFlyout
      headerText={t("heading")}
      headerIcon={<VFamiliesIcon />}
      heroIllustration={VFamiliesDuotoneSpot}
      isOpen={isOpen}
      padding={isMobile ? "24px 0" : "24px 40px"}
      onClose={onClose}
      ctaIcon={<Call />}
      ctaText={ctaText}
      ctaLink={`tel:${ctaText}`}
      ctaType={callback && "link"}
      ctaCallback={callback}
      secondaryCTA={
        <Button
          marginTop={"1.375rem"}
          onClick={() => {
            Router.push(routes.InviteDependents.to, routes.InviteDependents.as);
          }}
          leftIcon={<Plus color={"primary.500"} />}
        >
          {t("inviteDependent")}
        </Button>
      }
    >
      <DependentsFlyoutBody />
    </InformationFlyout>
  );
};
