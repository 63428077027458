import { useState } from "react";
import PropTypes from "prop-types";
import * as yup from "yup";
import { useController, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  PinInput,
} from "@springcare/sh-component-library";

import OtpInput from "react-otp-input";

const mfaPinFormSchema = yup.object().shape({
  code: yup.string().length(6).required("A valid PIN is required"),
});

const VerificationCodeInput = ({
  control,
  name,
  pinInputHeight,
  pinInputWidth,
  pinsDisabled,
}) => {
  const { field } = useController({ name, control });
  const [otp, setOtp] = useState("");

  return (
    <PinInput>
      <OtpInput
        value={otp}
        id={name}
        numInputs={6}
        renderSeparator={<span style={{ width: "4px" }} />}
        renderInput={(props) => {
          return (
            <Box
              bgColor={pinsDisabled ? "background-disabled" : "border-subtle"}
              borderRadius={"4px"}
              width={pinInputWidth}
              height={pinInputHeight}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <input
                {...props}
                aria-label="Please enter your pin code"
                style={{
                  backgroundColor: "transparent",
                  textAlign: "center",
                  width: "100%",
                  height: "100%",
                }}
                inputMode="numeric"
                disabled={pinsDisabled}
              />
            </Box>
          );
        }}
        onChange={(val) => {
          setOtp(val);
          if (val.length === 6) {
            field.onChange(val);
          }
        }}
      />
    </PinInput>
  );
};

const VerifyPinForm = ({
  children,
  formLabel,
  formSubmit,
  icon,
  isDisabled,
  isRequired,
  onSubmit,
  pinInputHeight,
  pinInputWidth,
  pinsDisabled,
  withStickyFooterButton,
  ...rest
}) => {
  const name = "code";
  const { control, formState, handleSubmit, watch } = useForm({
    defaultValues: { [name]: "" },
    resolver: yupResolver(mfaPinFormSchema),
  });
  const { errors, isSubmitting } = formState;
  const isInvalidInput = watch(name).length !== 6;

  return (
    <>
      <Box w={withStickyFooterButton && "80%"} {...rest}>
        <form
          // eslint-disable-next-line no-unused-vars
          onSubmit={handleSubmit((data, _event) => onSubmit(data))}
          aria-label="verify pin form"
        >
          <FormControl isInvalid={errors?.[name]} isRequired={isRequired}>
            <Flex
              gap={24}
              flexDirection={{
                base: "column",
                md: withStickyFooterButton ? "column" : "row",
              }}
              alignItems={{ md: withStickyFooterButton ? "start" : "end" }}
            >
              <Flex flexDirection={"column"} maxWidth={"100%"}>
                <FormLabel htmlFor={name}>{formLabel}</FormLabel>
                <Box>
                  <VerificationCodeInput
                    pinInputWidth={pinInputWidth}
                    pinInputHeight={pinInputHeight}
                    pinsDisabled={pinsDisabled}
                    control={control}
                    name={name}
                  />
                  {errors?.[name] && (
                    <FormErrorMessage color="error.base" fontWeight={700}>
                      {errors[name]?.message}
                    </FormErrorMessage>
                  )}
                </Box>
              </Flex>
              {!withStickyFooterButton && (
                <Button
                  type="submit"
                  variant="high-emphasis"
                  height={48}
                  w={"fit-content"}
                  rightIcon={icon}
                  isLoading={isSubmitting}
                  isDisabled={isDisabled || isSubmitting || isInvalidInput}
                >
                  {formSubmit}
                </Button>
              )}
            </Flex>
          </FormControl>
          {/**
           * TODO: The below render-props was leveraged to incorporate the redesigns of the MFA
           * login pages, which required use of the SHStickyFooter button AND moving the link to
           * trigger the backup code form under that button. If the old sign in page is ever
           * retired, it may be beneficial to remove the render props and all rendering that is
           * conditioned on withStickyFooterButton.
           */}
          {withStickyFooterButton &&
            children({
              isLoading: isSubmitting,
              isDisabled: isDisabled || isSubmitting || isInvalidInput,
            })}
        </form>
      </Box>
    </>
  );
};

VerifyPinForm.propTypes = {
  children: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  pinInputWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  icon: PropTypes.element,
  isRequired: PropTypes.bool,
  withStickyFooterButton: PropTypes.bool,
};

VerifyPinForm.defaultProps = {
  children: () => {},
  pinInputWidth: 56,
  pinInputHeight: 56,
  isRequired: true,
  isDisabled: false,
  pinsDisabled: false,
  withStickyFooterButton: false,
};

export default VerifyPinForm;
