/* eslint-disable react/jsx-key */
import { createIcon } from "@springcare/sh-component-library";

export const Provider = createIcon({
  displayName: "Provider",
  viewBox: "0 0 24 24",
  path: [
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.9352 10.5295C15.2004 9.42945 16.0002 7.80813 16.0002 6C16.0002 2.68629 13.3139 0 10.0002 0C6.68645 0 4.00016 2.68629 4.00016 6C4.00016 7.80813 4.79996 9.42945 6.06508 10.5295C3.48213 11.5209 1.51662 13.8185 1.03889 16.6848L0.0137621 22.8356C-0.0770329 23.3804 0.290987 23.8956 0.835757 23.9864C1.38053 24.0772 1.89575 23.7092 1.98655 23.1644L3.01168 17.0136C3.49387 14.1205 5.99701 12 8.93005 12H11.0703C14.0033 12 16.5064 14.1205 16.9886 17.0136L18.0138 23.1644C18.1046 23.7092 18.6198 24.0772 19.1646 23.9864C19.7093 23.8956 20.0773 23.3804 19.9865 22.8356L18.9614 16.6848C18.4837 13.8185 16.5182 11.5209 13.9352 10.5295ZM10.0002 2C7.79102 2 6.00016 3.79086 6.00016 6C6.00016 8.20914 7.79102 10 10.0002 10C12.2093 10 14.0002 8.20914 14.0002 6C14.0002 3.79086 12.2093 2 10.0002 2Z"
    />,
    <path
      fill="currentColor"
      d="M9.25016 15C9.11208 15 9.00016 15.1119 9.00016 15.25V17H7.25016C7.11208 17 7.00016 17.1119 7.00016 17.25V18.75C7.00016 18.8881 7.11208 19 7.25016 19H9.00016V20.75C9.00016 20.8881 9.11208 21 9.25016 21H10.7502C10.8882 21 11.0002 20.8881 11.0002 20.75V19H12.7502C12.8882 19 13.0002 18.8881 13.0002 18.75V17.25C13.0002 17.1119 12.8882 17 12.7502 17H11.0002V15.25C11.0002 15.1119 10.8882 15 10.7502 15H9.25016Z"
    />,
  ],
  defaultProps: {
    color: "platform.900",
  },
});
