import { createIcon } from "@springcare/sh-component-library";

export const InfoIcon = createIcon({
  displayName: "InfoIcon",
  viewBox: "0 0 16 16",
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 1.75342C4.54822 1.75342 1.75 4.55164 1.75 8.00342C1.75 11.4552 4.54822 14.2534 8 14.2534C11.4518 14.2534 14.25 11.4552 14.25 8.00342C14.25 4.55164 11.4518 1.75342 8 1.75342ZM0.25 8.00342C0.25 3.72321 3.71979 0.253418 8 0.253418C12.2802 0.253418 15.75 3.72321 15.75 8.00342C15.75 12.2836 12.2802 15.7534 8 15.7534C3.71979 15.7534 0.25 12.2836 0.25 8.00342ZM7.99963 6.75671C7.58542 6.75692 7.2498 7.09287 7.25 7.50708L7.25195 11.5071C7.25216 11.9213 7.58811 12.2569 8.00232 12.2567C8.41653 12.2565 8.75216 11.9206 8.75195 11.5063L8.75 7.50635C8.7498 7.09213 8.41385 6.75651 7.99963 6.75671ZM8 5.50671C8.41421 5.50671 8.75 5.17093 8.75 4.75671C8.75 4.3425 8.41421 4.00671 8 4.00671C7.58579 4.00671 7.25 4.3425 7.25 4.75671C7.25 5.17093 7.58579 5.50671 8 5.50671Z"
      fill="currentColor"
    />
  ),
  defaultProps: {
    "aria-label": "Info icon",
    role: "img",
  },
});
