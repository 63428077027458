import {
  teenWebLeftLinks,
  adultWebLeftLinks,
  adultWebWorkplaceManagerLeftLinks,
  minorWebLeftLinks,
  t2MemberWebLeftLinks,
  t2MemberWebLeftLinksWithWorkplace,
  adultMobileSidebarLinks,
  privatePracticeMobileSidebarLinks,
  adultMobileWorkplaceManagerSidebarLinks,
  minorMobileSidebarLinks,
  t2MemberMobileSidebarLinks,
  teenMobileSidebarLinks,
  t2MemberMobileSidebarLinksWithWorkplace,
} from "components/templates/MemberDashboard/PageWrapper/config/navConfig";
import {
  MenuNavLink,
  navLinkTogglesTypes,
  MobileMenuNavLink,
} from "components/templates/MemberDashboard/PageWrapper/types/navLinkTypes";

export const getNavLinks = ({
  type,
  isAT2Member,
  isATeenMember,
  isAMinorMember,
  isAGlobalMember,
  canSeeWorkplaceLink,
  viewWorkplaceLinkFeatureFlag,
  isAWorkplaceManager,
  isADependent,
  isPrivatePractice = false,
}: navLinkTogglesTypes): MenuNavLink[] | MobileMenuNavLink[] => {
  const canViewWorkplaceLink =
    canSeeWorkplaceLink &&
    isAWorkplaceManager &&
    !isADependent &&
    !isAMinorMember &&
    !isPrivatePractice;

  const canAccessWorkplace =
    canViewWorkplaceLink &&
    (isAGlobalMember ? viewWorkplaceLinkFeatureFlag : true);

  if (type === "web") {
    if (isAT2Member) {
      if (canViewWorkplaceLink && viewWorkplaceLinkFeatureFlag) {
        return t2MemberWebLeftLinksWithWorkplace;
      }
      return t2MemberWebLeftLinks;
    }
    if (isATeenMember) return teenWebLeftLinks;
    if (isAMinorMember) return minorWebLeftLinks;
    if (canAccessWorkplace) return adultWebWorkplaceManagerLeftLinks;
    return adultWebLeftLinks;
  }

  if (type === "mobile") {
    if (isAT2Member) {
      if (canViewWorkplaceLink && viewWorkplaceLinkFeatureFlag) {
        return t2MemberMobileSidebarLinksWithWorkplace;
      }
      return t2MemberMobileSidebarLinks;
    }
    if (isATeenMember) return teenMobileSidebarLinks;
    if (isAMinorMember) return minorMobileSidebarLinks;
    if (canAccessWorkplace) return adultMobileWorkplaceManagerSidebarLinks;
    if (isPrivatePractice) return privatePracticeMobileSidebarLinks;
    return adultMobileSidebarLinks;
  }
};
