import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

// Display localStorage items from Magic Env Toggle first, then default to .env values if provided
const EnvDetails = ({ environment, apiUrl, authApiUrl }) => {
	if (environment !== 'dev') {
		return null;
	}

	const rotom = localStorage.getItem('rotom');
	const diglett = localStorage.getItem('diglett');
	const pikachu = localStorage.getItem('pikachu');

	return (
		<div role="complementary" className={styles.envDetails}>
			<p className={styles.envString}>
				Rotom: { rotom || apiUrl }
				<br />
				Auth: { diglett || pikachu || authApiUrl }
			</p>
		</div>
	);
};

EnvDetails.propTypes = {
	environment : PropTypes.string.isRequired,
	apiUrl      : PropTypes.string.isRequired,
	authApiUrl  : PropTypes.string.isRequired,
};

export default EnvDetails;
