import {
  Flex,
  Link,
  ListItem,
  Text,
  UnorderedList,
} from "@springcare/sh-component-library";
import { Trans, useTranslation } from "react-i18next";
import { BenefitsNumberedSteps } from "modules/MemberDashboard/CareVisits/AppointmentDetails/components";
import { VisitsCoveredCard } from "modules/MemberDashboard/Home/components/BenefitsModalT2/components/VisitsCoveredCard";

export const Description = ({
  item,
  memberData,
  isBetweenBaseToSm,
  isMoreThanLg,
  itemMap,
}) => {
  const { t } = useTranslation("t2Benefits");

  const CrisisSupportDescription = () => {
    return (
      <Flex
        flexDirection="column"
        alignItems="flex-start"
        alignSelf="stretch"
        gap="24px"
        maxWidth={isMoreThanLg ? "660px" : "516px"}
      >
        <Text>{t("crisisSupport.descriptionT2")}</Text>
        <Flex flexDirection="column" gap="8px">
          <Text size="body-medium-strong">{t("crisisSupport.whenToCall")}</Text>
          <UnorderedList>
            <ListItem>{t("crisisSupport.whatToExpectContentOne")}</ListItem>
            <ListItem>{t("crisisSupport.whatToExpectContentTwo")}</ListItem>
          </UnorderedList>
        </Flex>
        <Flex flexDirection="column" gap="8px">
          <Text size="body-medium-strong">
            {t("crisisSupport.commonConcernsTitle")}
          </Text>
          <Text>
            <Trans
              ns={"t2Benefits"}
              i18nKey={"crisisSupport.commonConcernsContent"}
              components={[<strong key="0" />]}
            />
          </Text>
        </Flex>
      </Flex>
    );
  };

  const WorkLifeSupportDescription = () => {
    const browseArticlesCTA = memberData?.user?.member?.cohort?.cohort_eap_link;
    return (
      <Flex
        flexDirection="column"
        alignItems="flex-start"
        alignSelf="stretch"
        gap="32px"
        maxWidth={isMoreThanLg ? "660px" : "516px"}
      >
        <Text>
          <Trans
            ns={"t2Benefits"}
            i18nKey={"EAPBenefit.descriptionT2"}
            components={[
              <Link key="0" target={"_blank"} href={browseArticlesCTA} />,
            ]}
          />
        </Text>
        <BenefitsNumberedSteps
          steps={[t(`${itemMap[item]}.t2Stepper`, { returnObjects: true })]}
        />
      </Flex>
    );
  };

  const NumberedStepsDescription = () => {
    return (
      <Flex
        flexDirection="column"
        alignItems="flex-start"
        alignSelf="stretch"
        gap="32px"
        maxWidth={isMoreThanLg ? "660px" : "516px"}
      >
        {isBetweenBaseToSm && (
          <VisitsCoveredCard
            isBetweenBaseToSm={isBetweenBaseToSm}
            item={item}
            memberData={memberData}
          />
        )}
        <Text>{t(`${itemMap[item]}.descriptionT2`)}</Text>
        <BenefitsNumberedSteps
          steps={[t(`${itemMap[item]}.t2Stepper`, { returnObjects: true })]}
        />
      </Flex>
    );
  };

  if (item === "CrisisSupport") {
    return <CrisisSupportDescription />;
  }
  if (item === "WorkLifeSupport") {
    return <WorkLifeSupportDescription />;
  }
  return <NumberedStepsDescription />;
};
