import React from "react";
import NextLink from "next/link";
import { useQuery } from "@apollo/client";
import {
  AppointmentAction,
  AppointmentKind,
  modalIds,
  getAppointmentMediumForTracking,
  getAppointmentKindForTracking,
  getModalNameForTracking,
} from "@spring/constants";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  addNotification,
  closeModal,
  openModal,
} from "@spring/smeargle/actions";

import { ActiveCareCard } from "components/molecules";
import { ActiveCareNoProvidersCard } from "components/molecules/ActiveCareCard";
import { NonCircleAvatar } from "components";
import {
  Medication,
  PlantInHand,
  PlantLeaves,
  WateringCan,
  StackedStones,
  SocialFactors,
} from "design-system/assets";
import {
  Box,
  Spinner,
  Link,
  useBreakpointValue,
} from "design-system/components";
import { Button, Text, useDisclosure } from "@springcare/sh-component-library";
import { getAppointments } from "operations/queries/appointment";
import Router from "next/router";
import routes from "routes";

import { useAllUpcomingAppointmentSlots } from "hooks";
import { useScheduleModalWithProps } from "shared/hooks";
import { formatNextSessionText } from "./ActiveCareSection.util";
import { getAppointmentSlotStartTimeAndMedium } from "utils/schedulingHelpers";
import { TRACK_EVENT } from "utils/mixpanel";
import CareNavigatorAvailabilityModal from "components/modals/CareNavigatorScheduleModal";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";
import { CareProviderScheduleModalV2 } from "components/modals";

export function redirectToMomentsOnlyPush() {
  const { to, as } = routes.MemberMoments;

  TRACK_EVENT.BUTTON_CLICKED(
    window.location.pathname,
    "Explore Moments library",
    {
      spring_doc_id: "HPR_017",
      location: "Active Care",
    },
  );
  Router.push(to, as);
}

export const ActiveCareSection = ({
  careTeam,
  includeMoments,
  openModal,
  hasEverCompletedARecommendedCareItem = false,
  isOnTeenHomepage = false,
  isDirectScheduling,
  isGlobalWithNoCoachingSessions = false,
  memberHasInPersonSupport,
  memberCountry,
  memberNextUpcomingAppointments,
  hasManagedDependents = false,
  memberCanBookTherapyAppointments = false,
  memberCanBookCoachingAppointments = false,
  isSpecialtyCareNavigatorSupported = false,
  openSpecializedCNScheduleModal = null,
}) => {
  const { t } = useTranslation([
    "carePlan",
    "benefits",
    "homepage",
    "teenHomepage",
  ]);

  const { data: cnAppointmentData, loading: cnAppointmentLoading } = useQuery(
    getAppointments,
    {
      variables: {
        limit: 1,
        kind: "Initial Care Navigator Call",
      },
    },
  );

  const { data: scnAppointmentData, loading: scnAppointmentLoading } = useQuery(
    getAppointments,
    {
      skip: !isSpecialtyCareNavigatorSupported,
      variables: {
        limit: 1,
        kind: [
          "Initial Specialty Care Navigation Appointment",
          "Follow Up Specialty Care Navigation Appointment",
        ],
      },
    },
  );

  const hasEverHadCareNavigatorAppointment =
    !cnAppointmentLoading &&
    cnAppointmentData &&
    cnAppointmentData.appointments.data.length > 0;

  const hasEverHadSpecialtyCareNavigatorAppointment =
    !scnAppointmentLoading &&
    scnAppointmentData &&
    scnAppointmentData.appointments.data.length > 0;

  const therapist = careTeam?.user?.member?.care_team?.therapist;
  const medicationManager =
    careTeam?.user?.member?.care_team?.medication_manager;
  const coach = careTeam?.user?.member?.care_team?.coach;
  const careNavigator = careTeam?.user?.member?.care_team?.care_navigator;
  const specialtyCareNavigator =
    careTeam?.user?.member?.care_team?.specialty_care_navigator;
  const peerRecoverySpecialist =
    careTeam?.user?.member?.care_team?.peer_recovery_specialist;
  const isPeerRecoverySpecialistSupported =
    careTeam?.user?.member?.is_peer_recovery_specialist_supported;
  const isShowingActiveCareCards =
    hasEverHadCareNavigatorAppointment ||
    therapist ||
    coach ||
    medicationManager;

  const nextTherapyAppointment = memberNextUpcomingAppointments?.therapy;
  const nextMedicationManagementAppointment =
    memberNextUpcomingAppointments?.medication_management;
  const nextCoachingAppointment = memberNextUpcomingAppointments?.coaching;
  const nextCareNavigatorAppointment =
    memberNextUpcomingAppointments?.care_navigation;
  const nextSpecialtyCareNavigatorAppointment =
    memberNextUpcomingAppointments?.specialty_care_navigation;
  const nextPeerRecoverySpecialistAppointment =
    memberNextUpcomingAppointments?.peer_recovery;

  const { data: upcomingCoachingAppointmentSlots } =
    useAllUpcomingAppointmentSlots(
      coach,
      AppointmentKind.Coaching,
      1,
      false,
      true,
    );

  const { data: upcomingTherapyAppointmentSlots } =
    useAllUpcomingAppointmentSlots(therapist, AppointmentKind.Therapy, 1);

  const { data: upcomingMedicationManagementAppointmentSlots } =
    useAllUpcomingAppointmentSlots(
      medicationManager,
      AppointmentKind.FollowUpMedicationManagement,
      1,
    );

  const {
    isOpen: isCNScheduleModalOpen,
    onOpen: openCNScheduleModal,
    onClose: closeCNScheduleModal,
  } = useDisclosure();

  const {
    isOpen: isScheduleModalOpen,
    onOpen: onScheduleModalOpen,
    onClose: onScheduleModalClose,
    modalProps: scheduleModalProps,
  } = useScheduleModalWithProps();

  const shouldShowNewInsuranceModal = useFeatureFlag(FLAGS.NEW_INSURANCE_MODAL);
  const showPeerRecoverySpecialistExp = useFeatureFlag(
    FLAGS.PEER_RECOVERY_SPECIALIST_EXPERIENCE,
  );

  const openTherapistModal = () => {
    if (therapist) {
      if (shouldShowNewInsuranceModal) {
        onScheduleModalOpen({
          kind: AppointmentKind.Therapy,
          provider: therapist,
          buttonText: t("carePlanSteps.confirmAppointment"),
          action: AppointmentAction.Create,
          telemetryProps: {
            springDocId: "HPR_012",
            location: `Active Care`,
            to: getModalNameForTracking(modalIds.careProviderScheduleModal),
          },
        });
      } else {
        const {
          initialStartTime: therapyInitialStartTime,
          medium: therapyMedium,
        } = getAppointmentSlotStartTimeAndMedium(
          upcomingTherapyAppointmentSlots,
          therapist,
          memberHasInPersonSupport,
          memberCountry,
        );

        TRACK_EVENT.BUTTON_CLICKED(
          window.location.pathname,
          "Schedule Therapist",
          {
            spring_doc_id: "HPR_012",
            location: "Active Care",
            to: getModalNameForTracking(modalIds.careProviderScheduleModal),
            appointment_type: getAppointmentKindForTracking(
              AppointmentKind.Therapy,
            ),
            appointment_medium: getAppointmentMediumForTracking(therapyMedium),
            provider_id: therapist.id,
          },
        );
        openModal(modalIds.careProviderScheduleModal, {
          ...therapist,
          kind: AppointmentKind.Therapy,
          medium: therapyMedium,
          mpLocation: "Your Care",
          providerRole: "Therapist",
          buttonText: t("carePlanSteps.confirmAppointment"),
          action: AppointmentAction.Create,
          initialStartTime: therapyInitialStartTime,
        });
      }
    }
  };

  const openCoachingModal = () => {
    if (coach) {
      if (shouldShowNewInsuranceModal) {
        onScheduleModalOpen({
          kind: AppointmentKind.Coaching,
          provider: coach,
          buttonText: t("carePlanSteps.confirmAppointment"),
          action: AppointmentAction.Create,
          telemetryProps: {
            springDocId: "HPR_013",
            location: `Active Care`,
            to: getModalNameForTracking(modalIds.careProviderScheduleModal),
          },
        });
      } else {
        const {
          initialStartTime: coachingInitialStartTime,
          medium: coachingMedium,
        } = getAppointmentSlotStartTimeAndMedium(
          upcomingCoachingAppointmentSlots,
          coach,
        );

        TRACK_EVENT.BUTTON_CLICKED(
          window.location.pathname,
          "Schedule Coaching",
          {
            spring_doc_id: "HPR_013",
            location: "Active Care",
            to: getModalNameForTracking(modalIds.careProviderScheduleModal),
            appointment_type: getAppointmentKindForTracking(
              AppointmentKind.Coaching,
            ),
            appointment_medium: getAppointmentMediumForTracking(coachingMedium),
            provider_id: coach.id,
          },
        );
        openModal(modalIds.careProviderScheduleModal, {
          ...coach,
          kind: AppointmentKind.Coaching,
          medium: coachingMedium,
          mpLocation: "Your Care",
          providerRole: "Coach",
          buttonText: t("carePlanSteps.confirmAppointment"),
          action: AppointmentAction.Create,
          initialStartTime: coachingInitialStartTime,
        });
      }
    }
  };

  const openMedicationManagementModal = () => {
    if (medicationManager) {
      if (shouldShowNewInsuranceModal) {
        onScheduleModalOpen({
          kind: AppointmentKind.InitialMedicationManagement,
          provider: medicationManager,
          buttonText: t("carePlanSteps.confirmAppointment"),
          action: AppointmentAction.Create,
          telemetryProps: {
            springDocId: "HPR_014",
            location: `Active Care`,
            to: getModalNameForTracking(modalIds.careProviderScheduleModal),
          },
        });
      } else {
        const {
          initialStartTime: medicationManagementInitialStartTime,
          medium: medicationManagementMedium,
        } = getAppointmentSlotStartTimeAndMedium(
          upcomingMedicationManagementAppointmentSlots,
          medicationManager,
        );

        TRACK_EVENT.BUTTON_CLICKED(
          window.location.pathname,
          "Schedule Medication Management",
          {
            spring_doc_id: "HPR_014",
            location: "Active Care",
            to: getModalNameForTracking(modalIds.careProviderScheduleModal),
            appointment_type: getAppointmentKindForTracking(
              AppointmentKind.medicationManagement,
            ),
            appointment_medium: getAppointmentMediumForTracking(
              medicationManagementMedium,
            ),
            provider_id: medicationManager.id,
          },
        );
        openModal(modalIds.careProviderScheduleModal, {
          ...medicationManager,
          // careProviderScheduleModal will change the kind to AppointmentKind.FollowUpMedicationManagement if member has already had a MM session
          kind: AppointmentKind.InitialMedicationManagement,
          medium: medicationManagementMedium,
          mpLocation: "Your Care",
          providerRole: "Medication Manager",
          buttonText: t("carePlanSteps.confirmAppointment"),
          action: AppointmentAction.Create,
          initialStartTime: medicationManagementInitialStartTime,
        });
      }
    }
  };

  const iconSize = useBreakpointValue([12.5, 12.5, 24, 24]);
  const careCardIconColor = "platform.900";

  const activeCareCardConfig = {
    therapy: {
      imageUrl: NonCircleAvatar(nextTherapyAppointment?.provider || therapist),
      overline: t("homepage:activeCare.therapy.overline"),
      icon: <PlantLeaves color={careCardIconColor} boxSize={iconSize} />,
      cardTitle: nextTherapyAppointment?.provider?.name || therapist?.name,
      subtitle: nextTherapyAppointment
        ? `${t("homepage:activeCare.subtitle.nextSession")}: ${formatNextSessionText(nextTherapyAppointment)}`
        : t("homepage:activeCare.subtitle.noUpcomingSession"),
      subtitleLinkText: t("homepage:activeCare.subtitleLinkText"),
      subtitleLinkURL: "MemberAppointmentDetail",
      subtitleLinkQueryParams: { id: nextTherapyAppointment?.id },
      buttonText: t("homepage:activeCare.scheduleSession"),
      buttonCallback: () => openTherapistModal(),
      memberCanBookAppointments: memberCanBookTherapyAppointments,
    },
    medicationManagement: {
      imageUrl: NonCircleAvatar(
        nextMedicationManagementAppointment?.provider || medicationManager,
      ),
      overline: t("homepage:activeCare.medicationManagement.overline"),
      icon: <Medication color={careCardIconColor} boxSize={iconSize} />,
      cardTitle:
        nextMedicationManagementAppointment?.provider?.name ||
        medicationManager?.name,
      subtitle: nextMedicationManagementAppointment
        ? `${t("homepage:activeCare.subtitle.nextSession")}: ${formatNextSessionText(
            nextMedicationManagementAppointment,
          )}`
        : t("homepage:activeCare.subtitle.noUpcomingSession"),
      subtitleLinkText: t("homepage:activeCare.subtitleLinkText"),
      subtitleLinkURL: "MemberAppointmentDetail",
      subtitleLinkQueryParams: { id: nextMedicationManagementAppointment?.id },
      buttonText: t("homepage:activeCare.scheduleSession"),
      buttonCallback: () => openMedicationManagementModal(),
    },
    coaching: {
      imageUrl: NonCircleAvatar(nextCoachingAppointment?.provider || coach),
      overline: t("homepage:activeCare.coaching.overline"),
      icon: <WateringCan color={careCardIconColor} boxSize={iconSize} />,
      cardTitle: nextCoachingAppointment?.provider?.name || coach?.name,
      subtitle: nextCoachingAppointment
        ? `${t("homepage:activeCare.subtitle.nextSession")}: ${formatNextSessionText(nextCoachingAppointment)}`
        : t("homepage:activeCare.subtitle.noUpcomingSession"),
      subtitleLinkText: t("homepage:activeCare.subtitleLinkText"),
      subtitleLinkURL: "MemberAppointmentDetail",
      subtitleLinkQueryParams: { id: nextCoachingAppointment?.id },
      buttonText: t("homepage:activeCare.scheduleSession"),
      buttonCallback: () => openCoachingModal(),
      memberCanBookAppointments: memberCanBookCoachingAppointments,
    },
    careNavigation: {
      imageUrl: nextCareNavigatorAppointment
        ? NonCircleAvatar(
            nextCareNavigatorAppointment?.provider || careNavigator,
          )
        : "/static/DefaultAvatar.png",
      overline:
        hasManagedDependents || isOnTeenHomepage
          ? t("homepage:activeCare.careNavigation.familyOverline")
          : t("homepage:activeCare.careNavigation.overline"),
      icon: <PlantInHand color={careCardIconColor} boxSize={iconSize} />,
      cardTitle: nextCareNavigatorAppointment
        ? nextCareNavigatorAppointment?.provider?.name || careNavigator?.name
        : null,
      subtitle: nextCareNavigatorAppointment
        ? `${t("homepage:activeCare.subtitle.nextSession")}: ${formatNextSessionText(nextCareNavigatorAppointment)}`
        : t("homepage:activeCare.subtitle.noUpcomingSession"),
      subtitleLinkText: t("homepage:activeCare.subtitleLinkText"),
      subtitleLinkURL: "MemberAppointmentDetail",
      subtitleLinkQueryParams: { id: nextCareNavigatorAppointment?.id },
      buttonText: nextCareNavigatorAppointment
        ? t("homepage:activeCare.scheduleSession")
        : t("homepage:activeCare.scheduleCall"),
      buttonCallback: () => {
        Router.push(routes.CareNavigation.as);
      },
    },
    specialtyCareNavigation: {
      imageUrl: nextSpecialtyCareNavigatorAppointment
        ? NonCircleAvatar(
            nextSpecialtyCareNavigatorAppointment?.provider ||
              specialtyCareNavigator,
          )
        : "/static/DefaultAvatar.png",
      overline: hasManagedDependents
        ? t("homepage:activeCare.specialtyCareNavigation.familyOverline")
        : t("homepage:activeCare.specialtyCareNavigation.overline"),
      icon: <PlantInHand color={careCardIconColor} boxSize={iconSize} />,
      cardTitle: nextSpecialtyCareNavigatorAppointment
        ? nextSpecialtyCareNavigatorAppointment?.provider?.name ||
          specialtyCareNavigator?.name
        : null,
      subtitle: nextSpecialtyCareNavigatorAppointment
        ? `${t("homepage:activeCare.subtitle.nextSession")}: ${formatNextSessionText(nextSpecialtyCareNavigatorAppointment)}`
        : t("homepage:activeCare.subtitle.noUpcomingSession"),
      subtitleLinkText: t("homepage:activeCare.subtitleLinkText"),
      subtitleLinkURL: "MemberAppointmentDetail",
      subtitleLinkQueryParams: {
        id: nextSpecialtyCareNavigatorAppointment?.id,
      },
      buttonText: nextSpecialtyCareNavigatorAppointment
        ? t("homepage:activeCare.scheduleSession")
        : t("homepage:activeCare.scheduleCall"),
      buttonCallback: () => {
        if (openSpecializedCNScheduleModal) {
          openSpecializedCNScheduleModal();
        }
      },
    },
    moments: {
      imageSvg: StackedStones,
      overline: t("homepage:activeCare.moments.overline"),
      cardTitle: t("homepage:activeCare.moments.cardTitle"),
      buttonText: t("homepage:activeCare.moments.buttonText"),
      buttonCallback: () => redirectToMomentsOnlyPush(),
    },
    peerRecoverySpecialist: {
      imageUrl: NonCircleAvatar(peerRecoverySpecialist),
      overline: t("homepage:activeCare.peerRecoverySpecialist.overline"),
      icon: (
        <span style={{ marginTop: "5px", display: "flex" }}>
          <SocialFactors color={careCardIconColor} boxSize={iconSize} />
        </span>
      ),
      cardTitle: peerRecoverySpecialist?.name,
      subtitle: nextPeerRecoverySpecialistAppointment
        ? `${t("homepage:activeCare.subtitle.nextSession")}: ${formatNextSessionText(nextPeerRecoverySpecialistAppointment)}`
        : t("homepage:activeCare.subtitle.noUpcomingSession"),
      subtitleLinkText: t("homepage:activeCare.subtitleLinkText"),
      subtitleLinkURL: "MemberAppointmentDetail",
      subtitleLinkQueryParams: {
        id: nextPeerRecoverySpecialistAppointment?.id,
      },
      buttonText: t("homepage:activeCare.scheduleSession"),
      buttonCallback: () => {
        Router.push(
          routes["ScheduleAppointmentProviderDetail"].as.replace(
            ":id",
            peerRecoverySpecialist.id,
          ),
        );
      },
    },
  };

  return (
    <>
      {cnAppointmentLoading ? (
        <Spinner speed="1s" size="xl" />
      ) : (
        <>
          {!isOnTeenHomepage && !hasEverCompletedARecommendedCareItem ? (
            peerRecoverySpecialist &&
            isPeerRecoverySpecialistSupported &&
            showPeerRecoverySpecialistExp ? (
              <Box pb="24">
                <ActiveCareCard
                  {...activeCareCardConfig.peerRecoverySpecialist}
                />
              </Box>
            ) : (
              <ActiveCareNoProvidersCard />
            )
          ) : (
            <>
              {therapist && (
                <Box pb="24">
                  <ActiveCareCard {...activeCareCardConfig.therapy} />
                </Box>
              )}
              {medicationManager && (
                <Box pb="24">
                  <ActiveCareCard
                    {...activeCareCardConfig.medicationManagement}
                  />
                </Box>
              )}
              {isDirectScheduling &&
                coach &&
                !isGlobalWithNoCoachingSessions && (
                  <Box pb="24">
                    <ActiveCareCard {...activeCareCardConfig.coaching} />
                  </Box>
                )}
              {!isSpecialtyCareNavigatorSupported &&
                careNavigator &&
                hasEverHadCareNavigatorAppointment && (
                  <Box pb="24">
                    <ActiveCareCard {...activeCareCardConfig.careNavigation} />
                  </Box>
                )}
              {isSpecialtyCareNavigatorSupported &&
                specialtyCareNavigator &&
                hasEverHadSpecialtyCareNavigatorAppointment && (
                  <Box pb="24">
                    <ActiveCareCard
                      {...activeCareCardConfig.specialtyCareNavigation}
                    />
                  </Box>
                )}
              {peerRecoverySpecialist &&
                isPeerRecoverySpecialistSupported &&
                showPeerRecoverySpecialistExp && (
                  <Box pb="24">
                    <ActiveCareCard
                      {...activeCareCardConfig.peerRecoverySpecialist}
                    />
                  </Box>
                )}
              {includeMoments && (
                <Box pb="24">
                  <ActiveCareCard {...activeCareCardConfig.moments} />
                </Box>
              )}
            </>
          )}
        </>
      )}
      {isShowingActiveCareCards && (
        // NOTE: this is funky, but the -30px rather than -40px max width ends up looking more correct
        <Box pb="24" maxWidth="calc(100vw - 30px)" me={0}>
          <NextLink href="/members/care_visits" passHref>
            <Button
              colorScheme="base"
              variant="low-emphasis"
              as={Link}
              size="md"
              _focusVisible={{ boxShadow: "0 0 0 3px black" }}
            >
              <Text size="body-medium-strong">
                {isOnTeenHomepage
                  ? t("teenHomepage:yourCare.goToMyCarePage")
                  : t("homepage:activeCare.viewAllSessions")}
              </Text>
            </Button>
          </NextLink>
          <CareNavigatorAvailabilityModal
            isOpen={isCNScheduleModalOpen}
            onOpen={openCNScheduleModal}
            onClose={closeCNScheduleModal}
            preventAutoOpen
          />
        </Box>
      )}
      <CareProviderScheduleModalV2
        isOpen={isScheduleModalOpen}
        onClose={onScheduleModalClose}
        {...scheduleModalProps}
      />
    </>
  );
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps, {
  addNotification,
  closeModal,
  openModal,
})(ActiveCareSection);
