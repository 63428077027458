import { format } from "date-fns/format";
import uniq from "lodash/uniq";
import type {
  AppointmentTimeSlotsType,
  GroupedAppointmentsType,
  TimeSlotType,
} from "modules/MemberDashboard/Scheduling/types";
import type { AvailableCnAppointmentTimeOutputType } from "modules/shared/graphql-codegen/graphql";

const getTimePeriod = (time: string): string => {
  const localHour = new Date(time).getHours();
  if (localHour < 12) {
    return "morning";
  } else if (localHour >= 12 && localHour < 18) {
    return "afternoon";
  } else {
    return "night";
  }
};

export const groupAppointmentsByTimePeriod = (
  appointments: AppointmentTimeSlotsType,
): GroupedAppointmentsType => {
  const groupedAppointments = {
    morning: [],
    afternoon: [],
    night: [],
  };

  if (appointments.length > 0) {
    appointments.forEach((appointment) => {
      const timePeriod = getTimePeriod(appointment.timestamp);
      groupedAppointments[timePeriod].push(appointment);
    });
  }

  return groupedAppointments;
};

export const formatSelectedDay = (selectedDay: Date): string =>
  selectedDay.toLocaleDateString(undefined, {
    weekday: "long",
    month: "long",
    day: "numeric",
  });

export const getUsersTimezone = (showLong = true): string =>
  new Date()
    .toLocaleDateString(undefined, {
      timeZoneName: showLong ? "long" : "short",
    })
    .split(", ")[1];

export const getIncludedDates = (
  allAvailable: AvailableCnAppointmentTimeOutputType[] = [],
): string[] =>
  uniq(
    allAvailable.map((appt) => new Date(appt?.start_time).toLocaleDateString()),
  );

export const getTimeSlotsForDay = (
  selectedDayAppts: AvailableCnAppointmentTimeOutputType[],
): TimeSlotType[] =>
  selectedDayAppts.map((appt) => ({
    timestamp: appt.start_time,
    formattedTime: format(appt.start_time, "hh:mm aa"),
    payload: {
      careProviderUserId: appt.care_provider.user_id,
      careProviderId: appt.care_provider.id,
    },
  }));

export const sortAppointmentsByDay = (
  appointments: AvailableCnAppointmentTimeOutputType[],
) => [...appointments].sort((a, b) => a.start_time - b.start_time);
