import React from "react";
import { useRouter } from "next/router";
import { useTranslation } from "react-i18next";
import {
  ActionChip,
  VMessageIcon,
  Text,
  IconButton,
  useBreakpointValue,
  Flex,
  NotificationBadge,
} from "@springcare/sh-component-library";
import { useHasUnreadMessages } from "@springcare/sh-messaging-library";

import routes from "routes";
import { TRACK_EVENT } from "utils/mixpanel";

export const MessagesButton = () => {
  const { t } = useTranslation("common");
  const router = useRouter();
  const hasUnread = useHasUnreadMessages();

  const handleMessagesClick = () => {
    router.push(
      {
        pathname: routes.Messages.as,
        query: { from: router.pathname },
      },
      routes.Messages.as,
    );
    TRACK_EVENT.BUTTON_CLICKED(router.asPath, "Messages");
  };

  const CircularNoTextButton = ({ handleMessagesClick }) => {
    return (
      <IconButton
        variant="low-emphasis"
        size="sm"
        colorScheme="neutral"
        borderRadius="30px"
        borderWidth="1px"
        borderStyle="solid"
        aria-label={t("common:navigation.links.messages")}
        onClick={handleMessagesClick}
        fontSize="lg"
        fontWeight={600}
        icon={VMessageIcon}
      />
    );
  };

  const FullTextbutton = ({
    handleMessagesClick,
    hasUnread,
    shouldHideText,
  }) => {
    return (
      <ActionChip
        variant="low-emphasis"
        colorScheme="base"
        icon={VMessageIcon}
        onClick={handleMessagesClick}
      >
        <Flex alignItems="center" gap={4}>
          {!shouldHideText && (
            <Text size="body-medium-strong">
              {t("common:navigation.links.messages")}
            </Text>
          )}
          {hasUnread && (
            <NotificationBadge
              type="indicator"
              colorScheme="negative"
              variant="high-emphasis"
            />
          )}
        </Flex>
      </ActionChip>
    );
  };

  const shouldHideText = useBreakpointValue({ base: true, xl: false });

  if (!router) {
    return null;
  }

  return shouldHideText && !hasUnread ? (
    <CircularNoTextButton handleMessagesClick={handleMessagesClick} />
  ) : (
    <FullTextbutton
      handleMessagesClick={handleMessagesClick}
      shouldHideText={shouldHideText}
      hasUnread={hasUnread}
    />
  );
};
