//
import { Box } from "@springcare/sh-component-library";
import { LoadingDisplay } from "components/templates/CostEstimation/components";
import * as constants from "components/templates/CostEstimation/constants";
import {
  ModalInitialDisplay,
  InsuranceFormWrapper,
  ModalSelfPayDisplay,
} from "./components";

export type MemberInsuranceData = {
  user: User;
};
type User = {
  id: string;
  member: Member;
};
type Member = {
  id: string;
  payment_preference: string;
  visits_remaining_total: number;
  insurance_policy: InsurancePolicy;
  cohort: Cohort;
};
type InsurancePolicy = {
  id: string;
  carrier_name: string;
  insurance_group_id: string;
  insurance_member_id: string;
  plan_name: string;
  insurance_card_front_id?: string;
  insurance_card_back_id?: string;
  cost_estimate_requested_date?: string;
};
type Cohort = {
  in_network: boolean;
};

export const PaymentInfoUpdateWrapper = ({
  closeModal,
  displayState,
  setDisplayState,
  wrappedMemberInsuranceData,
  isMemberInsuranceLoading,
}) => {
  // NOTE: would be for telemetry
  const trackingProperties = {
    page_version: "Request cost estimate",
  };

  return (
    <>
      {/* Conditional rendering of displays depending on state below */}
      {displayState === constants.COST_ESTIMATE_DISPLAY_STATES.LOADING && (
        <LoadingDisplay isMemberInsuranceLoading={isMemberInsuranceLoading} />
      )}
      {displayState ===
        constants.COST_ESTIMATE_DISPLAY_STATES.INITIAL_DISPLAY && (
        <ModalInitialDisplay
          trackingProperties={trackingProperties}
          changeToInsuranceFormDisplayCallback={() => {
            setDisplayState(
              constants.COST_ESTIMATE_DISPLAY_STATES.INSURANCE_FORM,
            );
          }}
          changeToSelfPayDisplayCallback={() => {
            setDisplayState(
              constants.COST_ESTIMATE_DISPLAY_STATES.SELF_PAY_DISPLAY,
            );
          }}
          handleSkipToSchedulingModal={closeModal}
        />
      )}
      {displayState ===
        constants.COST_ESTIMATE_DISPLAY_STATES.INSURANCE_FORM && (
        <InsuranceFormWrapper
          memberInsuranceInfo={wrappedMemberInsuranceData}
          changeDisplay={closeModal}
        />
      )}
      {displayState ===
        constants.COST_ESTIMATE_DISPLAY_STATES.SELF_PAY_DISPLAY && (
        <Box mb={16}>
          <ModalSelfPayDisplay
            memberId={wrappedMemberInsuranceData?.user.member.id}
            handleDisplayChange={closeModal}
          />
        </Box>
      )}
    </>
  );
};
