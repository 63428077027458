import { Center, Box, Skeleton } from "@springcare/sh-component-library";
import { SHHeader } from "design-system/components";
export const LoadingState = () => {
  return (
    <>
      <Box
        style={{
          position: "sticky",
          top: 0,
          background: "white",
          zIndex: 1,
        }}
      >
        {/* @ts-ignore */}
        <SHHeader
          sliderDismissed
          isLoggedIn
          displayBackBtn={() => false}
          showSplitHeaderDesktop={false}
          showLanguageSelect={false}
        />
      </Box>
      <Center marginTop="50px">
        <Skeleton height="150px" width="30%" borderRadius="10px" />
      </Center>
      {Array(4)
        .fill(true)
        .map((_item, index) => {
          return (
            <Center key={index} marginTop="50px">
              <Skeleton height="50px" width="30%" borderRadius="10px" />
            </Center>
          );
        })}
    </>
  );
};
