import { useEffect } from "react";
import { SHFullScreenModal } from "design-system/components";
import {
  FilterChip,
  Link,
  Stack,
  Text,
  VCallIcon,
  VCareNavigatorDuotoneSpot,
  useDisclosure,
  Box,
} from "@springcare/sh-component-library";
import { getModalNameForTracking } from "@spring/constants";
import { useHelpInfo } from "hooks/useHelpInfo";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import { getMemberAddress } from "operations/queries/member";
import Meowth from "@spring/meowth";
import { TRACK_EVENT } from "utils/mixpanel";
import { getPhoneStyleForRTL } from "shared/utils/getPhoneStyleForRTL";
import { formatPhoneForDisplay } from "utils/global";

export type ModalVariantType =
  | "StandardSuicidePrevention"
  | "NonImmediateSuicidePrevention"
  | "ImmediateSuicidePrevention";

type RiskBasedSuicidePreventionModalProps = {
  /**
   * Whether or not the modal is open
   */
  isOpen: boolean;
  /**
   * Optional close modal function if there are other actions desired on close
   */
  closeModalCallback?: () => void;
  /**
   * The value from the API response which determines which variant of this modal to show
   */
  variant: ModalVariantType;
};

const RiskBasedSuicidePreventionModal = ({
  isOpen,
  closeModalCallback,
  variant,
}: RiskBasedSuicidePreventionModalProps) => {
  useEffect(() => {
    if (isOpen) {
      TRACK_EVENT.MODAL_OPENED(
        window.location.pathname,
        getModalNameForTracking(variant),
        {
          spring_doc_id: "suicidepreventionmodal001",
        },
      );
    }
  }, [isOpen, variant]);

  const { onClose } = useDisclosure();
  const memberAddress = useQuery(getMemberAddress, {
    ...Meowth.apolloOptionsUserId(),
    skip: Meowth.apolloSkipUserId(),
  });

  const getTranslationKey = () => {
    switch (variant) {
      case "NonImmediateSuicidePrevention":
        return "mediumRisk";
      case "ImmediateSuicidePrevention":
        return "highRisk";
      default:
        return "lowRisk";
    }
  };

  const { helpInfo } = useHelpInfo();
  const crisisSupportNumber = helpInfo?.region?.crisis_support_number;
  const translationKey = getTranslationKey();

  const { t } = useTranslation("suicidePreventionModal");

  const handleModalClose = () => {
    TRACK_EVENT.MODAL_CLOSED(
      window.location.pathname,
      getModalNameForTracking(variant),
      {
        spring_doc_id: "suicidepreventionmodal002",
      },
    );
    closeModalCallback && closeModalCallback();
    onClose();
  };

  const content = () => {
    return (
      <Stack>
        <Text size="body-medium-regular">
          {t(`${translationKey}.supportHeading`)}
        </Text>
        <Text size="body-medium-regular" mt="8px">
          {t(`${translationKey}.supportSubHeading`)}
        </Text>
        {variant === "ImmediateSuicidePrevention" && (
          <>
            <Text size="body-medium-strong">
              {t(`${translationKey}.emphasizedContent`)}
            </Text>
            <Text size="body-medium-regular">
              {t(`${translationKey}.hereToHelpNote`)}
            </Text>
          </>
        )}
      </Stack>
    );
  };

  const handleViewDetailsClicked = () => {
    TRACK_EVENT.LINK_CLICKED(
      window.location.pathname,
      `tel:+${crisisSupportNumber}`,
      "Crisis Support",
      {
        spring_doc_id: "assessment04",
        location: getModalNameForTracking(variant) + " Modal",
      },
    );
  };

  const ctaBtn = () => {
    return (
      <Stack mt={"v-20"}>
        <Text size="body-medium-strong">{t("careTeamLine")}</Text>
        <Link
          href={`tel:+${crisisSupportNumber}`}
          tabIndex={1}
          style={{ textDecoration: "none" }}
          onClick={handleViewDetailsClicked}
        >
          <FilterChip
            variant="medium-emphasis"
            size="sm"
            icon={VCallIcon}
            tabIndex={-1}
          >
            <Box style={getPhoneStyleForRTL()}>
              {formatPhoneForDisplay(crisisSupportNumber)}
            </Box>
          </FilterChip>
        </Link>
      </Stack>
    );
  };

  return (
    <SHFullScreenModal
      ModalImg={VCareNavigatorDuotoneSpot}
      dataCy={`${variant}-modal-close-button`}
      ctaBtn={ctaBtn()}
      title={t("heading")}
      content={content()}
      btnCopy={t("return")}
      isOpen={!memberAddress.loading && isOpen}
      onClose={() => handleModalClose()}
      bodyDisplay="flex"
    />
  );
};

export default RiskBasedSuicidePreventionModal;
