import { createIcon } from "@springcare/sh-component-library";

export const InPerson = createIcon({
  displayName: "InPerson",
  viewBox: "0 0 24 24",
  path: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 5C9.79086 5 8 6.79086 8 9C8 11.2091 9.79086 13 12 13C14.2091 13 16 11.2091 16 9C16 6.79086 14.2091 5 12 5ZM10 9C10 7.89543 10.8954 7 12 7C13.1046 7 14 7.89543 14 9C14 10.1046 13.1046 11 12 11C10.8954 11 10 10.1046 10 9Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.3117 23.7255C11.3119 23.7257 11.3121 23.7258 12 23L11.3117 23.7255C11.6975 24.091 12.3021 24.0914 12.6879 23.7258L12 23C12.6879 23.7258 12.6877 23.726 12.6879 23.7258L12.6888 23.7249L12.6905 23.7233L12.6961 23.718L12.7157 23.6992C12.7325 23.6831 12.7567 23.6597 12.7877 23.6295C12.8498 23.569 12.9394 23.4808 13.0526 23.367C13.279 23.1396 13.6 22.8095 13.9842 22.394C14.7516 21.5641 15.7763 20.3877 16.8033 19.0024C18.817 16.2862 21 12.5577 21 9C21 4.02944 16.9706 0 12 0C7.02944 0 3 4.02944 3 9C3 12.5577 5.18295 16.2862 7.19668 19.0024C8.22368 20.3877 9.24843 21.5641 10.0158 22.394C10.4 22.8095 10.721 23.1396 10.9474 23.367C11.0606 23.4808 11.1502 23.569 11.2123 23.6295C11.2433 23.6597 11.2675 23.6831 11.2843 23.6992L11.3039 23.718L11.3095 23.7233L11.3117 23.7255ZM5 9C5 5.13401 8.13401 2 12 2C15.866 2 19 5.13401 19 9C19 11.8606 17.183 15.1321 15.1967 17.8113C14.2237 19.1238 13.2484 20.2439 12.5158 21.0361C12.3257 21.2417 12.1523 21.4248 12 21.583C11.8477 21.4248 11.6743 21.2417 11.4842 21.0361C10.7516 20.2439 9.77632 19.1238 8.80332 17.8113C6.81705 15.1321 5 11.8606 5 9Z"
        fill="currentColor"
      />
    </>
  ),
  defaultProps: {
    "aria-label": "In person meeting icon",
    role: "img",
    color: "tertiary.700",
  },
});
