import { createIcon } from "@springcare/sh-component-library";

export const CalendarPrimary = createIcon({
  displayName: "Calendar",
  viewBox: "0 0 24 24",
  path: (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
      <path
        fill="#F1FCF9"
        d="M7 15v2H5v-2h2ZM11 11H9v2h2v-2ZM9 15h2v2H9v-2ZM15 11h-2v2h2v-2ZM13 15h2v2h-2v-2ZM19 13v-2h-2v2h2Z"
      />
      <path
        fill="#F1FCF9"
        d="M7 1a1 1 0 0 1 1 1v1h8V2a1 1 0 1 1 2 0v1h2a3 3 0 0 1 3 3v12a3 3 0 0 1-3 3H4a3 3 0 0 1-3-3V6a3 3 0 0 1 3-3h2V2a1 1 0 0 1 1-1Zm9 4H8v1a1 1 0 1 1-2 0V5H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1V6a1 1 0 0 0-1-1h-2v1a1 1 0 1 1-2 0V5Z"
      />
    </svg>
  ),
  defaultProps: {
    "aria-label": "Calendar icon",
    role: "img",
    color: "tertiary.700",
  },
});
