import { useGetWorkplaceCustomization } from "modules/MemberDashboard/ManagerExperience/Workplace/hooks/useGetWorkplaceCustomization";
import {
  GLOBAL_SUPPORT_EMAIL,
  GLOBAL_SUPPORT_EMAIL_T1_T2,
} from "modules/MemberDashboard/ManagerExperience/shared/components/ContactUs/utils/ContactUsSection.utils";
import { links } from "@spring/constants";
import { useTranslation } from "hooks/react-i18next";
import { WorkplaceCustomizationInput } from "modules/shared/graphql-codegen/graphql";
import {
  CriticalIncidentInfo,
  ManagerConsultInfo,
} from "modules/MemberDashboard/ManagerExperience/Workplace/types/types";

export const useWorkplaceCustomizationData = (
  customer_id: string,
  crisisSupportNumber: string,
) => {
  const { t } = useTranslation("managerExperience");

  const {
    workplaceCustomizationData,
    isWorkplaceCustomizationLoading,
    isManagerExperienceMilestone2Enabled,
  } = useGetWorkplaceCustomization(customer_id);

  //TODO: The code from L24-L56 is temp and will be removed https://springhealth.atlassian.net/browse/MXE-784
  const defaultWorkplaceCustomization = {
    isWorkplaceCustomizationLoading: false,
    customerHasWorkplaceEnabled: true, //NOTE: Since MANAGER_EXPERIENCE_MILESTONE_2 is false, we are setting this to true to mimic prod behavior
    customerHasEmployerServicesEnabled: true, //NOTE: Since MANAGER_EXPERIENCE_MILESTONE_2 is false, we are setting this to true to mimic prod behavior
    customerHasManagerConsultEnabled: false,
    customerHasCriticalIncidentsEnabled: false,
    shouldShowManagerConsult: true, //NOTE: Since MANAGER_EXPERIENCE_MILESTONE_2 is false, we are setting this to true to mimic prod behavior
    shouldShowCriticalIncidents: true, //NOTE: Since MANAGER_EXPERIENCE_MILESTONE_2 is false, we are setting this to true to mimic prod behavior
    shouldShowReferralContents: true, //NOTE: Since MANAGER_EXPERIENCE_MILESTONE_2 is false, we are setting this to true to mimic prod behavior
    generalConsultationsReferralInfo: {
      //Setting default values for generalConsultationsReferrals to true to match prod
      shouldShowFitnessForDutyReferral: true,
      shouldShowFormalReferral: true,
      shouldShowInformalReferral: true,
      shouldShowMandatoryReferral: true,
      showShowSubstanceAbuseReferral: true,
    },
    criticalIncidentEmailAddress: GLOBAL_SUPPORT_EMAIL_T1_T2,
    criticalIncidentFormLink: links.WorkplaceCriticalIncidentResponse,
    criticalIncidentPhoneNumber: crisisSupportNumber,
    criticalIncidentDialOption: t("contactUsCard.press2Extension"),
    managerConsultEmailAddress: GLOBAL_SUPPORT_EMAIL,
    managerConsultFormLink: links.WorkplaceManagementConsult,
    managerConsultPhoneNumber: crisisSupportNumber,
    managerConsultDialOption: t("contactUsCard.press4Then2Extension"),
    managerConsultSubstituteTerm: t("defaultManagerConsultantTerm"),
    employeeSubstituteTerm: t("defaultEmployeeUppercaseTerm"),
    managerSubstituteTerm: t("defaultManagerUppercaseTerm"),
    leaderCourseURL: "",
    leaderCourseTitle: t("leaderCourse.title"),
    leaderCourseDescription: t("leaderCourse.description"),
  };

  if (!isManagerExperienceMilestone2Enabled) {
    return defaultWorkplaceCustomization;
  }

  const workplaceData: WorkplaceCustomizationInput =
    workplaceCustomizationData?.customer_workplace_customization;

  const customerHasWorkplaceEnabled = workplaceData?.workplace_enabled;

  const customerHasEmployerServicesEnabled =
    workplaceData?.employer_services_enabled;

  const customerHasManagerConsultEnabled =
    workplaceData?.manager_consult_enabled;

  const customerHasCriticalIncidentsEnabled =
    workplaceData?.critical_incident_response_enabled;

  const shouldShowManagerConsult =
    customerHasEmployerServicesEnabled && customerHasManagerConsultEnabled;

  const shouldShowCriticalIncidents =
    customerHasEmployerServicesEnabled && customerHasCriticalIncidentsEnabled;

  const generalConsultationsReferralInfo = {
    shouldShowFitnessForDutyReferral:
      workplaceData?.fitness_for_duty_referral_type_enabled,
    shouldShowFormalReferral: workplaceData?.formal_referral_type_enabled,
    shouldShowInformalReferral: workplaceData?.informal_referral_type_enabled,
    shouldShowMandatoryReferral: workplaceData?.mandatory_referral_type_enabled,
    showShowSubstanceAbuseReferral:
      workplaceData?.substance_abuse_referral_type_enabled,
  };

  const criticalIncidentInfo: CriticalIncidentInfo = shouldShowCriticalIncidents
    ? {
        criticalIncidentEmailAddress:
          workplaceData?.critical_incident_email ??
          defaultWorkplaceCustomization.criticalIncidentEmailAddress,
        criticalIncidentFormLink:
          workplaceData?.critical_incident_form_link ??
          defaultWorkplaceCustomization.criticalIncidentFormLink,
        criticalIncidentPhoneNumber:
          workplaceData?.critical_incident_phone_number ??
          defaultWorkplaceCustomization.criticalIncidentPhoneNumber,
        criticalIncidentDialOption:
          workplaceData?.critical_incident_phone_extension ??
          defaultWorkplaceCustomization.criticalIncidentDialOption,
      }
    : {
        criticalIncidentEmailAddress:
          defaultWorkplaceCustomization.criticalIncidentEmailAddress,
        criticalIncidentFormLink:
          defaultWorkplaceCustomization.criticalIncidentFormLink,
        criticalIncidentPhoneNumber:
          defaultWorkplaceCustomization.criticalIncidentPhoneNumber,
        criticalIncidentDialOption:
          defaultWorkplaceCustomization.criticalIncidentDialOption,
      };

  const managerConsultationInfo: ManagerConsultInfo = shouldShowManagerConsult
    ? {
        managerConsultEmailAddress:
          workplaceData?.manager_consult_email ??
          defaultWorkplaceCustomization.managerConsultEmailAddress,
        managerConsultFormLink:
          workplaceData?.manager_consult_form_link ??
          defaultWorkplaceCustomization.managerConsultFormLink,
        managerConsultPhoneNumber:
          workplaceData?.manager_consult_phone_number ??
          defaultWorkplaceCustomization.managerConsultPhoneNumber,
        managerConsultDialOption:
          workplaceData?.manager_consult_phone_extension ??
          defaultWorkplaceCustomization.managerConsultDialOption,
        managerConsultSubstituteTerm:
          workplaceData?.manager_consult_term ??
          defaultWorkplaceCustomization.managerConsultSubstituteTerm,
      }
    : {
        managerConsultEmailAddress:
          defaultWorkplaceCustomization.managerConsultEmailAddress,
        managerConsultFormLink:
          defaultWorkplaceCustomization.managerConsultFormLink,
        managerConsultPhoneNumber:
          defaultWorkplaceCustomization.managerConsultPhoneNumber,
        managerConsultDialOption:
          defaultWorkplaceCustomization.managerConsultDialOption,
        managerConsultSubstituteTerm:
          defaultWorkplaceCustomization.managerConsultSubstituteTerm,
      };

  const leaderCourseInfo = workplaceData?.leader_course_url
    ? {
        leaderCourseURL: workplaceData?.leader_course_url,
        leaderCourseTitle:
          workplaceData?.leader_course_title ??
          defaultWorkplaceCustomization.leaderCourseTitle,
        leaderCourseDescription:
          workplaceData?.leader_course_description ??
          defaultWorkplaceCustomization.leaderCourseDescription,
      }
    : false;

  const employeeSubstituteTerm = workplaceData?.term_employee_known_as;

  const managerSubstituteTerm = workplaceData?.term_manager_known_as;

  const shouldShowReferralContents =
    generalConsultationsReferralInfo.shouldShowFitnessForDutyReferral ||
    generalConsultationsReferralInfo.shouldShowFormalReferral ||
    generalConsultationsReferralInfo.shouldShowInformalReferral ||
    generalConsultationsReferralInfo.shouldShowMandatoryReferral ||
    generalConsultationsReferralInfo.showShowSubstanceAbuseReferral;

  return {
    isWorkplaceCustomizationLoading,
    customerHasWorkplaceEnabled,
    customerHasEmployerServicesEnabled,
    customerHasManagerConsultEnabled,
    customerHasCriticalIncidentsEnabled,
    shouldShowManagerConsult,
    shouldShowCriticalIncidents,
    generalConsultationsReferralInfo,
    criticalIncidentInfo,
    managerConsultationInfo,
    employeeSubstituteTerm,
    managerSubstituteTerm,
    leaderCourseInfo,
    shouldShowReferralContents,
  };
};
