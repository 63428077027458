import {
  createContext,
  useState,
  useMemo,
  useContext,
  Dispatch,
  useEffect,
} from "react";
import { useRouter } from "next/router";
/**
 * NOTE - There is an issue with the import of useMediaQuery due to the work in
 * https://github.com/SpringCare/member-portal/pull/6523
 *
 * For some reason, importing useMediaQuery from design-system/components breaks tests, while
 * importing it from design-system/components/base works fine. As one example, using the old
 * import path causes the tests for NoShowReschedulePage to break with the following failure:
 *
 * >> TypeError: (0 , _router).withRouter is not a function
 */
import { useMediaQuery } from "design-system/components/base";
import { useTranslation } from "react-i18next";

import type { AvailableSSOClientOption } from "actions/auth";

export const STEPS = {
  WELCOME_SCREEN: "WELCOME_SCREEN", // promo mobile swiper
  EMAIL_VERIFICATION: "EMAIL_VERIFICATION", // find CL by email
  COVERED_LIFE_VERIFICATION: "COVERED_LIFE_VERIFICATION", // find CL by email OR fname,lname,dob
  ENHANCED_VERIFICATION: "ENHANCED_VERIFICATION", // find CL by fname,lname,dob
  CREATE_ACCOUNT: "CREATE_ACCOUNT", // signup w/o address
  SHORTENED_CREATE_ACCOUNT: "SHORTENED_CREATE_ACCOUNT",
  AUTOMATIC_ACCOUNT_CREATION: "AUTOMATIC_ACCOUNT_CREATION", // automatic account creation
  ACCOUNT_CREATED_CONTACT_INFO: "ACCOUNT_CREATED_CONTACT_INFO", // finish signup w/ address
  LOGIN: "LOGIN",
  PRIMARY_OR_DEPENDENT: "PRIMARY_OR_DEPENDENT", // user chooses if they are Primary or Dependent
  LOOKUP_VERIFICATION: "LOOKUP_VERIFICATION",
  MANUAL_VERIFICATION: "MANUAL_VERIFICATION",
  THANK_YOU: "THANK_YOU",
  EMERGENCY_CONTACT: "EMERGENCY_CONTACT",
  LOGIN_VIA_SSO_PROVIDER: "LOGIN_VIA_SSO_PROVIDER",
  SIGNUP_VIA_SSO_PROVIDER: "SIGNUP_VIA_SSO_PROVIDER",
  SELECT_SSO_PROVIDER: "SELECT_SSO_PROVIDER",
};

const SIGNUP_SOURCE = {
  INVITE: "invite",
  SSO: "sso",
  COLD: "cold",
};

export enum REGISTRATION_STRATEGIES {
  DEPENDENT,
  EMAIL,
  NO_EMAIL,
}

interface SsoData {
  provider: string | string[] | null;
  external_user_id: string | string[] | null;
  relay_token: string | string[] | null;
  auth_message: string | string[] | null;
}

interface SsoAttributesData {
  city: string | null;
  date_of_birth: string | null; // Will always be YYYY-MM-DD format if set
  email: string | null;
  external_provider_id: string | null; // SSO gateway (i.e. jpmc / castlight / etc)
  external_user_id: string | null; // Unique external IDP ID (i.e uuid / email / unique integer)
  first_name: string | null;
  last_name: string | null;
  state: string | null; // Should be a 2 digit state code (i.e. MI, NY, FL, etc...)
  street_address: string | null;
  zip_code: string | null;
  phone: string | null;
  phone_country_code: string | null;
}

interface PotentialCL extends Partial<SsoData> {
  email?: string;
  id?: string;
  first_name?: string;
  last_name?: string;
  date_of_birth?: string;
  month?: string;
  day?: string;
  year?: string;
  country?: string;
  signup_source?: string;
}

const defaultSetSsoData: Dispatch<SsoData> = () => {};
const defaultSetSignUpSource: Dispatch<any> = () => {};
const isMobileTrueDefaultValue: boolean | null = null;
const defaultSetRegistrationStrategy: Dispatch<
  REGISTRATION_STRATEGIES
> = () => {};

interface RegContextValues {
  global: any;
  setGlobal: Dispatch<any>;
  coveredLife: any;
  setCoveredLife: Dispatch<any>;
  potentialCL: PotentialCL;
  setPotentialCL: Dispatch<PotentialCL>;
  user: any;
  setUser: Dispatch<any>;
  member: any;
  setMember: Dispatch<any>;
  accountCreation: any;
  onAccountCreation: Dispatch<any>;
  currentView: string;
  setCurrentView: Dispatch<any>;
  isDependentObj: boolean | null;
  setIsDependentObj: Dispatch<any>;
  notification: any;
  setNotification: Dispatch<any>;
  isMobileTrue: boolean | null;
  signupSource: any;
  setSignUpSource: Dispatch<any>;
  ssoData: SsoData;
  setSsoData: Dispatch<SsoData>;
  ssoAttributesTransformed: SsoAttributesData;
  setSsoAttributesTransformed: Dispatch<any>;
  STEPS: typeof STEPS;
  t: (x: string) => string;
  isDependentOrNoEmail: boolean | null;
  setIsDependentOrNoEmail: Dispatch<any>;
  SIGNUP_SOURCE: typeof SIGNUP_SOURCE;
  providedDateOfBirth: string;
  setProvidedDateOfBirth: Dispatch<any>;
  REGISTRATION_STRATEGIES: typeof REGISTRATION_STRATEGIES;
  setRegistrationStrategy: Dispatch<REGISTRATION_STRATEGIES>;
  registrationStrategy: REGISTRATION_STRATEGIES | null;
  isWhitelistEmail: boolean;
  setIsWhitelistEmail: Dispatch<any>;
  setSsoClientOptions: Dispatch<AvailableSSOClientOption[] | null>;
  ssoClientOptions: AvailableSSOClientOption[] | null;
}

// NOTE: Look into what can become AuthContext/GlobalContext, what stays as shared RegisterContext,
// what goes back to local register context.

// DO NOT ADD SHARED GLOBAL CONTEXT VALUES HERE - Create a different context for that.

const defaultValue: RegContextValues = {
  global: null,
  setGlobal: () => {},
  coveredLife: null,
  setCoveredLife: () => {},
  potentialCL: null,
  setPotentialCL: () => {},
  user: null,
  setUser: () => {},
  member: null,
  setMember: () => {},
  accountCreation: null,
  onAccountCreation: () => {},
  currentView: "",
  setCurrentView: () => {},
  isDependentObj: false,
  setIsDependentObj: () => {},
  notification: null,
  setNotification: () => {},
  isMobileTrue: isMobileTrueDefaultValue,
  signupSource: null,
  setSignUpSource: defaultSetSignUpSource,
  ssoData: null,
  setSsoData: defaultSetSsoData,
  ssoAttributesTransformed: null,
  setSsoAttributesTransformed: () => {},
  STEPS: STEPS,
  t: (text: string) => {
    return text;
  },
  isDependentOrNoEmail: null,
  setIsDependentOrNoEmail: () => {},
  providedDateOfBirth: null,
  setProvidedDateOfBirth: () => {},
  SIGNUP_SOURCE: SIGNUP_SOURCE,
  REGISTRATION_STRATEGIES: REGISTRATION_STRATEGIES,
  setRegistrationStrategy: defaultSetRegistrationStrategy,
  registrationStrategy: null,
  isWhitelistEmail: false,
  setIsWhitelistEmail: () => {},
  ssoClientOptions: null,
  setSsoClientOptions: () => {},
};

const RegContext = createContext(defaultValue);

export function RegisterContext({ children }) {
  const router = useRouter();
  // context state setup
  const [global, setGlobal] = useState({
    country: "US",
    lang: "en",
    showGlobalExperience: "false",
    country_code: "1",
  });
  const [coveredLife, setCoveredLife] = useState(null);
  const [potentialCL, setPotentialCL] = useState(null);
  const [user, setUser] = useState(null);
  const [member, setMember] = useState(null);
  const [accountCreation, onAccountCreation] = useState(null);
  const [currentView, setCurrentView] = useState<string>("");
  const [providedDateOfBirth, setProvidedDateOfBirth] = useState<string>("");
  const [isDependentObj, setIsDependentObj] = useState(null);
  const [isDependentOrNoEmail, setIsDependentOrNoEmail] =
    useState<boolean>(false);
  const [notification, setNotification] = useState(null);
  const [signupSource, setSignUpSource] = useState(null);
  const [ssoData, setSsoData] = useState<SsoData>(null);
  const [ssoAttributesTransformed, setSsoAttributesTransformed] =
    useState<SsoAttributesData>(null);
  const [ssoClientOptions, setSsoClientOptions] = useState<
    AvailableSSOClientOption[] | null
  >(null);

  const [isMobile] = useMediaQuery(
    "(max-width: 767px) and (orientation: portrait)",
  );
  const { t } = useTranslation(["limitedLangRegister", "common"]);
  const [registrationStrategy, setRegistrationStrategy] =
    useState<REGISTRATION_STRATEGIES>(null);
  const [isWhitelistEmail, setIsWhitelistEmail] = useState(false);

  // context updates uses useMemo, which cuts down redundant re-renders
  const globalValue = useMemo(() => ({ global, setGlobal }), [global]);
  const clValue = useMemo(
    () => ({ coveredLife, setCoveredLife }),
    [coveredLife],
  );
  const potentialCLValue = useMemo(
    () => ({ potentialCL, setPotentialCL }),
    [potentialCL],
  );

  useEffect(() => {
    return () => {
      if (currentView === "" && router?.query?.page === "no-email") {
        setRegistrationStrategy(REGISTRATION_STRATEGIES.NO_EMAIL);
        setCurrentView(STEPS.ENHANCED_VERIFICATION);
      } else if (currentView === "" && router?.query?.page === "dependent") {
        setIsDependentOrNoEmail(true);
        setRegistrationStrategy(REGISTRATION_STRATEGIES.DEPENDENT);
        setCurrentView(STEPS.ENHANCED_VERIFICATION);
      }
    };
  }, [currentView]);

  const userValue = useMemo(() => ({ user, setUser }), [user]);

  const memberValue = useMemo(() => ({ member, setMember }), [member]);

  const accountCreationValue = useMemo(
    () => ({ accountCreation, onAccountCreation }),
    [accountCreation],
  );

  const stepValue = useMemo(
    () => ({ currentView, setCurrentView }),
    [currentView],
  );

  const isDependentValue = useMemo(
    () => ({ isDependentObj, setIsDependentObj }),
    [isDependentObj],
  );

  const notificationValue = useMemo(
    () => ({ notification, setNotification }),
    [notification],
  );

  const isDependentOrNoEmailValue = useMemo(
    () => ({ isDependentOrNoEmail, setIsDependentOrNoEmail }),
    [isDependentOrNoEmail],
  );

  const providedDateOfBirthValue = useMemo(
    () => ({ providedDateOfBirth, setProvidedDateOfBirth }),
    [providedDateOfBirth],
  );

  const registrationStrategyValue = useMemo(
    () => ({ registrationStrategy, setRegistrationStrategy }),
    [registrationStrategy],
  );

  const registerContext: RegContextValues = {
    ...globalValue,
    ...clValue,
    ...potentialCLValue,
    ...userValue,
    ...memberValue,
    ...accountCreationValue,
    ...stepValue,
    ...isDependentValue,
    ...notificationValue,
    ...isDependentOrNoEmailValue,
    ...providedDateOfBirthValue,
    ...registrationStrategyValue,
    isMobileTrue: isMobile,
    ssoData,
    setSsoData,
    ssoAttributesTransformed,
    setSsoAttributesTransformed,
    ssoClientOptions,
    setSsoClientOptions,
    setSignUpSource,
    signupSource,
    t,
    REGISTRATION_STRATEGIES,
    STEPS,
    SIGNUP_SOURCE,
    isWhitelistEmail,
    setIsWhitelistEmail,
  };

  return (
    <RegContext.Provider value={registerContext}>
      {children}
    </RegContext.Provider>
  );
}

export function useRegisterContext() {
  return useContext(RegContext);
}
