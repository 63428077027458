import { useRef } from "react";
import { useTranslation } from "react-i18next";
import {
  Flex,
  Box,
  Text,
  VInsuranceIcon,
  VFinancialHelpIcon,
  TextButton,
} from "@springcare/sh-component-library";

import { InteractableCard } from "components/templates/CostEstimation/components/shared";
import { TRACK_EVENT } from "utils/mixpanel";

export const ModalInitialDisplay = ({
  trackingProperties: _trackingProperties, // TODO: currently unused, but checking to see if it *should* be
  changeToInsuranceFormDisplayCallback,
  changeToSelfPayDisplayCallback,
  handleSkipToSchedulingModal,
}) => {
  const { t } = useTranslation("costEstimate");
  const ref = useRef();

  return (
    <Flex
      data-cy="pre-visit-modal"
      ref={ref}
      width="100%"
      height="100%"
      flexDirection="column"
      justifyContent="flex-start"
    >
      <Text mb={16}> {t("modal.initialDisplaySubHeadParagraph")}</Text>
      <Box mb="-16px">
        <InteractableCard
          data-cy="request-cost-estimate-insurance-card"
          cardText={t("modal.addInsuranceCtaText")}
          cardSubtext={t("modal.addInsuranceCtaSubtext")}
          primaryIcon={VInsuranceIcon}
          handleClickCallback={() => {
            TRACK_EVENT.BUTTON_CLICKED(
              window.location.pathname,
              "Add Insurance Info",
              { location: "Insurance Details Prompt Modal" },
            );
            changeToInsuranceFormDisplayCallback();
          }}
        />
      </Box>
      <InteractableCard
        cardText={t("modal.selfPayCtaText")}
        cardSubtext={t("modal.selfPayCtaSubtext")}
        primaryIcon={VFinancialHelpIcon}
        handleClickCallback={() => {
          TRACK_EVENT.BUTTON_CLICKED(window.location.pathname, "Self Pay", {
            location: "Insurance Details Prompt Modal",
          });
          changeToSelfPayDisplayCallback();
        }}
      />
      <TextButton
        data-cy="skip-for-now-button"
        mb={16}
        display="inline-block"
        color="content-primary"
        width="90px"
        onClick={handleSkipToSchedulingModal}
      >
        {t("modal.skipForNow")}
      </TextButton>
    </Flex>
  );
};
