import { useEffect, useState, useRef } from "react";
import { useTranslation } from "hooks/react-i18next";
import {
  Flex,
  Box,
  Image,
  Heading,
  Text,
  useBoolean,
  Spacer,
  SHTooltip as Tooltip,
  useDisclosure,
  useMediaQuery,
  BooksFastBadge,
  MemberFavoriteBadge,
} from "design-system/components";
import { Stars } from "design-system/assets";
import { Badge } from "@springcare/sh-component-library";
import { Form, DedicatedIcon } from "@spring/smeargle";
import { PlantLeaves, Medication, WateringCan } from "design-system/assets";

import { useInViewportOnce } from "hooks";
import AppointmentSection from "./AppointmentSection";

import { trackProviderCardViewed } from "components/templates/Browse/ProviderBrowsePage/analytics";
import { truncateWords } from "@spring/immutability";
import { InNetworkStatusDisclaimer } from "components/molecules";

import { trackBioClicked } from "components/templates/Browse/ProviderBrowsePage/analytics";
import { useProviderBrowseContext } from "context/ProviderBrowseContext";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";
import camelCase from "lodash/camelCase";
import { isEmpty } from "lodash/fp";
import {
  providerProfileClick,
  isBrecsVariant,
  getFakeBadgesTracking,
  providerBooksFast,
  providerMemberFavorite,
} from "components/templates/Browse/ProviderBrowsePage/utils";
import { ProviderRole } from "@spring/constants";
import { Consultations } from "./Consultations";
import ProfileVideoPlayButton from "components/molecules/ProfileVideoPlayer/ProfileVideoPlayButton";
import useProfileVideos from "hooks/useProfileVideos";

type Props = {
  provider: {
    id: string;
    name: string;
    bio: string;
    languages: string[];
    avatar: string;
    tags: any;
    licenses: string[];
    roles: string[];
    associate_status: string;
    care_provider_tags: any;
    on_site: boolean;
    dedicated_customer: {
      name: string;
    };
    top_tags: string[];
    group_name?: string;
    phone?: string;
    first_name?: string;
    email?: string;
    allows_email_consults?: boolean;
    allows_phone_consults?: boolean;
  };
  index: number;
  pageNumber: number;
  queryRequestId?: string;
  user: any;
  providerType: string;
};

const ProviderSubheadSection = ({
  name,
  licenses,
  top_tags,
  associateStatus,
  booksFast,
  memberFavorite,
}) => {
  const [isMobile] = useMediaQuery("(max-width: 767px)");
  const { isOpen, onOpen, onToggle, onClose } = useDisclosure();
  const { t } = useTranslation("careProvider");
  enum AssociateProviderStatus {
    Active = "active",
    InActive = "inactive",
  }
  const isAssociateProvider =
    associateStatus === AssociateProviderStatus.Active;
  const subheadLicenses = isAssociateProvider
    ? licenses?.slice(0, 2)?.join(", ")
    : licenses?.join(", ");
  const { providerType, customRoleName } = useProviderBrowseContext();

  const Icon =
    providerType === ProviderRole.Therapist || isAssociateProvider
      ? PlantLeaves
      : providerType === ProviderRole.MedicationManager
        ? Medication
        : WateringCan;

  let displayRole;
  if (isAssociateProvider) {
    displayRole = ProviderRole.AssociateProvider;
  } else if (providerType === ProviderRole.Therapist) {
    displayRole = ProviderRole.Therapist;
  } else if (providerType === ProviderRole.MedicationManager) {
    displayRole = ProviderRole.MedicationManager;
  } else if (providerType === ProviderRole.Coach) {
    displayRole = ProviderRole.Coach;
  } else if (isEmpty(providerType)) {
    displayRole = customRoleName;
  }

  const therapyTopSpecialtiesFlag =
    useFeatureFlag(FLAGS.THERAPY_TOP_SPECIALTIES) &&
    providerType === ProviderRole.Therapist;
  const translatedRoles = t(`careProvider.${camelCase(displayRole)}`, {
    ns: "careVisits",
  });

  return (
    <Box>
      <Flex justifyContent={["center", "center", "center", "start"]}>
        {booksFast && <BooksFastBadge />}
        {memberFavorite && <MemberFavoriteBadge />}
      </Flex>
      <Heading
        fontFamily={[
          "HKGroteskSemiBold, sans serif",
          "HKGroteskSemiBold, sans serif",
          "HKGroteskSemiBold, sans serif",
          "HKGroteskBold, sans serif",
        ]}
        fontSize={[21, 21, 21, 29]}
        margin={["0 auto", "0 auto", "0 auto", 0]}
        pb={8}
      >
        {name}
      </Heading>

      <Flex
        mb={8}
        alignItems="center"
        flexWrap="wrap"
        justifyContent={isMobile ? "center" : ""}
      >
        <Icon boxSize={24} />
        {isAssociateProvider ? (
          <Tooltip
            label={
              <Text size="body-small-regular">
                {t("benefitsSummary.associateProviderTooltip")}
              </Text>
            }
            placement="top"
            role="tooltip"
            borderRadius={8}
            p={8}
            maxWidth="202px"
            isOpen={isOpen}
          >
            <Text
              variant="body_v1"
              ml={8}
              mr={4}
              borderBottom="1px dashed #00131738"
              as="span"
              onMouseEnter={onOpen}
              onMouseLeave={onClose}
              onClick={onToggle}
            >
              {translatedRoles}
            </Text>
          </Tooltip>
        ) : (
          <Text variant="body_v1" ml={8} mr={4}>
            {translatedRoles}
          </Text>
        )}
        <Text variant="body_v1" as="span">
          {` • ${subheadLicenses}`}
        </Text>
      </Flex>
      {therapyTopSpecialtiesFlag && top_tags?.length > 0 && (
        <Flex
          direction={["column", "row"]}
          textAlign={["center", "left"]}
          alignItems={"baseline"}
        >
          <Text
            whiteSpace={"nowrap"}
            variant="bodyBold_v1"
            width={["100%", "auto"]}
            mr={4}
          >
            {t("topSpecialties")}
          </Text>
          <Text variant="body_v1" color="#737778">
            {[...top_tags]?.sort()?.join(", ")}
          </Text>
        </Flex>
      )}
    </Box>
  );
};

const ProviderBioSection = ({
  provider,
  pageNumber,
  providerIndex,
  queryRequestId,
}) => {
  const { t } = useTranslation(["careProvider", "common"]);
  const isBrecsFlag = isBrecsVariant(
    useFeatureFlag(FLAGS.BRECS_ABC_EXPERIMENT),
  );
  const [isExpanded, setIsExpanded] = useBoolean(false);

  const { selectedFilters } = useProviderBrowseContext();
  const isCouplesFilterSelected =
    selectedFilters?.filters.specialties.includes("Couples");

  const bio = provider?.bio;

  const collapsedBioLength = 68;
  const showExpandButton = bio.trim().split(/\s+/).length > collapsedBioLength;
  const trackingCategory =
    isBrecsFlag && provider?.group_name ? "recommended" : "browse";

  return (
    <Box pb={32}>
      <Text variant="body_v1" pb={8} color="#737778">
        {isExpanded ? bio : truncateWords(collapsedBioLength, bio)}

        {showExpandButton && (
          <Text
            variant="body_v1"
            as="button"
            color="#737778"
            sx={{ textDecoration: "underline" }}
            onClick={(e) => {
              e.stopPropagation();
              providerProfileClick(
                provider.id,
                queryRequestId,
                trackingCategory,
                isCouplesFilterSelected,
              )();

              trackBioClicked({
                provider,
                pageNumber,
                providerIndex,
                isExpanded,
                profileTabFlag: true,
                queryRequestId,
              });
            }}
            cursor="pointer"
            ml={4}
          >
            {isExpanded ? t("bio.hide") : t("bio.see")}
          </Text>
        )}
      </Text>
    </Box>
  );
};

const ProviderLanguagesSection = ({ languages }) => {
  const { t } = useTranslation(["careProvider", "common"]);
  const providerLanguages = [...languages];
  const finalLanguages = providerLanguages
    ? providerLanguages
        .sort(function (a, b) {
          return a.localeCompare(b);
        })
        .join(", ")
    : [];

  return (
    <Box mb={16}>
      <Text variant="bodyBold_v1">{t("language")}</Text>
      <Text variant="body_v1" color="#737778">
        {finalLanguages}
      </Text>
    </Box>
  );
};

const ProviderTagSection = ({ tags }) => {
  const { t } = useTranslation(["careProvider", "common"]);

  return (
    <Box mb={16}>
      <Text variant="bodyBold_v1" mb={8}>
        {t("help")}
      </Text>
      <Flex flexWrap="wrap">
        {tags?.map((tag: any, index: number) => (
          <Box
            w="fit-content"
            mr={8}
            mb={8}
            p={8}
            bg="platform.25"
            color="platform.600"
            borderRadius={8}
            key={index}
          >
            <Text variant="caption_v1">{tag.name}</Text>
          </Box>
        ))}
      </Flex>
    </Box>
  );
};

const ProviderCard = ({
  provider,
  index,
  pageNumber,
  queryRequestId,
  user,
}: Props) => {
  const member = user?.member;
  const { showVideoProfile, videoUrl } = useProfileVideos(provider.id);
  const { providerType, paging } = useProviderBrowseContext();
  const therapyTopSpecialtiesFlag =
    useFeatureFlag(FLAGS.THERAPY_TOP_SPECIALTIES) &&
    providerType === ProviderRole.Therapist;
  const isBrecsFlag =
    isBrecsVariant(useFeatureFlag(FLAGS.BRECS_ABC_EXPERIMENT)) &&
    providerType === ProviderRole.Therapist;
  const supportsProviderConsultation =
    useFeatureFlag(FLAGS.PROVIDER_CONSULTATION) &&
    providerType === ProviderRole.Therapist &&
    (provider?.allows_email_consults || provider?.allows_phone_consults);
  const { t } = useTranslation(["careProvider", "common"]);
  const { providerCardClick, selectedFilters } = useProviderBrowseContext();
  const isCouplesFilterSelected =
    selectedFilters?.filters.specialties.includes("Couples");
  const tagIgnoreKind = ["GENDER", "ETHNICITY", "LANGUAGE"];
  const careTags = provider?.care_provider_tags.filter(
    (tag) => !tagIgnoreKind.includes(tag.kind),
  );
  const sortedTags = careTags?.sort((a, b) => a.name.localeCompare(b.name));
  const trackingCategory =
    isBrecsFlag && provider?.group_name ? "recommended" : "browse";

  let topTags = "";
  if (therapyTopSpecialtiesFlag && provider?.top_tags?.length > 0) {
    topTags = Array.from(provider?.top_tags || [])
      .sort()
      .join(", ");
  }
  const booksFast = providerBooksFast(provider);
  const memberFavorite = providerMemberFavorite(provider);

  const ref = useRef();
  const inViewport = useInViewportOnce(ref, "0px"); // Trigger as soon as the element becomes visible
  const [trackedOnce, setTrackedOnce] = useState(0);

  const apptSectionRef = useRef();
  const apptSectionInView = useInViewportOnce(apptSectionRef, "0px");

  useEffect(() => {
    if (inViewport && trackedOnce === 0) {
      const badges = getFakeBadgesTracking(provider);
      trackProviderCardViewed({
        provider,
        index,
        pageNumber,
        queryRequestId,
        topTags,
        badges,
      });
      setTrackedOnce(1);
    }
  }, [inViewport]);

  const providerOnClick = (provider) => {
    return () => {
      if (
        providerCardClick[provider?.id] &&
        typeof providerCardClick[provider?.id]["tracking"] === "function"
      ) {
        providerCardClick[provider?.id]["tracking"]();
      }
      providerProfileClick(
        provider.id,
        queryRequestId,
        trackingCategory,
        isCouplesFilterSelected,
      )();
    };
  };

  return (
    <Flex
      key={index}
      direction="column"
      justify="space-between"
      borderRadius={12}
      boxShadow="cardShadow"
      px={[24, 24, 24, 48]}
      py={[24, 24, 24, 20]}
      mb={24}
      onClick={providerOnClick(provider)}
      _hover={{
        cursor: "pointer",
      }}
      data-cy={
        provider.name
          ? `provider-card-${provider.name.split(" ").join("-").toLowerCase()}`
          : ""
      }
    >
      {isBrecsFlag && provider?.group_name && paging?.total >= 6 && (
        <Badge icon={Stars} w="fit-content" colorScheme="positive">
          {t("recommendedProviders.preferences.badgeDefaultMatch")}
        </Badge>
      )}
      <Box>
        {provider?.dedicated_customer?.name && (
          <DedicatedIcon
            dedicatedText={`${t("dedicatedText")} ${provider.dedicated_customer.name}`}
          />
        )}
      </Box>
      <Flex direction={["column", "column", "column", "row"]}>
        <Box flex={7} ref={ref}>
          <Flex direction="column" justifyContent={[null, null, null, "start"]}>
            <Box margin={["0 auto", "0 auto", "0 auto", "0"]} py={6}>
              <Flex
                direction={["column", "column", "column", "row"]}
                justifyContent={[null, null, null, "start"]}
                alignItems="center"
              >
                <Box
                  position="relative"
                  width="104px"
                  height="104px"
                  mb={showVideoProfile && [24, 24, 24, 0]}
                >
                  <Image
                    src={provider.avatar}
                    alt={provider.name}
                    width={104}
                    height={104}
                    aria-hidden="true"
                  />

                  {showVideoProfile && (
                    <ProfileVideoPlayButton
                      name={provider.name}
                      videoUrl={videoUrl}
                    />
                  )}
                </Box>

                <Box
                  ml={[0, 0, 0, 24]}
                  mt={[2, 2, 2, 12]}
                  onClick={(e) => e.stopPropagation()}
                >
                  <ProviderSubheadSection
                    name={provider.name}
                    licenses={provider.licenses}
                    top_tags={provider.top_tags}
                    associateStatus={provider.associate_status}
                    booksFast={booksFast}
                    memberFavorite={memberFavorite}
                  />
                </Box>
              </Flex>
            </Box>

            <ProviderBioSection
              {...{
                provider,
                pageNumber,
                providerIndex: index,
                queryRequestId,
              }}
            />

            {!!provider.languages.length && (
              <ProviderLanguagesSection languages={provider.languages} />
            )}

            {!!sortedTags.length && <ProviderTagSection tags={sortedTags} />}
            {!provider?.dedicated_customer?.name && (
              <InNetworkStatusDisclaimer
                showCTA={false}
                inNetwork={member?.cohort?.in_network}
                hasFreeSessions={!!member?.visits_covered_total}
                internationalCohort={member?.cohort?.international}
                customerName={member?.cohort?.customer?.name}
                virtualSessionCostTerm={
                  member?.cohort?.contract_term?.virtual_session_cost_term
                }
                coaching={providerType === ProviderRole.Coach}
                inNetworkDisclaimerConfig={{
                  inNetworkFreeSessions: t(
                    "benefitsSummary.additionalInNetworkSessions",
                    // @ts-ignore
                    { customerName: member?.cohort?.customer?.name },
                  ),
                  inNetworkNoFreeSessions: t(
                    "benefitsSummary.inNetworkSessions",
                    // @ts-ignore
                    { customerName: member?.cohort?.customer?.name },
                  ),
                  notInNetworkFreeSessions: null,
                  notInNetworkNoFreeSessions: null,
                  default: null,
                }}
              />
            )}
          </Flex>
        </Box>

        <Spacer flex={1} />

        <Box ref={apptSectionRef} flex={5} my={16} ml={[0, 32]} mr={[0, 16]}>
          {/* TODO: Replace Smeargle / Redux component */}
          {apptSectionInView && (
            <>
              <Form
                dataCy="appointment-section"
                formKey={`care-provider-schedule-${provider.id}`}
                theme="simple"
              >
                <AppointmentSection
                  provider={provider}
                  providerIndex={index}
                  pageNumber={pageNumber}
                  queryRequestId={queryRequestId}
                  trackingCategory={trackingCategory}
                />
              </Form>
              {supportsProviderConsultation && (
                <Consultations
                  provider={provider}
                  isCard={true}
                  memberName={`${user?.first_name} ${user?.last_name}`}
                />
              )}
            </>
          )}
        </Box>
      </Flex>
    </Flex>
  );
};

export default ProviderCard;
