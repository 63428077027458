import { useRef, useEffect, useMemo } from "react";
import {
  LiveKitRoom,
  RoomAudioRenderer,
  LiveKitRoomProps,
} from "@livekit/components-react";
import { RoomOptions, Room, ConnectionState } from "livekit-client";
import { isSafari } from "react-device-detect";

import { FLAGS, useFeatureFlag } from "utils/launchdarkly";
import { Box, useMediaQuery } from "@springcare/sh-component-library";

import ParticipantPermissionsChecker from "components/templates/SHSessionRoom/components/ParticipantPermissionsChecker";
import PreJoin from "components/templates/SHSessionRoom/views/PreJoin";
import SessionRoomStage from "components/templates/SHSessionRoom/components/SessionRoomStage";
import SessionEndedMember from "components/templates/VirtualSessionView/components/EndSessionView";
import SessionEndedProvider from "components/templates/SHSessionRoom/views/SessionEndedProvider";

import {
  useSessionViewStatus,
  useSessionConnectStatus,
  useParticipantMetadata,
  useSessionDevices,
  useSessionUserActive,
} from "context/SessionRoomContext";

import { SessionViewStatus } from "components/templates/SHSessionRoom/types";
import { ParticipantType } from "components/templates/SHSessionRoom/types";
import { RoomEventHandler } from "components/templates/SHSessionRoom/utils/SHSessionRoomUtils";
import { releaseKeepActive } from "components/templates/SHSessionRoom/utils/keepMemberLoggedIn";

import { useCompassAuthSync } from "./hooks/useCompassAuthSync";
import {
  useSpecificAudioCapture,
  useSpecificVideoCapture,
} from "components/templates/SHSessionRoom/utils/useSpecificDeviceCapture";
import {
  logSessionJoinError,
  logSessionViewStateChanged,
  logSessionPageReloaded,
  logSessionRoomUnmounted,
} from "components/templates/SHSessionRoom/telemetry/datadog";

import { setLiveKitLogExtension } from "components/templates/SHSessionRoom/telemetry/livekitLogExtenstion";

const TypedLiveKitRoom = LiveKitRoom as React.ComponentType<LiveKitRoomProps>;

// NOTE: _loading and _error should pass lint while unused until future
const SHSessionRoom = ({ sessionData, loading: _loading, error: _error }) => {
  const isDebugModeEnabledFF = useFeatureFlag(
    FLAGS.ENABLE_AUDIO_LIVEKIT_LOG_EXTENSION,
  );
  const videoRef = useRef(null);
  const { sessionViewStatus } = useSessionViewStatus();
  const { sessionDevices, devicePermissionsDenied } = useSessionDevices();
  const { selectedAudioInputDevice, selectedVideoDevice } = sessionDevices;

  const { shouldConnect, isConnected, setIsConnected, handleDisconnect } =
    useSessionConnectStatus();
  const { appointmentId, participantId } = useParticipantMetadata();

  const { wakeLock, setWakeLock, isLoggedIn } = useSessionUserActive();

  const [isMobile] = useMediaQuery("(max-width: 450px)");

  const {
    livekit_url,
    jwt,
    member_name,
    participant_type,
    participant_name,
    provider_name,
  } = sessionData;

  useCompassAuthSync(participant_type === ParticipantType.Provider);

  const {
    setParticipantType,
    setLocalParticipantName,
    setRemoteParticipantName,
    setMemberName,
    setProviderName,
    setAppointmentId,
    setParticipantId,
  } = useParticipantMetadata();
  useEffect(() => {
    setParticipantType(participant_type);
    setLocalParticipantName(participant_name);
    setMemberName(member_name);
    setProviderName(provider_name);
    if (participant_type === ParticipantType.Member) {
      setRemoteParticipantName(provider_name);
    } else {
      setRemoteParticipantName(member_name);
    }
    const urlPaths = window.location.pathname.split("/");
    // url is: <envUrl>[0]/session[1]/<appointmentId>[2]/<participantId>[3]
    const participantId = urlPaths[3];
    const appointmentId = urlPaths[2];
    setAppointmentId(appointmentId);
    setParticipantId(participantId);
  }, [sessionData]);

  const PREJOIN_ROOM =
    devicePermissionsDenied ||
    sessionViewStatus === SessionViewStatus.NotStarted ||
    sessionViewStatus === SessionViewStatus.MemberIsWaiting ||
    sessionViewStatus === SessionViewStatus.ProviderNoShow ||
    sessionViewStatus === SessionViewStatus.Connecting;

  const SESSION_IN_PROGRESS =
    isConnected && sessionViewStatus === SessionViewStatus.InProgress;

  const SESSION_ENDED = sessionViewStatus === SessionViewStatus.Ended;
  const SESSION_ENDED_MEMBER =
    SESSION_ENDED && participant_type === ParticipantType.Member;
  const SESSION_ENDED_PROVIDER =
    SESSION_ENDED && participant_type === ParticipantType.Provider;

  const handleError = (error) => {
    logSessionJoinError(error, jwt, appointmentId, participantId, "livekit");
  };

  const audioDeviceConfig = useSpecificAudioCapture(
    sessionDevices.devices.audioinput,
    selectedAudioInputDevice.deviceId,
    selectedAudioInputDevice.label,
  );

  const videoDeviceConfig = useSpecificVideoCapture(
    sessionDevices.devices.videoinput,
    selectedVideoDevice.deviceId,
  );

  const roomOptions = useMemo((): RoomOptions => {
    return {
      audioCaptureDefaults: {
        deviceId: audioDeviceConfig,
      },
      videoCaptureDefaults: {
        deviceId: videoDeviceConfig,
      },
      publishDefaults: {
        stopMicTrackOnMute: true,
      },
    };
  }, [selectedAudioInputDevice.deviceId, selectedVideoDevice.deviceId]);

  const roomRef = useRef<Room | null>(null);

  if (!roomRef.current) {
    roomRef.current = new Room(roomOptions);
  }
  const room = roomRef.current;

  useEffect(() => {
    return () => {
      if (appointmentId && participantId) {
        logSessionRoomUnmounted(appointmentId, participantId);
      }
    };
  }, [appointmentId, participantId]);

  useEffect(() => {
    if (room.state != ConnectionState.Connected) {
      roomRef.current = new Room(roomOptions);
    }
  }, [roomOptions]);

  useEffect(() => {
    if (!isSafari) {
      room.switchActiveDevice(
        "audiooutput",
        sessionDevices.selectedAudioOutputDevice.deviceId ?? "default",
      );
    }
  }, [sessionDevices.selectedAudioOutputDevice.deviceId]);

  const prevSessionViewStatusRef = useRef(sessionViewStatus);

  // sends a DD log if view state changes
  useEffect(() => {
    if (prevSessionViewStatusRef.current !== sessionViewStatus) {
      logSessionViewStateChanged(
        sessionViewStatus,
        appointmentId,
        participantId,
      );
      prevSessionViewStatusRef.current = sessionViewStatus;
    }
  }, [sessionViewStatus]);

  // Saves viewStatus _before_ reload, used for logSessionPageReloaded
  useEffect(() => {
    const handleBeforeUnload = () => {
      sessionStorage.setItem(
        "sessionViewStatusBeforeReload",
        sessionViewStatus,
      );
      // clean the idle-logout lock up
      if (isLoggedIn) {
        releaseKeepActive(wakeLock, setWakeLock);
      }
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [sessionViewStatus]);

  // sends a DD log if page is reloaded
  useEffect(() => {
    if (appointmentId && participantId) {
      const [navEntry] = performance.getEntriesByType("navigation") || [];
      if (navEntry && "type" in navEntry && navEntry.type === "reload") {
        logSessionPageReloaded(
          sessionStorage.getItem("sessionViewStatusBeforeReload"),
          sessionViewStatus,
          appointmentId,
          participantId,
        );
        // clean-up sessionStorage
        sessionStorage.removeItem("sessionViewStatusBeforeReload");
      }
    }
  }, [appointmentId, participantId]);

  useEffect(() => {
    setLiveKitLogExtension(isDebugModeEnabledFF);
  }, [isDebugModeEnabledFF]);

  return (
    <>
      {jwt && livekit_url && (
        <TypedLiveKitRoom
          data-lk-theme="default"
          token={jwt}
          serverUrl={livekit_url}
          connect={shouldConnect}
          onConnected={() => setIsConnected(true)}
          onDisconnected={handleDisconnect}
          onError={handleError}
          room={room}
        >
          {/* NOTE: These are non-UI components that handle the prejoin/room lifecycle */}
          <RoomEventHandler />
          <ParticipantPermissionsChecker videoRef={videoRef} />

          {PREJOIN_ROOM && (
            <PreJoin
              sessionData={sessionData}
              videoRef={videoRef}
              appointmentId={appointmentId}
              isMobile={isMobile}
            />
          )}

          {SESSION_IN_PROGRESS && (
            <Box margin="0 auto" h="100vh" bg="#000" role="main">
              <SessionRoomStage isMobile={isMobile} />
              <RoomAudioRenderer />
            </Box>
          )}
        </TypedLiveKitRoom>
      )}
      {SESSION_ENDED_MEMBER && !isConnected && (
        <SessionEndedMember appointmentId={appointmentId} />
      )}
      {SESSION_ENDED_PROVIDER && !isConnected && (
        <Box role="main">
          <SessionEndedProvider />
        </Box>
      )}
    </>
  );
};

export default SHSessionRoom;
