import {
  Box,
  Flex,
  Text,
  Avatar,
  Divider,
} from "@springcare/sh-component-library";
import { LocalParticipant, RemoteParticipant } from "livekit-client";
import {
  useParticipants,
  useParticipantAttributes,
} from "@livekit/components-react";
import { ParticipantType } from "components/templates/SHSessionRoom/types";
import { useParticipantMetadata } from "context/SessionRoomContext";
import { useTranslation } from "hooks/react-i18next";

const ParticipantRow = ({
  participant,
}: {
  participant: LocalParticipant | RemoteParticipant;
}) => {
  const {
    localParticipantName,
    remoteParticipantName,
    memberName,
    providerName,
  } = useParticipantMetadata();

  const { t } = useTranslation("livekitExperience", {
    keyPrefix: "sessionRoomDrawer.attendeeSection",
  });

  const participantAttributes = useParticipantAttributes({ participant });

  const getName = () => {
    const participantType = participantAttributes?.attributes?.participant_type;
    if (!participantType) {
      return participant.isLocal ? localParticipantName : remoteParticipantName;
    } else if (participantType === ParticipantType.Provider) {
      return providerName;
    } else if (participantType === ParticipantType.Member) {
      return memberName;
    }
    return t("guest");
  };

  const name = getName();

  return (
    <Box key={participant?.sid}>
      <Flex py="v-20" px="v-4">
        <Avatar name={name} />
        <Text ml="v-8" size="body-medium">
          {name}
        </Text>
      </Flex>
      <Divider w="100%" />
    </Box>
  );
};

const AttendeeSection = () => {
  const participants = useParticipants();
  const { t } = useTranslation("livekitExperience", {
    keyPrefix: "sessionRoomDrawer.attendeeSection",
  });

  return (
    <Box px="v-24" pt="v-24">
      <Box>
        <Text size="body-medium-strong">{t("inSession")}</Text>
      </Box>
      {participants.map((participant) => {
        return (
          <ParticipantRow key={participant.sid} participant={participant} />
        );
      })}
    </Box>
  );
};

export default AttendeeSection;
