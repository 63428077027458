import {
  Heading,
  ListItem,
  Text,
  UnorderedList,
  VSubstanceUseDisorderDuotoneSpot,
  VSubstanceUseDisorderFlatSpot,
} from "@springcare/sh-component-library";
import { InformationFlyout } from "components";
import { useTranslation } from "react-i18next";

type SubstanceUseFlyoutType = {
  callback: () => void;
  isAlsoAvailableTile?: boolean;
  isOpen: boolean;
  onClose: () => void;
};

const SubstanceUseFlyoutBody = ({
  isAlsoAvailableTile,
}: {
  isAlsoAvailableTile: boolean;
}) => {
  const { t } = useTranslation("homepage", {
    keyPrefix: "careInfoFlyout.substanceUse",
  });

  return (
    <>
      {isAlsoAvailableTile ? (
        ""
      ) : (
        <>
          <Heading size="heading-medium" mb="v-8">
            {t("whyIsThisRecommendedHeader")}
          </Heading>
          <Text mb={24}>{t("whyIsThisRecommendedBody")}</Text>
        </>
      )}
      <Heading size="heading-small" mb="v-8">
        {t("benefitsHeader")}
      </Heading>
      <Text mb={24}>{t("benefitsBody")}</Text>
      <Heading size="heading-small" mb="v-8">
        {t("whatToExpect.header")}
      </Heading>
      <Text mb={24}>{t("whatToExpect.body")}</Text>
      <Heading size="heading-small" mb="v-8">
        {t("commonConcerns.header")}
      </Heading>
      <UnorderedList mb={24}>
        <ListItem>
          <Text display="inline" size="body-medium-strong">
            {t("commonConcerns.item1Q")}
          </Text>{" "}
          {t("commonConcerns.item1A")}{" "}
        </ListItem>
        <ListItem>
          <Text display="inline" size="body-medium-strong">
            {t("commonConcerns.item2Q")}
          </Text>{" "}
          {t("commonConcerns.item2A")}
        </ListItem>
      </UnorderedList>
    </>
  );
};

export const SubstanceUseFlyout = ({
  callback,
  isAlsoAvailableTile = false,
  isOpen,
  onClose,
}: SubstanceUseFlyoutType) => {
  const { t } = useTranslation("homepage", {
    keyPrefix: "careInfoFlyout.substanceUse",
  });

  return (
    <InformationFlyout
      headerText={t("heading")}
      headerIcon={<VSubstanceUseDisorderFlatSpot />}
      heroIllustration={VSubstanceUseDisorderDuotoneSpot}
      isOpen={isOpen}
      onClose={onClose}
      ctaText={t("ctaText")}
      ctaType="button"
      ctaCallback={callback}
      isButtonDisabled={window.localStorage.getItem("sud_requested") === "true"}
    >
      <SubstanceUseFlyoutBody isAlsoAvailableTile={isAlsoAvailableTile} />
    </InformationFlyout>
  );
};
