import React from "react";
import PropTypes from "prop-types";
import { truncateWords } from "@spring/immutability";
import { useTranslation } from "react-i18next";

import styles from "./styles.module.scss";
import { clickableDivProps } from "lib/accessibility_helpers";
import { Box, Text } from "@springcare/sh-component-library";

const ProviderBio = ({
  provider,
  toggleBio,
  bioExpanded,
  collapsedBioLength,
}) => {
  const { t } = useTranslation("careProvider");
  const showExpandButton =
    provider.bio.trim().split(/\s+/).length > collapsedBioLength;

  return (
    <Box as="section" marginY={24}>
      {provider.bio && (
        <Text color={"content.primary"}>
          {bioExpanded
            ? provider.bio
            : truncateWords(collapsedBioLength, provider.bio)}
          {showExpandButton && (
            <Text
              textDecoration="underline"
              as="span"
              className={styles.providerBioLink}
              onClick={toggleBio}
              {...clickableDivProps({ onClick: toggleBio })}
            >
              {bioExpanded ? t("bio.hide") : t("bio.see")}
            </Text>
          )}
        </Text>
      )}
    </Box>
  );
};

ProviderBio.propTypes = {
  provider: PropTypes.object,
  toggleBio: PropTypes.func,
  collapsedBioLength: PropTypes.number,
  bioExpanded: PropTypes.bool,
};

export default ProviderBio;
