import { createIcon } from "@springcare/sh-component-library";

export const Error = createIcon({
  displayName: "Error",
  viewBox: "0 0 24 24",
  path: (
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 12C0 6.00753 4.88974 0 12 0C19.1103 0 24 6.00753 24 12C24 17.9925 19.1103 24 12 24C4.88974 24 0 17.9925 0 12ZM12 2C6.11026 2 2 6.99247 2 12C2 17.0075 6.11026 22 12 22C17.8897 22 22 17.0075 22 12C22 6.99247 17.8897 2 12 2ZM12 6C12.5523 6 13 6.44772 13 7V13C13 13.5523 12.5523 14 12 14C11.4477 14 11 13.5523 11 13V7C11 6.44772 11.4477 6 12 6ZM10.5 16.5C10.5 15.6716 11.1716 15 12 15C12.8284 15 13.5 15.6716 13.5 16.5C13.5 17.3284 12.8284 18 12 18C11.1716 18 10.5 17.3284 10.5 16.5Z"
    />
  ),
  defaultProps: {
    "aria-label": "Error icon",
    role: "img",
  },
});
