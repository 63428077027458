import { useDisclosure } from "@springcare/sh-component-library";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";
import { isLowRisk, isMediumRisk, isMinor } from "utils/memberHelpers";
import { useGetAllowDirectScheduling } from "./useGetAllowDirectScheduling";
import { useGetMemberAppointmentAndAssessmentData } from "./useGetMemberAppointmentAndAssessmentData";

// Moments Experiment #4: https://springhealth.atlassian.net/browse/MXE-679
export const useCarePopupExperiment = () => {
  const { data: memberData, loading: memberDataLoading } =
    useGetMemberAppointmentAndAssessmentData();

  const member = memberData?.user?.member;
  const memberId = member?.id;

  const { data: coachingTier, loading: coachingTierLoading } =
    useGetAllowDirectScheduling({ memberId });

  const allowDirectSchedulingCoaching =
    coachingTier?.member_coaching_tier?.allow_direct_scheduling;

  const carePopupExperimentFeatureFlag: boolean = useFeatureFlag(
    FLAGS.MOMENTS_CARE_POPUP_EXPERIMENT,
  );

  const {
    isOpen: isCarePopupExperimentModalOpen,
    onOpen: onOpenCarePopupExperimentModal,
    onClose: onCloseCarePopupExperimentModal,
  } = useDisclosure();

  const memberIsMinor = isMinor(member);

  const hasAttendedAppointmentInLast90Days =
    member?.attended_appointment_last_90_days;

  const totalCompletedExercises: number =
    member?.total_completed_exercises || 0;

  const mostRecentAssessment = member?.most_recent_assessment;

  const memberIsLowRisk = isLowRisk([mostRecentAssessment]);
  const memberIsMediumRisk = isMediumRisk([mostRecentAssessment]);

  const hasViewedModalThisSession =
    sessionStorage.getItem("mcpeModalViewed") === "true";

  const setModalViewedThisSession = () =>
    sessionStorage.setItem("mcpeModalViewed", "true");

  const hasBaselineQualificationsForExperiment =
    carePopupExperimentFeatureFlag &&
    !hasViewedModalThisSession &&
    !hasAttendedAppointmentInLast90Days &&
    !memberIsMinor &&
    totalCompletedExercises >= 1;

  const isCoachingTreatmentMember =
    hasBaselineQualificationsForExperiment &&
    allowDirectSchedulingCoaching &&
    (memberIsLowRisk || memberIsMediumRisk);

  const isTherapyTreatmentMember =
    hasBaselineQualificationsForExperiment && !isCoachingTreatmentMember;

  const isControlGroupMember =
    !carePopupExperimentFeatureFlag ||
    (!isTherapyTreatmentMember && !isCoachingTreatmentMember);

  const memberHasUnlimitedCoachingSessions =
    coachingTier?.member_coaching_tier?.unlimited_coaching_direct_scheduling;

  const remainingCoveredTherapySessions: number =
    member?.visits_remaining_basic || 0;

  const remainingCoveredCoachingSessions: number =
    member?.visits_remaining_coaching || 0;

  const calculatedRemainingTherapySessions = isTherapyTreatmentMember
    ? remainingCoveredTherapySessions
    : null;

  const calculatedRemainingCoachingSessions = memberHasUnlimitedCoachingSessions
    ? "unlimited"
    : remainingCoveredCoachingSessions;

  const remainingSessions: number | string | null = isCoachingTreatmentMember
    ? calculatedRemainingCoachingSessions
    : calculatedRemainingTherapySessions;

  const isLoading = memberDataLoading || coachingTierLoading;

  if (
    !hasViewedModalThisSession &&
    !isCarePopupExperimentModalOpen &&
    (isCoachingTreatmentMember || isTherapyTreatmentMember)
  ) {
    onOpenCarePopupExperimentModal();
  }

  return {
    isLoading,
    isControlGroupMember,
    isCoachingTreatmentMember,
    isTherapyTreatmentMember,
    remainingSessions,
    setModalViewedThisSession,
    isCarePopupExperimentModalOpen,
    onCloseCarePopupExperimentModal,
    carePopupExperimentFeatureFlag,
    totalCompletedExercises,
  };
};
