import React from "react";
import PropTypes from "prop-types";
import { Trans } from "react-i18next";
import { Box, Flex, Heading, Text } from "@springcare/sh-component-library";

const EnableMfaStepCard = (props) => {
  const {
    stepNumber,
    stepTitle,
    stepDescription,
    children,
    childrenFlexDirection,
    ...rest
  } = props;

  return (
    <Box {...rest}>
      <Box px={24} py={32} boxShadow="md" borderWidth="1px" borderRadius="base">
        <Flex
          flexDirection={childrenFlexDirection}
          justifyContent="space-between"
        >
          <Flex flexDirection="column">
            <Trans
              i18nKey="multiFactorAuthentication.stepNumber"
              ns="limitedLangSettings"
            >
              <Text color="platform.base">Step {{ stepNumber }}</Text>
            </Trans>
            <Heading variant="lg" color="primary.700">
              {stepTitle}
            </Heading>
            <Text variant="body1" color="platform.base" mb={24}>
              {stepDescription}
            </Text>
          </Flex>
          {children}
        </Flex>
      </Box>
    </Box>
  );
};

EnableMfaStepCard.propTypes = {
  children: PropTypes.node,
  childrenFlexDirection: PropTypes.string,
  stepDescription: PropTypes.node.isRequired,
  stepNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  stepTitle: PropTypes.string.isRequired,
};

EnableMfaStepCard.defaultProps = {
  childrenFlexDirection: "column",
};

export default EnableMfaStepCard;
