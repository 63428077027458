import {
  Box,
  Flex,
  Text,
  Input,
  IconButton,
  ArrowUpIcon,
} from "@springcare/sh-component-library";
import { useEffect, useRef, useState } from "react";
import { useLocalParticipant } from "@livekit/components-react";
import ChatMessageBubble from "components/templates/SHSessionRoom/components/SessionRoomDrawer/ChatMessageBubble";
import { trackChatMessageSent } from "components/templates/SHSessionRoom/telemetry/analytics";
import { useTranslation } from "hooks/react-i18next";

const ChatSection = ({ send, chatMessages, isSending }) => {
  const { localParticipant } = useLocalParticipant();
  const localSid = localParticipant?.sid;
  const { t } = useTranslation("livekitExperience", {
    keyPrefix: "sessionRoomDrawer.chatSection",
  });
  const [inputMessage, setInputMessage] = useState("");
  const chatRef = useRef(null);

  useEffect(() => {
    chatRef.current.scrollTop = chatRef.current.scrollHeight;
  }, [chatMessages]);

  const handleSend = () => {
    if (!inputMessage) return;
    send(inputMessage);
    setInputMessage("");
    trackChatMessageSent();
  };

  const handleEnterKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSend();
    }
  };

  const sendMessageLabel = t("sendMessage");

  const dateIndicator = new Date().toLocaleDateString("en-US", {
    weekday: "short",
    month: "short",
    day: "numeric",
  });

  return (
    <Flex px="v-24" flexDir="column">
      <Flex
        h="calc(100vh - 276px)"
        flexGrow={1}
        flexDir="column"
        overflowY="auto"
        justifyContent="start"
        ref={chatRef}
        pt="v-16"
        aria-live="polite"
      >
        <Box w="100%" mb="v-16" color="content-primary">
          <Text>{t("chatNotSavedWarning")}</Text>
        </Box>
        <Flex
          w="100%"
          mb="v-16"
          color="content-secondary"
          justifyContent="center"
        >
          <Text size="body-small-regular">{dateIndicator}</Text>
        </Flex>
        {chatMessages.map((chatMessage) => (
          <ChatMessageBubble
            key={chatMessage.id}
            message={chatMessage}
            localSid={localSid}
          />
        ))}
      </Flex>

      <Flex align="center" pt="v-16" pb="v-16" gap="v-8">
        <Input
          type="text"
          name="inputMessage"
          value={inputMessage}
          onChange={(e) => setInputMessage(e.target.value)}
          onKeyPress={handleEnterKeyPress}
          pe="v-32"
          ps="v-12"
          placeholder={sendMessageLabel}
          aria-label={sendMessageLabel}
          autoComplete="off"
        />
        <IconButton
          icon={ArrowUpIcon}
          w={16}
          aria-label={sendMessageLabel}
          onClick={handleSend}
          isLoading={isSending}
          disabled={!inputMessage}
        />
      </Flex>
    </Flex>
  );
};

export default ChatSection;
