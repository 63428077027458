import i18n from "i18next";
import HttpApi from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

const config = {
  debug: false,
  supportedLngs: [
    "en",
    "es",
    "fr",
    "pt",
    "de",
    "nl",
    "pl",
    "ja",
    "hi",
    "fr-CA",
    "cs",
    "ar",
    "hu",
    "id",
    "he",
    "it",
    "ms",
    "fil",
    "pt-PT",
    "ro",
    "zh",
    "ko",
    "el",
    "tr",
    "sv",
    "da",
    "fi",
    "et",
    "hr",
    "sl",
    "sk",
    "lv",
    "si",
    "uk",
    "kk",
    "ur",
    "hy",
    "zh-TW",
    "zh-HK",
    "mk",
    "is",
    "be",
    "th",
    "bn",
    "vi",
    "sr",
    "no",
    "ka",
    "bg",
    "lt",
  ],
  nonExplicitSupportedLngs: true,
  fallbackLng: "en",
  returnEmptyString: false,
  interpolation: {
    escapeValue: false,
  },
  react: {
    bindI18n: "loaded languageChanged",
    bindI18nStore: "added",
    useSuspense: false,
    wait: true,
  },
  ns: [
    "a11y",
    "limitedLangAuth",
    "benefits",
    "benefitsSnapshot",
    "carePlan",
    "careProvider",
    "careVisits",
    "common",
    "emailContent",
    "error",
    "goals",
    "journey",
    "loginless",
    "memberDashboard",
    "memberHelpCard",
    "memberTermination",
    "membership",
    "previsitPage",
    "limitedLangSettings",
    "suicidePreventionModal",
    "limitedLangValidations",
    "homepage",
    "virtualSession",
    "zoom",
    "t2Benefits",
    "limitedLangRegister",
  ],
};

// for browser use http backend to load translations
if (process && !process.release) {
  i18n.use(HttpApi).use(initReactI18next);
}

if (!i18n.isInitialized) {
  i18n.init(config);
}

i18n.on("languageChanged", (lng) => {
  if (typeof window !== "undefined") {
    document.documentElement.setAttribute("lang", lng);
    document.documentElement.setAttribute("dir", i18n.dir(lng));
  }
});

export default i18n;
