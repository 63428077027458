import { Box } from "@springcare/sh-component-library";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";

import { images } from "@spring/constants";
import { SHCareCard } from "design-system/components/custom/cards/SHCareCard";
import {
  getFormLinkWPMHC,
  getBadgeExists,
  getIsUniqueWPMCHCopy,
} from "utils/employerServicesHelpers";

const CareConsultantCard = ({ customerId }): ReactElement => {
  const { t } = useTranslation("careVisits");
  const badgeExists = getBadgeExists(customerId);
  const formLinkSDOH = getFormLinkWPMHC(customerId);
  const isUniqueWPMHCCopy = getIsUniqueWPMCHCopy(customerId);
  const stringKey = isUniqueWPMHCCopy ? customerId : "default";

  return (
    <Box mr={[0, 0, 8]} mb={[11, 11, 0]}>
      <SHCareCard
        badgeText={
          badgeExists
            ? t(`homepage:careInfoFlyout.careConsultant.${customerId}.badge`)
            : null
        }
        buttonTrackingText="WPMHC Care Card"
        cardSubtitle={t(
          `findNewCareTab.sdohCards.cards.${stringKey}.wpmhc.description`,
        )}
        cardTitle={t(`findNewCareTab.sdohCards.cards.${stringKey}.wpmhc.title`)}
        componentTrackingText="WPMHC Care Card"
        externalUrl={formLinkSDOH}
        imageAlt={t(
          `findNewCareTab.sdohCards.cards.${stringKey}.wpmhc.imageAlt`,
        )}
        imageUrl={images.CareConsultantCard}
        testId="wpmhc-card"
      />
    </Box>
  );
};

export default CareConsultantCard;
