import { FLAGS, useFeatureFlag } from "utils/launchdarkly";
import { useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { useInViewportOnce } from "hooks/useInViewport";
import { TRACK_EVENT } from "utils/mixpanel";
import { Box } from "@springcare/sh-component-library";
import { UpNextCard } from "shared/components";

export const CoverageCard = ({ cardConfig }) => {
  const shouldShowCoverageTab = useFeatureFlag(FLAGS.ENABLE_COST_TRANSPARENCY);
  const { has_access_to_cost_estimation: hasAccessToCostEstimation } =
    useSelector((state) => state?.auth?.userIdentifiers?.user?.member || {});
  const coverageCardEnabled =
    shouldShowCoverageTab && hasAccessToCostEstimation;

  const ref = useRef();
  const inViewport = useInViewportOnce(ref, "0px");
  const [trackedOnce, setTrackedOnce] = useState(false);

  useEffect(() => {
    if (inViewport && !trackedOnce && coverageCardEnabled) {
      TRACK_EVENT.COMPONENT_VIEWED(window.location.pathname, "Coverage Card");
      setTrackedOnce(true);
    }
  }, [inViewport, trackedOnce, coverageCardEnabled]);

  return (
    <>
      {coverageCardEnabled && (
        <Box ref={ref} pb={"24px"}>
          <UpNextCard {...cardConfig.coverage} buttonVariant="solid" />
        </Box>
      )}
    </>
  );
};
