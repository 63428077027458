import { useEffect } from "react";
import {
  Alert,
  VInfoIcon,
  useMediaQuery,
} from "@springcare/sh-component-library";
import { useTranslation } from "react-i18next";
import Router from "next/router";
import { TRACK_EVENT } from "utils/mixpanel";
import { UpcomingChangesBannerProps } from "./types";
import { getAccountTransitionDate } from "modules/shared/components/Notifications/utils";
import { FAQ_LINK_FOR_AGING_OUT_RANGE } from "modules/shared/components/Notifications/constants/AccountTransitions.constants";

export const UpcomingChangesBanner: React.FC<UpcomingChangesBannerProps> = ({
  agingOutRange,
  dateOfBirth = "",
}) => {
  const { t } = useTranslation("accountTransition");
  const [isDesktop] = useMediaQuery("(min-width: 993px)");
  const formattedMemberDOB = getAccountTransitionDate(dateOfBirth);
  const translatedText = t(`alert.${agingOutRange}.content`, {
    transitionDate: formattedMemberDOB,
  });

  useEffect(() => {
    TRACK_EVENT.COMPONENT_VIEWED(
      Router.asPath,
      "Aging Out Upcoming Changes Alert",
      {
        spring_doc_id: "agingOut001",
      },
    );
  }, []);

  const handleButtonClick = () => {
    TRACK_EVENT.BUTTON_CLICKED(
      Router.asPath,
      "Aging Out Upcoming Changes Alert",
      {
        spring_doc_id: "agingOut002",
        to: FAQ_LINK_FOR_AGING_OUT_RANGE[agingOutRange],
      },
    );

    window.open(FAQ_LINK_FOR_AGING_OUT_RANGE[agingOutRange], "_blank");
  };

  return (
    <Alert
      colorScheme="base"
      title={t("upcomingChanges", { ns: "accountTransition" })}
      icon={VInfoIcon}
      content={translatedText}
      primaryAction={{
        children: t(`alert.${agingOutRange}.button`),
        onClick: handleButtonClick,
      }}
      showIcon="on"
      showTitle="on"
      size="medium"
      type={isDesktop ? "one-line" : "standard"}
    />
  );
};
