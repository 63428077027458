import { useTheme } from "@springcare/sh-component-library";

export const useChartTheme = () => {
  const baseTheme = useTheme();

  return {
    background: baseTheme.colors.white,
    fontFamily: "inherit",

    axis: {
      domain: {
        line: {
          strokeWidth: 1,
          stroke: baseTheme.semanticTokens.colors["border-subtle"].default,
        },
      },
      ticks: {
        text: {
          fill: baseTheme.semanticTokens.colors["content-secondary"].default,
          fontSize: baseTheme.textStyles["body-medium-regular"].fontSize,
          fontWeight: baseTheme.textStyles["body-medium-regular"].fontWeight,
        },
      },
      legend: {
        text: {
          fontSize: baseTheme.textStyles["body-medium-regular"].fontSize,
        },
      },
    },

    colors: {
      text: baseTheme.semanticTokens.colors["content-secondary"].default,
    },

    text: {
      fill: baseTheme.semanticTokens.colors["content-secondary"].default,
    },

    grid: {
      line: {
        stroke: baseTheme.semanticTokens.colors["border-subtle"].default,
        strokeDasharray: "2.5",
      },
    },
  };
};
