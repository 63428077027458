/* eslint-disable react/jsx-key */
import { createIcon } from "@springcare/sh-component-library";

export const Convenience = createIcon({
  displayName: "Provider",
  viewBox: "0 0 24 24",
  path: [
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.5 15.4759C16.1545 15.0276 16.6264 14.3241 16.7649 13.4932L16.8757 12.8287C16.9158 12.5879 17.0659 12.3199 17.3436 12.0537C18.6713 10.7809 19.5 8.98636 19.5 7C19.5 3.13401 16.366 0 12.5 0C8.63401 0 5.5 3.13401 5.5 7C5.5 8.98636 6.32867 10.7809 7.65644 12.0537C7.93407 12.3199 8.08419 12.5879 8.12432 12.8287L8.23507 13.4932C8.37356 14.3241 8.84546 15.0276 9.5 15.4759V18C9.5 19.1046 10.3954 20 11.5 20H13.5C14.6046 20 15.5 19.1046 15.5 18V15.4759ZM12.5 2C9.73858 2 7.5 4.23858 7.5 7C7.5 8.41906 8.08986 9.69866 9.04048 10.61C9.52041 11.07 9.96472 11.7056 10.0971 12.4999L10.2079 13.1644C10.2882 13.6466 10.7054 14 11.1943 14H11.5V10.4142L10.2929 9.20711C9.90237 8.81658 9.90237 8.18342 10.2929 7.79289C10.6834 7.40237 11.3166 7.40237 11.7071 7.79289L12.5 8.58579L13.2929 7.79289C13.6834 7.40237 14.3166 7.40237 14.7071 7.79289C15.0976 8.18342 15.0976 8.81658 14.7071 9.20711L13.5 10.4142V14H13.8057C14.2946 14 14.7118 13.6466 14.7921 13.1644L14.9029 12.4999C15.0353 11.7056 15.4796 11.07 15.9595 10.61C16.9101 9.69866 17.5 8.41906 17.5 7C17.5 4.23858 15.2614 2 12.5 2ZM13.5 16H11.5V18H13.5V16Z"
    />,
    <path
      fill="currentColor"
      d="M23.5 8C24.0523 8 24.5 7.55228 24.5 7C24.5 6.44772 24.0523 6 23.5 6H21.5C20.9477 6 20.5 6.44772 20.5 7C20.5 7.55228 20.9477 8 21.5 8H23.5Z"
    />,
    <path
      fill="currentColor"
      d="M22.8923 1C23.1684 1.47829 23.0046 2.08988 22.5263 2.36603L20.7942 3.36603C20.3159 3.64217 19.7043 3.47829 19.4282 3C19.1521 2.52171 19.3159 1.91012 19.7942 1.63397L21.5263 0.633975C22.0046 0.357832 22.6162 0.521707 22.8923 1Z"
    />,
    <path
      fill="currentColor"
      d="M22.5263 11.634C23.0046 11.9101 23.1684 12.5217 22.8923 13C22.6162 13.4783 22.0046 13.6422 21.5263 13.366L19.7942 12.366C19.3159 12.0899 19.1521 11.4783 19.4282 11C19.7043 10.5217 20.3159 10.3578 20.7942 10.634L22.5263 11.634Z"
    />,
    <path
      fill="currentColor"
      d="M4.5 7C4.5 7.55228 4.05228 8 3.5 8H1.5C0.947715 8 0.5 7.55228 0.5 7C0.5 6.44772 0.947715 6 1.5 6H3.5C4.05228 6 4.5 6.44772 4.5 7Z"
    />,
    <path
      fill="currentColor"
      d="M5.20578 12.366C5.68407 12.0899 5.84795 11.4783 5.57181 11C5.29566 10.5217 4.68407 10.3578 4.20578 10.634L2.47373 11.634C1.99544 11.9101 1.83156 12.5217 2.1077 13C2.38385 13.4783 2.99544 13.6422 3.47373 13.366L5.20578 12.366Z"
    />,
    <path
      fill="currentColor"
      d="M5.57181 3C5.84795 2.52171 5.68407 1.91012 5.20578 1.63397L3.47373 0.633975C2.99544 0.357832 2.38385 0.521707 2.1077 1C1.83156 1.47829 1.99544 2.08988 2.47373 2.36603L4.20578 3.36603C4.68407 3.64217 5.29566 3.47829 5.57181 3Z"
    />,
  ],
  defaultProps: {
    color: "platform.900",
  },
});
