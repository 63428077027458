import { useEffect } from "react";
import { Box, Heading, Text } from "@springcare/sh-component-library";
import {
  TimeSlotGroups,
  EmptyState,
} from "modules/MemberDashboard/Scheduling/components";
import { getUsersTimezone } from "modules/MemberDashboard/Scheduling/utils";
import { useTranslation } from "react-i18next";
import type { TimePickerPropType } from "modules/MemberDashboard/Scheduling/types";
import { TRACK_EVENT } from "utils/mixpanel";
import { useLimitCalls } from "hooks/useLimitCalls";

export const TimePicker = ({
  appointments,
  handleTimeSlotClick,
  selectedTimeSlot,
  selectedDayString,
}: TimePickerPropType) => {
  const { t } = useTranslation("scheduling", { keyPrefix: "timePicker" });
  const timeZone = getUsersTimezone();
  const [trackOnce] = useLimitCalls();

  useEffect(() => {
    trackOnce(() => {
      TRACK_EVENT.COMPONENT_VIEWED(window.location.pathname, "Time Picker");
    });
  }, [trackOnce]);

  return (
    <Box mx={8} w="100%" maxW={{ lg: 272 }} mt="v-40">
      <Heading size="heading-small" mb={8}>
        {t("title")}
      </Heading>
      {appointments?.length === 0 ? (
        <EmptyState />
      ) : (
        <>
          <Text mb={8}>{selectedDayString}</Text>
          <Text mb={8} color="content-secondary" whiteSpace={{ xl: "nowrap" }}>
            {t("timezone")} {timeZone}
          </Text>
          <TimeSlotGroups
            appointments={appointments}
            handleTimeSlotClick={handleTimeSlotClick}
            selectedTimeSlot={selectedTimeSlot}
          />
        </>
      )}
    </Box>
  );
};
