import { useForm } from "react-hook-form";
import { useTranslation } from "hooks/react-i18next";
import { useMutation } from "@apollo/client";
import {
  Box,
  Button,
  Grid,
  SimpleGrid,
  SHTextInput,
  SHSelectInput,
} from "design-system/components";

import {
  shouldShowStateField,
  isStateMailingEnabled,
  normalizeToName,
} from "utils/global";

import { requiredValidation } from "schemas/hookFormSchema";

import { updateMember } from "operations/mutations/member";

import { useProviderBrowseContext } from "context/ProviderBrowseContext";
import { trackEditAddressSubmit } from "components/templates/Browse/ProviderBrowsePage/analytics";
import { useCountryStates } from "hooks";
import { Center, Spinner } from "@springcare/sh-component-library";

type Props = {
  onClose: () => void;
};

type UpdateAddressFormValues = {
  street_address_1: string;
  street_address_2?: string;
  city: string;
  state: string;
  zip_code: string;
  country: string;
};

export const UpdateAddressForm = ({ onClose }: Props) => {
  const { t } = useTranslation(["careProvider", "common"]);
  const {
    memberInfo,
    setMemberInfo,
    global,
    setNotification,
    isMobile,
    setAddressUpdated,
  } = useProviderBrowseContext();
  const [doUpdateMember, { loading: updateAddressLoading }] =
    useMutation(updateMember);
  const showState = shouldShowStateField(global?.country || "US");
  const stateMailingEnabled = isStateMailingEnabled(global?.country || "US");

  const { data: countryStates, loading: countryStatesLoading } =
    useCountryStates();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<UpdateAddressFormValues>({
    mode: "onTouched",
    defaultValues: {
      street_address_1:
        memberInfo?.user?.member?.postal_address?.street_address_1 || null,
      street_address_2:
        memberInfo?.user?.member?.postal_address?.street_address_2 || null,
      city: memberInfo?.user?.member?.postal_address?.city || null,
      state: memberInfo?.user?.member?.postal_address?.state || null,
      zip_code: memberInfo?.user?.member?.postal_address?.zip_code || null,
      country: normalizeToName(global?.country),
    },
  });

  async function onSubmit(values: UpdateAddressFormValues) {
    const { street_address_1, street_address_2, city, zip_code, state } =
      values;
    // @ts-ignore
    const { id } = memberInfo.user.member;
    trackEditAddressSubmit();

    try {
      const res = await doUpdateMember({
        variables: {
          input: {
            id,
            patch: {
              street_address_1,
              street_address_2,
              city,
              state,
              zip_code,
            },
          },
        },
      });

      if (res?.data?.updateMember?.success) {
        // update postal_address in context (memberInfo)
        setMemberInfo({
          ...memberInfo,
          user: {
            ...memberInfo.user,
            member: {
              ...memberInfo.user.member,
              postal_address: {
                ...values,
              },
            },
          },
        });

        setAddressUpdated(true);

        setNotification({
          status: "success",
          text: "Address updated successfully.",
        });
        onClose();
      }

      if (!res?.data?.updateMember?.success) {
        setNotification({
          status: "error",
          text: "Something went wrong. Please try again.",
        });
      }
    } catch (_e) {
      setNotification({
        status: "error",
        text: "Something went wrong. Please try again.",
      });
    }
  }

  if (countryStatesLoading) {
    return (
      <Center p="20px">
        <Spinner size="xl" />
      </Center>
    );
  }

  return (
    <Box as="form" onSubmit={handleSubmit(onSubmit)} mb={24}>
      <Grid templateColumns={isMobile ? null : "1fr 130px"} gap={8}>
        <SHTextInput
          fcMb={16}
          required
          errors={errors?.street_address_1}
          name="street_address_1"
          label={t("common:form.label.homeAddress")}
          register={register}
          inputType="text"
          autoComplete="address-line1"
          validation={requiredValidation("Address")}
        />

        <SHTextInput
          fcMb={16}
          required
          errors={errors?.street_address_2}
          name="street_address_2"
          label={t("common:form.label.apartmentOrSuite")}
          register={register}
          inputType="text"
          autoComplete="address-line2"
        />
      </Grid>

      <SimpleGrid
        templateColumns={
          isMobile
            ? null
            : showState && stateMailingEnabled
              ? "repeat(3, 1fr)"
              : "1fr 130px"
        }
        gap={8}
      >
        <SHTextInput
          fcMb={16}
          required
          errors={errors?.city}
          name="city"
          label={t("common:form.label.city")}
          register={register}
          inputType="text"
          autoComplete="city"
          validation={requiredValidation("City")}
        />

        {showState && stateMailingEnabled && (
          <SHSelectInput
            errors={errors?.state}
            required
            name="state"
            register={register}
            validation={requiredValidation("State")}
            label={t("common:form.label.state")}
            options={countryStates?.states || []}
            hasOptionLabel
            autoComplete="address-level1"
          />
        )}
        <SHTextInput
          fcMb={16}
          required
          errors={errors?.zip_code}
          name="zip_code"
          label={t("common:form.label.zipCode")}
          register={register}
          inputType="text"
          autoComplete="postal-code"
          validation={requiredValidation("Zip Code")}
        />
      </SimpleGrid>
      <SHTextInput
        fcMb={32}
        required
        errors={errors?.country}
        name="country"
        label={t("common:form.label.country")}
        register={register}
        inputType="text"
        autoComplete="country"
        disabled
      />
      <Button
        type="submit"
        isLoading={updateAddressLoading}
        onClick={handleSubmit(onSubmit)}
        variant="solid"
        colorScheme="primary"
        w="100%"
        h={56}
      >
        {t("common:form.submitText")}
      </Button>
    </Box>
  );
};
