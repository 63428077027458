import { Box, Heading, Text } from "@springcare/sh-component-library";
import { useSessionViewStatus } from "context/SessionRoomContext";
import { SessionViewStatus } from "components/templates/SHSessionRoom/types";
import { useTranslation } from "hooks/react-i18next";

const SessionDetailsHeader = ({ isMobile }) => {
  const { sessionViewStatus } = useSessionViewStatus();
  const { t } = useTranslation("livekitExperience", {
    keyPrefix: "preJoin",
  });

  // Define a single config object for all session view statuses
  const UX_COPY_CONFIG = {
    [SessionViewStatus.BrowserPermissionsDenied]: {
      mainHeaderText: t("browserPermissionsDeniedCopy.header"),
      subHeaderText: t("browserPermissionsDeniedCopy.subHeader"),
    },
    [SessionViewStatus.SystemPermissionsDenied]: {
      mainHeaderText: t("systemPermissionsDeniedCopy.header"),
      subHeaderText: t("systemPermissionsDeniedCopy.subHeader"),
    },
    [SessionViewStatus.CameraInUsePermissionsDenied]: {
      mainHeaderText: t("cameraInUsePermissionsDenied.header"),
      subHeaderText: t("cameraInUsePermissionsDenied.subHeader"),
    },
    [SessionViewStatus.AmbiguousPermissionsDenied]: {
      mainHeaderText: t("ambiguousPermissionsDenied.header"),
      subHeaderText: t("ambiguousPermissionsDenied.subHeader"),
    },
    [SessionViewStatus.NotStarted]: {
      mainHeaderText: t("notStartedCopy.header"),
      subHeaderText: t("notStartedCopy.subHeader"),
    },
    [SessionViewStatus.MemberIsWaiting]: {
      mainHeaderText: t("memberWaitingCopy.header"),
      subHeaderText: t("memberWaitingCopy.subHeader"),
    },
    [SessionViewStatus.ProviderNoShow]: {
      mainHeaderText: t("providerNoShowCopy.header"),
      subHeaderText: t("providerNoShowCopy.subHeader"),
    },
  };

  const getCopy = (status: SessionViewStatus) => {
    return (
      UX_COPY_CONFIG[status] || UX_COPY_CONFIG[SessionViewStatus.NotStarted]
    );
  };
  const { mainHeaderText, subHeaderText } = getCopy(sessionViewStatus);

  return (
    <>
      <Box>
        <Heading
          size={isMobile ? "heading-small" : "heading-medium"}
          mb="v-8"
          as="h1"
        >
          {mainHeaderText}
        </Heading>
      </Box>

      <Box mb="v-8">
        <Text color="content-secondary">{subHeaderText}</Text>
      </Box>
    </>
  );
};

export default SessionDetailsHeader;
