import { Flex, useMediaQuery } from "@springcare/sh-component-library";
import { tabTypes } from "modules/MemberDashboard/Benefits/constants/coveredCareTabTypes";
import { LeftPanelT2 } from "./LeftPanelT2";
import { RightPanelT2 } from "./RightPanelT2";
import { MomentsCareTabT2 } from "./MomentsCareTabT2";

export const CoveredCareContentT2 = ({
  title,
  description,
  tabType,
  memberInfo,
}) => {
  const [isMobile] = useMediaQuery(["(max-width: 680px)"]);

  if (tabType === tabTypes.MOMENTS) {
    return <MomentsCareTabT2 />;
  }
  return (
    <Flex my="32px" flexDirection={isMobile ? "column" : "row"}>
      <Flex width="100%" me="64px" maxWidth="608px">
        <LeftPanelT2
          title={title}
          description={description}
          tabType={tabType}
          memberInfo={memberInfo}
          isMobile={isMobile}
        />
      </Flex>
      <Flex
        marginTop={isMobile && "2rem"}
        width="100%"
        maxWidth="352px"
        flexDirection="column"
        alignItems="center"
      >
        <RightPanelT2
          tabType={tabType}
          cohort={memberInfo?.user?.member?.cohort}
        />
      </Flex>
    </Flex>
  );
};
