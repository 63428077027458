import { gql } from "@apollo/client";

import {
  postalAddress,
  experienceState,
  contractTerm,
} from "operations/fragments/member";

const userInfo = gql`
  fragment userInfo on User {
    id
    created_at
    first_name
    last_name
    date_of_birth
    email
    phone
    country_code
    roles
    time_zone
    preferred_language
    member {
      id
      minor
      valid_privacy_policy_agreement
      access_limit_date
      workplace_manager
      has_access_to_cost_estimation
      eligible_for_insurance_validation
      aged_out_consent_fields {
        created_at
        terms_of_service_agreed_at
      }
      inside_inactivation_window
      date_of_birth
      terminate_reason
      covered_life {
        country
        dependent
      }
      # New endpoints
      visits_covered_total # Includes Therapy (basic), Specialist (MM), and sponsored covered Sessions
      visits_covered_coaching # Only coaching covered
      visits_covered_specialist # Only Specialist covered
      visits_used_total # Includes Therapy, Specialist (MM), and sponsored used sessions
      visits_used_basic # Only Therapy (basic) used sessions
      visits_used_specialist # Only Specialist (MM) used sessions
      visits_used_coaching # Only Coaching used sessions
      visits_remaining_coaching # Only Coaching remaining sessions
      visits_remaining_total # Includes Therapy (basic), Specialist (MM), and sponsored remaining sessions
      visits_remaining_specialist # Only Specialist (MM) remaining sessions
      visits_eligibility_limit_date # The contract term expiration date
      workplace_manager
      # -----------------------------
      is_global_t2
      is_private_practice
      sms_opt_in
      postal_address {
        ...postalAddress
      }
      previsit {
        id
        can_schedule
        data
      }
      experience_state {
        ...experienceState
      }

      cohort {
        id
        dependents_allowed
        child_dependents_allowed
        in_person_supported
        contract_term {
          ...contractTerm
        }
      }

      manager {
        id
        first_name
        last_name
        email
        date_of_birth
        minor
        covered_life {
          dependent
        }
      }

      managed_dependents {
        id
        first_name
        last_name
        email
        minor
        date_of_birth
      }
      missed_late_cancel_policy_ack_required
    }
    customer {
      id
      name
      active
      trial
      is_health_plan
    }
  }
  ${postalAddress}
  ${experienceState}
  ${contractTerm}
`;

export default userInfo;
