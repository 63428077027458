import React, { ReactNode } from "react";
import { Flex } from "@springcare/sh-component-library";

interface GroupProps {
  children?: ReactNode;
  justifyContent?: "start" | "center" | "end";
  gap?: string;
}

const Group = ({
  children,
  justifyContent = "start",
  gap = "v-4",
}: GroupProps) => {
  return (
    <Flex justifyContent={justifyContent} gap={gap}>
      {children}
    </Flex>
  );
};

export const Start: React.FC = (props) => {
  return <Group {...props} justifyContent="start" />;
};

export const Center: React.FC = (props) => {
  return <Group {...props} justifyContent="center" gap="v-16" />;
};

export const End: React.FC = (props) => {
  return <Group {...props} justifyContent="end" />;
};
