import { Box, Spinner, Tooltip } from "@springcare/sh-component-library";
import { useTranslation } from "react-i18next";
import { useMemberInfo } from "hooks";
import { useMemberBenefits } from "hooks/useMemberBenefits";
import { AppointmentKind, isCoaching } from "@spring/constants";
import { BookAgainButton } from "./BookAgainButton";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";
import {
  AttendedStatusDisplayI18nKeyMapping,
  AttendedStatusTooltipI18nKeyMapping,
} from "./attendedStatusTooltip";
import type { Appointment } from "modules/shared/graphql-codegen/graphql";

type PastAppointmentStatusProps = {
  appointmentRow: Appointment;
  isGlobalMemberWithNoVisitsRemaining: boolean;
  onScheduleModalOpen: (args: any) => void;
  openModal: (args: any) => void;
  getLazyNextAppointmentSlot: (args: any) => void;
  getLazySlotRequestVars: (args: any) => void;
};

const StatusTooltip = ({ appointmentAttended }) => {
  const { t } = useTranslation(["careVisits"]);
  const attendedStatusCopy =
    t(`${AttendedStatusDisplayI18nKeyMapping(appointmentAttended)}`) !==
    "undefined"
      ? t(`${AttendedStatusDisplayI18nKeyMapping(appointmentAttended)}`)
      : "";
  const toolTipCopy =
    t(`${AttendedStatusTooltipI18nKeyMapping(appointmentAttended)}`) !==
    "undefined"
      ? t(`${AttendedStatusTooltipI18nKeyMapping(appointmentAttended)}`)
      : "";

  return (
    <Tooltip
      label={toolTipCopy}
      aria-label={toolTipCopy}
      placement="top"
      id="appointmentStatusTooltip"
      role="tooltip"
      bg="neutral-bold"
      borderRadius="6px"
      textAlign="left"
      p={"4px 8px"}
      gutter={0}
    >
      <Box p={"10px 0"} color="platform.500">
        {attendedStatusCopy}
      </Box>
    </Tooltip>
  );
};

const BookedAppointment = ({
  appointmentRow,
  onScheduleModalOpen,
  openModal,
  getLazyNextAppointmentSlot,
  getLazySlotRequestVars,
}) => {
  const { data: memberInfoData, loading: memberDataLoading } = useMemberInfo();

  const {
    isCoachingEnabled,
    hasContinuedCoaching,
    isLoading: isBenefitsLoading,
  } = useMemberBenefits(memberInfoData?.user?.member?.id);

  const isCoachingProvider = isCoaching(appointmentRow?.kind);
  const bookAgainCoaching = isCoachingEnabled && hasContinuedCoaching;
  const hasPeerRecoverFlag = useFeatureFlag(
    FLAGS.PEER_RECOVERY_SPECIALIST_EXPERIENCE,
  );
  const isPeerRecoverySpecialist = [
    AppointmentKind.InitialPeerRecoverySpecialist,
    AppointmentKind.FollowUpPeerRecoverySpecialist,
  ].includes(appointmentRow?.kind);

  const isPeerRecoverySpecialistSupported =
    memberInfoData?.user?.member?.is_peer_recovery_specialist_supported &&
    hasPeerRecoverFlag;

  if (memberDataLoading || isBenefitsLoading) {
    return <Spinner />;
  }

  if (
    (isCoachingProvider &&
      bookAgainCoaching !== undefined &&
      !bookAgainCoaching) ||
    (isPeerRecoverySpecialist && !isPeerRecoverySpecialistSupported)
  ) {
    return null;
  }

  if (appointmentRow.attended == "Kept" || appointmentRow.attended === null) {
    return (
      <BookAgainButton
        appointmentRow={appointmentRow}
        onScheduleModalOpen={onScheduleModalOpen}
        openModal={openModal}
        getLazyNextAppointmentSlot={getLazyNextAppointmentSlot}
        getLazySlotRequestVars={getLazySlotRequestVars}
        memberInfoData={memberInfoData}
      />
    );
  }

  return <StatusTooltip appointmentAttended={appointmentRow?.attended} />;
};

const CancelledAppointment = ({ appointmentRow }) => {
  const { t } = useTranslation(["careVisits"]);

  if (appointmentRow.attended !== null) {
    return <StatusTooltip appointmentAttended={appointmentRow?.attended} />;
  }

  return (
    <Box
      p={"10px 0 10px 5px"}
      w={["auto", "120px", "150px"]}
      color="platform.500"
      fontWeight={400}
    >
      {t("appointmentsTable.cancelled")}
    </Box>
  );
};

export const PastAppointmentStatus = ({
  appointmentRow,
  isGlobalMemberWithNoVisitsRemaining,
  onScheduleModalOpen,
  openModal,
  getLazyNextAppointmentSlot,
  getLazySlotRequestVars,
}: PastAppointmentStatusProps) => {
  const { t } = useTranslation(["careVisits"]);

  if (appointmentRow?.provider?.status === "Disabled") {
    return (
      <Box
        p={"10px 0 10px 5px"}
        w={["auto", "120px", "150px"]}
        color="platform.500"
        fontWeight={400}
      >
        {t("appointmentsTable.providerStatusDisabled")}
      </Box>
    );
  }

  if (
    appointmentRow?.status === "Booked" &&
    !isGlobalMemberWithNoVisitsRemaining
  ) {
    return (
      <BookedAppointment
        appointmentRow={appointmentRow}
        onScheduleModalOpen={onScheduleModalOpen}
        openModal={openModal}
        getLazyNextAppointmentSlot={getLazyNextAppointmentSlot}
        getLazySlotRequestVars={getLazySlotRequestVars}
      />
    );
  }

  if (appointmentRow?.status === "Cancelled") {
    return <CancelledAppointment appointmentRow={appointmentRow} />;
  }

  return null;
};
