import { Box, Spinner } from "@springcare/sh-component-library";

export const LoadingSpinner = () => {
  return (
    <Box
      height="100vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Spinner speed="1s" width={100} height={100} />
    </Box>
  );
};
