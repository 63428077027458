import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  modalIds,
  AppointmentKind,
  AppointmentAction,
} from "@spring/constants";
import { openModal } from "@spring/smeargle/actions";

import { isCoachWithParentingSpecialty } from "components/templates/HomePage/utils/helpers";
import { Box, Heading, Text, VButton } from "design-system/components";
import {
  useAllUpcomingAppointmentSlots,
  useCareInfoFlyoutDisclosures,
} from "hooks";
import { TRACK_EVENT } from "utils/mixpanel";
import { getAppointmentSlotStartTimeAndMedium } from "utils/schedulingHelpers";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";
import { ScheduleModalOpenOptions } from "shared/hooks";
import { CareProvider } from "shared/contexts";
import { ParentCoachingFlyout } from "components/organisms/CareInfoFlyout/components";

type ParentCoachingCardProps = {
  coach: CareProvider;
  onScheduleModalOpen: (props: ScheduleModalOpenOptions) => void;
};

export const ParentCoachingCard: React.FC<ParentCoachingCardProps> = ({
  coach,
  onScheduleModalOpen,
}) => {
  const { parentCoaching: parentCoachingDisclosure } =
    useCareInfoFlyoutDisclosures();
  const { t } = useTranslation(["homepage", "carePlan"]);
  const dispatch = useDispatch();
  const shouldShowNewInsuranceModal = useFeatureFlag(FLAGS.NEW_INSURANCE_MODAL);

  const cardButtonText = isCoachWithParentingSpecialty(coach)
    ? t("careInfoFlyout.parentCoaching.ctaScheduleText")
    : t("careInfoFlyout.parentCoaching.ctaBrowseCoachesText");

  const openParentCoachingFlyout = () => {
    parentCoachingDisclosure.onOpen();
    TRACK_EVENT.BUTTON_CLICKED(window.location.pathname, "Parent Coaching", {
      location: "Parent Coaching",
      spring_doc_id: "famhp004",
    });
  };

  const { data: upcomingCoachAppointmentSlots } =
    useAllUpcomingAppointmentSlots(
      coach,
      AppointmentKind.Coaching,
      1,
      false,
      true,
    );

  const openSchedulingModal = () => {
    parentCoachingDisclosure.onClose();

    if (shouldShowNewInsuranceModal) {
      onScheduleModalOpen({
        kind: AppointmentKind.Coaching,
        provider: coach,
        providerRole: "Coach",
        action: AppointmentAction.Create,
        buttonText: t("carePlanSteps.confirmAppointment", { ns: "carePlan" }),
      });
    } else {
      const {
        initialStartTime: coachingInitialStartTime,
        medium: coachingMedium,
      } = getAppointmentSlotStartTimeAndMedium(
        upcomingCoachAppointmentSlots,
        coach,
      );

      dispatch(
        openModal(modalIds.careProviderScheduleModal, {
          ...coach,
          kind: AppointmentKind.Coaching,
          medium: coachingMedium,
          providerRole: "Coach",
          buttonText: t("carePlanSteps.confirmAppointment", { ns: "carePlan" }),
          action: AppointmentAction.Create,
          initialStartTime: coachingInitialStartTime,
        }),
      );
    }
  };

  const flyoutCallback = isCoachWithParentingSpecialty(coach)
    ? openSchedulingModal
    : null;

  return (
    <Box
      w="100%"
      p={24}
      borderWidth="2px"
      borderRadius="12px"
      borderColor="tertiary.light"
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <Heading variant="sm_v1" pb={8}>
        {t("familySection.coachingCard.heading")}
      </Heading>
      <Text
        pb={24}
        maxWidth={["100%", "100%", "347px", "100%"]}
        textAlign="center"
      >
        {t("familySection.coachingCard.connectWithACoach")}
      </Text>
      <VButton variant="low-emphasis" onClick={openParentCoachingFlyout}>
        {cardButtonText}
      </VButton>
      <ParentCoachingFlyout
        isOpen={parentCoachingDisclosure.isOpen}
        onClose={parentCoachingDisclosure.onClose}
        callback={flyoutCallback}
      />
    </Box>
  );
};
