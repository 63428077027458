import { useSelect } from "downshift";
import {
  FilterChip,
  Flex,
  List,
  ListItem,
  Text,
  useMediaQuery,
} from "@springcare/sh-component-library";
import { truncateString } from "@spring/immutability";
import { TRACK_EVENT } from "utils/mixpanel";

const FilterChipDropdown = ({ list, selectedItem, updateGoal }) => {
  const itemToString = (item) => {
    return item ? item.description : "";
  };

  const {
    isOpen,
    highlightedIndex,
    getToggleButtonProps,
    getMenuProps,
    getItemProps,
  } = useSelect({
    items: list,
    itemToString,
    selectedItem,
    onSelectedItemChange: ({ selectedItem: newSelectedItem }) => {
      TRACK_EVENT.BUTTON_CLICKED(window.location.pathname, "Progress Goal", {
        to: newSelectedItem?.description,
      });
      updateGoal(list.indexOf(newSelectedItem));
    },
  });

  const [isMobile] = useMediaQuery("(max-width: 767px)");
  const selectedItemText = isMobile
    ? truncateString(itemToString(selectedItem), 36)
    : itemToString(selectedItem);

  return (
    <Flex flexDirection="column" position="relative">
      <FilterChip
        variant="medium-emphasis"
        size="md"
        type="menu"
        marginRight="auto"
        _active={{
          background: "",
        }}
        {...getToggleButtonProps()}
      >
        <Text>{selectedItemText}</Text>
      </FilterChip>
      <List
        position="absolute"
        top={"44px"}
        zIndex={1}
        overflowY="scroll"
        marginRight="auto"
        borderRadius="16px"
        boxShadow="0px 8px 16px -6px rgba(72, 45, 40, 0.12)"
        border={isOpen ? "1px solid #E2E8F0" : ""}
        padding={isOpen ? "2" : ""}
        background="#FFFFFF"
        {...getMenuProps()}
      >
        {isOpen
          ? list?.map(
              (item, index) =>
                item?.description && (
                  <ListItem
                    padding="4px"
                    borderRadius="8px"
                    key={`goal_${index}`}
                    bgColor={
                      index === highlightedIndex
                        ? "hover-base-low-emphasis"
                        : undefined
                    }
                    {...getItemProps({ item, index })}
                  >
                    {item?.description}
                  </ListItem>
                ),
            )
          : null}
      </List>
    </Flex>
  );
};

export { FilterChipDropdown };
