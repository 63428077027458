//

import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { graphql } from "@apollo/client/react/hoc";
import { COLORS } from "@spring/smeargle";
import { get } from "lodash/fp";
import Meowth from "@spring/meowth";
import { AppointmentMedium, links } from "@spring/constants";
import { useTranslation, Trans } from "react-i18next";
import {
  track,
  TRACK_EVENT,
  makeEventString,
  EVENT_TYPE,
} from "utils/mixpanel";
import routes from "routes";

import { VerifyPhone } from "components";
import { getUserInfo } from "operations/queries/user";
import {
  Badge,
  Box,
  Button,
  HStack,
  Link,
  Text,
  VStack,
  VCopyIcon,
  VWorkplaceIcon,
  useCustomToast,
} from "@springcare/sh-component-library";

export class Map extends PureComponent {
  static propTypes = {
    locations: PropTypes.shape({
      forEach: PropTypes.func,
      length: PropTypes.number,
    }),
  };

  static map = null;
  static geocoder = null;

  componentDidMount() {
    // inserting script into head and loading it
    // https://cuneyt.aliustaoglu.biz/en/using-google-maps-in-react-without-custom-libraries/
    if (!window.google) {
      const googleMapsScript = document.createElement("script");
      googleMapsScript.type = "text/javascript";

      if (process.env.GOOGLE_MAPS_KEY) {
        googleMapsScript.src = `https://maps.google.com/maps/api/js?key=${process.env.GOOGLE_MAPS_KEY}&libraries=places`;
      }

      googleMapsScript.defer = true;
      googleMapsScript.async = true;

      const firstScript = document.getElementsByTagName("script")[0];
      firstScript?.parentNode?.insertBefore(googleMapsScript, firstScript);

      // cannot access google.maps until it's finished loading
      googleMapsScript.addEventListener("load", () => {
        this.initMap();
      });
    } else {
      this.initMap();
    }
  }

  initMap = () => {
    const maps = window.google.maps;
    this.map = new maps.Map(document.getElementById("google-map"), {
      styles: COLORS.GOOGLE_MAPS,
    });
    this.geocoder = new maps.Geocoder();

    const bounds = new maps.LatLngBounds();

    this.props.locations.forEach((l) => {
      let marker;

      this.geocoder.geocode({ address: l }, (results, status) => {
        if (status === "OK") {
          const pos = results[0].geometry.location;

          marker = new maps.Marker({
            map: this.map,
            position: new maps.LatLng(pos.lat(), pos.lng()),
          });

          if (this.props.locations.length > 1) {
            bounds.extend(marker.position);
          } else {
            this.map.setZoom(14);
            this.map.setCenter(marker.position);
          }
        }
      });
    });

    if (this.props.locations.length > 1) {
      this.map.fitBounds(bounds);
    }
  };

  render() {
    return (
      <div
        style={{ height: "50vh", marginBottom: 32, ...this.props.style }}
        id="google-map"
      />
    );
  }
}

const AppointmentLocation = (props) => {
  const { t } = useTranslation("careVisits");
  const copiedToast = useCustomToast({
    type: "base",
    message: "Copied",
    duration: 5000,
    layout: "fit-content",
  });

  const onCopyClicked = async () => {
    // copy text
    await navigator.clipboard.writeText(props.appointmentLocation);
    copiedToast();
    track("Appointments -- Copied Zoom Link", {
      deprecated: true,
      replaced_with: makeEventString(EVENT_TYPE.BUTTON_CLICKED, {
        page: routes.MemberAppointmentDetail.as,
        type: "Appointments -- Copied Zoom Link",
      }),
    });
    TRACK_EVENT.BUTTON_CLICKED(
      window.location.pathname,
      "Appointments -- Copied Video Link",
    );
  };

  const trackAppointment = () => {
    // example URL: https://host/session/:participant_id/:secondaryMtgId
    const url = new URL(props.appointmentLocation);

    track("Zoom Appointment Location", {
      deprecated: true,
      replaced_with: makeEventString(EVENT_TYPE.LINK_CLICKED, {
        page: routes.MemberAppointmentDetail.as,
        type: "Appointment Location",
      }),
    });

    TRACK_EVENT.BUTTON_CLICKED(
      window.location.pathname,
      "Video Appointment Location",
      {
        appointment_id: props?.appointmentId,
        provider_PMI: url.pathname.split("/")[3],
      },
    );
  };

  const handleAppointmentClick = (route) => {
    trackAppointment();
    window.open(route, "_blank");
  };

  if (props.medium === AppointmentMedium.InPerson) {
    return (
      <>
        <Box mt={32} mb={8}>
          <Text size="body-medium-strong">
            {t("appointmentDetails.appointmentLocation.location")}
          </Text>
        </Box>
        {props.onSite && (
          <Box mt={10}>
            <Badge icon={VWorkplaceIcon} colorScheme="positive">
              {t("appointmentDetails.appointmentLocation.onSite")}
            </Badge>
          </Box>
        )}
        <Box mt={20}>
          <Map locations={[props.appointmentLocation]} />
        </Box>
      </>
    );
  }

  if (props.medium === AppointmentMedium.Phone) {
    return (
      <VerifyPhone
        phone={get("data.user.phone", props)}
        editLinkAlias="MemberSettings"
      />
    );
  }

  if (props.medium === AppointmentMedium.Video) {
    return (
      <VStack
        my={28}
        alignItems="flex-start"
        bgColor="platform.25"
        p={24}
        borderRadius={16}
      >
        <Text fontSize={21} fontWeight={600}>
          {t("appointmentDetails.joinAppointment")}
        </Text>
        <HStack pb={2}>
          <Button
            variant="high-emphasis"
            height="v-lg"
            onClick={() => handleAppointmentClick(props.appointmentLocation)}
            _focus={{ outline: "0px solid transparent !important" }}
            _focusVisible={{ outline: "2px solid black !important" }}
          >
            {t("appointmentDetails.startAppointment")}
          </Button>

          <Box onClick={onCopyClicked}>
            <VCopyIcon
              width={22}
              height={22}
              color="var(--chakra-colors-primary-base)"
              cursor="pointer"
            />
          </Box>
        </HStack>
        <Text>
          <Trans
            t={t}
            i18nKey={`appointmentDetails.providerGracePeriod`}
            components={[
              <Link
                href={links.Support}
                isExternal
                letterSpacing="normal"
                key={`appointmentDetails.providerGracePeriod`}
              />,
            ]}
          />
        </Text>
      </VStack>
    );
  }

  return null;
};

AppointmentLocation.propTypes = {
  appointmentLocation: PropTypes.any,
  medium: PropTypes.any,
  onSite: PropTypes.bool,
  trackingData: PropTypes.object,
};

export default graphql(getUserInfo, { options: Meowth.apolloOptionsUserId })(
  AppointmentLocation,
);
