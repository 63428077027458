import {
  Heading,
  ListItem,
  Text,
  UnorderedList,
  VStonesDuotoneSpot,
  VStonesFlatSpot,
} from "@springcare/sh-component-library";
import { InformationFlyout } from "components";
import { useTranslation } from "react-i18next";

type MomentsFlyoutType = {
  isOpen: boolean;
  onClose: () => void;
};

const CommonConcerns = () => {
  const { t } = useTranslation("homepage", {
    keyPrefix: "careInfoFlyout.moments",
  });
  const listItems = [];

  for (let i = 1; i <= 3; i++) {
    listItems.push(
      <ListItem key={`moments_common_concerns_${i}`}>
        <Text display="inline" size="body-medium-strong">
          {t(`commonConcerns.item${i}Q`)}
        </Text>{" "}
        {t(`commonConcerns.item${i}A`)}
      </ListItem>,
    );
  }

  return (
    <>
      <Heading size="heading-small" mb="v-8">
        {t("commonConcerns.header")}
      </Heading>
      <UnorderedList mb={24}> {listItems}</UnorderedList>
    </>
  );
};

const MomentsFlyoutBody = () => {
  const { t } = useTranslation("homepage", {
    keyPrefix: "careInfoFlyout.moments",
  });
  return (
    <>
      <Text mb={40}>{t("accessToMomentsIsFree")}</Text>
      <Heading size="heading-small" mb="v-8">
        {t("whyIsThisRecommendedHeader")}
      </Heading>
      <Text mb={24}>{t("whyIsThisRecommendedBody")}</Text>
      <Heading size="heading-medium" mb="v-8">
        {t("benefitsHeader")}
      </Heading>
      <Text mb={24}>{t("benefitsBody")}</Text>
      <Heading size="heading-small" mb="v-8">
        {t("whatToExpectHeader")}
      </Heading>
      <Text mb={24}>{t("whatToExpectBody")}</Text>
      <CommonConcerns />
    </>
  );
};

export const MomentsFlyout = ({ isOpen, onClose }: MomentsFlyoutType) => {
  const { t } = useTranslation("homepage", {
    keyPrefix: "careInfoFlyout.moments",
  });

  return (
    <InformationFlyout
      headerText={t("heading")}
      headerIcon={<VStonesFlatSpot />}
      heroIllustration={VStonesDuotoneSpot}
      isOpen={isOpen}
      onClose={onClose}
      ctaText={t("ctaText")}
      ctaLink="/members/moments"
    >
      <MomentsFlyoutBody />
    </InformationFlyout>
  );
};
