import { useTranslation } from "hooks/react-i18next";
import { useQuery, useMutation } from "@apollo/client";
import { ReactElement, useEffect } from "react";

import {
  Text,
  ModalSheet,
  HStack,
  Button,
  FieldValues,
  useForm,
} from "@springcare/sh-component-library";

import { TRACK_EVENT } from "utils/mixpanel";
import Meowth from "@spring/meowth";
import { Spinner, SHNotification, useToast } from "design-system/components";

import { getMemberInsurance } from "operations/queries/member";
import InsuranceForm from "./InsuranceForm";
import { updateMemberInsurance } from "operations/mutations/member";

const InsuranceVerificationModal = ({
  trackingProperties,
  isOpen,
  onClose,
}) => {
  const { t } = useTranslation(["limitedLangSettings"]);

  const { data: insuranceData, loading: insuranceLoading } = useQuery(
    getMemberInsurance,
    {
      variables: {
        id: Meowth.getUserId(),
      },
      skip: !isOpen,
    },
  );

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({ mode: "onTouched" });

  useEffect(() => {
    reset(insuranceData?.user?.member?.insurance_policy);
  }, [insuranceData, reset]);

  const [updateInsurance, { loading: mutationLoading }] = useMutation(
    updateMemberInsurance,
    {
      onCompleted: () => {
        onClose();
        createToast(t("insurance.detailsSaved"), "success");
      },
      onError: (_err) => createToast(t("notifications.genericError"), "error"),
    },
  );

  const toast = useToast();
  const createToast = (text: string, status: string) => {
    toast({
      containerStyle: {
        maxWidth: "100%",
        width: "80%",
      },
      render: () => (
        <SHNotification notification={{ text: text, status: status }} />
      ),
    });
  };

  const onSubmit = (data: FieldValues) => {
    updateInsurance({
      variables: {
        member_id: insuranceData?.user?.member?.id,
        carrier_name: data.carrier_name,
        plan_name: data.plan_name,
        insurance_group_id: data.insurance_group_id,
        insurance_member_id: data.insurance_member_id,
      },
    });
  };

  const Footer = (): ReactElement => {
    return (
      <HStack display="flex" justifyContent="flex-end">
        <Button
          onClick={onClose}
          variant="medium-emphasis"
          size="md"
          tabIndex={2}
        >
          {t("insurance.cancelSaveDetails")}
        </Button>
        <Button
          isLoading={isSubmitting || mutationLoading}
          type="submit"
          onClick={handleSubmit((data) => {
            TRACK_EVENT.BUTTON_CLICKED(
              window.location.pathname,
              "Save Insurance Info",
              trackingProperties,
            );
            onSubmit(data);
          })}
          variant="high-emphasis"
          size="md"
          tabIndex={1}
        >
          {t("insurance.saveDetails")}
        </Button>
      </HStack>
    );
  };

  return (
    <ModalSheet
      isOpen={isOpen}
      onClose={onClose}
      title={t("insurance.verifyDetails")}
      variant={"center"}
      footerChildren={Footer()}
      size="lg"
    >
      <>
        <Text mb={16}>{t("insurance.upToDate")}</Text>
        {!insuranceLoading && (
          <InsuranceForm
            register={register}
            errors={errors}
            handleSubmit={handleSubmit(onSubmit)}
          />
        )}
        {insuranceLoading && <Spinner />}
      </>
    </ModalSheet>
  );
};

export default InsuranceVerificationModal;
