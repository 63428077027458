import { useState } from "react";
import { SHTextArea, SHStickyFooter } from "design-system/components";
import { Button, Flex, Box } from "@springcare/sh-component-library";
import { useTranslation } from "react-i18next";

type TeenAssessmentTextAreaProps = {
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  submitQuestionResponse: (value: string) => void;
};

export const TeenAssessmentTextArea = ({
  value,
  setValue,
  submitQuestionResponse,
}: TeenAssessmentTextAreaProps): React.ReactElement => {
  const { t } = useTranslation("assessments");
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] =
    useState<boolean>(false);

  const handleFinishAssessment = (value: string): void => {
    setIsSubmitButtonDisabled(!isSubmitButtonDisabled);
    submitQuestionResponse(value);
  };

  return (
    <Box mt={17} width={[325, 450, 650, 716]}>
      <Box width={[325, 450, 650, 716]}>
        <SHTextArea
          value={value}
          onChange={setValue}
          placeholder={`${t("teenAssessments.textAreaPlaceHolder")}`}
        />
      </Box>
      <SHStickyFooter left={"0"} height={"30vh"}>
        <Flex
          flexDirection="column"
          justifyContent="space-evenly"
          alignItems="center"
          paddingTop="v-24"
          paddingBottom="v-40"
        >
          <Button
            w={["329px", "397px", "397px", "397px"]}
            variant="high-emphasis"
            colorScheme="positive"
            size="lg"
            mt={"10vh"}
            onClick={() => handleFinishAssessment(value)}
            data-cy="teen-assessment-question-next-button"
            isDisabled={isSubmitButtonDisabled}
          >
            {t("teenAssessments.sIBTextArea.submit")}
          </Button>
        </Flex>
      </SHStickyFooter>
    </Box>
  );
};
