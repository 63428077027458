import { useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  Center,
  VAvatarDuotoneSpot,
  VStack,
  Heading,
  HStack,
  Text,
  Avatar,
  useBreakpointValue,
  Button,
  Box,
  useMediaQuery,
} from "@springcare/sh-component-library";
import { SHStickyFooter } from "design-system/components";
import ConsentCheckbox from "components/templates/RegisterPage/components/ConsentCheckbox";
import { normalizeToISO2 } from "utils/global";
import { useRegisterContext } from "context/RegisterContext";
import { useTranslation, Trans } from "react-i18next";
import { UpdateConsentProps } from "./types";
import { TRACK_EVENT } from "utils/mixpanel";
import Router from "next/router";

export const UpdateConsent: React.FC<UpdateConsentProps> = ({
  handleUpdateConsent,
}) => {
  useEffect(() => {
    TRACK_EVENT.COMPONENT_VIEWED(Router.asPath, "Aged Out Update Consent", {
      spring_doc_id: "agingOut003",
    });
  }, []);

  const {
    setValue,
    watch,
    formState: { errors },
    register,
  } = useForm({
    mode: "onTouched",
  });
  const { t } = useTranslation("accountTransition");
  const [isMobile] = useMediaQuery("(max-width: 450px)");

  const { currentView, global } = useRegisterContext();

  const isCountryUS = normalizeToISO2(global?.country) === "US";

  const hippaPrivacyPolicyChecked = watch("hipaa_privacy_policy");
  const informedConsentChecked = watch("informed_consent");
  const privacyPoliciesChecked = watch("privacy_policies");

  const electronicCommsConsentChecked = watch("electronic_comm_consent");
  const healthInfoConsentChecked = watch("health_info_consent");

  const countryBasedConsentsChecked = isCountryUS
    ? hippaPrivacyPolicyChecked
    : electronicCommsConsentChecked && healthInfoConsentChecked;

  const allConsentsAreChecked =
    informedConsentChecked &&
    privacyPoliciesChecked &&
    countryBasedConsentsChecked;

  const buttonMarginVal = useBreakpointValue([50, 50, 100]);

  const handleButtonClick = () => {
    TRACK_EVENT.BUTTON_CLICKED(Router.asPath, "Aged Out Update Consent", {
      spring_doc_id: "agingOut004",
    });

    handleUpdateConsent();
  };

  return (
    <>
      <Center marginTop={[30, 132]}>
        <VStack m={"v-40"} alignItems="flex-start">
          <Box>
            <VAvatarDuotoneSpot aria-hidden={true} />
          </Box>
          <Heading size="heading-medium" as="h1">
            {t("updateConsent.heading")}
          </Heading>
          <Text size="body-medium-regular">
            {t("updateConsent.subHeading")}
          </Text>
          <Box>
            <HStack marginY="v-16">
              <Avatar name="1" size="md" marginInlineEnd="v-16" />
              <Trans i18nKey="updateConsent.consentCopyOneBold">
                <Text>{t("updateConsent.consentCopyOneBold")}</Text>
              </Trans>
            </HStack>
          </Box>
          <HStack>
            <Avatar name="2" size="md" marginInlineEnd="v-16" />
            <Text size="body-medium-strong">
              {t("updateConsent.consentCopyTwo")}
            </Text>
          </HStack>
          <Box pt="32px" w={["100%", "75%"]}>
            <ConsentCheckbox
              currentView={currentView}
              errors={errors}
              isCountryUS={isCountryUS}
              register={register}
              setValue={setValue}
            />
          </Box>
          <Center w="100%" style={{ marginTop: buttonMarginVal }}>
            {!isMobile ? (
              <Button
                w={[340, 390]}
                isDisabled={!allConsentsAreChecked}
                variant="high-emphasis"
                size="md"
                onClick={handleButtonClick}
              >
                {t("updateConsent.consentCTALabel")}
              </Button>
            ) : (
              <SHStickyFooter height={100} width={["340px"]}>
                <Button
                  w={"100%"}
                  isDisabled={!allConsentsAreChecked}
                  mt="v-24"
                  mb="v-8"
                  onClick={handleButtonClick}
                >
                  {t("updateConsent.consentCTALabel")}
                </Button>
              </SHStickyFooter>
            )}
          </Center>
        </VStack>
      </Center>
    </>
  );
};
