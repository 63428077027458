import React, { ReactNode } from "react";
import { Flex } from "@springcare/sh-component-library";

export interface RowProps {
  children: ReactNode;
}

export const Row: React.FC<RowProps> = ({ children }) => {
  return (
    <Flex width="100%" justifyContent="space-between">
      {children}
    </Flex>
  );
};

Row.displayName = "Row";
