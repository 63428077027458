import { Box } from "@springcare/sh-component-library";
import { TimeSlotGroup } from "modules/MemberDashboard/Scheduling/components";
import { groupAppointmentsByTimePeriod } from "modules/MemberDashboard/Scheduling/utils";
import type { TimeSlotsPropType } from "modules/MemberDashboard/Scheduling/types";

export const TimeSlotGroups = ({
  appointments,
  handleTimeSlotClick,
  selectedTimeSlot,
}: TimeSlotsPropType) => {
  const groupedAppointments = groupAppointmentsByTimePeriod(appointments);
  return (
    <Box mt={16}>
      <TimeSlotGroup
        groupedAppointments={groupedAppointments.morning}
        handleTimeSlotClick={handleTimeSlotClick}
        selectedTimeSlot={selectedTimeSlot}
        key="morning-time-slot-group"
        type="morning"
      />

      <TimeSlotGroup
        groupedAppointments={groupedAppointments.afternoon}
        handleTimeSlotClick={handleTimeSlotClick}
        selectedTimeSlot={selectedTimeSlot}
        key="afternoon-time-slot-group"
        type="afternoon"
      />

      <TimeSlotGroup
        groupedAppointments={groupedAppointments.night}
        handleTimeSlotClick={handleTimeSlotClick}
        selectedTimeSlot={selectedTimeSlot}
        key="evening-time-slot-group"
        type="evening"
      />
    </Box>
  );
};
