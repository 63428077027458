import {
  getAppointmentMediumForTracking,
  getAppointmentKindForTracking,
} from "@spring/constants";

import { COST_ESTIMATE_DISPLAY_STATES } from "components/templates/CostEstimation/constants";

export const getModalLocation = (routes, mpLocation, eventProps) => {
  const isBenefitsPage = window.location.pathname.includes(
    routes.MemberBenefits.as,
  );
  const isHomePage = window.location.pathname.includes(routes.MemberHome.as);
  const isCareVisitsPage = window.location.pathname.includes(
    routes.MemberCareVisits.as,
  );
  if (isBenefitsPage) {
    return "Benefit Cards";
  }
  if (isHomePage) {
    return mpLocation;
  }
  if (isCareVisitsPage && eventProps.appointment_type === "Care Navigation") {
    return "Your Care Navigator";
  }
  return mpLocation || "Appointment Confirmation";
};

export const getEventProps = (
  provider,
  kind,
  medium,
  therapyTopSpecialtiesFlag,
) => {
  return {
    provider_id: provider?.id,
    appointment_type: getAppointmentKindForTracking(kind),
    appointment_medium: getAppointmentMediumForTracking(medium),
    top_specialties: getTopTags(provider, therapyTopSpecialtiesFlag),
  };
};

const getTopTags = (provider, therapyTopSpecialtiesFlag) => {
  if (therapyTopSpecialtiesFlag && provider.top_tags?.length > 0) {
    return provider.top_tags?.sort()?.join(", ");
  }
  return "";
};

export const lookupModalTitle = (t) => ({
  [COST_ESTIMATE_DISPLAY_STATES.INITIAL_DISPLAY]: t(
    "modal.initialDisplayHeading",
  ),
  [COST_ESTIMATE_DISPLAY_STATES.SELF_PAY_DISPLAY]: t("modal.selfPayHeading"),
  [COST_ESTIMATE_DISPLAY_STATES.INSURANCE_FORM]: t(
    "modal.insuranceFormHeading",
  ),
  [COST_ESTIMATE_DISPLAY_STATES.CANCELLATION_POLICY]: t(
    "modal.cancellationPolicyHeading",
  ),
});

export const getModalPlacement = (
  isMobile,
  displayState,
  showScheduleContent,
) => {
  if (isMobile) {
    return "bottom";
  } else if (
    (!showScheduleContent &&
      displayState === COST_ESTIMATE_DISPLAY_STATES.INITIAL_DISPLAY) ||
    displayState === COST_ESTIMATE_DISPLAY_STATES.CANCELLATION_POLICY
  ) {
    return "center";
  }
  return "center-scroll";
};
