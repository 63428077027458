import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useRouter } from "next/router";
import {
  Box,
  Button,
  Flex,
  Heading,
  Spinner,
  TextButton,
  useMediaQuery,
  VArrowLeftIcon,
  VStack,
  useCustomToast,
  useTheme,
} from "@springcare/sh-component-library";
import {
  AppointmentDetails,
  CareNavigatorInfo,
  ImmediateSupport,
} from "modules/MemberDashboard/Scheduling/components";
import type {
  CareProviderType,
  TimeSlotType,
} from "modules/MemberDashboard/Scheduling/types";
import routes from "routes";
import { useLimitCalls } from "hooks/useLimitCalls";
import { TRACK_EVENT } from "utils/mixpanel";
import { SHStickyFooter } from "design-system/components";

type Member = {
  cohort: {
    customer: {
      id: string;
      trial: boolean;
    };
  };
};

type BookingReviewPropType = {
  bookedAppointment: string;
  careProvider: CareProviderType;
  selectedTimeSlot: TimeSlotType;
  isBookingAppointment: boolean;
  handleBackToCalendar?: () => void;
  handleBookAppointment: () => void;
  showSUDCN: boolean;
  member: Member;
};

export const BookingReview = ({
  bookedAppointment,
  careProvider,
  selectedTimeSlot,
  isBookingAppointment,
  handleBackToCalendar,
  handleBookAppointment,
  showSUDCN,
  member,
}: BookingReviewPropType) => {
  const Router = useRouter();
  const { t } = useTranslation("scheduling", { keyPrefix: "bookingReview" });
  const { t: tCareProvider } = useTranslation("careProvider");
  const { breakpoints } = useTheme();
  const [isDesktop] = useMediaQuery(`(min-width: ${breakpoints.lg})`);
  const [trackOnce] = useLimitCalls();

  useEffect(() => {
    trackOnce(() =>
      TRACK_EVENT.COMPONENT_VIEWED(window.location.pathname, "Booking Review", {
        provider_id: selectedTimeSlot.payload.careProviderId,
        start_at: selectedTimeSlot.timestamp,
      }),
    );
  }, [
    selectedTimeSlot.payload.careProviderId,
    selectedTimeSlot.timestamp,
    trackOnce,
  ]);

  useEffect(() => {
    if (bookedAppointment) {
      const { to, as } = routes.MemberAppointmentDetail;
      Router.replace(
        {
          pathname: to,
          query: { id: bookedAppointment },
        },
        as.replace(":id", bookedAppointment),
      );
    }
  }, [bookedAppointment, Router]);

  const showTrialBookingToast = useCustomToast({
    type: "error",
    message: tCareProvider("trialBooking.toast"),
    action: "off",
    layout: "fit-content",
    duration: 5000,
  });

  const handleBookingButton = () => {
    const isTrialCustomer = member?.cohort?.customer?.trial;

    if (isTrialCustomer) {
      return showTrialBookingToast();
    }

    return handleBookAppointment();
  };

  if (isBookingAppointment || bookedAppointment) {
    return (
      <Box
        height="100vh"
        display="flex"
        justifyContent="center"
        alignItems="center"
        data-testid="calendar-loading-spinner"
      >
        <Spinner speed="1s" width={100} height={100} />
      </Box>
    );
  }

  return (
    <Box w="100%" m="0 auto" px={{ lg: 32 }}>
      {handleBackToCalendar && (
        <TextButton
          icon={VArrowLeftIcon}
          fontSize={16}
          mb={8}
          onClick={handleBackToCalendar}
          colorScheme="neutral"
          mt={[32, 0]}
        >
          {t("availability")}
        </TextButton>
      )}
      <Heading
        as="h1"
        size="heading-medium"
        mb={8}
        pt={!handleBackToCalendar && "24px"}
        pb={!handleBackToCalendar && "16px"}
      >
        {showSUDCN ? t("specializedHeading") : t("heading")}
      </Heading>
      <Flex direction={{ base: "column", lg: "row" }}>
        <CareNavigatorInfo careProvider={careProvider} showSUDCN={showSUDCN} />
        <VStack w="100%" maxW={{ lg: 298 }} mt="v-40" gap={16}>
          <AppointmentDetails selectedTimeSlot={selectedTimeSlot} />
          <ImmediateSupport />
          {!isDesktop ? (
            <SHStickyFooter height={64} left="0">
              <Flex justify="end" px={24} py={8}>
                <Button onClick={handleBookingButton} width={["100%", "auto"]}>
                  {t("bookAppointment")}
                </Button>
              </Flex>
            </SHStickyFooter>
          ) : (
            <Button width="100%" onClick={handleBookingButton}>
              {t("bookAppointment")}
            </Button>
          )}
        </VStack>
      </Flex>
    </Box>
  );
};
