import { createIcon } from "@springcare/sh-component-library";

export const FoldedDocument = createIcon({
  displayName: "FoldedDocument",
  viewBox: "0 0 24 24",
  path: (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="24" fill="none">
      <path
        fill="currentColor"
        d="M4 13a1 1 0 0 1 1-1h8a1 1 0 1 1 0 2H5a1 1 0 0 1-1-1ZM5 16a1 1 0 1 0 0 2h5a1 1 0 1 0 0-2H5Z"
      />
      <path
        fill="currentColor"
        fill-rule="evenodd"
        d="M6.293.293A1 1 0 0 1 7 0h8a3 3 0 0 1 3 3v18a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V7a1 1 0 0 1 .293-.707l6-6ZM15 2H8v3a3 3 0 0 1-3 3H2v13a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1ZM3.414 6H5a1 1 0 0 0 1-1V3.414L3.414 6Z"
        clip-rule="evenodd"
      />
    </svg>
  ),
  defaultProps: {
    "aria-label": "Folded Document icon",
    role: "img",
    color: "tertiary.700",
  },
});
