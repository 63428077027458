import React, { ReactNode } from "react";
import { Flex } from "@springcare/sh-component-library";
import { PlayerControls } from "./PlayerControls";

export interface FooterProps {
  children?: ReactNode;
}

export const Footer: React.FC<FooterProps> = ({ children }) => {
  return (
    <Flex
      position="absolute"
      width="100%"
      height="auto"
      flexDirection="column"
      justifyContent="flex-end"
      alignItems="flex-end"
      gap="v-8"
      paddingTop="v-8"
      paddingLeft="v-12"
      paddingBottom="v-8"
      paddingRight="v-12"
      bottom="0"
    >
      <PlayerControls.Timeline />
      {children}
    </Flex>
  );
};

Footer.displayName = "Footer";
