import { DateTime } from "luxon";
import { Text } from "@springcare/sh-component-library";
import { useTranslation, Trans } from "react-i18next";

export const RequestedDateText = ({ requestDateInISO }) => {
  const { t } = useTranslation("costEstimate");
  const today = DateTime.now();
  const todayFormatted = today.toLocaleString({
    month: "long",
    day: "numeric",
    weekday: "long",
  });

  const requestDate = DateTime.fromISO(requestDateInISO);
  const requestDateFormatted = requestDate.toLocaleString({
    month: "long",
    day: "numeric",
    weekday: "long",
  });

  const timeSinceRequestMade = today.diff(requestDate, "days").toObject();
  const humanReadableTimeDiff = Math.trunc(timeSinceRequestMade.days);

  return (
    <Text>
      <Trans
        t={t}
        i18nKey={
          humanReadableTimeDiff <= 1
            ? "interpolatedText.requestedTimeToday"
            : "interpolatedText.requestedTime"
        }
        components={{
          BoldText: <Text as="span" size="body-medium-strong" />,
        }}
        values={
          humanReadableTimeDiff <= 1
            ? { formattedDate: todayFormatted }
            : {
                formattedDate: requestDateFormatted,
                daysSinceRequested: humanReadableTimeDiff,
              }
        }
      />
    </Text>
  );
};
