import { createIcon } from "@springcare/sh-component-library";

export const Delete = createIcon({
  displayName: "Delete",
  viewBox: "0 0 24 24",
  // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
  path: (
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12ZM12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1ZM7.09294 16.8993C6.70242 16.5088 6.70242 15.8756 7.09294 15.4851L10.5858 11.9923L7.36112 8.76761C6.97059 8.37709 6.97059 7.74392 7.36112 7.3534C7.75164 6.96287 8.38481 6.96287 8.77533 7.3534L12 10.5781L15.2247 7.3534C15.6152 6.96287 16.2484 6.96287 16.6389 7.3534C17.0294 7.74392 17.0294 8.37709 16.6389 8.76761L13.4142 11.9923L16.9071 15.4851C17.2976 15.8756 17.2976 16.5088 16.9071 16.8993C16.5165 17.2899 15.8834 17.2899 15.4928 16.8993L12 13.4065L8.50716 16.8993C8.11663 17.2899 7.48347 17.2899 7.09294 16.8993Z"
    />
  ),
  defaultProps: {
    "aria-label": "Delete icon",
    role: "img",
  },
});
