import { getAppointmentKindForTracking } from "@spring/constants";
import {
  HStack,
  RadioButton,
  RadioGroup,
  Text,
  VAdultCareIcon,
  VCoupleCareIcon,
  RadioButtonProps,
} from "@springcare/sh-component-library";
import { useEffect, useRef, useState } from "react";
import { useRouter } from "next/router";
import { useProviderBrowseContext } from "context/ProviderBrowseContext";
import { Heading } from "design-system/components";
import { useInViewportOnce } from "hooks";
import { useTranslation } from "hooks/react-i18next";
import { RequestableAppointmentKindEnum } from "modules/shared/graphql-codegen/graphql";
import { TRACK_EVENT } from "utils/mixpanel";
import { TherapyButtonValueType, TherapyTypeProps } from "./types";

export const TherapyTypeSection: React.FC<TherapyTypeProps> = ({
  careProviderTags,
  setSelectedTherapyType,
}) => {
  const { selectedFilters } = useProviderBrowseContext();
  const router = useRouter();

  const isCouplesFilterSelected =
    selectedFilters?.filters.specialties.includes("Couples");
  const hasCouplesFilterQueryParam = Boolean(router.query.couplesFilter);
  const shouldInitiallySelectCouplesType =
    isCouplesFilterSelected || hasCouplesFilterQueryParam;
  const initialButtonValue: TherapyButtonValueType =
    shouldInitiallySelectCouplesType
      ? RequestableAppointmentKindEnum.CouplesTherapy
      : RequestableAppointmentKindEnum.Therapy;

  const [buttonValue, setButtonValue] =
    useState<TherapyButtonValueType>(initialButtonValue);

  useEffect(() => {
    setSelectedTherapyType(initialButtonValue);
  }, [initialButtonValue]);

  const ref = useRef();
  const therapyTypeInViewport = useInViewportOnce(ref);
  const [viewedTherapyTypeOnce, setViewedTherapyTypeOnce] = useState(false);

  useEffect(() => {
    if (therapyTypeInViewport && !viewedTherapyTypeOnce) {
      TRACK_EVENT.COMPONENT_VIEWED(
        window.location.pathname,
        "Therapy Type radio buttons",
        {
          location: "Care Provider Schedule Modal",
          spring_doc_id: "couples_therapy_004",
          initially_selected_therapy_type:
            getAppointmentKindForTracking(initialButtonValue),
        },
      );
      setViewedTherapyTypeOnce(true);
    }
  }, [therapyTypeInViewport, viewedTherapyTypeOnce, initialButtonValue]);

  const { t } = useTranslation("careProvider");

  const offersIndividualText = t("scheduleModal.therapyType.offersIndividual");

  const handleSelectTherapyType = (value: TherapyButtonValueType): void => {
    setButtonValue(value);
    setSelectedTherapyType(value);

    TRACK_EVENT.BUTTON_CLICKED(
      window.location.pathname,
      "Therapy Type radio buttons",
      {
        location: "Care Provider Schedule Modal",
        spring_doc_id: "couples_therapy_005",
        selected_therapy_type: getAppointmentKindForTracking(value),
      },
    );
  };

  // can't make these icons aria-hidden due to component-library limitations
  // ticket to update component-library is DSM-1366 (https://springhealth.atlassian.net/browse/DSM-1366)
  // once that is done, we can update the icons here to be aria-hidden
  const radioOptions: RadioButtonProps[] = [
    {
      radio: { value: RequestableAppointmentKindEnum.Therapy },
      icon: {
        component: VAdultCareIcon,
        alt: "",
      },
      label: t("scheduleModal.therapyType.individual"),
    },
    {
      radio: { value: RequestableAppointmentKindEnum.CouplesTherapy },
      icon: {
        component: VCoupleCareIcon,
        alt: "",
      },
      label: t("scheduleModal.therapyType.couples"),
    },
  ];

  const offersCouplesTherapy = careProviderTags.some(
    (tag) => tag.kind === "SPECIALTY" && tag.name === "Couples",
  );

  return (
    <>
      <Heading variant="sm_v1" pt="v-16" pb="v-8">
        {t("scheduleModal.therapyType.heading")}
      </Heading>

      {!offersCouplesTherapy && <Text pb="v-24">{offersIndividualText}</Text>}

      {offersCouplesTherapy && (
        <RadioGroup
          ref={ref}
          value={buttonValue}
          pb="v-40"
          defaultValue={initialButtonValue}
        >
          <HStack pt="v-8">
            {radioOptions.map((radioOption) => (
              <RadioButton
                key={radioOption.radio.value}
                {...radioOption}
                onClick={() =>
                  handleSelectTherapyType(
                    radioOption.radio.value as TherapyButtonValueType,
                  )
                }
                indicator="end"
                card={{
                  sx: {
                    p: "v-16",
                    "& div": {
                      alignItems: "center",
                      display: "inline-grid",
                    },
                  },
                }}
              />
            ))}
          </HStack>
        </RadioGroup>
      )}
    </>
  );
};
