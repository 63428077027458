import { Card, Heading, Text } from "@springcare/sh-component-library";

/* Strictly presentational component
 See https://www.figma.com/file/7Gr8rbMyzeDTXR39VS3LC1/MX-Billing-and-payments?type=design&node-id=1210-23535&mode=dev
 for designs, but generally speaking, it's a card with text
*/

type MessageCardProps = {
  heading: string;
  text: JSX.Element;
};

export const MessageCard = ({ heading, text }: MessageCardProps) => {
  return (
    <Card
      variant="medium-emphasis"
      borderColor="background-subtle"
      minWidth="100%"
      paddingX={[24, 24, 32, 32]}
      _hover={{ borderColor: "background-subtle" }}
    >
      <>
        <Heading letterSpacing="normal" size="heading-small" paddingBottom={8}>
          {heading}
        </Heading>
        <Text>{text}</Text>
      </>
    </Card>
  );
};
