import { useQuery } from "@apollo/client";
import getInsuranceEligibilityCheck from "operations/queries/member/insurance/getInsuranceEligibilityCheck";
import { InsuranceEligibilityCheck } from "modules/shared/graphql-codegen/graphql";

interface InsuranceEligibilityCheckProps {
  member_id: string;
  insurance_member_id: string;
  insurance_payer_id: string;
  on_success_create_coverage: boolean;
}

interface InsuranceEligibilityCheckResponse {
  insurance_eligibility_check: InsuranceEligibilityCheck;
}

export function useInsuranceEligibilityCheck(
  member_id: string,
  insurance_member_id: string,
  insurance_payer_id: string,
  on_success_create_coverage = false,
) {
  return useQuery<
    InsuranceEligibilityCheckResponse,
    InsuranceEligibilityCheckProps
  >(getInsuranceEligibilityCheck, {
    variables: {
      member_id: member_id,
      insurance_member_id: insurance_member_id,
      insurance_payer_id: insurance_payer_id,
      on_success_create_coverage: on_success_create_coverage,
    },
    skip: !member_id || !insurance_member_id || !insurance_payer_id,
  });
}
