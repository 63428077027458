import { Flex, Text } from "@springcare/sh-component-library";
import { LeftRays, RightRays } from "design-system/assets";
import { useTranslation } from "hooks/react-i18next";

type Props = {
  title?: string;
  mb?: string;
};

export const BooksFastBadge = ({ mb = "8px" }: Props) => {
  const { t } = useTranslation("careProvider");

  return (
    <Flex alignItems="center" mb={mb}>
      <LeftRays />
      <Text size="body-medium-strong" color="primary-bold" ml="2px" mr="2px">
        {t("benefitsSummary.booksFast")}
      </Text>
      <RightRays />
    </Flex>
  );
};
