import { useTranslation } from "react-i18next";
import { Heading, Text, Box } from "@springcare/sh-component-library";
import { useMemberInsurance } from "hooks";
import { InsuranceForm } from "components/templates/CostEstimation/components/shared";

export const SettingsInsuranceForm = () => {
  const { t } = useTranslation("limitedLangSettings");
  const { data: insuranceData, loading: insuranceDataLoading } =
    useMemberInsurance();

  if (insuranceDataLoading) return null;
  return (
    <Box width="100%" padding={[8, 8, "initial"]} mb="40px">
      <Heading size="heading-medium" as="h2" marginBottom={8}>
        {t("newInsuranceForm.primaryHeading")}
      </Heading>
      <Text mb={24}>{t("newInsuranceForm.subtitle")}</Text>
      <InsuranceForm
        isSaveInsuranceOperation={true}
        memberInsuranceInfo={insuranceData}
        renderInputProps={{
          borderRadius: "v-sm",
          borderWidth: "1px",
          variant: "low-emphasis",
        }}
      />
    </Box>
  );
};
