import { createIcon } from "@springcare/sh-component-library";

export const Download = createIcon({
  displayName: "Download",
  viewBox: "0 0 24 24",
  path: (
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 4C13 3.44772 12.5523 3 12 3C11.4477 3 11 3.44772 11 4V14.2629L6.05162 10.1107C5.62855 9.7557 4.99779 9.81088 4.64279 10.234C4.28779 10.657 4.34297 11.2878 4.76604 11.6428L11.374 17.1875C11.5763 17.3572 11.826 17.4332 12.0703 17.4201C12.3147 17.4332 12.5644 17.3572 12.7666 17.1875L19.3746 11.6428C19.7977 11.2878 19.8528 10.657 19.4978 10.234C19.1428 9.81088 18.5121 9.7557 18.089 10.1107L13 14.3809V4ZM5 15.002C5 14.4497 4.55228 14.002 4 14.002C3.44772 14.002 3 14.4497 3 15.002V20.002C3 20.5542 3.44772 21.002 4 21.002H20C20.5523 21.002 21 20.5542 21 20.002V15.002C21 14.4497 20.5523 14.002 20 14.002C19.4477 14.002 19 14.4497 19 15.002V19.002H5V15.002Z"
    />
  ),
  defaultProps: {
    "aria-label": "Download icon",
    role: "img",
  },
});
