import { isSafari } from "react-device-detect";
import {
  FilterChip,
  Flex,
  Menu,
  MenuButton,
  MenuDivider,
  MenuList,
  Text,
  VMicOnIcon,
  VVideoChatIcon,
  VVolumeLowIcon,
} from "@springcare/sh-component-library";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";
import { useSessionDevices } from "context/SessionRoomContext";
import { DeviceTypes } from "components/templates/SHSessionRoom/types";
import { DeviceMenuOptionGroup } from "components/templates/SHSessionRoom/components/VideoCallControls/DeviceMenuOptionGroup";
import {
  AudioOutputTesterMenuItem,
  AudioInputTesterMenuItem,
} from "components/templates/SHSessionRoom/components/AudioTesterMenuItem";
import { NoiseFilterMenuItem } from "components/templates/SHSessionRoom/components/NoiseFilterMenuItem";
import { SafariAudioDevice } from "components/templates/SHSessionRoom/types";

import { trackDeviceMenuOpened } from "components/templates/SHSessionRoom/telemetry/analytics";

const truncateLabel = (label, maxLength = 16) => {
  return label?.length > maxLength ? `${label.slice(0, maxLength)}...` : label;
};

const DeviceMenuSection = () => {
  const { sessionDevices } = useSessionDevices();
  const isAudioInputTesterEnabled = useFeatureFlag(
    FLAGS.ENABLE_AUDIO_INPUT_TESTER,
  );
  const {
    selectedAudioOutputDevice,
    selectedAudioInputDevice,
    selectedVideoDevice,
  } = sessionDevices;

  const deviceMap = [];

  if (selectedAudioInputDevice.deviceId) {
    deviceMap.push({
      type: DeviceTypes.AudioInput,
      selected: selectedAudioInputDevice,
    });
  }

  if (selectedAudioOutputDevice.deviceId || isSafari) {
    // Safari doesn't support changing audio output device, so we need to show a virtual option
    deviceMap.push({
      type: DeviceTypes.AudioOutput,
      selected: isSafari
        ? {
            label: SafariAudioDevice.SystemDefaultSpeaker,
            deviceId: SafariAudioDevice.DeviceId,
          }
        : selectedAudioOutputDevice,
    });
  }

  if (selectedVideoDevice.deviceId) {
    deviceMap.push({
      type: DeviceTypes.VideoInput,
      selected: selectedVideoDevice,
    });
  }

  return (
    <Flex gap="v-8" w="100%" p="1px">
      {deviceMap.length > 1 &&
        deviceMap.map(({ type, selected }, index) => (
          <Menu key={index} placement="top" isLazy>
            <MenuButton
              as={FilterChip}
              /* @ts-ignore -- the 'type' prop for FilterChip from component-library is valid */
              type="menu"
              w="100%"
              justifyContent="start"
              onClick={() => trackDeviceMenuOpened(type, "pre_join")}
              _focus={{ outline: "1px solid grey !important" }}
              _focusVisible={{ outline: "2px solid black !important" }}
            >
              <Flex align="center" gap="v-8">
                {type === DeviceTypes.AudioInput && (
                  <VMicOnIcon aria-hidden={true} />
                )}
                {type === DeviceTypes.AudioOutput && (
                  <VVolumeLowIcon aria-hidden={true} />
                )}
                {type === DeviceTypes.VideoInput && (
                  <VVideoChatIcon aria-hidden={true} />
                )}
                <Text>{truncateLabel(selected.label)}</Text>
              </Flex>
            </MenuButton>
            <MenuList>
              <DeviceMenuOptionGroup type={type} sourceView={"pre_join"} />
              {type === DeviceTypes.AudioOutput && (
                <>
                  <AudioOutputTesterMenuItem />
                  <MenuDivider />
                  <NoiseFilterMenuItem />
                </>
              )}

              {type === DeviceTypes.AudioInput && isAudioInputTesterEnabled && (
                <AudioInputTesterMenuItem />
              )}
            </MenuList>
          </Menu>
        ))}
    </Flex>
  );
};

export default DeviceMenuSection;
