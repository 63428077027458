import { useState, useEffect, FC } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Heading,
  Text,
  VStack,
  Center,
  Show,
  Flex,
  VFamiliesTeenDuotoneSpot,
} from "@springcare/sh-component-library";
import Router from "next/router";
import routes from "routes";
import { SHStickyFooter } from "design-system/components";
import { TRACK_EVENT } from "utils/mixpanel";
import { TEEN_ASSESSMENT_TELEMETRY_TYPES } from "components/templates/TeenAssessment/constants/telemetryValues";

type ShareWithYourTeenProps = {
  memberFirstName: string;
  isCheckIn?: boolean;
};

const ShareWithYourTeen: FC<ShareWithYourTeenProps> = ({
  memberFirstName,
  isCheckIn = false,
}): React.ReactElement => {
  const { t } = useTranslation("assessments");
  const copyLinkButtonText = `${t(
    "teenAssessments.shareWithYourTeen.copyLinkButtonText",
  )}`;
  const linkIsCopiedText = `${t(
    "teenAssessments.shareWithYourTeen.linkIsCopiedText",
  )}`;
  const shareLink = `${window.location.protocol}//${window.location.host}${
    isCheckIn ? routes.TeenCheckIn.as : routes.TeenAssessment.as
  }`;

  const [copyButtonText, setCopyButtonText] =
    useState<string>(copyLinkButtonText);

  useEffect(() => {
    TRACK_EVENT.PAGE_VERSION_VIEWED(
      Router.asPath,
      TEEN_ASSESSMENT_TELEMETRY_TYPES.SHARE_LINK,
      {
        spring_doc_id: "guardiansharelink001",
      },
    );
  }, []);

  useEffect(() => {
    let buttonLabelTimer;
    if (copyButtonText !== copyLinkButtonText) {
      buttonLabelTimer = setTimeout(() => {
        setCopyButtonText(copyLinkButtonText);
      }, 1000);
    }
    return () => {
      clearTimeout(buttonLabelTimer);
    };
  }, [copyButtonText, copyLinkButtonText]);

  const handleOnClickCopy = async (buttonCopy: string): Promise<void> => {
    TRACK_EVENT.BUTTON_CLICKED(
      Router.asPath,
      TEEN_ASSESSMENT_TELEMETRY_TYPES.SURVEY_LINK_COPIED,
      {
        page_version: TEEN_ASSESSMENT_TELEMETRY_TYPES.SHARE_LINK,
        spring_doc_id: "guardiansharelink002",
      },
    );

    await navigator.clipboard.writeText(shareLink);
    setCopyButtonText(buttonCopy);
  };

  const handleShareLinkClick = async (): Promise<void> => {
    TRACK_EVENT.BUTTON_CLICKED(
      Router.asPath,
      TEEN_ASSESSMENT_TELEMETRY_TYPES.SURVEY_LINK_COPIED,
      {
        page_version: TEEN_ASSESSMENT_TELEMETRY_TYPES.SHARE_LINK,
        spring_doc_id: "guardiansharelink003",
      },
    );

    try {
      await navigator.share({
        url: shareLink,
      });
    } catch (_e) {
      // TODO: add datadog error handling
    }
  };

  const handleDoneClicked = () => {
    TRACK_EVENT.BUTTON_CLICKED(Router.asPath, "Done", {
      page_version: TEEN_ASSESSMENT_TELEMETRY_TYPES.SHARE_LINK,
      spring_doc_id: "guardiansharelink004",
    });

    Router.push(routes.TeenHome.to, routes.TeenHome.as);
  };

  return (
    <>
      <Center marginTop={["0", "90px"]}>
        <VStack w={["327px", "502px"]} alignItems="start" gap={8}>
          <VFamiliesTeenDuotoneSpot aria-hidden={true} />
          <Heading
            as="h1"
            size="heading-medium"
            data-testid="share-with-teen-heading"
          >
            {`${t("teenAssessments.shareWithYourTeen.headerText")}`}
          </Heading>
          <Text variant="body-medium" data-testid="share-with-teen-text">
            {`${t("teenAssessments.shareWithYourTeen.autonomyText", {
              ns: "assessments",
              memberFirstName: memberFirstName,
            })}`}
          </Text>
          <Text variant="body-medium">
            {`${t("teenAssessments.shareWithYourTeen.privacyText", {
              ns: "assessments",
              memberFirstName: memberFirstName,
            })}`}
          </Text>
          <VStack alignItems="start" width={"100%"} style={{ margin: "0" }}>
            <Show above="416px">
              <Button
                width={"100%"}
                data-testId="copy-share-button"
                onClick={() => handleOnClickCopy(linkIsCopiedText)}
                mt="v-32"
              >
                {copyButtonText}
              </Button>
              <Button
                variant="low-emphasis"
                width={"100%"}
                data-testid="done-button"
                onClick={handleDoneClicked}
              >
                {`${t("teenAssessments.shareWithYourTeen.doneButtonText")}`}
              </Button>
            </Show>
            <Show below="415px">
              <SHStickyFooter left={"0px"}>
                <Flex
                  flexDirection="column"
                  justifyContent="space-evenly"
                  alignItems="center"
                  padding={16}
                >
                  <Button
                    width={"100%"}
                    data-testid="copy-share-button"
                    onClick={handleShareLinkClick}
                    mb="v-8"
                  >
                    {`${t(
                      "teenAssessments.shareWithYourTeen.shareButtonText",
                    )}`}
                  </Button>
                  <Button
                    variant="low-emphasis"
                    width={"100%"}
                    data-testid="done-button"
                    onClick={handleDoneClicked}
                  >
                    {`${t("teenAssessments.shareWithYourTeen.doneButtonText")}`}
                  </Button>
                </Flex>
              </SHStickyFooter>
            </Show>
          </VStack>
        </VStack>
      </Center>
    </>
  );
};

export default ShareWithYourTeen;
