import React, { useEffect, useState } from "react";
import { TRACK_EVENT } from "utils/mixpanel";
import { DateTime } from "luxon";
import { Text, Box } from "design-system/components";
import { useTranslation } from "react-i18next";
import {
  getAppointmentKindForTracking,
  getAppointmentMediumForTracking,
} from "@spring/constants";
import { InNetworkStatusDisclaimer } from "components/molecules";
import { money_format } from "modules/MemberDashboard/Billing/utils";
const getTimeToAppointment = (appointmentStartTime) => {
  const appointmentTime = DateTime.fromISO(appointmentStartTime);
  const currentTime = DateTime.fromISO(new Date().toISOString());
  const appointmentTimeDifference = appointmentTime.diff(currentTime, "hours");
  return parseFloat(appointmentTimeDifference.hours).toFixed(2);
};

interface VirtualSessionCostTerm {
  id: string;
  enable_show: boolean;
  percentage_covered: number;
  customer_display_name: string;
}

interface PaymentDetailContractTerm {
  virtual_session_cost_term: VirtualSessionCostTerm;
  coaching_support: boolean;
}

interface PaymentDetailsCustomer {
  name: string;
}

interface PaymentDetailsCohort {
  in_network: boolean;
  international: boolean;
  customer?: PaymentDetailsCustomer;
  contract_term?: PaymentDetailContractTerm;
}

interface PaymentDetailsMember {
  visits_remaining_coaching: number;
  visits_covered_coaching: number;
  visits_covered_total: number;
  visits_covered_specialist: number;
  visits_remaining_total: number;
  cohort?: PaymentDetailsCohort;
}

export interface PaymentDetailsProps {
  coaching: boolean;
  therapy: boolean;
  member: PaymentDetailsMember;
  isMedicationManager: boolean;
  appointmentId: string;
  kind: string;
  medium: string;
  time: string;
  isDedicatedCustomer: boolean;
  isCoveredForMember: boolean;
  customerId?: string;
  memberCostOfCoaching?: number;
}

export const PaymentDetails: (a: PaymentDetailsProps) => React.JSX.Element = ({
  coaching,
  therapy,
  member,
  isMedicationManager,
  appointmentId,
  kind,
  medium,
  time,
  isDedicatedCustomer,
  isCoveredForMember,
  memberCostOfCoaching,
}: PaymentDetailsProps) => {
  const { t } = useTranslation("careVisits");
  const [trackedOnce, setTrackedOnce] = useState(false);
  const {
    visits_covered_coaching,
    visits_covered_total,
    visits_covered_specialist,
    cohort,
  } = member;
  const paidCoachingExperience =
    visits_covered_coaching !== null && visits_covered_coaching >= 0;

  if (!therapy && !coaching && !isMedicationManager) return null;

  const getSessionPayment = (hasFreeSessions: boolean) => {
    // checking which provider type modal is open and if no sessions of that type are covered
    if (coaching && !isCoveredForMember && memberCostOfCoaching > 0) {
      if (isCoveredForMember == null) return "";
      return t("appointmentDetails.paymentDetails.coaching", {
        coachingCost: money_format(memberCostOfCoaching),
      });
    }

    // If dedicated customer, do not show the in network status.
    if (isDedicatedCustomer) {
      return null;
    }

    useEffect(() => {
      if (!trackedOnce) {
        TRACK_EVENT.COMPONENT_VIEWED(
          window.location.pathname,
          "Request cost estimate",
          {
            location: "Appointment Details",
            appointment_id: String(appointmentId),
            appointment_type: getAppointmentKindForTracking(kind),
            appointment_medium: getAppointmentMediumForTracking(medium),
            appointment_time_utc: time,
            time_to_appointment: getTimeToAppointment(time),
          },
        );
      }
      setTrackedOnce(true);
    }, []);

    const headerText = (() => {
      // In rare cases, our backend data could be wrong, and we can't determine if the session is free.
      // Oftentimes, this will just be because the job to calculate appointment allocations has not yet run, so
      // we just need to wait a little longer.  When this happens, just don't show the user anything.
      if (isCoveredForMember === null) return <div />;
      const paymentTextKey =
        (coaching && (isCoveredForMember || memberCostOfCoaching == 0)) ||
        (!coaching && isCoveredForMember)
          ? "appointmentDetails.paymentDetails.noCost"
          : "appointmentDetails.paymentDetails.therapy";
      return (
        <Box>
          <Text as="span" variant="bodyBold_v1">
            {t(paymentTextKey)}
          </Text>
        </Box>
      );
    })();

    return (
      <InNetworkStatusDisclaimer
        inNetwork={cohort?.in_network}
        hasFreeSessions={hasFreeSessions}
        internationalCohort={cohort?.international}
        customerName={cohort?.customer?.name}
        showCTA={true}
        underlineCTA={true}
        showIcon={false}
        coaching={coaching}
        virtualSessionCostTerm={
          cohort?.contract_term?.virtual_session_cost_term
        }
        inNetworkDisclaimerConfig={{
          inNetworkFreeSessions: (
            <>
              {headerText}{" "}
              {t("appointmentDetails.paymentDetails.afterRemainingSessions", {
                customerName: cohort?.customer?.name,
              })}
            </>
          ),
          inNetworkNoFreeSessions: (
            <>
              {headerText}{" "}
              {t("appointmentDetails.paymentDetails.inNetworkSessions", {
                customerName: cohort?.customer?.name,
              })}
            </>
          ),
          notInNetworkFreeSessions: headerText,
          notInNetworkNoFreeSessions: headerText,
          default: null,
        }}
      />
    );
  };

  if (therapy) {
    return <Box my={16}>{getSessionPayment(!!visits_covered_total)}</Box>;
  } else if (isMedicationManager) {
    return <Box my={16}>{getSessionPayment(!!visits_covered_specialist)}</Box>;
  } else if (paidCoachingExperience && coaching) {
    return (
      <Box my={16}>
        <Text as="h2" variant="bodyBold_v1">
          {getSessionPayment(!!visits_covered_coaching)}
        </Text>
      </Box>
    );
  } else {
    return null;
  }
};
