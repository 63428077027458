/* eslint-disable react/jsx-key */
import { createIcon } from "@springcare/sh-component-library";

export const Confidential = createIcon({
  displayName: "Provider",
  viewBox: "0 0 24 24",
  path: [
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5001 14.4742C12.062 15.071 11.3554 15.3541 10.6688 15.2791L9.32704 17.1071C9.00024 17.5523 8.37439 17.6483 7.92917 17.3215C7.48396 16.9947 7.38796 16.3688 7.71476 15.9236L9.05654 14.0957C8.77925 13.4631 8.83749 12.7041 9.27553 12.1073C9.92913 11.2169 11.1808 11.0249 12.0713 11.6785C12.9617 12.3321 13.1537 13.5838 12.5001 14.4742Z"
    />,
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 0C7.23858 0 5 2.23858 5 5V7.58152C2.06817 8.76829 0 11.6426 0 15V21C0 21.5523 0.447715 22 1 22H12C16.4183 22 20 18.4183 20 14V8C20 7.44772 19.5523 7 19 7H7V5C7 3.34315 8.34315 2 10 2H10.5C11.5353 2 12.4236 2.62931 12.8033 3.52626C13.0186 4.03486 13.4477 4.5 14 4.5C14.5523 4.5 15.0115 4.04661 14.8903 3.5078C14.4384 1.4999 12.6444 0 10.5 0H10ZM8 9C4.68629 9 2 11.6863 2 15V20H12C15.3137 20 18 17.3137 18 14V9H8Z"
    />,
  ],
  defaultProps: {
    color: "platform.900",
  },
});
