import { useDispatch } from "react-redux";
import { DateTime } from "luxon";
import { useRef } from "react";

import { Box } from "@springcare/sh-component-library";
import { Form } from "@spring/smeargle";
import { openModal, addNotification } from "@spring/smeargle/actions";
import {
  AppointmentAction,
  getProviderRoleForTracking,
  ProviderRole,
  modalIds,
} from "@spring/constants";
import Meowth from "@spring/meowth";

import {
  getModalId,
  supportsInPerson,
  modalData,
  getTimeToAppointment,
} from "./utils";
import { isLegacyMinor, isMinor } from "utils/memberHelpers";
import { NewAppointmentSlots } from "components/form";
import { TRACK_EVENT } from "utils/mixpanel";
import { getFirstError } from "utils/apollo/errorHandler";
import { useInViewportOnce } from "hooks";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";

const AppointmentSection = ({
  providerData,
  providerRole,
  memberData,
  appointmentKind,
  showGlobalExperience,
  onSchedule,
  isCNAvailabilityModal,
  scheduleConfirmation,
  disableTracking,
  isAssignedCN,
  providerOrder,
  singleTabTitle,
  onNoAppointments,
  onScheduleModalOpen,
  isProviderASpecializedCareNavigator,
}) => {
  const dispatch = useDispatch();
  const apptSectionRef = useRef();
  const apptSectionInView = useInViewportOnce(apptSectionRef, "0px");
  const enableInPersonGlobal = useFeatureFlag(FLAGS.IN_PERSON_GLOBAL);
  const shouldShowGlobalExperience =
    showGlobalExperience && !enableInPersonGlobal;
  const shouldShowNewInsuranceModal = useFeatureFlag(FLAGS.NEW_INSURANCE_MODAL);

  const schedule = (provider, index) => {
    if (shouldShowNewInsuranceModal && !isCNAvailabilityModal) {
      onScheduleModalOpen({
        kind: appointmentKind,
        provider: provider,
        buttonText: scheduleConfirmation,
        action: AppointmentAction.Create,
        dataCy: "schedule-provider-modal",
        providerOrder: index,
        telemetryProps: {
          location: "Book Timeslot",
        },
      });
    } else {
      return dispatch(
        openModal(getModalId(memberData, providerRole), {
          ...modalData(
            provider,
            appointmentKind,
            memberData,
            providerRole,
            shouldShowGlobalExperience,
          ),
          videoTherapy: true,
          buttonText: scheduleConfirmation,
          action: AppointmentAction.Create,
          dataCy: "schedule-provider-modal",
          providerOrder: index,
          isMinor: isMinor(memberData?.member),
        }),
      );
    }
  };

  const requestAvailability = async (provider) => {
    try {
      return dispatch(
        openModal(modalIds.availabilityRequestModal, {
          ...modalData(
            provider,
            appointmentKind,
            memberData,
            providerRole,
            shouldShowGlobalExperience,
          ),
        }),
      );
    } catch (err) {
      return dispatch(addNotification(getFirstError(err), "error"));
    }
  };

  return (
    <Box ref={apptSectionRef} pt={{ base: 16, md: 0 }}>
      {apptSectionInView && (
        <Form
          formKey={`care-provider-schedule-${providerData.id}`}
          theme="simple"
          onChange={(formData) => {
            if (formData.startTime) {
              if (onSchedule) {
                return onSchedule(formData.startTime.selectedDateTime);
              }
              schedule(
                modalData(
                  providerData,
                  appointmentKind,
                  memberData,
                  providerRole,
                  shouldShowGlobalExperience,
                ),
                0,
              );
            }
          }}
        >
          <NewAppointmentSlots
            fieldKey="startTime"
            initialStartTimeFieldKey="initialStartTime"
            bookings={[Meowth.getUserId(), providerData.user_id]}
            notTherapist={providerRole != ProviderRole.Therapist}
            stripe={false}
            showMore
            limit={3}
            moreAction={(initialStartTime) => {
              if (onSchedule) {
                return onSchedule(initialStartTime);
              }
              return schedule(
                modalData(
                  providerData,
                  appointmentKind,
                  memberData,
                  providerRole,
                  shouldShowGlobalExperience,
                ),
                0,
              );
            }}
            kind={appointmentKind}
            start={DateTime.local()
              .plus({ hours: 25 })
              .set({ minutes: 0, seconds: 0, milliseconds: 0 })
              .toISO()}
            dayIncrement={3}
            provider={providerData}
            inPersonFilterSelected={false}
            upcomingSlotsOnly
            supportsVirtual={providerData?.virtual_supported}
            supportsInPerson={supportsInPerson(
              providerData,
              memberData,
              shouldShowGlobalExperience,
            )}
            showGlobalExperience={showGlobalExperience}
            isALegacyMinor={isLegacyMinor(memberData)}
            requestAvailability={requestAvailability}
            modalData={modalData(
              providerData,
              appointmentKind,
              memberData,
              providerRole,
              shouldShowGlobalExperience,
            )}
            providerIndex={0}
            pageNumber={0}
            showDirectScheduling
            smallSlotStyle
            trackAppointmentSlotsViewed={(first_appointment_time_utc) => {
              if (disableTracking) return;

              let tracking = {};

              if (isProviderASpecializedCareNavigator) {
                tracking = {
                  location: "Specialized Care Navigator Availability Modal",
                };
              }

              TRACK_EVENT.COMPONENT_VIEWED(
                window.location.pathname,
                "Provider Profile",
                {
                  provider_id: providerData.id,
                  provider_type: getProviderRoleForTracking(providerRole),
                  first_appointment_time_utc:
                    first_appointment_time_utc || null,
                  time_to_first_available_appointment:
                    getTimeToAppointment(first_appointment_time_utc) || null, // In minutes
                  assigned_provider: isAssignedCN ? "true" : "false",
                  provider_order: providerOrder,
                  ...tracking,
                },
              );
            }}
            phoneSupported={
              providerData.supported_appointment_mediums[0] === "PHONE"
            }
            isAssignedCN={isAssignedCN}
            isCNAvailabilityModal={isCNAvailabilityModal}
            singleTabTitle={singleTabTitle}
            disableTracking={disableTracking}
            onNoAppointments={onNoAppointments}
            isProviderASpecializedCareNavigator={
              isProviderASpecializedCareNavigator
            }
          />
        </Form>
      )}
    </Box>
  );
};

export default AppointmentSection;
