import { createIcon } from "@springcare/sh-component-library";

export const PlantInHand = createIcon({
  displayName: "PlantInHandV1.1",
  viewBox: "0 0 24 24",
  path: (
    <path
      fill="currentColor"
      d="M14.1773 0H17.2153V1.04347C17.2153 2.77236 15.8551 4.17389 14.1773 4.17389H13.1646V11.5291L13.5712 11.4393C15.5029 11.0128 17.0689 12.6149 17.038 14.3788L18.1877 13.6497C19.4493 12.8497 21.0341 12.8231 22.3203 13.5805L22.9794 13.9686C24.2351 14.7079 24.3558 16.5261 23.2095 17.4347L18.9247 20.831C18.0396 21.5326 16.9542 21.9129 15.8376 21.9129H7.11681C5.3175 21.9129 3.55844 22.4617 2.06133 23.4902L1.57449 23.8246C1.10914 24.1443 0.480413 24.0147 0.170183 23.5352C-0.140047 23.0557 -0.0143014 22.4079 0.451043 22.0882L0.937883 21.7538C2.7677 20.4968 4.91765 19.826 7.11681 19.826H15.8376C16.5076 19.826 17.1588 19.5978 17.6898 19.1768L21.9746 15.7805L21.3155 15.3924C20.6724 15.0138 19.88 15.0271 19.2492 15.4271L17.2414 16.7003C15.0627 18.0818 12.4208 18.4562 9.961 17.732L9.84848 17.6989C9.31072 17.5406 8.99934 16.963 9.15298 16.4089C9.30663 15.8548 9.86712 15.5339 10.4049 15.6922L10.5174 15.7254C11.7496 16.0882 13.0406 16.0965 14.2563 15.7693C14.2808 15.7094 14.3111 15.6511 14.3474 15.595L14.864 14.7965C15.2791 14.1549 14.7273 13.3183 13.9956 13.4799L12.8904 13.7239C11.6962 13.9876 10.4537 13.8929 9.31018 13.451C7.99707 12.9436 6.52726 13.118 5.36039 13.9196L1.57449 16.5203C1.10914 16.84 0.480413 16.7104 0.170183 16.2309C-0.140047 15.7514 -0.0143014 15.1035 0.451043 14.7839L4.23695 12.1831C5.9458 11.0092 8.0983 10.7539 10.0213 11.497C10.3828 11.6367 10.7588 11.7255 11.1393 11.7628L11.1393 7.30431H9.11402C7.43619 7.30431 6.07605 5.90278 6.07605 4.17389V3.13042H8.10136C9.77837 3.13042 11.138 4.53059 11.1393 6.25832L11.1393 3.12704C11.1411 1.39971 12.5006 0 14.1773 0Z"
    />
  ),
  defaultProps: {
    "aria-hidden": true,
    "aria-label": "Plant in hand icon",
    role: "img",
    color: "tertiary.700",
  },
});
