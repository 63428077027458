import { useMember } from "context/MemberContext";
import { useRouter } from "next/router";
import { useEffect } from "react";
import routes from "routes";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";

// Automatically redirects from route to ChooseUser if user is CHIP guardian (isCareEnabled is false)
export const useGuardianRedirect = () => {
  const isChipGuardianEnabled = useFeatureFlag(FLAGS.CHIP_GUARDIAN_RELEASE);
  const { replace } = useRouter();
  const { isCareEnabled, hasResolved } = useMember();

  useEffect(() => {
    if (!hasResolved || isChipGuardianEnabled === undefined) return;

    if (isChipGuardianEnabled && isCareEnabled === false) {
      replace(routes.ChooseUser.to, routes.ChooseUser.as);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasResolved, isCareEnabled, isChipGuardianEnabled]);
};
