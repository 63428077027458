import {
  Box,
  Button,
  Flex,
  Heading,
  Text,
  useMediaQuery,
  VFinancialHelpIcon,
} from "@springcare/sh-component-library";
import { TRACK_EVENT } from "utils/mixpanel";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";
import { acknowledgeLateCancelMissedPolicy } from "operations/mutations/appointment";
import { useState } from "react";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";
import { CancellationPolicy } from "components/templates/CareVisitDetails/components";

export const AcknowledgeLateCancelContent = ({
  memberId,
  trackingProps,
  trackingType,
  onComplete,
  buttonText,
}) => {
  const shouldShowDeltaCopy = useFeatureFlag(
    FLAGS.DELTA_CANCELATION_POLICY_COPY,
  );
  const { t } = useTranslation(["careVisits"]);
  const [isMobile] = useMediaQuery("(max-width: 767px)");
  const iconSize = isMobile ? 16 : 24;

  const [updateAcknowledgeLateCancelTimestamp] = useMutation(
    acknowledgeLateCancelMissedPolicy,
  );
  const [submitDisabled, setSubmitDisabled] = useState(false);

  return (
    <>
      <Text pb={16}>{t("noShowlandingPage.reminder")}</Text>

      <Box bg="background-subtle" borderRadius={16} p={{ base: 16, md: 24 }}>
        <Box pb={16}>
          <Heading size="heading-small" pb={8}>
            {t("noShowlandingPage.cancellationPolicy")}
          </Heading>
          <CancellationPolicy
            showDollarIcon={false}
            showViewPolicyCTA={false}
            showConformOrCancelPrefix={true}
            defaultCopy={t("noShowlandingPage.avoidCharge")}
          />
        </Box>
        {!shouldShowDeltaCopy && (
          <Box>
            <Text display="inline" size="body-medium-strong">
              {t("noShowlandingPage.changesWithin24hours")}
            </Text>
            <Flex direction={"column"} pt={12} gap={8}>
              <Flex direction={"row"} gap={8}>
                <Box mt={isMobile ? 1 : 0} mr={4} color={"primary-base"}>
                  <VFinancialHelpIcon height={iconSize} width={iconSize} />
                </Box>
                <Text>{t("noShowlandingPage.losingNoCost")}</Text>
              </Flex>
              <Flex direction={"row"} gap={8}>
                <Box mt={isMobile ? 1 : 0} mr={4} color={"primary-base"}>
                  <VFinancialHelpIcon height={iconSize} width={iconSize} />
                </Box>
                <Text>{t("noShowlandingPage.costOfFullAppt")}</Text>
              </Flex>
            </Flex>
          </Box>
        )}
      </Box>
      <Box pt={32} pb={{ base: 26, md: 16 }}>
        <Button
          minW="100%"
          disabled={submitDisabled}
          onClick={() => {
            if (memberId) {
              setSubmitDisabled(true);
              TRACK_EVENT.BUTTON_CLICKED(
                window.location.pathname,
                trackingType,
                trackingProps,
              );
              updateAcknowledgeLateCancelTimestamp({
                variables: {
                  member_id: memberId,
                },
                onCompleted: () => {
                  onComplete();
                },
              });
            }
          }}
        >
          {buttonText}
        </Button>
      </Box>
    </>
  );
};
