import { DateTime } from "luxon";

/**
 * @params {DateTime} appointmentTime - a Luxon DateTime object that represents appointment time
 * @params {DateTime} currentTime - a Luxon DateTime object that represents the current
 */
export const isCurrentTimeWithin24HoursOfAppointment = (
  currentTime: DateTime,
  appointmentTime: DateTime,
) => {
  if (
    DateTime.isDateTime(appointmentTime) &&
    DateTime.isDateTime(currentTime)
  ) {
    const time24HoursBeforeAppointment = appointmentTime.minus({ hours: 24 });

    currentTime.toISO();
    appointmentTime.toISO();
    time24HoursBeforeAppointment.toISO();

    return (
      currentTime < appointmentTime &&
      currentTime > time24HoursBeforeAppointment
    );
  }
  throw new Error("Please wrap each param inside a DateTime Object");
};

export const isDuringAppointment = (
  currentTime: DateTime,
  appointmentTime: DateTime,
) => {
  if (
    DateTime.isDateTime(appointmentTime) &&
    DateTime.isDateTime(currentTime)
  ) {
    currentTime.toISO();
    appointmentTime.toISO();

    return (
      currentTime >= appointmentTime &&
      currentTime <= appointmentTime.plus({ hours: 1 })
    );
  }
  throw new Error("Please wrap each param inside a DateTime Object");
};

export const is10MinutesAfterAppointment = (
  currentTime: DateTime,
  appointmentTime: DateTime,
) => {
  if (
    DateTime.isDateTime(appointmentTime) &&
    DateTime.isDateTime(currentTime)
  ) {
    currentTime.toISO();
    appointmentTime.toISO();

    return (
      currentTime >= appointmentTime.plus({ hours: 1 }) &&
      currentTime <= appointmentTime.plus({ hours: 1, minutes: 11 })
    );
  }
  throw new Error("Please wrap each param inside a DateTime Object");
};

/**
 * @params {DateTime} appointmentTime - a Luxon DateTime object that represents appointment time
 * @params {DateTime} currentTime - a Luxon DateTime object that represents the current
 */
export const isPastAppointment = (
  currentTime: DateTime,
  appointmentTime: DateTime,
) => {
  if (
    DateTime.isDateTime(appointmentTime) &&
    DateTime.isDateTime(currentTime)
  ) {
    currentTime.toISO();
    appointmentTime.toISO();

    return currentTime > appointmentTime;
  }
  throw new Error("Please wrap each param inside a DateTime Object");
};
