/* eslint-disable react/jsx-key */
import { createIcon } from "@springcare/sh-component-library";

export const Afternoon = createIcon({
  displayName: "Afternoon",
  viewBox: "0 0 26 26",
  path: [
    <path
      d="M13 3V1"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M8.0239 4.14302L7.11914 2.57593"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M17.9761 4.14302L18.8809 2.57593"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M21.6189 7.78562L23.186 6.88086"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M4.38106 7.78562L2.81396 6.88086"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 5C11.9494 5 10.9091 5.20693 9.93853 5.60896C8.96793 6.011 8.08601 6.60028 7.34315 7.34315C6.60028 8.08601 6.011 8.96793 5.60896 9.93853C5.20693 10.9091 5 11.9494 5 13C5 14.0506 5.20693 15.0909 5.60896 16.0615C6.011 17.0321 6.60028 17.914 7.34315 18.6569C8.08601 19.3997 8.96793 19.989 9.93853 20.391C10.9091 20.7931 11.9494 21 13 21C14.0506 21 15.0909 20.7931 16.0615 20.391C17.0321 19.989 17.914 19.3997 18.6569 18.6569C19.3997 17.914 19.989 17.0321 20.391 16.0615C20.7929 15.0913 20.9998 14.0516 21 13.0015C21 13.001 21 13.0005 21 13C21 11.9494 20.7931 10.9091 20.391 9.93853C19.989 8.96793 19.3997 8.08601 18.6569 7.34315C17.914 6.60028 17.0321 6.011 16.0615 5.60896C15.0909 5.20693 14.0506 5 13 5ZM19 12.9987C18.9998 12.2112 18.8446 11.4315 18.5433 10.7039C18.2417 9.97595 17.7998 9.31451 17.2426 8.75736C16.6855 8.20021 16.0241 7.75825 15.2961 7.45672C14.5681 7.15519 13.7879 7 13 7C12.2121 7 11.4319 7.15519 10.7039 7.45672C9.97595 7.75825 9.31451 8.20021 8.75736 8.75736C8.20021 9.31451 7.75825 9.97595 7.45672 10.7039C7.15519 11.4319 7 12.2121 7 13C7 13.0003 7 13.0007 7 13.001C7.00013 13.7886 7.15532 14.5685 7.45672 15.2961C7.75825 16.0241 8.20021 16.6855 8.75736 17.2426C9.31451 17.7998 9.97595 18.2417 10.7039 18.5433C11.4319 18.8448 12.2121 19 13 19C13.7879 19 14.5681 18.8448 15.2961 18.5433C16.0241 18.2417 16.6855 17.7998 17.2426 17.2426C17.7998 16.6855 18.2417 16.0241 18.5433 15.2961C18.8448 14.5681 19 13.7879 19 13C19 12.9996 19 12.9991 19 12.9987Z"
    />,
    <path
      d="M13 23L13 25"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M23 13L25 13"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M1 13L3 13"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M17.9761 21.857L18.8809 23.4241"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M8.0239 21.857L7.11914 23.4241"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M4.38106 18.2144L2.81396 19.1191"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M21.6189 18.2144L23.186 19.1191"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
  ],
  defaultProps: {
    color: "tertiary.700",
  },
});
