import { useState } from "react";
import { Alert } from "@springcare/sh-component-library";
import { VideoChat } from "design-system/index";
import useCaptureConsents from "components/templates/SHSessionRoom/hooks/useCaptureConsents";
import { useTranslation } from "hooks/react-i18next";

interface SessionConsentJoinAlertProps {
  handleJoin: () => void;
  appointmentId: string;
}

const SessionConsentJoinAlert = ({
  handleJoin,
  appointmentId,
}: SessionConsentJoinAlertProps) => {
  const [loadingButton, setLoadingButton] = useState(null);
  const { t } = useTranslation("livekitExperience", {
    keyPrefix: "sessionConsentJoin",
  });

  const [apiClient] = useCaptureConsents();

  const handleClick = (consented, buttonType) => {
    setLoadingButton(buttonType);
    handleJoin();
    apiClient.post({
      appointment_id: appointmentId,
      consented: consented ? "true" : "false",
      audio_track_id: "eeeeeee test",
    });
  };

  return (
    <Alert
      showIcon={"on"}
      icon={VideoChat}
      colorScheme="base"
      content={t("pleaseConfirmConsent")}
      showTitle="on"
      size="small"
      title={t("consentToAudioRecording")}
      primaryAction={{
        children: t("agreeJoin"),
        onClick: () => handleClick(true, "primary"),
        isLoading: loadingButton === "primary",
      }}
      secondaryAction={{
        children: t("declineJoin"),
        onClick: () => handleClick(false, "secondary"),
        isLoading: loadingButton === "secondary",
      }}
      type="standard"
    />
  );
};
export default SessionConsentJoinAlert;
