import { countries } from "@spring/constants";
import {
  FormControl,
  Input,
  ComboboxInput,
  Box,
  Grid,
  GridItem,
} from "@springcare/sh-component-library";

import type {
  Control,
  Message,
  UseFormRegister,
} from "@springcare/sh-component-library";
import { Controller } from "react-hook-form";

export type PhoneInputProps = {
  control: Control<any>;
  errorMessage?: Message;
  isRequired?: boolean;
  codeLabel?: string;
  label?: string;
  size?: string;
  register: UseFormRegister<any>;
  names?: string[];
};

export const SHPhoneInput = ({
  control,
  errorMessage,
  isRequired = false,
  codeLabel,
  label,
  register,
  names = ["country_code", "phone"],
  ...rest
}: PhoneInputProps) => {
  return (
    <FormControl isInvalid={!!errorMessage} isRequired={isRequired}>
      <Box position="relative">
        <Grid templateColumns="125px 1fr" gap={8}>
          <GridItem>
            <Controller
              control={control}
              name={names[0]}
              render={({ field: { onChange, value } }) => (
                <ComboboxInput
                  type="tel"
                  name="code"
                  label={codeLabel}
                  items={countries.map((c) => ({
                    value: c.dialCode,
                    label: c.displayName,
                  }))}
                  size="sm"
                  InputStyleProps={{
                    padding: "4px 4px 4px 8px",
                  }}
                  InputRightElementStyleProps={{
                    width: "unset",
                    height: "unset",
                    top: "15px",
                    right: "0",
                    padding: 0,
                  }}
                  onSelectedItemChange={({ selectedItem }) => {
                    onChange(selectedItem.value);
                  }}
                  selectedItem={{
                    value: value,
                  }}
                  useComboboxProps={{
                    itemToString: (item) => `+${item.value}`,
                  }}
                />
              )}
            />
          </GridItem>

          <GridItem>
            <Input
              label={label}
              type="number"
              paddingLeft={10}
              isRequired={isRequired}
              isInvalid={!!errorMessage}
              errorText={errorMessage}
              {...register(names[1])}
              {...rest}
            />
          </GridItem>
        </Grid>
      </Box>
    </FormControl>
  );
};
