import { useMemo } from "react";
import { useMemberInfo } from "hooks";

export const useNoShowPolicyConfig = () => {
  const { data: memberInfoData, error, loading } = useMemberInfo();

  const config = useMemo(() => {
    if (!memberInfoData) {
      return {
        customerName: null,
        customerCostForNoShow: null,
        customerPayForNoShow: false,
        requireConfirmOrCancel: false,
        memberPayForNoShow: false,
        memberCostForNoShow: null,
        hasGracePeriod: false,
        showDefaultCopy: true,
      };
    }

    const cohort = memberInfoData?.user?.member?.cohort;
    const contract_term = cohort?.contract_term;

    // Customer pay for no-show
    const customerName = cohort?.customer.name;
    const customerCostForNoShow = contract_term?.customer_no_show_cost;
    const customerPayForNoShow = contract_term?.customer_pay_for_no_show;

    // Member pay for no-show
    const memberPayForNoShow =
      contract_term?.member_pay_for_no_show ||
      contract_term?.no_show_term?.enable_members_pay;
    const memberCostForNoShow = contract_term?.member_no_show_cost;
    const isPayer =
      memberInfoData?.user?.member?.cohort?.customer?.is_health_plan;
    const hasGracePeriodMissedAppointments =
      memberInfoData?.user?.member?.visits_remaining_no_show > 0 ||
      contract_term?.member_pay_for_no_show;

    // Require confirmation of appointment will be canceled
    const requireConfirmOrCancel = contract_term?.require_confirm_or_cancel;

    return {
      customerName,
      customerCostForNoShow,
      customerPayForNoShow,
      requireConfirmOrCancel,
      memberPayForNoShow,
      memberCostForNoShow,
      hasGracePeriodMissedAppointments,
      showDefaultCopy: !customerPayForNoShow && !memberPayForNoShow,
      isPayer,
    };
  }, [memberInfoData]);

  return { config, error, loading };
};
