import { useTranslation } from "hooks/react-i18next";
import { BackgroundCurve } from "@spring/smeargle";
import { getOr, isEmpty } from "lodash/fp";

import { isMinor } from "utils/memberHelpers";
import { Heading } from "design-system/components";

import { useProviderBrowseContext } from "context/ProviderBrowseContext";

type Props = {
  providerType: string | string[];
};

const BrowseHeader = ({ providerType }: Props) => {
  const { t } = useTranslation(["careProvider", "common", "coachesProvider"]);
  const { memberInfo } = useProviderBrowseContext();

  const toProviderCopyFromType = (type: string) => {
    switch (type) {
      case "medication_manager":
        return "Medication Manager";
      case "therapist":
        return "Therapy";
      default:
        return "Providers";
    }
  };

  const setTitle = (providerType: string | string[]) => {
    const isAMinor = isMinor(getOr({}, "user.member", memberInfo));

    if (providerType === "medication_manager") {
      return t("browseHeader.medicationManager");
    }
    if (providerType === "coach") {
      return t("coachesProvider:browseHeader.findACoach");
    }
    if (isEmpty(providerType)) {
      return t("browseHeader.workplaceConsultations");
    }
    if (isAMinor)
      return t("browseHeader.scheduleDependent", {
        //@ts-ignore
        providerType: toProviderCopyFromType(providerType),
        //@ts-ignore
        first_name: memberInfo?.user?.first_name,
      });

    return t("browseHeader.schedule");
  };

  return (
    <>
      <Heading as="h1" fontSize={48} fontWeight={300} color="#182023">
        {setTitle(providerType)}
      </Heading>
      <BackgroundCurve marginTop="" pageRoute="BrowseProviders" />
    </>
  );
};

export default BrowseHeader;
