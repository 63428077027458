const SupportedLanguages = [
	{
		nativeName  : 'English',
		englishName : 'English',
		code        : 'en',
	},
	{
		nativeName  : 'Español',
		englishName : 'Spanish',
		code        : 'es',
	},
	{
		nativeName  : 'Français',
		englishName : 'French',
		code        : 'fr',
	},
	{
		nativeName  : 'Português',
		englishName : 'Portuguese',
		code        : 'pt',
	},
	{
		nativeName  : 'Deutsch',
		englishName : 'German',
		code        : 'de',
	},
	{
		nativeName  : 'Nederlands',
		englishName : 'Dutch',
		code        : 'nl',
	},
	{
		nativeName  : 'Polski',
		englishName : 'Polish',
		code        : 'pl',
	},
	{
		nativeName  : '日本語',
		englishName : 'Japanese',
		code        : 'ja',
	},
	{
		nativeName  : 'हिन्दी',
		englishName : 'Hindi',
		code        : 'hi',
	},
	{
		nativeName  : 'Français Canadien',
		englishName : 'Canadian French',
		code        : 'fr-CA',
	},
	{
		nativeName  : 'čeština',
		englishName : 'Czech',
		code        : 'cs',
	},
	{
		nativeName  : 'عربي',
		englishName : 'Arabic',
		code        : 'ar',
	},
	{
		nativeName  : 'Magyar',
		englishName : 'Hungarian',
		code        : 'hu',
	},
	{
		nativeName  : 'Bahasa Indonesia',
		englishName : 'Indonesian',
		code        : 'id',
	},
	{
		nativeName  : 'עִברִית',
		englishName : 'Hebrew',
		code        : 'he',
	},
	{
		nativeName  : 'Italiano',
		englishName : 'Italian',
		code        : 'it',
	},
	{
		nativeName  : 'Bahasa Melayu',
		englishName : 'Malay',
		code        : 'ms',
	},
	{
		nativeName  : 'Filipino',
		englishName : 'Filipino',
		code        : 'fil',
	},
	{
		nativeName  : 'Português Europeu',
		englishName : 'European Portuguese',
		code        : 'pt-PT',
	},
	{
		nativeName  : 'Română',
		englishName : 'Romanian',
		code        : 'ro',
	},
	{
		nativeName  : '简体字',
		englishName : 'Simplified Mandarin',
		code        : 'zh',
	},
	{
		nativeName  : '한국어',
		englishName : 'Korean',
		code        : 'ko',
	},
	{
		nativeName  : 'Ελληνικά',
		englishName : 'Greek',
		code        : 'el',
	},
	{
		nativeName  : 'Türkçe',
		englishName : 'Turkish',
		code        : 'tr',
	},
	{
		nativeName  : 'Svenska',
		englishName : 'Swedish',
		code        : 'sv',
	},
	{
		nativeName  : 'Dansk',
		englishName : 'Danish',
		code        : 'da',
	},
	{
		nativeName  : 'Suomi',
		englishName : 'Finnish',
		code        : 'fi',
	},
	{
		nativeName  : 'српски',
		englishName : 'Serbian',
		code        : 'sr',
	},
	{
		nativeName  : 'Norsk',
		englishName : 'Norwegian',
		code        : 'no',
	},
	{
		nativeName  : 'ქართული',
		englishName : 'Georgian',
		code        : 'ka',
	},
	{
		nativeName  : 'Български',
		englishName : 'Bulgarian',
		code        : 'bg',
	},
	{
		nativeName  : 'Lietuvių',
		englishName : 'Lithuanian',
		code        : 'lt',
	},
];

module.exports = SupportedLanguages;
