import React from "react";
import { Text, Spinner, Flex } from "design-system/components";
import { useTranslation } from "react-i18next";

export const SessionCountText = ({ sessionSnapshot }) => {
  const { t } = useTranslation("homepage", {
    keyPrefix: "careInfoFlyout.medicationManager",
  });
  // NOTE: probably should have a loader here
  if (!sessionSnapshot) {
    return (
      <Flex h={48} mb={40} justifyContent="center" alignItems="center">
        <Spinner label="Loading your therapy/ medication management session counts." />
      </Flex>
    );
  }

  return (
    <Text mb={40} data-testid="session-count">
      {t("sessionsCountText", {
        remainingCovered: sessionSnapshot.remainingCovered,
        totalVisitsCovered: sessionSnapshot.totalVisitsCovered,
        expirationDate: sessionSnapshot.expirationDate,
        specialtyVistsRemaining: sessionSnapshot.specialtyVistsRemaining,
      })}
    </Text>
  );
};
