import {
  Box,
  Button,
  ButtonProps,
  Flex,
  Heading,
  Text,
  useMediaQuery,
} from "@springcare/sh-component-library";
import { useTranslation } from "hooks/react-i18next";
import { trackFinishedExerciseDoneClicked } from "modules/MemberDashboard/Moments/components/analytics";

interface ThanksForLettingUsKnowProps {
  exerciseId?: string;
  exerciseTitle?: string;
  exerciseCategory?: unknown;
  exerciseMediaType?: unknown;
  handleOnClick: () => void;
}

export const ThanksForLettingUsKnow = ({
  exerciseId,
  exerciseTitle,
  exerciseCategory,
  exerciseMediaType,
  handleOnClick,
}: ThanksForLettingUsKnowProps) => {
  const [isMobile] = useMediaQuery("(max-width:600px)");
  const { t } = useTranslation("moments");

  return (
    <Flex flexDirection="column" alignItems="center">
      <Flex pt="v-16" pb={isMobile ? "v-none" : "34px"} px="v-24">
        <Heading
          size="heading-small"
          w="100%"
          textAlign="center"
          data-cy="thank-you-confirmation"
        >
          {t("completeExerciseModal.thankYou")}
        </Heading>
      </Flex>
      <Flex
        justifyContent={isMobile ? "center" : "flex-end"}
        pt="v-16"
        w={isMobile ? "247px" : "78px"}
        marginInlineStart={isMobile ? undefined : "auto"}
      >
        <Button
          h="48px"
          w="100%"
          colorScheme="base"
          data-cy="moments-done-button"
          onClick={() => {
            handleOnClick();
            trackFinishedExerciseDoneClicked(
              exerciseId,
              exerciseTitle,
              exerciseCategory,
              exerciseMediaType,
            );
          }}
        >
          <Text size="body-medium-strong">
            {t("completeExerciseModal.done")}
          </Text>
        </Button>
      </Flex>
    </Flex>
  );
};
