import {
  Heading,
  VCelebrationConfettiDuotoneSpot,
  VStack,
  useMediaQuery,
  useTheme,
  HStack,
  Flex,
} from "@springcare/sh-component-library";
import PropTypes from "prop-types";

const WelcomeContent = ({ t, isDesktop }) => {
  return isDesktop ? (
    <Flex alignItems="center" gap={4} role="banner">
      <VCelebrationConfettiDuotoneSpot
        w={80}
        h={80}
        aria-label="celebration confetti icon"
      />
      <Heading size="heading-large" as="h1" ps={24}>
        {t("welcome.header")}
      </Heading>
    </Flex>
  ) : (
    <Flex role="banner" flexDirection={"column"}>
      <VCelebrationConfettiDuotoneSpot
        w={80}
        h={80}
        aria-label="celebration confetti icon"
      />
      <Heading pt={16} size="heading-medium" as="h1" role={"heading"}>
        {t("welcome.header")}
      </Heading>
    </Flex>
  );
};

const Welcome = ({ t }) => {
  const { breakpoints } = useTheme();
  const [isDesktop] = useMediaQuery(`(min-width: ${breakpoints.md})`);
  const [isLgScreen] = useMediaQuery(
    `(min-width: 768px) and (max-width: 1250px)`,
  );

  const [isXLgScreen] = useMediaQuery(
    `(min-width: 1250px) and (max-width: 1400px)`,
  );

  return isDesktop ? (
    <HStack
      alignItems={"flex-start"}
      spacing={4}
      w={isXLgScreen ? "1200px" : "100%"}
      h="100%"
      pt={40}
      pb={80}
      ps={isXLgScreen ? 0 : isLgScreen ? 24 : 107}
      justifyContent={isXLgScreen && "flex-start"}
    >
      <WelcomeContent t={t} isDesktop={isDesktop} />
    </HStack>
  ) : (
    <VStack
      spacing={4}
      alignItems={"flex-start"}
      w="100%"
      h="100%"
      px={24}
      pt={17}
      pb={8}
    >
      <WelcomeContent t={t} isDesktop={isDesktop} />
    </VStack>
  );
};

Welcome.propTypes = {
  t: PropTypes.func,
};

export default Welcome;
