import React, { useRef, useEffect, useState } from "react";
import { Box, Button, Flex, Text } from "design-system/components/index";

import PropTypes from "prop-types";
import { useInViewportOnce } from "hooks/useInViewport";
import { Sparkle, VanillaSimple } from "design-system/assets";

export const SHRecommendationsCard = ({
  marginTop,
  marginBottom,
  onClick,
  title,
  subTitle,
  submitBtnText,
  role,
  dataCy,
  trackingText,
  ariaLabel = null,
  TRACK_EVENT,
}) => {
  const ref = useRef();
  const inViewport = useInViewportOnce(ref, "0px"); // Trigger as soon as the element becomes visible

  const [trackedOnce, setTrackedOnce] = useState(0);

  useEffect(() => {
    if (inViewport && trackedOnce === 0) {
      TRACK_EVENT.COMPONENT_VIEWED(window.location.pathname, trackingText);
      setTrackedOnce(1);
    }
  }, [inViewport]);

  return (
    <Flex
      ref={ref}
      p={24}
      mt={marginTop}
      mb={marginBottom}
      borderRadius="8px"
      background="#E5F2F0"
      width="100%"
      flexDirection={["column", "column", "row"]}
      justifyContent={["space-between"]}
      alignItems="center"
    >
      <Flex flexDirection={"row"} alignItems="center">
        <Box width={[45, 50]}>
          <VanillaSimple width={50} height={50} />
        </Box>
        <Box mb={[8, 8, 0, 0]} pl={3}>
          <Text pb={[0, 0, 4]} variant="bodyBold_v1" color="primary.dark">
            {title}
          </Text>
          <Text variant="body_v1" color="primary.dark">
            {subTitle}
          </Text>
        </Box>
      </Flex>

      <Box w={{ base: "100%", lg: "280px" }}>
        <Button
          size="lg"
          role={role}
          variant="solid"
          colorScheme="primary"
          w={{ base: "100%", lg: "280px" }}
          leftIcon={<Sparkle boxSize={24} />}
          onClick={onClick}
          data-cy={dataCy}
          aria-label={ariaLabel}
          _focusVisible={{ boxShadow: "0 0 0 3px black" }}
        >
          <Text variant="bodyBold_v1">{submitBtnText}</Text>
        </Button>
      </Box>
    </Flex>
  );
};

SHRecommendationsCard.defaultProps = {
  marginTop: 64,
  marginBottom: 64,
};

SHRecommendationsCard.propTypes = {
  marginTop: PropTypes.number,
  marginBottom: PropTypes.number,
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  submitBtnText: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  trackingText: PropTypes.string.isRequired,
  TRACK_EVENT: PropTypes.object,
  ariaLabel: PropTypes.string,
};
