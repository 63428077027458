import {
  Avatar,
  Box,
  Divider,
  Flex,
  Grid,
  GridItem,
  HStack,
  Text,
  VStack,
  Link,
} from "@springcare/sh-component-library";
import React, { FC } from "react";
import { Trans } from "react-i18next";

type NumberedStepsProps = {
  children?: React.ReactNode;
  steps: any;
  isChip?: boolean;
};
type TimeLineStepsProps = {
  headline: String;
  text: String;
};

export const NumberedSteps: FC<NumberedStepsProps> = ({
  steps,
  isChip = false,
}) => {
  return (
    <Box>
      <Grid gap={20} role="ol">
        {steps.map((step, index) => (
          <GridItem key={step?.headline || step} role="li">
            <HStack gap={20} alignItems="flex-start">
              <Avatar
                colorScheme="base"
                name={`${index + 1}`}
                size="2xl"
                style={{
                  backgroundColor: "white",
                  border: "3px solid #011219ab",
                }}
              />
              <VStack alignItems="flex-start">
                <Text size="body-medium-strong">{step?.headline}</Text>
                {/* hardcoding this is a bummer but we needed to interpolate links in this string */}
                {isChip && index === 0 ? (
                  <Text>
                    <Trans
                      components={[
                        <Link href="https://www.zoom.us/download" key="0" />,
                        <Link
                          href="https://itunes.apple.com/us/app/id546505307"
                          key="1"
                        />,
                        <Link
                          href="https://play.google.com/store/apps/details?id=us.zoom.videomeetings"
                          key="2"
                        />,
                      ]}
                    >
                      {step?.text}
                    </Trans>
                  </Text>
                ) : (
                  <Text>{step?.text}</Text>
                )}
              </VStack>
            </HStack>
          </GridItem>
        ))}
      </Grid>
    </Box>
  );
};

export const BenefitsNumberedSteps: ({
  steps,
}: {
  steps: object;
}) => React.JSX.Element = ({ steps }) => {
  return (
    <Flex direction="column">
      {steps[0]?.map((step, index) => (
        <Flex key={step} direction="row">
          <Flex direction="column" alignItems="center" me="32px">
            <Avatar
              name={`${index + 1}`}
              width="40px"
              height="40px"
              background="accent-subtle"
              padding="12px 0px"
              display="flex"
              fontSize="12px"
            />
            {index === steps[0]?.length - 1 ? null : (
              <div
                style={{
                  background: "transparent",
                  height: "100%",
                  width: "2px",
                  minHeight: "22px",
                  position: "relative",
                  padding: "4px 0 4px 0",
                }}
              >
                <Divider
                  orientation="vertical"
                  variant="dashed"
                  style={{
                    position: "absolute",
                    height: "calc(100% - 8px)",
                  }}
                  color="border-bold"
                />
              </div>
            )}
          </Flex>
          <Box
            marginTop={index === 0 ? "8px" : 0}
            marginBottom={index !== steps[0]?.length - 1 ? "24px" : 0}
          >
            <Text>{step}</Text>
          </Box>
        </Flex>
      ))}
    </Flex>
  );
};
export const TimeLineSteps: ({
  steps,
}: {
  steps: TimeLineStepsProps[];
}) => React.JSX.Element = ({ steps }) => {
  return (
    <Flex direction="column" justifyContent={"space-between"} gap={"14px"}>
      {steps?.map((step, index) => (
        <Flex key={index} direction="row">
          <Flex direction="column" alignItems="center" me="32px">
            <Avatar
              size="md"
              style={{
                backgroundColor: "white",
                border: "1px solid #EFEDE8",
              }}
            />
            {index != steps?.length - 1 && (
              <div
                style={{
                  background: "transparent",
                  height: "100%",
                  width: "2px",
                  minHeight: "22px",
                  position: "relative",
                }}
              >
                <Divider
                  orientation="vertical"
                  style={{
                    position: "absolute",
                    height: "calc(100% + 14px)",
                  }}
                  color="border-subtle"
                />
              </div>
            )}
          </Flex>
          <Box>
            <VStack alignItems="flex-start">
              <Text size="body-medium-strong" mb={"4px"}>
                {step?.headline}
              </Text>
              <Text>{step?.text}</Text>
            </VStack>
          </Box>
        </Flex>
      ))}
    </Flex>
  );
};
