import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import { Avatar, Content, FlexRow, Stout, Subtitle } from "@spring/smeargle";
import { Button } from "@springcare/sh-component-library";

import styles from "./styles.module.scss";
import { useOpenModal } from "hooks";

interface AvatarBlockProps {
  image: string | React.ReactElement;
  careNavigator: {
    avatar: string;
    name: string;
  };
}

const AvatarBlock: FC<AvatarBlockProps> = ({ image, careNavigator }) => {
  const { t } = useTranslation(["benefits"]);
  if (image) {
    const imgProps = { alt: "" };

    return (
      <FlexRow alignment="center" justification="center">
        {typeof image !== "string" ? (
          image
        ) : (
          <Avatar
            src={image}
            imgProps={imgProps}
            height={"auto"}
            width={"50%"}
            square
            data-testid="careNavigatorAvatar"
          />
        )}
      </FlexRow>
    );
  } else if (careNavigator) {
    const imgProps = { alt: "" };
    const { openCareProviderDetailModal } = useOpenModal();

    return (
      <FlexRow alignment="center">
        <Avatar
          round
          height="80px"
          width="80px"
          src={careNavigator.avatar}
          imgProps={imgProps}
          data-testid="careNavigatorAvatar"
        />

        <div className={styles.careNavigatorName}>
          <Subtitle noMargin>{careNavigator.name}</Subtitle>
          <Stout>{t("coveredCareContent.yourCareNavigator")}</Stout>
          <Content>
            <Button
              size="sm_v1"
              variant="link"
              colorScheme="primary"
              fontWeight={100}
              _focusVisible={{ boxShadow: "0 0 0 3px black" }}
              textDecoration="underline"
              onClick={() => {
                openCareProviderDetailModal(careNavigator);
              }}
            >
              {t("coveredCareContent.readIntroduction")}
            </Button>
          </Content>
        </div>
      </FlexRow>
    );
  }
  return null;
};

export default AvatarBlock;
