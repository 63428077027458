//
import PropTypes from "prop-types";
import { compose } from "redux";
import { Component } from "react";
import { graphql } from "@apollo/client/react/hoc";
import { TransitionStack } from "@spring/smeargle";
import Meowth from "@spring/meowth";

import styles from "./styles.module.scss";

import { TakeAssessment } from "components";
import { createInitialEvaluation } from "operations/mutations/assessment";
import { getMemberInfo } from "operations/queries/member";
import { NewMemberGoalForm } from "components/organisms/NewMemberGoalForm";
import { connect } from "react-redux";
import { CompactPageHeader, Box } from "@springcare/sh-component-library";
import { logout } from "actions/auth";
import {
  LogoutBtnNew,
  NavHelpBtn,
} from "design-system/components/custom/headers/HeaderButtons";
import { SHFlyout } from "design-system/components";
import { MemberHelpCard } from "components";
import { getIterableCampaignInfo } from "utils/localStorage";

class LegacyInitialAssessment extends Component {
  static propTypes = {
    createInitialEvaluation: PropTypes.func,
    showNewAssessmentComponents: PropTypes.bool,
    assessmentVersion: PropTypes.number,
    helpClickBtnHandler: PropTypes.func,
  };
  // eslint-disable-line react/display-name
  state = { goalSet: false };

  afterSubmit = () => {
    // eslint-disable-next-line react/prop-types
    const memberId = this.props?.data?.user?.member?.id;
    const version_number = this.props.assessmentVersion;

    this.props.createInitialEvaluation(memberId, version_number).then((res) => {
      this.setState({
        goalSet: true,
        assessmentId: res.data.createInitialEvaluation.assessment.id,
      });
    });
  };

  get headerActions() {
    return [
      <NavHelpBtn
        key="nav-help-button"
        onClick={this.props.helpClickBtnHandler}
      />,
      <LogoutBtnNew key="nav-logout-button" />,
    ];
  }

  get headerActionsMobile() {
    this.props.setFileName("common");
    return {
      help: {
        label: `${this.props.t(["limitedLangMemberHelpCard:helpButtonText", "Help"])}`,
        onClick: this.props.helpClickBtnHandler,
      },
      logout: {
        label: `${this.props.t(["common:navigation.links.logout", "Logout"])}`,
        onClick: () => {
          logout();
        },
      },
    };
  }

  get headerHelpText() {
    this.props.setFileName("memberHelpCard");
    return this.props.t("limitedLangMemberHelpCard:footerHeader");
  }

  render() {
    const renderComponent = (
      <>
        <Box zIndex={10} position="relative">
          <CompactPageHeader
            progressBar={true}
            actions={this.headerActions}
            actionsMobile={this.headerActionsMobile}
            progressBarAriaLabel={"assessmentProgress"}
            progressBarMax={1}
            progressBarCurrent={0}
            headerState="scrolled"
          />
        </Box>
        <div key="memberGoalForm" className={styles.goalsFormWrapper}>
          {!this.state.goalSet && (
            <div className={styles.goalsFormSticky}>
              <NewMemberGoalForm
                advance={this.afterSubmit}
                assessmentVersion={this.props.assessmentVersion}
              />
            </div>
          )}
        </div>
      </>
    );

    return (
      <>
        <TransitionStack
          animation="fadeWipeLeft"
          currentIndex={this.state.goalSet ? 1 : 0}
          stack={[
            renderComponent,
            <div key="initialAssessment">
              {this.state.goalSet && (
                <TakeAssessment
                  id={this.state.assessmentId}
                  helpClickBtnHandler={this.props.helpClickBtnHandler}
                  showGoalsLast={false}
                  t={this.props.t}
                  setFileName={this.props.setFileName}
                />
              )}
            </div>,
          ]}
        />
        <SHFlyout
          isOpen={this.props.isOpen}
          onClose={this.props.onClose}
          onOpen={this.props.onOpen}
          size="md"
          headerText={this.headerHelpText}
          isMemberHelpFlyout={true}
        >
          <MemberHelpCard closeFlyout={this.props.onClose} />
        </SHFlyout>
      </>
    );
  }
}

export { LegacyInitialAssessment };

export default compose(
  graphql(getMemberInfo, { options: Meowth.apolloOptionsUserId }),
  graphql(createInitialEvaluation, {
    props: ({ mutate }) => ({
      createInitialEvaluation: (id, version_number) =>
        mutate({
          variables: {
            member_id: id,
            version_number: version_number,
            campaign: getIterableCampaignInfo(),
          },
        }),
    }),
  }),
  connect((state) => ({
    isLoggedIn: state.auth?.isLoggedIn || false,
  })),
)(LegacyInitialAssessment);
