/* eslint-disable react/jsx-key */
import { createIcon } from "@springcare/sh-component-library";

export const ChevronUp = createIcon({
  displayName: "ChevronUp",
  viewBox: "0 0 26 26",
  path: (
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.7071 16.7071C20.3166 17.0976 19.6834 17.0976 19.2929 16.7071L12 9.41421L4.70711 16.7071C4.31658 17.0976 3.68342 17.0976 3.29289 16.7071C2.90237 16.3166 2.90237 15.6834 3.29289 15.2929L11.2929 7.29289C11.4804 7.10536 11.7348 7 12 7C12.2652 7 12.5196 7.10536 12.7071 7.29289L20.7071 15.2929C21.0976 15.6834 21.0976 16.3166 20.7071 16.7071Z"
    />
  ),
  defaultProps: {
    color: "tertiary.700",
  },
});
