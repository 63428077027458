import { useCallback, useEffect, useRef, useState } from "react";
import { VStack, Flex } from "@springcare/sh-component-library";
import { motion, useAnimation, useMotionValue } from "framer-motion";
import { debounce } from "lodash";
import i18n from "i18next";

const MotionFlex = motion(Flex);

const transitionProps = {
  stiffness: 400,
  type: "spring",
  damping: 60,
  mass: 3,
};

const CarouselTrack = ({
  setActiveItem,
  activeItem,
  setItemWidth,
  itemWidth,
  positions: initialPositions,
  children,
  constraint,
  delayItemWidthUpdate,
}) => {
  const controls = useAnimation();
  const x = useMotionValue(0);
  const flexRef = useRef(null);
  const hasDragged = useRef(null);
  const startX = useRef(null);
  const [positions, setPositions] = useState([]);
  const [lastPosition, setLastPosition] = useState(0);
  const [dir, setDir] = useState(i18n.dir());

  const handleResize = useCallback(() => {
    if (!isNaN(positions[activeItem])) {
      controls.start({
        x: positions[activeItem],
        transition: {
          ...transitionProps,
        },
      });
    }
  }, [activeItem, controls, positions]);

  useEffect(() => {
    handleResize();
  }, [handleResize, positions]);

  useEffect(() => {
    setDir(i18n.dir());
  }, [i18n.dir()]);

  const calculatePositions = useCallback(() => {
    let newPositions = [...initialPositions];
    if (dir === "rtl") {
      newPositions = newPositions.map((pos) => -pos);
    }

    setPositions(newPositions);
    setLastPosition(newPositions[newPositions.length - constraint]);
  }, [
    initialPositions,
    dir,
    constraint,
    setPositions,
    setLastPosition,
    lastPosition,
  ]);

  useEffect(() => {
    calculatePositions();
  }, [calculatePositions]);

  // This effect is used to update the item width when the carousel is resized
  useEffect(() => {
    if (flexRef.current) {
      const resizeObserver = new ResizeObserver((entries) => {
        setTimeout(() => {
          if (entries[0]) {
            setItemWidth(entries[0].contentRect.width / children.length);
          }
        });
      });

      if (delayItemWidthUpdate) {
        debounce(() => resizeObserver.observe(flexRef.current), 100);
      } else {
        resizeObserver.observe(flexRef.current);
      }

      return () => {
        resizeObserver.disconnect();
      };
    }
  }, [children.length, setItemWidth, flexRef.current]);

  const handleScroll = useCallback(
    (e) => {
      e.preventDefault();
      const newX = x.get() + e.deltaY;

      if (dir === "rtl") {
        if (newX >= 0 && newX <= lastPosition) x.set(newX);
      } else {
        if (newX <= 0 && newX >= lastPosition) x.set(newX);
      }
    },
    [x, lastPosition, dir],
  );

  const updateActiveItem = useCallback(
    debounce(() => {
      if (x.get()) {
        const currentItemIndex = Math.round(Math.abs(x.get() / itemWidth));

        if (currentItemIndex !== activeItem) {
          setActiveItem(currentItemIndex);
        }
      }
    }, 100),
    [x, itemWidth, setActiveItem, activeItem],
  );

  useEffect(() => {
    const unsubscribeX = x.onChange(() => {
      updateActiveItem();
    });

    return () => unsubscribeX();
  }, [x, updateActiveItem]);

  const handleMouseDown = useCallback(
    (e) => {
      startX.current = e.x;
    },
    [startX],
  );

  const handleMouseUp = useCallback(
    (e) => {
      hasDragged.current = false;
      if (Math.abs(e.x - startX.current) > 20) {
        hasDragged.current = true;
      }
    },
    [startX, hasDragged],
  );

  const handleClick = useCallback(
    (e) => {
      if (hasDragged.current === true) {
        e.stopPropagation();
      }
    },
    [hasDragged],
  );

  useEffect(() => {
    const handleScrollListener = (e) => handleScroll(e);
    const handleMouseDownListener = (e) => handleMouseDown(e);
    const handleMouseUpListener = (e) => handleMouseUp(e);
    const handleClickListener = (e) => handleClick(e);

    flexRef.current?.addEventListener("wheel", handleScrollListener, {
      passive: false,
    });
    flexRef.current?.addEventListener("mousedown", handleMouseDownListener);
    flexRef.current?.addEventListener("mouseup", handleMouseUpListener);
    flexRef.current?.addEventListener("click", handleClickListener, {
      passive: false,
    });

    return () => {
      flexRef.current?.removeEventListener("wheel", handleScrollListener, {
        passive: false,
      });
      flexRef.current?.removeEventListener(
        "mousedown",
        handleMouseDownListener,
      );
      flexRef.current?.removeEventListener("mouseup", handleMouseUpListener);
      flexRef.current?.removeEventListener("click", handleClickListener, {
        passive: false,
      });
    };
  }, [
    flexRef.current,
    handleScroll,
    handleMouseDown,
    handleMouseUp,
    handleClick,
  ]);

  return (
    <>
      {itemWidth && (
        <VStack spacing={5} alignItems="stretch">
          <MotionFlex
            ref={flexRef}
            animate={controls}
            style={{ x }}
            drag="x"
            dragConstraints={{
              left: dir === "rtl" ? 0 : lastPosition,
              right: dir === "rtl" ? lastPosition : 0,
            }}
            _active={{ cursor: "grabbing" }}
            minWidth="min-content"
            flexWrap="nowrap"
            cursor="grab"
          >
            {children}
          </MotionFlex>
        </VStack>
      )}
    </>
  );
};

export default CarouselTrack;
