import {
  Box,
  Button,
  Flex,
  Heading,
  Text,
  useMediaQuery,
} from "@springcare/sh-component-library";
import { useCNScheduling } from "hooks/useCNScheduling";
import {
  BookingReview,
  Calendar,
  TimePicker,
} from "modules/MemberDashboard/Scheduling/components";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

type SchedulingTemplateProps = {
  setOnBackClickAction: (action: () => void) => void;
  setIsScheduleOpen: (isOpen: boolean) => void;
};

const SpecialtyCareSchedule = ({
  setOnBackClickAction,
  setIsScheduleOpen,
}: SchedulingTemplateProps) => {
  const showSUDCN = true;
  const { t } = useTranslation("scheduling");
  const [isSidebarOpen] = useMediaQuery("(max-width: 961px)");

  const {
    availableAppointments,
    bookedAppointment,
    careProviderData,
    careProviderLoading,
    handleBackToCalendar,
    handleBookAppointment,
    handleNextClick,
    handleSelectedDay,
    handleSelectedTimeSlot,
    isAvailableAppointmentsLoading,
    isBookingAppointment,
    isMemberDataLoading,
    member,
    selectedDateTimeSlots,
    selectedDayString,
    selectedTimeSlot,
    shouldShowReviewScreen,
    selectedDate,
  } = useCNScheduling(showSUDCN);
  useEffect(() => {
    shouldShowReviewScreen
      ? setOnBackClickAction(() => () => handleBackToCalendar())
      : setOnBackClickAction(() => () => setIsScheduleOpen(false));
  }, [shouldShowReviewScreen]);

  return shouldShowReviewScreen && careProviderData?.care_provider ? (
    <Box maxWidth={"1024px"} mx="auto" mt={"64px"} mb={24}>
      <BookingReview
        careProvider={careProviderData?.care_provider}
        selectedTimeSlot={selectedTimeSlot}
        handleBookAppointment={handleBookAppointment}
        isBookingAppointment={isBookingAppointment}
        bookedAppointment={bookedAppointment}
        showSUDCN={showSUDCN}
        member={member}
      />
    </Box>
  ) : (
    <Box w="100%">
      <Flex
        direction={"column"}
        gap={4}
        maxW={"1024px"}
        mt={40}
        mx={"auto"}
        pt={24}
        pb={16}
      >
        <Heading as="h1" size="heading-medium">
          {t("title")}
        </Heading>
        <Text>{t("subtitle")}</Text>
      </Flex>
      <Flex
        direction={!isSidebarOpen ? "row" : "column"}
        gap={64}
        mx={"auto"}
        maxW={"1024px"}
      >
        <Calendar
          handleSelectedDay={handleSelectedDay}
          selectedDate={selectedDate}
          availableAppointments={availableAppointments}
        />
        <TimePicker
          appointments={selectedDateTimeSlots}
          handleTimeSlotClick={handleSelectedTimeSlot}
          selectedTimeSlot={selectedTimeSlot}
          selectedDayString={selectedDayString}
        />
      </Flex>

      {selectedTimeSlot?.formattedTime && (
        <Flex justify="end" maxW={"1024px"} mx={"auto"}>
          <Button px={16} py={12} me={8} onClick={handleNextClick}>
            {t("next")}
          </Button>
        </Flex>
      )}
    </Box>
  );
};

export default SpecialtyCareSchedule;
