import { useEffect, useRef } from "react";
import { useInViewportOnce } from "hooks";
import NextLink from "next/link";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import { modalIds } from "@spring/constants";
import { MomentsExerciseCard } from "@spring/smeargle";
import { openModal as smeargleOpenModal } from "@spring/smeargle/actions";

import { Carousel } from "components/molecules";
import { useParentingMoments } from "components/templates/HomePage/hooks/useParentingMoments";
import {
  Box,
  Button,
  Text,
  Flex,
  Link,
  Skeleton,
  SkeletonText,
} from "design-system/components";
import routes from "routes";
import { toTitleCase } from "utils/mixpanel";

import { CarouselHeading } from "./CarouselHeading";
import {
  trackFamilyMomentCarouselClicked,
  trackParentingMomentSeeAllLinkClicked,
  trackFamilyMomentsOnHomePageViewed,
} from "modules/MemberDashboard/Moments/components/analytics";
import { useMediaQuery, useTheme } from "@springcare/sh-component-library";

export const ParentingMomentsCarousel = ({ openModal }) => {
  const { t } = useTranslation(["a11y", "homepage"]);
  const { parentingMomentsData, parentingMomentsLoading } =
    useParentingMoments();

  const ref = useRef();
  const inViewport = useInViewportOnce(ref, "0px");

  useEffect(() => {
    if (inViewport) {
      trackFamilyMomentsOnHomePageViewed();
    }
  }, [inViewport]);

  const familyMoments = parentingMomentsData?.exercises?.data;
  const iconAriaLabels = {
    book: t("icons.bookAlt"),
    headphones: t("icons.headphonesAlt"),
  };
  const { to, as, query } = routes.MemberMomentsParenting;

  const { breakpoints } = useTheme();
  const [isGreaterThanXL] = useMediaQuery(`(min-width: ${breakpoints.xl})`);

  return (
    <Box ref={ref}>
      {parentingMomentsLoading && (
        <Box mt="v-xl" data-testid="parenting-moments-skeleton">
          <Box width="210px">
            <SkeletonText noOfLines={1} skeletonHeight="36px" my="2" />
          </Box>

          <Flex justifyContent="space-between">
            <Box width="330px">
              <Skeleton height="241px"></Skeleton>
            </Box>
            <Box width="330px">
              <Skeleton height="241px"></Skeleton>
            </Box>
            <Box width="330px">
              <Skeleton height="241px"></Skeleton>
            </Box>
          </Flex>

          <Box width="84px">
            <Skeleton height="37px" my="2" />
          </Box>
        </Box>
      )}
      {parentingMomentsData && (
        <>
          <Box pt={2.5}>
            <Box>
              <Carousel
                gap={0}
                CarouselHeading={CarouselHeading}
                hideSliderButtons={isGreaterThanXL}
              >
                {familyMoments.map((moment) => (
                  <Flex
                    key={moment.id}
                    justifyContent="space-between"
                    flexDirection="column"
                    rounded={5}
                    flex={1}
                    py={2}
                    pe={2}
                    my={4}
                    me={"16px"}
                  >
                    <MomentsExerciseCard
                      exercise={moment}
                      iconAriaLabels={iconAriaLabels}
                      t={t}
                      onClick={() => {
                        openModal(modalIds.momentsStartExerciseModal, moment);
                        trackFamilyMomentCarouselClicked(
                          moment.id,
                          moment.title,
                          moment.categories
                            ?.map((item) => toTitleCase(item.category))
                            .join(", "),
                          moment.content_formats?.join(", "),
                        );
                      }}
                    />
                  </Flex>
                ))}
              </Carousel>
              <NextLink
                href={{
                  pathname: to,
                  query: query,
                }}
                as={as}
                passHref
              >
                <Button
                  variant="outline"
                  colorScheme="primary"
                  size="md"
                  as={Link}
                  onClick={trackParentingMomentSeeAllLinkClicked}
                >
                  {t("familySection.moments.seeAll", { ns: "homepage" })}
                </Button>
              </NextLink>
            </Box>
          </Box>
        </>
      )}
      {!parentingMomentsLoading && !parentingMomentsData && (
        <Text>{t("familySection.error")}</Text>
      )}
    </Box>
  );
};

const mapStateToProps = () => ({});
export default connect(mapStateToProps, { openModal: smeargleOpenModal })(
  ParentingMomentsCarousel,
);
