import {
  Stack,
  Box,
  Text,
  Center,
  Button,
  Link,
} from "design-system/components";
import { VCelebrationDuotoneSpot } from "@springcare/sh-component-library";
import routes from "routes";
import { useTranslation } from "react-i18next";

export const ErrorScreen = () => {
  const { t } = useTranslation("survey");

  return (
    <Center mt={200}>
      <Stack width={[330, 380]}>
        <VCelebrationDuotoneSpot
          width="80px"
          height="80px"
          aria-hidden={true}
        />
        <Box m={32}>
          <Text
            fontSize={32}
            fontWeight={700}
            mb={8}
            lineHeight={1.2}
            data-cy="finalCtaHeading"
          >
            {t("finalCTAPrompt.errorMessage")}
          </Text>
        </Box>
        <Button
          as={Link}
          variant="solid"
          colorScheme="primary"
          href={routes.MemberCareVisits.as}
          mt={100}
        >
          {t("surveyButtonText.scheduleNextAppointment")}
        </Button>
      </Stack>
    </Center>
  );
};
