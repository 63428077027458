import { Box, Flex, Heading, Text } from "@springcare/sh-component-library";
import Image from "next/image";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import { SHCareCard } from "design-system/components/custom/cards/SHCareCard";
import { useTrackOnceInViewport } from "hooks/useTrackOnceInViewport";
import { TRACK_EVENT } from "utils/mixpanel";
import { useGetFindNewCareCards } from "components/templates/CareVisitsPage/components/Tabs/hooks";

import type { FindNewCareCardConfigType } from "components/templates/CareVisitsPage/components/Tabs/types";

const CareProviderCard = ({
  kind,
  imageUrl,
  route,
  trackingText,
  showMinorDescription = false,
}: FindNewCareCardConfigType) => {
  const { t } = useTranslation("careVisits", {
    keyPrefix: `findNewCareTab.careProviderList.cards.${kind}`,
  });

  return (
    <Box>
      <SHCareCard
        imageUrl={imageUrl}
        imageAlt={t("imageAlt")}
        cardTitle={t("title")}
        cardSubtitle={
          showMinorDescription ? t("minorDescription") : t("description")
        }
        route={route}
        componentTrackingText={trackingText}
        buttonTrackingText={trackingText}
        tabIndex={0}
        dataCy={`browse-${kind}-card`}
      />
    </Box>
  );
};

const EmptyCard = () => {
  const { t } = useTranslation("careVisits", {
    keyPrefix: "findNewCareTab.careProviderList.cards.empty",
  });
  const ref = useRef();
  const trackOnce = useTrackOnceInViewport(ref);

  useEffect(() => {
    trackOnce(() =>
      TRACK_EVENT.COMPONENT_VIEWED(
        window.location.pathname,
        "Empty Care Provider Card",
      ),
    );
  }, [trackOnce]);

  return (
    <Flex
      ref={ref}
      direction={{ base: "column", md: "row" }}
      border="2px dashed"
      borderColor="border-subtle"
      borderRadius={12}
      w="100%"
      py={30}
      justifyContent="space-around"
      alignItems="center"
      tabIndex={0}
    >
      <Box w={{ base: "80%", md: "30%" }}>
        <Heading size="heading-small" mb={10}>
          {t("title")}
        </Heading>
        <Text>{t("description")}</Text>
      </Box>
      <Box
        w={{ base: "167px", md: "300px" }}
        sx={{ aspectRatio: "1" }}
        pos="relative"
      >
        <Image layout="fill" src="/static/safety.svg" alt="Safety Image" />
      </Box>
    </Flex>
  );
};

export const CareProviderList = () => {
  const providerCards = useGetFindNewCareCards();

  if (providerCards.length === 0) {
    return <EmptyCard />;
  }

  return (
    <Flex flexWrap="wrap" gap={8}>
      {providerCards.map((card) => (
        <CareProviderCard
          key={card.kind}
          kind={card.kind}
          imageUrl={card.imageUrl}
          route={card.route}
          trackingText={card.trackingText}
          showMinorDescription={card?.showMinorDescription}
        />
      ))}
    </Flex>
  );
};
