import { Flex, Box } from "@springcare/sh-component-library";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";
import ActiveAddress from "./ActiveAddress";
import TravelDisclaimer from "./TravelDisclaimer";
import FilterSection from "./FilterSection";
import SelectedFilterChips from "./SelectedFilterChips";

const BrowseSubHeader = ({ brecsOldFilters, isMobile, scrollToSection }) => {
  const needsTravelDisclaimer = useFeatureFlag(FLAGS.TRAVEL_DISCLAIMER);

  return (
    <Flex
      mb={24}
      mt={brecsOldFilters && 40}
      flexDirection={[
        "column-reverse",
        "column-reverse",
        "column-reverse",
        "row",
      ]}
    >
      <Box flex={1}>
        <FilterSection />
        <SelectedFilterChips />
      </Box>

      <Box flex={1}>
        <Box
          ml="auto"
          w={["100%", "100%", "100%", "100%"]}
          pl={!isMobile ? "8px" : 0}
          maxW={["100%", "100%", "100%", "400px"]}
        >
          <ActiveAddress />

          <Box mb={[16, 16, 36, 36]}>
            {needsTravelDisclaimer && (
              <TravelDisclaimer scrollToSection={scrollToSection} />
            )}
          </Box>
        </Box>
      </Box>
    </Flex>
  );
};

export default BrowseSubHeader;
