import { Box, Text } from "@springcare/sh-component-library";
import { SHCareCard } from "design-system/components/custom/cards/SHCareCard";

// IMPORTANT: This component should NOT be accessible in the prod env.
// It is vaporware: parts of the page are static, mocked, or hardcoded.
// It is only intended for our sales team to use in the demo env.
// It was built without performance, scalability, or maintainability in mind.
// See https://springhealth.atlassian.net/browse/MXE-520 for more detail.

const NeurodiversitySupportCard = () => {
  return (
    <Box mr={["v-none", "v-none", "v-8"]} mb={[11, 11, 11, "v-8"]}>
      <SHCareCard
        imageUrl="https://moments-videos.staging.springtest.us/neurodivergence_images/01_ND_find_care.jpg"
        imageAlt="Neurodiversity Support card"
        cardTitle="Neurodiversity support"
        cardSubtitle="Find care and support for all neurodiverse needs at home and at work."
        route={{ to: "/members/neurodiversity", as: "/members/neurodiversity" }}
        tabIndex={0}
        //the SHCareCard componet expects these attributes
        buttonTrackingText={""}
        componentTrackingText={""}
      />
    </Box>
  );
};

export const NeurodiversitySection = () => {
  return (
    <Box mt="40px">
      <Box mb="v-16">
        <Text size="body-medium-strong">For neurodiversity needs</Text>
      </Box>
      <NeurodiversitySupportCard />
    </Box>
  );
};
