import { useEffect } from "react";
import {
  Box,
  VSelfAwarenessDuotoneSpot as SunOverWaterIcon,
  useMediaQuery,
} from "@springcare/sh-component-library";

import NicelyDoneText from "./NicelyDoneText";
import SubmitAssessmentButton from "./SubmitAssessmentButton";
import { TRACK_EVENT } from "utils/mixpanel";

type NicelyDoneScreenProps = {
  firstName: string;
  handleSubmit: () => void;
  loading?: boolean;
  submitButtonCopy: string;
};

export const NicelyDoneScreen = ({
  firstName,
  handleSubmit,
  loading = false,
  submitButtonCopy,
}: NicelyDoneScreenProps) => {
  const [isMobile] = useMediaQuery("(max-width: 450px)");
  useEffect(() => {
    TRACK_EVENT.PAGE_VERSION_VIEWED(
      window.location.pathname,
      "Member viewed nicely done screen",
      {},
    );
  });

  return (
    <Box mt={144} mx={[24, "auto"]} w={isMobile ? "90%" : ["auto", "400px"]}>
      <SunOverWaterIcon aria-hidden="true" />

      <NicelyDoneText firstName={firstName} />

      <SubmitAssessmentButton
        handleSubmit={handleSubmit}
        loading={loading}
        text={submitButtonCopy}
      />
    </Box>
  );
};
