import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Spinner,
  Text,
  VCircleCheckIcon,
  VErrorIcon,
} from "@springcare/sh-component-library";
import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import { debounce } from "lodash";
import { useInsuranceEligibilityCheck } from "components/templates/CostEstimation/hooks/useInsuranceEligibilityCheck";
import { useTranslation } from "react-i18next";
import { ErrorMessage } from "@hookform/error-message";
import { InsuranceEligibilityCheck } from "modules/shared/graphql-codegen/graphql";

const onSubtleStyles = {
  border: "1px solid var(--Info-On-Subtle, #244F73)",
  color: "info-on-subtle",
  backgroundColor: "info-subtle",
  _focus: {
    borderColor: "info-on-subtle",
    boxShadow: "0px 0px 0px 4px rgba(36, 79, 115, 0.24);",
    color: "info-on-subtle",
    backgroundColor: "info-subtle",
  },
  _hover: {
    borderColor: "info-on-subtle",
  },
};

export enum ValidationState {
  INITIAL = "INITIAL", // field is clean and untouched
  INDETERMINATE = "INDETERMINATE", // field is invalid, try again
  FALLBACK = "FALLBACK", // attempts exhausted, use fallback form
  SUCCESS = "SUCCESS", // field is validated
  CHECKING = "CHECKING", // we are currently checking the eligibility
}

export type OnValidationChangeEvent = {
  state: ValidationState;
  data?: InsuranceEligibilityCheck;
};

export const InsuranceMemberIdInput = ({
  label,
  placeholder,
  name,
  register,
  setValue,
  errors,
  onValidationChange,
  primaryInsuranceCarrier,
  memberId,
}) => {
  const { t } = useTranslation("insurance");

  const [isValidated, setIsValidated] = useState(false);
  const [insuranceMemberId, setInsuranceMemberId] = useState("");

  const [failedAttempts, setFailedAttempts] = useState(0);
  const FAILED_ATTEMPT_THRESHOLD = 2;

  const { data: eligibilityData, loading: isCheckingEligibility } =
    useInsuranceEligibilityCheck(
      memberId,
      insuranceMemberId,
      primaryInsuranceCarrier,
      false,
    );

  useEffect(() => {
    if (isCheckingEligibility) {
      onValidationChange({ state: ValidationState.CHECKING });
    }
    if (eligibilityData) {
      // wait until eligibilityData is defined to notify the parent
      const isEligible = eligibilityData?.insurance_eligibility_check.success;
      setIsValidated(isEligible);
      // Keep track of the failed attempts
      // If they fail again after 2 attempts, we will show the fallback form
      if (!isEligible) {
        setFailedAttempts(failedAttempts + 1);
      }
      // If the user has exhausted their attempts we'll fall back
      // but on attempts after that which are successful we'll still show the success state
      if (failedAttempts >= FAILED_ATTEMPT_THRESHOLD - 1 && !isEligible) {
        onValidationChange({ state: "FALLBACK" });
        return;
      }
      if (!isEligible) {
        onValidationChange({ state: "INDETERMINATE" });
        return;
      } else {
        onValidationChange({
          state: "SUCCESS",
          data: eligibilityData?.insurance_eligibility_check,
        });
      }
    }
  }, [eligibilityData, insuranceMemberId]);

  // Once debounce is done, setting the insuranceMemberId will trigger the useInsuranceEligibilityCheck hook
  const debounceValidate = useCallback(
    debounce((value) => {
      setInsuranceMemberId(value);
      setValue(name, value, { shouldValidate: true });
    }, 980),
    [],
  );

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onValidationChange({ state: ValidationState.INITIAL }); // Broadcast to the parent that the value was changed
    setIsValidated(false); // The moment the user changes the form field it is no longer validated
    debounceValidate(e.target.value); // Debounce the input change
  };

  const isIndeterminate =
    !isCheckingEligibility &&
    !isValidated &&
    Boolean(eligibilityData) &&
    failedAttempts < FAILED_ATTEMPT_THRESHOLD;

  return (
    <FormControl isRequired={true} isInvalid={!!errors[name]}>
      <FormLabel fontWeight="normal" htmlFor={name}>
        {label}
      </FormLabel>
      <InputGroup>
        <Input
          ps={"v-16"} // bizarre design requirements
          {...register(name, {
            required: t("form.errors.requiredField"),
          })}
          name={name}
          type={"text"}
          sx={isIndeterminate && onSubtleStyles}
          variant={"medium-emphasis"}
          placeholder={placeholder}
          _placeholder={{ color: "content-placeholder" }}
          onChange={handleChange}
        />
        {isCheckingEligibility && (
          <InputRightElement height={48} width={48}>
            <Flex
              p={1}
              width={32}
              height={32}
              borderRadius={"v-md"}
              alignItems={"center"}
              justifyContent={"center"}
              backgroundColor={"accent-subtle"}
            >
              <Icon
                color={"accent-on-subtle"}
                height={19}
                width={19}
                as={Spinner}
              />
            </Flex>
          </InputRightElement>
        )}
        {isIndeterminate && (
          <InputRightElement height={48}>
            <Icon
              data-testid={"indeterminate-icon"}
              aria-label={"indeterminate-icon"}
              aria-hidden={true}
              boxSize={"v-md"}
              as={VErrorIcon}
              color="info-on-subtle"
            />
          </InputRightElement>
        )}
        {isValidated && !isCheckingEligibility && (
          <InputRightElement height={48} width={48}>
            <Flex
              p={1}
              width={32}
              height={32}
              borderRadius={"v-md"}
              backgroundColor={"positive-subtle"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Icon
                data-testid={"circle-check-icon"}
                as={VCircleCheckIcon}
                color={"positive-on-subtle"}
                height={22}
                width={22}
              />
            </Flex>
          </InputRightElement>
        )}
      </InputGroup>
      {/* Render custom error message - cant use the FormErrorMessage component
        since the indeterminate state is not a react-hook-form validation error. */}
      {isIndeterminate && (
        <Text
          color={"hover-info-high-emphasis"}
          mt={"v-8"}
          size={"body-medium-regular"}
        >
          {t("form.indeterminateInsuranceMemberId")}
        </Text>
      )}
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
      />
    </FormControl>
  );
};
