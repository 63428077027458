import { useEffect, useState } from "react";
import { FlagDefinition } from "./flags";
import { useLDClient } from "launchdarkly-react-client-sdk";
/**
 * This version handles the async return of useFlags() by
 * listening to a change in all LD flags then fetching the current value
 * - current theory is that the LD client on the front end has to do some comparison
 * ... calculation and it's running too slow, so this waits for that computation to finish more or less
 * ... before updating local state
 *
 * TODO: This is a bandage fix and should probably be removed when MEI-23 is released
 * - see ticket to remove: https://springhealth.atlassian.net/browse/MEI-139
 */
// TODO: add a test for this
export function useListenToFeatureFlag(flag: FlagDefinition) {
  const [flagState, setFlagState] = useState<boolean | string | null>(null);
  const ldClient = useLDClient();

  useEffect(() => {
    ldClient.on("change", () => {
      const fetchedLDFlags = ldClient.allFlags();
      const fetchedLDFlag = fetchedLDFlags[flag.name];
      setFlagState(fetchedLDFlag || flag.default);
    });
    ldClient.on("error", (e) => {
      // eslint-disable-next-line no-console
      console.error("ld Client error:", e);
      setFlagState(flag.default);
    });
    return () => {
      ldClient.off("change", () => {
        // eslint-disable-next-line no-console
        console.log("deregistering LD CLient listener for CHANGE events");
      });
      ldClient.off("error", () => {
        // eslint-disable-next-line no-console
        console.log("deregistering LD CLient listener for ERROR events");
      });
    };
  }, []);

  return flagState;
}
