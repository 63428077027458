import { useMemo, useEffect } from "react";
import {
  useRoomContext,
  useMediaDeviceSelect,
} from "@livekit/components-react";
import { isSafari } from "react-device-detect";
import {
  MenuOptionGroup,
  MenuItemOption,
} from "@springcare/sh-component-library";

import {
  useSessionViewStatus,
  useSessionDevices,
} from "context/SessionRoomContext";
import { trackDeviceMenuSelection } from "components/templates/SHSessionRoom/telemetry/analytics";
import {
  DeviceTypes,
  SafariAudioDevice,
} from "components/templates/SHSessionRoom/types";

import {
  excludeVirtualDeviceRegex,
  cleanAlphanumericParentheticals,
} from "components/templates/SHSessionRoom/utils/cleanLabelsUtils";

import { logActiveDeviceChanged } from "components/templates/SHSessionRoom/telemetry/datadog";

import { useTranslation } from "hooks/react-i18next";

export const LivekitDeviceMenuOptionGroup = ({ type }) => {
  const room = useRoomContext();
  const { sessionViewStatus } = useSessionViewStatus();
  const { sessionDevices, setSessionDevices } = useSessionDevices();

  const { t } = useTranslation("livekitExperience", {
    keyPrefix: "videoCallControls.deviceMenuOptionGroup",
  });

  const simulatedSafariAudioOutput: InputDeviceInfo = {
    deviceId: SafariAudioDevice.DeviceId,
    label: SafariAudioDevice.SystemDefaultSpeaker,
    groupId: "",
    kind: "audiooutput",
    toJSON: () => ({}),
  };

  // NOTE: Livekit doesn't support audiooutput, so mediaDevices returns audioinput+videoinput devices
  const {
    devices: mediaDevices,
    activeDeviceId,
    setActiveMediaDevice,
  } = useMediaDeviceSelect({ kind: type });

  const devices = useMemo(() => {
    if (isSafari && type === DeviceTypes.AudioOutput) {
      return [simulatedSafariAudioOutput];
    } else if (type === DeviceTypes.AudioOutput) {
      return sessionDevices?.devices?.audiooutput;
    }
    return mediaDevices;
  }, [type, sessionDevices?.devices?.audiooutput, mediaDevices]);

  const selectedDevice = useMemo((): InputDeviceInfo => {
    if (isSafari && type === DeviceTypes.AudioOutput) {
      return simulatedSafariAudioOutput;
    } else if (!isSafari && type === DeviceTypes.AudioOutput) {
      return {
        deviceId: sessionDevices.selectedAudioOutputDevice.deviceId,
        label: sessionDevices.selectedAudioOutputDevice.label,
        groupId: "",
        kind: "audiooutput",
        toJSON: () => ({}),
      };
    }

    return devices.find((device) => device.deviceId === activeDeviceId);
  }, [devices, activeDeviceId, sessionDevices.selectedAudioOutputDevice]);

  useEffect(() => {
    if (isSafari && type == DeviceTypes.AudioInput) {
      const selectedAudioInputDevice: MediaDeviceInfo = {
        deviceId: sessionDevices.selectedAudioInputDevice.deviceId,
        label: sessionDevices.selectedAudioInputDevice.label,
        groupId: "",
        kind: "audioinput",
        toJSON: () => ({}),
      };
      setTimeout(() => {
        selectActiveDevice(selectedAudioInputDevice);
      }, 1000);
    }
  }, [activeDeviceId]);

  const title = useMemo(() => {
    const titles = {
      [DeviceTypes.AudioOutput]: t("selectASpeaker"),
      [DeviceTypes.AudioInput]: t("selectAMicrophone"),
      [DeviceTypes.VideoInput]: t("selectACamera"),
    };
    return titles[type] || "";
  }, [type, t]);

  const selectActiveDevice = async (device: MediaDeviceInfo) => {
    if (isSafari && type == DeviceTypes.AudioOutput) {
      trackDeviceMenuSelection(type, "meeting_room", device.label);
      return;
    } else if (type == DeviceTypes.AudioOutput) {
      try {
        // this updates the speaker for Livekit's actual track
        await room.switchActiveDevice("audiooutput", device.deviceId);
        // this updates speaker
        setSessionDevices((prevState) => ({
          ...prevState,
          selectedAudioOutputDevice: device,
        }));
      } catch (error) {
        // Intentionally ignoring the error, tmp
      }
    } else if (type === DeviceTypes.AudioInput) {
      try {
        // this updates mic volume
        setSessionDevices((prevState) => ({
          ...prevState,
          selectedAudioInputDevice: device,
        }));
        await setActiveMediaDevice(device.deviceId, { exact: true });
      } catch (error) {
        // Intentionally ignoring the error, tmp
      }
    } else if (type === DeviceTypes.VideoInput) {
      try {
        await setActiveMediaDevice(device.deviceId, { exact: true });
        // this updates mic volume
        setSessionDevices((prevState) => ({
          ...prevState,
          selectedVideoDevice: device,
        }));
      } catch (error) {
        // Intentionally ignoring the error, tmp
      }
    }
    logActiveDeviceChanged(
      type,
      sessionDevices,
      sessionViewStatus,
      "LivekitDeviceMenuOptionGroup - selectActiveDevice()",
    );
    trackDeviceMenuSelection(type, "meeting_room", device.label);
  };

  return (
    <>
      {devices && selectedDevice && (
        <MenuOptionGroup
          defaultValue={selectedDevice.deviceId}
          title={title}
          type="radio"
        >
          {devices?.map((device) =>
            excludeVirtualDeviceRegex.test(device.label) ? null : (
              <MenuItemOption
                value={device.deviceId}
                key={device.deviceId}
                onClick={() => selectActiveDevice(device)}
                _focus={{ outline: "0px solid transparent !important" }}
                _focusVisible={{ outline: "2px solid black !important" }}
                _hover={{ bg: "hover-base-low-emphasis" }}
              >
                {cleanAlphanumericParentheticals(device.label)}
              </MenuItemOption>
            ),
          )}
        </MenuOptionGroup>
      )}
    </>
  );
};

export default LivekitDeviceMenuOptionGroup;
