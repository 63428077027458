import {
  Button,
  Center,
  Heading,
  ModalSheet,
  Text,
  VWorkersDuotoneSpot,
} from "@springcare/sh-component-library";
import { useTranslation, Trans } from "react-i18next";

type WorkplaceConsultationFlyoutType = {
  callback: () => void;
  isOpen: boolean;
  onClose: () => void;
};

export const WorkplaceConsultationFlyout = ({
  callback,
  isOpen,
  onClose,
}: WorkplaceConsultationFlyoutType) => {
  const { t } = useTranslation("homepage", {
    keyPrefix: "careInfoFlyout.workplaceConsultation",
  });

  return (
    <ModalSheet
      title={t("heading")}
      caption={t("caption")}
      isOpen={isOpen}
      onClose={onClose}
      placement="right"
      size="640px"
      footer="on"
      footerChildren={
        <Button onClick={callback} mb={24}>
          {t("ctaText")}
        </Button>
      }
    >
      <>
        <Center my={16} p={33}>
          <VWorkersDuotoneSpot width={154} height={154} />
        </Center>
        <Heading size="heading-small" mb="v-8">
          {t("subheading")}
        </Heading>
        <Text mb={24}>{t("body")}</Text>
        <Trans>
          <Text size="heading-small" mb={20}>
            {t("employeeConsultants")}
          </Text>
        </Trans>
        <Trans>
          <Text mb={24}>{t("managementHR.body1")}</Text>
        </Trans>
        <Text>{t("managementHR.body2")}</Text>
        <Text size="body-medium-strong" my={16}>
          {t("whatToExpect.heading")}
        </Text>
        <Text mb={24}>{t("whatToExpect.body")}</Text>
      </>
    </ModalSheet>
  );
};
