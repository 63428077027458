// NOTE: Candidate for extraction to the design-system
// Also note that the NextLink import will crash Ladle as of
// ... 08/23/2022 due to some Vite compile issues.
// See relevant Github issue: https://github.com/tajo/ladle/issues/100

import React, { useState } from "react";

import {
  Box,
  Button,
  Flex,
  Heading,
  Link,
  Text,
  useMediaQuery,
} from "@springcare/sh-component-library";
import {
  Flyout,
  FlyoutBody,
  FlyoutContent,
  FlyoutFooter,
  FlyoutHeader,
  FlyoutOverlay,
  SHCloseButton,
} from "design-system/components";
import { debounce } from "lodash";
import NextLink from "next/link";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { TRACK_EVENT } from "utils/mixpanel";
import { getPhoneStyleForRTL } from "shared/utils/getPhoneStyleForRTL";

/**
 * @example
 * import {useDisclosure, InformationFlyout} from 'design-system'
 * const Sample = () => {
 *  const {isOpen, onClose, onOpen} = useDisclosure()
 *  return
 *  <InformationFlyout
 *    size="md"
 *    isOpen={isOpen}
 *    onClose={onClose}
 *    heroIllustration={<my hero image component/>}
 *    headerText={t('myHeaderKey)}
 *    ctaText={t('ctaTextKey')}
 *    ctaLink='/'
 *  >
 *    <>
 *      <Text>My Text Here</Text>
 *      <Text>ya</Text>
 *    </>
 *  </InformationFlyout>
 * }
 */
const CTA = ({
  type,
  text,
  icon,
  link,
  linkAs,
  callback,
  headerText,
  reEnableButton,
  isButtonDisabled,
}) => {
  const [isDisabled, setIsDisabled] = useState(isButtonDisabled);

  return type === "button" ? (
    <Button
      colorScheme="primary"
      size="lg"
      leftIcon={icon}
      onClick={() => {
        reEnableButton ? setIsDisabled(false) : setIsDisabled(true);
        TRACK_EVENT.BUTTON_CLICKED(
          window.location.pathname,
          `${text} ${headerText}`,
          {
            spring_doc_id: "HPR_025",
            location: `Informational fly-out - ${headerText}`,
          },
        );
        callback && callback();
      }}
      minWidth={["calc(100vw - 40px)", "calc(100vw - 40px)", "540px"]}
      minHeight="58px"
      disabled={isDisabled}
    >
      <Text style={getPhoneStyleForRTL()}>{text}</Text>
    </Button>
  ) : (
    <NextLink href={link} as={linkAs || link} passHref>
      <Button
        as={Link}
        variant="high-emphasis"
        colorScheme="base"
        size="lg"
        minWidth={["calc(100vw - 40px)", "calc(100vw - 40px)", "540px"]}
        onClick={() => {
          TRACK_EVENT.LINK_CLICKED(window.location.pathname, link, text, {
            spring_doc_id: "HPR_025",
            location: `Informational fly-out - ${headerText}`,
            to: link,
          });
        }}
      >
        <Text style={getPhoneStyleForRTL()}>{text}</Text>
      </Button>
    </NextLink>
  );
};

export const InformationFlyout = ({
  isOpen,
  onClose,
  headerBadge = null,
  headerIcon: Icon,
  headerText,
  subHeaderText = "",
  padding = "",
  showHeroImage = true,
  heroIllustration: HeroIllustration,
  heroImageSrc = "",
  children,
  ctaText,
  ctaIcon: CTAIcon = null,
  ctaLink = "",
  ctaLinkAs = "",
  ctaType = "link",
  ctaCallback = null,
  reEnableButton = false,
  isButtonDisabled = false,
  secondaryCTA = null,
  flyoutHeaderPaddingX = ["v-32", "v-32", "v-64"],
  flyoutHeaderPaddingY = ["10px", "v-24", "v-24"],
}) => {
  const [isMobile] = useMediaQuery("(max-width: 767px)");
  const [bodyScrollPos, setScrollPos] = useState(0);
  const { i18n } = useTranslation();
  const dir = i18n.dir(i18n.language);
  const placement = dir === "ltr" ? "right" : "left";

  const handleScroll = (e) => {
    // rough debounce so we're not triggering the state refresh constantly
    setScrollPos(e.target.scrollTop);
  };

  return (
    <Flyout isOpen={isOpen} onClose={onClose} placement={placement}>
      <FlyoutOverlay />
      <FlyoutContent
        maxWidth={isMobile ? "100%" : "676px"}
        p={padding || [0, 20, 64]}
        boxShadow="elevation.low.left"
      >
        <FlyoutHeader
          width={isMobile ? "100%" : "676px"}
          px={flyoutHeaderPaddingX}
          py={flyoutHeaderPaddingY}
          // only show header box shadow if scroll position > 8px from top
          boxShadow={bodyScrollPos > 0 && "elevation.low.bottom"}
          /*
          NOTE: the negative margin is kind of a hack but lets us not use
          position: absolute in order to get the flyout header to full width
          */
          marginStart={[0, -20, -64]}
          maxHeight="20%"
          display="flex"
          alignItems={headerBadge ? "flex-start" : "center"}
          flexDirection="row-reverse"
          position={padding && "relative"}
          left={padding && dir === "ltr" ? [0, "1.17em", "1.17em"] : 0}
          right={padding && dir === "rtl" ? [0, "1.17em", "1.17em"] : 0}
        >
          <Flex
            height="36px"
            width="28px"
            justifyContent="space-between"
            alignItems="center"
          >
            <SHCloseButton onClick={onClose} />
          </Flex>
          <Flex w="100%" flexDirection="column">
            {headerBadge && <Box mb={10}>{headerBadge}</Box>}
            <Flex w="100%">
              {Icon && (
                <Box
                  display={["none", "none", "inline-flex"]}
                  alignItems="center"
                  me={10}
                  background="tertiary.25"
                  justifyContent="center"
                  borderRadius={100}
                  p="9px"
                  w="38px"
                  h="38px"
                  aria-hidden="true"
                >
                  {React.cloneElement(Icon, { color: "success.800" })}
                </Box>
              )}
              <Box>
                <Heading variant="md_v1">{headerText}</Heading>
              </Box>
            </Flex>
            {subHeaderText && (
              <Text size="body-small-regular" mt={8}>
                {subHeaderText}
              </Text>
            )}
          </Flex>
        </FlyoutHeader>

        <FlyoutBody
          pt={24}
          pb={104}
          tabIndex={0}
          onScroll={debounce(handleScroll, 10)}
          px={[24, 0, 0]}
        >
          {showHeroImage && (
            <Flex pb="32" w="100%" justifyContent="center">
              <Flex
                height={["154px", "154px", "220px"]}
                width={["154px", "154px", "220px"]}
                justifyContent="center"
                alignItems="center"
                background="tertiary.25"
                borderRadius={200}
                data-testid="hero-image-with-roung-background"
              >
                {HeroIllustration ? (
                  <HeroIllustration
                    width="154px"
                    height="154px"
                    alt=""
                    aria-hidden="true"
                  />
                ) : (
                  heroImageSrc && (
                    <img src={heroImageSrc} alt="" aria-hidden="true" />
                  )
                )}
              </Flex>
            </Flex>
          )}

          {children}
        </FlyoutBody>
        {ctaText && (
          <FlyoutFooter
            position="absolute"
            bottom={0}
            insetStart={0}
            display="flex"
            flexDirection={secondaryCTA ? "column" : "row"}
            py={isMobile ? [32, 64] : [15, 24, 32]}
            justifyContent="center"
            alignItems="center"
            width={["100vw", "100vw", "100%"]}
            maxHeight="20%"
            background="platform.on" // i.e white
            boxShadow="elevation.low.top"
            data-testid="information-flyout-footer"
          >
            <CTA
              type={ctaType}
              text={ctaText}
              icon={CTAIcon}
              link={ctaLink}
              linkAs={ctaLinkAs}
              callback={ctaCallback}
              headerText={headerText}
              reEnableButton={reEnableButton}
              isButtonDisabled={isButtonDisabled}
            />
            {secondaryCTA && secondaryCTA}
          </FlyoutFooter>
        )}
      </FlyoutContent>
    </Flyout>
  );
};

InformationFlyout.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  headerBadge: PropTypes.element,
  headerIcon: PropTypes.element,
  headerText: PropTypes.node,
  showHeroImage: PropTypes.bool,
  heroIllustration: PropTypes.func, // element but hasn't been called yet
  heroImageSrc: PropTypes.string,
  ctaText: PropTypes.string,
  ctaIcon: PropTypes.element,
  ctaLink: PropTypes.string,
  ctaType: PropTypes.oneOf(["button", "link"]),
  padding: PropTypes.string,
  flyoutHeaderPaddingX: PropTypes.array,
  flyoutHeaderPaddingY: PropTypes.array,
};
