import { useTranslation } from "react-i18next";
import {
  Avatar,
  Box,
  HStack,
  Flex,
  Text,
  VStack,
  VFamiliesChildIcon,
  useBreakpointValue,
} from "@springcare/sh-component-library";
import routes from "routes";
import { formatMemberExactAge } from "utils/displayHelpers";
import { WrapperDesktop } from "./WrapperDesktop";
import { WrapperMobile } from "./WrapperMobile";
import { UpcomingChangesBadge } from "modules/shared/components/Notifications/components";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";

export const MinorCard = ({ minor }) => {
  const ResponsiveWrapper = useBreakpointValue([WrapperMobile, WrapperDesktop]);
  const agingOutFlagEnabled = useFeatureFlag(FLAGS.ENABLE_AGING_OUT_P1);
  const { t } = useTranslation("homepage");
  const minorsAccountLink = routes["ChooseUserPassword"].as.replace(
    ":id",
    minor.id,
  );
  const accountLinkAriaLabel = t(
    "familySection.yourFamily.viewAccountAriaLabel",
    { name: minor.first_name },
  );
  const accountLinkDataCy = `view-account-${minor.first_name}-button`;

  return (
    <ResponsiveWrapper
      link={minorsAccountLink}
      accountLinkAriaLabel={accountLinkAriaLabel}
      accountLinkDataCy={accountLinkDataCy}
    >
      <HStack>
        <Avatar aria-hidden="true" icon={VFamiliesChildIcon} size="2xl" />
        <VStack pl={8} align="stretch">
          <Box h="fit-content">
            <Flex
              gap={["5px", "5px", "10px"]}
              flexDirection={["column-reverse", "column-reverse", "row"]}
            >
              <Text pt={1} size="body-medium-strong">
                {minor.first_name}
              </Text>
              {agingOutFlagEnabled && (
                <UpcomingChangesBadge
                  dateOfBirth={minor.date_of_birth}
                  agingOutCategory={minor.within_aging_out}
                  minorId={minor.id}
                />
              )}
            </Flex>
          </Box>
          <Box>
            <Text mt={0} size="body-medium-regular">
              {t("familySection.yourFamily.childAge", {
                age: formatMemberExactAge(minor.date_of_birth),
              })}
            </Text>
          </Box>
        </VStack>
      </HStack>
    </ResponsiveWrapper>
  );
};
