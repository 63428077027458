const routes = {
  PublicIndex: {
    to: "/apps/Public",
    as: "/",
  },
  ForgotPassword: {
    to: "/apps/Public/forgot_password",
    as: "/forgot_password",
  },
  ResendConfirmationEmail: {
    to: "/apps/Public/resend_confirmation_email",
    as: "/resend_confirmation_email",
  },
  UpdateEmail: {
    to: "/apps/Public/update_email",
    as: "/update_email",
  },
  CodeConfirmationEmail: {
    to: "/apps/Public/code_confirmation_email",
    as: "/code_confirmation_email",
  },
  ResetPassword: {
    to: "/apps/Public/reset_password",
    as: "/reset_password",
  },
  SignIn: {
    to: "/apps/Public/sign_in",
    as: "/sign_in",
    ssr: true,
  },
  SsoSignIn: {
    to: "/apps/Public/sso_sign_in",
    as: "/sso_sign_in",
  },
  SignUp: {
    to: "/apps/Public/sign_up",
    as: "/sign_up",
  },
  SsoRegister: {
    to: "/apps/Public/sso_register",
    as: "/sso_register",
  },
  Register: {
    to: "/apps/Public/register",
    as: "/register",
    ssr: true,
    children: {
      RegisterDependent: {
        to: "/apps/Public/register/dependent",
        as: "/register/dependent",
        ssr: true,
      },
      RegisterNoEmail: {
        to: "/apps/Public/register/no-email",
        as: "/register/no-email",
        ssr: true,
      },
      RegisterSSO: {
        to: "/apps/Public/register/sso",
        as: "/register/sso",
        ssr: true,
      },
    },
  },
  Verification: {
    to: "/apps/Public/verification",
    as: "/verification",
  },
  EmailVerification: {
    to: "/apps/Public/email_verification",
    as: "/email_verification",
  },
  UnderageVerification: {
    to: "/apps/Public/underage_verification",
    as: "/underage_verification",
  },
  LookupVerification: {
    to: "/apps/Public/lookup_verification",
    as: "/lookup_verification",
  },
  ManualVerification: {
    to: "/apps/Public/manual_verification",
    as: "/manual_verification",
  },
  CreateAccount: {
    to: "/apps/Public/create_account",
    as: "/create_account",
  },
  ComingSoon: {
    to: "/apps/Public/coming_soon",
    as: "/coming_soon",
  },
  Logout: {
    to: "/apps/Public/logout",
    as: "/logout",
  },
  PrivacyPolicy: {
    to: "/apps/Public/privacy",
    as: "/privacy",
  },
  TermsOfService: {
    to: "/apps/Public/terms_of_service",
    as: "/terms_of_service",
  },
  SignUpDenied: {
    to: "/apps/Public/sign_up_denied",
    as: "/sign_up_denied",
  },
  Chat: {
    to: "/apps/Public/chat",
    as: "/chat",
  },
  ConfirmAppointment: {
    to: "/apps/Public/confirm_appointment",
    as: "/confirm_appointment",
  },
  Feedback: {
    to: "/apps/Public/feedback",
    as: "/feedback",
  },
  Session: {
    to: "/apps/Public/session/:appointmentId/:zoomMtgId",
    as: "/session/:appointmentId/:zoomMtgId",
  },
  NoShowReschedule: {
    to: "/apps/Public/no_show_reschedule",
    as: "/no_show_reschedule",
  },
  // Route for vanity URL link to Highmark portal.
  MyHmk: {
    to: "/apps/Public/myhmk",
    as: "/myhmk",
  },
  // Route for vanity URL link to Beneficity portal.
  Beneficity: {
    to: "/apps/Public/beneficity",
    as: "/beneficity",
  },
  // Route for vanity URL link to AllMyHealth (AMH) portal.
  Amh: {
    to: "/apps/Public/amh",
    as: "/amh",
  },
};

export default routes;
