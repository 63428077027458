import { useRouter } from "next/router";
import { useTranslation } from "react-i18next";

import { Link } from "components";
import { SpringLogoIcon } from "design-system/index";
import { Flex } from "design-system/components/index";
import routes from "routes";

export const SHLogoLink = ({ desktopBg, isLoggedIn }) => {
  const router = useRouter();
  const { t } = useTranslation("memberHelpCard");

  const homeAriaLabel = `${t("action.linkTo", { ns: "a11y", linkName: `${t("a11y:shLogoAlt")}` })}`;
  const signInAriaLabel = `${t("action.openNewTab", { ns: "a11y", linkName: `${t("a11y:shLogoAlt")}`, linkTo: t("a11y:pageTitle.signIn") })}`;
  const ShMarketingSiteAriaLabel = `${t("action.openNewTab", { ns: "a11y", linkName: `${t("a11y:shLogoAlt")}`, linkTo: "springhealth.com" })}`;
  const ariaLabel = isLoggedIn ? homeAriaLabel : signInAriaLabel;
  const isRegisterPage = router?.pathname === routes.Register.to;
  const isSignInPage = router?.pathname === routes.SignIn.to;

  return (
    <Flex bg={desktopBg ? desktopBg : "tertiary.25"} h={95} alignItems="center">
      <Flex ps={48}>
        {isLoggedIn ? (
          <Link
            to={routes.MemberHome.as}
            target={isRegisterPage ? "_blank" : null}
            ariaLabel={ariaLabel}
            mpTracking={{
              page: router.asPath,
              to: routes.MemberHome.as,
              type: "Logo",
              location: "Header",
            }}
          >
            <SpringLogoIcon boxSize="28px" />
          </Link>
        ) : (
          <Link
            to={
              isSignInPage ? "https://www.springhealth.com/" : routes.SignIn.as
            }
            target="_blank"
            ariaLabel={isSignInPage ? ShMarketingSiteAriaLabel : ariaLabel}
            mpTracking={{
              page: router.asPath,
              to: routes.SignIn.as,
              type: "Logo",
              location: "Header",
            }}
          >
            <SpringLogoIcon boxSize="28px" />
          </Link>
        )}
      </Flex>
    </Flex>
  );
};
