import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { addDays } from "date-fns/addDays";
import { format } from "date-fns/format";
import { startOfMonth } from "date-fns/startOfMonth";
import { differenceInMinutes } from "date-fns/differenceInMinutes";
import { Box, DatePicker, Heading } from "@springcare/sh-component-library";
import { TRACK_EVENT } from "utils/mixpanel";
import { useLimitCalls } from "hooks/useLimitCalls";
import { getIncludedDates } from "modules/MemberDashboard/Scheduling/utils";
import type { AvailableCnAppointmentTimeOutputType } from "modules/shared/graphql-codegen/graphql";

type CalendarPropTypes = {
  availableAppointments: AvailableCnAppointmentTimeOutputType[];
  handleSelectedDay: (selectedDay: Date) => void;
  selectedDate: Date;
};

export const Calendar = ({
  availableAppointments = [],
  handleSelectedDay,
  selectedDate,
}: CalendarPropTypes) => {
  const { t } = useTranslation("scheduling");
  const maxDate = addDays(new Date(), 60);
  const [trackOnce] = useLimitCalls();

  const includedDates = useMemo(
    () => getIncludedDates(availableAppointments),
    [availableAppointments],
  );

  useEffect(() => {
    trackOnce(() => {
      const formattedDates = includedDates.map((day) =>
        format(day, "yyyy-MM-dd"),
      );
      const difference =
        availableAppointments.length > 0
          ? differenceInMinutes(
              new Date(availableAppointments[0]?.start_time),
              Date.now(),
            )
          : null;
      TRACK_EVENT.COMPONENT_VIEWED(
        window.location.pathname,
        "CN Scheduling Calendar",
        {
          included_dates: formattedDates,
          time_to_first_available_appointment: difference,
        },
      );
    });
  }, [availableAppointments, includedDates, trackOnce]);

  const handleChange = (day: Date) => {
    TRACK_EVENT.BUTTON_CLICKED(
      window.location.pathname,
      "Calendar Date Clicked",
      {
        date_selected: format(day, "yyyy-MM-dd"),
      },
    );

    handleSelectedDay(day);
  };

  const handleMonthChange = (newMonth: Date) => {
    const startOfNewMonth = startOfMonth(newMonth);
    const monthName = format(startOfNewMonth, "MMMM");

    TRACK_EVENT.BUTTON_CLICKED(
      window.location.pathname,
      "Calendar Month Arrow Clicked",
      {
        month_selected: monthName,
      },
    );
  };

  return (
    <Box w="100%" mt="v-40" mr="v-64">
      <Heading size="heading-small">{t("calendarHeading")}</Heading>
      <DatePicker
        minDate={new Date()}
        maxDate={maxDate}
        onChange={handleChange}
        selected={selectedDate}
        onMonthChange={handleMonthChange}
        includeDates={includedDates}
      />
    </Box>
  );
};
