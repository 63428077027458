import { createIcon } from "@springcare/sh-component-library";

export const Pencil = createIcon({
  displayName: "PencilIcon",
  viewBox: "0 0 22 22",
  path: (
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.7631 0.873697C14.9281 -0.291231 16.8168 -0.291234 17.9817 0.873697L21.1263 4.01829C22.2912 5.18322 22.2912 7.07195 21.1263 8.23688L7.65607 21.7071C7.46854 21.8946 7.21418 22 6.94897 22H1C0.447715 22 0 21.5523 0 21V15.051C0 14.7858 0.105357 14.5315 0.292893 14.3439L13.7631 0.873697ZM16.5675 2.28791C16.1836 1.90403 15.5612 1.90403 15.1773 2.28791L14.3121 3.1531L18.8469 7.68785L19.7121 6.82266C20.096 6.43878 20.096 5.81639 19.7121 5.43251L16.5675 2.28791ZM17.4327 9.10207L12.8979 4.56732L2 15.4652V20H6.53475L17.4327 9.10207Z"
    />
  ),
  defaultProps: {
    "aria-label": "Edit icon",
    role: "img",
  },
});
