// TODO: Manage query if 'Reset filters' is clicked
import { useEffect, useRef } from "react";
import { useLazyQuery } from "@apollo/client";
import { useTranslation } from "hooks/react-i18next";
import { cloneDeep } from "lodash/fp";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";
import {
  Box,
  Flex,
  Heading,
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  HStack,
  CheckboxGroup,
  Checkbox,
  SHFlyout,
  Button,
} from "design-system/components";
import { VPlusIcon, ChevronDown } from "design-system/assets";
import { ProviderRole } from "@spring/constants";
import Meowth from "@spring/meowth";

import {
  searchCareProviders,
  getRecommendedTherapists,
} from "operations/queries/careProvider";

import { useProviderBrowseContext } from "context/ProviderBrowseContext";

import {
  prepareQueryFilters,
  dedupeProviders,
  BrecsVariants,
  isBrecsVariant,
  mapCategoryToSingular,
} from "components/templates/Browse/ProviderBrowsePage/utils";

import {
  trackUpdateFiltersClicked,
  trackFilterChangeApplied,
} from "components/templates/Browse/ProviderBrowsePage/analytics";

type Props = {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
};

const ProviderFilterFlyout = ({ isOpen, onOpen, onClose }: Props) => {
  const {
    providerList,
    setProviderList,
    setProviderListLoading,
    setPaging,
    brecsProviderList,
    setBrecsProviderList,
    possibleFilters,
    setPossibleFilters,
    queriedFilters,
    setQueriedFilters,
    selectedFilters,
    setSelectedFilters,
    setQueryRequestId,
    isMobile,
    activeAccordionIndex,
    isCompanyExclusive,
    memberInfo,
    providerType,
    minorTag,
  } = useProviderBrowseContext();
  const brecsVariantFlag = useFeatureFlag(FLAGS.BRECS_ABC_EXPERIMENT);
  const isBrecsVariants =
    isBrecsVariant(brecsVariantFlag) &&
    providerType === ProviderRole.Therapist &&
    !memberInfo?.user?.member?.minor;
  const isCombinedList =
    brecsVariantFlag === BrecsVariants.CombinedList && isBrecsVariants;

  const [doSearchCareProviders] = useLazyQuery(searchCareProviders, {
    fetchPolicy: "network-only",
  });
  const [doGetRecommendedTherapists] = useLazyQuery(getRecommendedTherapists, {
    fetchPolicy: "network-only",
  });
  const { t } = useTranslation(["careProvider", "common"]);
  const ref = useRef(null);

  const showAvailFilters = useFeatureFlag(FLAGS.AVAILABILITY_FILTER_RELEASE);
  const showInPersonMedMan = useFeatureFlag(
    FLAGS.IN_PERSON_MED_MANAGER_RELEASE,
  );
  const inPersonMedManagement =
    memberInfo?.user?.member?.cohort?.in_person_med_management_supported;

  const DEFAULT_FILTER_HEADERS = [
    "mediums",
    "conditions",
    "specialties",
    "genders",
    "ethnicities",
    "languages",
  ];

  const THERAPIST_FILTER_HEADERS = [
    "mediums",
    "conditions",
    "specialties",
    "genders",
    "ethnicities",
    "languages",
  ];

  const COACH_FILTER_HEADERS = [
    "specialties",
    "genders",
    "ethnicities",
    "languages",
  ];
  const MED_MANAGER_FILTER_HEADERS = [
    "conditions",
    "specialties",
    "genders",
    "ethnicities",
    "languages",
  ];

  let filterCategories = DEFAULT_FILTER_HEADERS;
  if (
    providerType == ProviderRole.MedicationManager &&
    showInPersonMedMan &&
    inPersonMedManagement
  ) {
    filterCategories = DEFAULT_FILTER_HEADERS;
  } else if (providerType == ProviderRole.MedicationManager) {
    filterCategories = MED_MANAGER_FILTER_HEADERS;
  } else if (providerType == ProviderRole.Coach) {
    filterCategories = COACH_FILTER_HEADERS;
  } else if (providerType == ProviderRole.Therapist) {
    filterCategories = THERAPIST_FILTER_HEADERS;
  } else {
    filterCategories = DEFAULT_FILTER_HEADERS;
  }

  if (showAvailFilters) {
    if (filterCategories[0] == "mediums") {
      filterCategories.splice(1, 0, "days_of_week");
      filterCategories.splice(2, 0, "time_of_day");
    } else {
      filterCategories.splice(0, 0, "days_of_week");
      filterCategories.splice(1, 0, "time_of_day");
    }
  }

  function resetSelectedFilters(shouldRetriggerRequest = true) {
    const updatedSelectedFilters = { ...selectedFilters };

    filterCategories.forEach((filterLabel) => {
      updatedSelectedFilters.filters[filterLabel] = [];
    });

    if (minorTag) {
      updatedSelectedFilters.filters.specialties.push(minorTag);
    }

    setQueriedFilters(updatedSelectedFilters);
    setSelectedFilters(updatedSelectedFilters);

    if (shouldRetriggerRequest) {
      updateFilteredProviders();
    }
  }

  function hasPopulatedArray(selectedFilters) {
    // @ts-ignore
    return Object.values(selectedFilters).some((array) => array.length > 0);
  }

  const hasSelectedFilters = hasPopulatedArray(selectedFilters.filters);

  // On "Update Results" Button Click
  async function updateFilteredProviders() {
    trackUpdateFiltersClicked(selectedFilters.filters);

    const selectedFiltersCopy = cloneDeep(selectedFilters);
    const preparedFilters = prepareQueryFilters(
      selectedFiltersCopy,
      providerType,
    );

    // Passes "selected" flyout filters to "queried" filters and display filter chips
    setQueriedFilters(selectedFiltersCopy);

    if (preparedFilters) {
      await getFilteredProviders(preparedFilters);
    }
  }

  async function getFilteredProviders(preparedFilters) {
    // Check if there are any conditions in the filters
    const conditionsInFilters = preparedFilters?.filters?.conditions?.length;
    const QUERY_VERSION = isBrecsVariants ? "browse_recs_v1" : "v2";

    preparedFilters["version"] = QUERY_VERSION;
    setProviderListLoading(true);

    const response = await doSearchCareProviders({
      variables: preparedFilters,
    });

    if (response) {
      onClose();
      setQueryRequestId(
        response?.data?.care_providers_search?.query_request_id,
      );
      setPossibleFilters(
        response?.data?.care_providers_search?.possible_filters,
      );
      if (isBrecsVariants) {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    }

    if (response && !isBrecsVariants) {
      setProviderList(response?.data?.care_providers_search?.providers);
      setProviderListLoading(false);
      setPaging(response?.data?.care_providers_search?.paging);
    } else if (response && isBrecsVariants && !conditionsInFilters) {
      await appendBrecsProviders(
        response?.data?.care_providers_search,
        preparedFilters,
      );
    } else if (response && isBrecsVariants && conditionsInFilters) {
      // no recommended providers shown so don't worry about more therapists button
      setBrecsProviderList([]);
      setProviderList(response?.data?.care_providers_search?.providers);
      // NOTE: ALWAYS call setProviderList BEFORE setProviderListLoading or else app't slots may not show up
      setProviderListLoading(false);
      setPaging(response?.data?.care_providers_search?.paging);
    }
  }

  async function getFilteredBrecsProviders(preparedFilters, queryVersion) {
    const apolloOptionsUserId = Meowth.apolloOptionsUserId();
    apolloOptionsUserId.variables.version = queryVersion;
    apolloOptionsUserId.variables.filters = preparedFilters.filters;

    const { data } = await doGetRecommendedTherapists({
      ...apolloOptionsUserId,
    });
    if (data?.user?.member?.recommended_therapists) {
      // Return the therapist key with group_name added within the therapist object
      const recommendedTherapists = data.user.member.recommended_therapists.map(
        (item) => ({
          ...item.therapist,
          group_name: item.group_name,
          isBrecsRecommended: true,
        }),
      );
      return {
        data: data.user.member.recommended_therapists,
        recommendedTherapists: recommendedTherapists,
      };
    } else {
      return {
        data: [],
        recommendedTherapists: [],
      };
    }
  }

  async function appendBrecsProviders(
    filteredSearchProvidersObj,
    preparedFilters,
  ) {
    const recommendedData = await getFilteredBrecsProviders(
      preparedFilters,
      "browse_recs_v1",
    );
    const filteredBrecsProviders =
      recommendedData && recommendedData?.recommendedTherapists;
    const filteredSearchProviders = filteredSearchProvidersObj?.providers;

    // if there's no recs, set the search providers
    if (!filteredBrecsProviders) {
      setBrecsProviderList([]);
      setProviderList(filteredSearchProviders);
      setProviderListLoading(false);
      setPaging(filteredSearchProvidersObj?.paging);
      setQueryRequestId(filteredSearchProviders?.query_request_id);
      return;
    }

    setBrecsProviderList(filteredBrecsProviders);
    const duplicatesRemoved = dedupeProviders(
      filteredSearchProviders,
      recommendedData?.data,
      providerList,
      setProviderList,
      isCombinedList,
      false, // isInitialLoad
    );

    const totalProviderCount =
      filteredBrecsProviders?.length +
      filteredSearchProvidersObj?.paging?.total -
      duplicatesRemoved;

    setProviderListLoading(false);
    setPaging({
      limit: filteredSearchProvidersObj?.paging?.limit,
      page: filteredSearchProvidersObj?.paging?.page,
      pages: filteredSearchProvidersObj?.paging?.pages,
      total: totalProviderCount,
    });
    setQueryRequestId(filteredSearchProvidersObj?.query_request_id);
  }

  // If a member clicks out of the Flyout without updating selectedFilters,
  // this will set the Flyout filter selection back to original state
  const handleClick = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      const queriedFiltersCopy = cloneDeep(queriedFilters);
      setSelectedFilters(queriedFiltersCopy);
      return;
    }
    return;
  };

  // Listens for onClick events on the Flyout
  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    document.addEventListener("touchstart", handleClick);

    return () => {
      document.removeEventListener("mousedown", handleClick);
      document.removeEventListener("touchstart", handleClick);
    };
  }, [ref, handleClick]);

  useEffect(() => {
    if (isCompanyExclusive) {
      resetSelectedFilters(false);
    }
  }, [isCompanyExclusive]);

  const AccordionHeader = ({ filterLabel, isExpanded }) => (
    <AccordionButton
      _hover={{ bg: "#fff" }}
      py={16}
      data-cy={`${filterLabel}-accordion-button`}
      data-ld-flag={
        // eslint-disable-next-line no-constant-condition
        filterLabel === "genders" || "ethnicities" ? "true" : "false"
      }
    >
      <Box flex="1" textAlign="left">
        <HStack spacing={8}>
          <Heading variant="sm_v1">
            {t(`newBrowseProviderPage.filterCategories.${filterLabel}`)}
          </Heading>
          {selectedFilters.filters[filterLabel]?.length === 1 && (
            <Text variant="body_v1">
              {selectedFilters.filters[filterLabel]?.length}{" "}
              {isBrecsVariants
                ? t("providerFilterFlyout.accordionHeader.preference")
                : t("providerFilterFlyout.accordionHeader.filter")}
            </Text>
          )}
          {selectedFilters.filters[filterLabel]?.length > 1 && (
            <Text variant="body_v1">
              {selectedFilters.filters[filterLabel]?.length}{" "}
              {isBrecsVariants
                ? t("providerFilterFlyout.accordionHeader.preferences")
                : t("providerFilterFlyout.accordionHeader.filters")}
            </Text>
          )}
        </HStack>
      </Box>
      {/* TODO: replace ChevronDown with a VMinus icon */}
      {isExpanded ? <ChevronDown /> : <VPlusIcon />}
    </AccordionButton>
  );

  const AccordionBody = ({ possibleFilters, filterLabel }) => {
    // On "Filter Tag" Checked / Unchecked
    function updateSelectedFilters(filterItem, isChecked) {
      const [key, value] = Object.entries(filterItem)[0];

      // Track tag added / removed for Mixpanel
      trackFilterChangeApplied(
        value,
        mapCategoryToSingular(key, t),
        isChecked,
        "Filter Flyout",
      );

      // Create a new activeFilters object based on the current state
      const updatedSelectedFilters = { ...selectedFilters };

      if (isChecked) {
        // Add the value to the key if it's not already present
        if (!updatedSelectedFilters?.filters[key].includes(value)) {
          updatedSelectedFilters?.filters[key].push(value);
        }
      } else {
        // Remove the value from the key if it's present
        updatedSelectedFilters.filters[key] = updatedSelectedFilters?.filters[
          key
        ].filter((item) => {
          return item !== value;
        });
      }
      // Update the state with the new activeFilters object
      setSelectedFilters(updatedSelectedFilters);
    }

    if (!possibleFilters[filterLabel]) return null;
    if (!providerType) return null;

    let sortedTags;
    if (filterLabel == "days_of_week" || filterLabel == "time_of_day") {
      sortedTags = possibleFilters[filterLabel];
    } else {
      sortedTags = possibleFilters[filterLabel]
        // TODO: this is a TEMPFIX - removes 'Transgender' under 'genders'
        // as it isn't currently hooked into the backend
        // See https://springhealth.atlassian.net/browse/BUG-5766 for more info
        .filter((tag) => !(filterLabel === "genders" && tag === "Transgender"))
        .sort((a, b) => a.localeCompare(b));
    }

    return (
      <>
        <AccordionPanel pb={4}>
          {sortedTags.map((tag, index) => {
            return (
              <div key={tag}>
                <CheckboxGroup
                  colorScheme="primary"
                  defaultValue={selectedFilters.filters[filterLabel]}
                >
                  <Box
                    style={
                      selectedFilters?.filters[filterLabel]?.includes(tag)
                        ? { background: "#F0FFFB" }
                        : null
                    }
                    w="fit-content"
                    border="1px transparent solid"
                    borderRadius={8}
                    _hover={{ background: "#F0FFFB" }}
                    mb={"6px"}
                  >
                    <HStack pl={16} spacing={8} key={index} cursor="pointer">
                      <Checkbox
                        size="lg"
                        py={8}
                        onChange={(e) =>
                          updateSelectedFilters(
                            { [filterLabel]: tag },
                            e.target.checked,
                          )
                        }
                        name={tag}
                        value={tag}
                        isDisabled={minorTag === tag ? true : false}
                        data-cy={`${filterLabel}-${tag}`}
                      >
                        <Text key={index} variant="body_v1" pr={16}>
                          {tag == "Morning"
                            ? "Morning (before 12pm)"
                            : tag == "Afternoon"
                              ? "Afternoon (before 5pm)"
                              : tag == "Evening"
                                ? "Evening (after 5pm)"
                                : tag}
                        </Text>
                      </Checkbox>
                    </HStack>
                  </Box>
                </CheckboxGroup>
              </div>
            );
          })}
        </AccordionPanel>
      </>
    );
  };

  return (
    <SHFlyout
      // @ts-ignore
      isOpen={isOpen}
      onClose={onClose}
      onOpen={onOpen}
      size="md"
      headerText={
        isBrecsVariants
          ? t("providerFilterFlyout.prefsTitle")
          : t("providerFilterFlyout.title")
      }
    >
      <div ref={ref}>
        <Box px={35} pt={16} pb={hasSelectedFilters ? 108 : 16}>
          <Accordion defaultIndex={activeAccordionIndex} allowToggle>
            {possibleFilters && (
              <>
                {hasSelectedFilters && (
                  <Button
                    p={0}
                    mb={16}
                    colorScheme="primary"
                    variant="link"
                    textDecoration="none"
                    onClick={() => {
                      resetSelectedFilters();
                    }}
                    display="block"
                    ml="auto"
                    w="fit-content"
                  >
                    {isBrecsVariants
                      ? t("providerFilterFlyout.resetPreferences")
                      : t("providerFilterFlyout.resetButton")}
                  </Button>
                )}
                {filterCategories.map((filterLabel, index) => {
                  return (
                    <Box key={index}>
                      <AccordionItem border={"0px"}>
                        {({ isExpanded }) => (
                          <>
                            <AccordionHeader
                              filterLabel={filterLabel}
                              isExpanded={isExpanded}
                            />

                            <AccordionBody
                              possibleFilters={possibleFilters}
                              filterLabel={filterLabel}
                            />
                          </>
                        )}
                      </AccordionItem>
                    </Box>
                  );
                })}
              </>
            )}
          </Accordion>
        </Box>
        {hasSelectedFilters && (
          <Box position="absolute" bottom={0} bg="#fff" w="100%">
            <Flex justify="center" align="center" h={108}>
              <Button
                w="80%"
                h={56}
                colorScheme="primary"
                variant="solid"
                onClick={updateFilteredProviders}
                data-cy="providerFilterSubmit-button"
              >
                {t("providerFilterFlyout.updateButton")}
              </Button>
            </Flex>
          </Box>
        )}
      </div>
    </SHFlyout>
  );
};

export default ProviderFilterFlyout;
