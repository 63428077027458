import { useQuery } from "@apollo/client";
import { useRouter } from "next/router";
import React, { useState, useRef, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { DateTime } from "luxon";
import camelCase from "lodash/camelCase";
import { Box, Heading, Flex, Text } from "@springcare/sh-component-library";
import { SHBadge } from "design-system/components";
import {
  Avatar,
  Content,
  Section,
  Form,
  FlexRow,
  Tag,
  Grid,
  Col,
  Underlined,
  FlexCol,
  DedicatedIcon,
} from "@spring/smeargle";
import { OnsiteBadge, CareProviderSubhead } from "components";
import Meowth from "@spring/meowth";
import {
  RequestableAppointmentKind,
  inPersonDistance,
  isMedicationManagerRole,
  ProviderRole,
  ProviderStatus,
} from "@spring/constants";

import { useTranslation } from "react-i18next";
import { truncateWords } from "@spring/immutability";

import NonAcuityContent from "components/NonAcuityContent";
import { NewAppointmentSlots } from "components/form";
import ProviderBio from "./ProviderBio";

import styles from "./styles.module.scss";

import { getMemberConsent } from "operations/queries/member";
import { TRACK_EVENT } from "utils/mixpanel";
import { useInViewportOnce } from "hooks";
import { clickableDivProps } from "lib/accessibility_helpers";

import { Show, Hide } from "@springcare/sh-component-library";
import { InNetworkStatusDisclaimer } from "components/molecules";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";
import { providerProfileClick } from "components/templates/Browse/ProviderBrowsePage/utils";
import routes from "routes";

const ProviderResult = ({
  provider,
  schedule,
  requestAvailability,
  appointmentKind,
  modalData,
  supportsInPerson,
  supportsVirtual,
  showGlobalExperience,
  isALegacyMinor,
  isAMinor,
  providerIndex,
  pageNumber,
  member,
  inPersonFilterSelected,
  groupName,
  showLongBioSnippet,
  queryRequestId,
  isRecsPage,
  providerType,
}) => {
  const { t } = useTranslation("careProvider");

  const { data: memberConsentData } = useQuery(
    getMemberConsent,
    Meowth.apolloOptionsUserId(),
  );

  // New Top Specialties added to Provider Card
  const therapyTopSpecialtiesFlag =
    useFeatureFlag(FLAGS.THERAPY_TOP_SPECIALTIES) &&
    providerType === ProviderRole.Therapist;

  const router = useRouter();
  const currentRoute = router?.asPath;
  const isBrowseOrRecs =
    currentRoute === routes.TherapistsBrowse.as ||
    currentRoute === routes.RecommendedTherapists.as;

  const notTherapist =
    provider.roles.includes("Psychiatrist") ||
    provider.roles.includes("Internist") ||
    provider.roles.includes("Family Practitioner") ||
    provider.roles.includes("Psychiatric Nurse Practitioner") ||
    provider.roles.includes("Coach") ||
    provider.roles.includes("Care Advocate") ||
    provider.roles.includes("Care Consultant");

  const { unlimited_coaching_direct_visits } = member.cohort;
  const { visits_covered_coaching } = member;
  const paidCoachingExperience =
    visits_covered_coaching !== null && visits_covered_coaching >= 0;
  const enableDirectSchedulingInCoaching =
    paidCoachingExperience || unlimited_coaching_direct_visits;
  const isCoach = provider.roles.includes("Coach");

  const shouldShowMedicationManager = isMedicationManagerRole(provider.roles);

  const shouldshowTherapist = provider.roles.includes("Therapist");
  const shouldShowCoachingScheduling =
    isCoach && enableDirectSchedulingInCoaching;

  const requestableKind = () => {
    if (isCoach) {
      return RequestableAppointmentKind.Coaching;
    }

    if (isALegacyMinor || isAMinor) {
      return notTherapist
        ? RequestableAppointmentKind.MedicationManagement
        : RequestableAppointmentKind.MinorTherapy;
    }

    return notTherapist
      ? RequestableAppointmentKind.MedicationManagement
      : RequestableAppointmentKind.Therapy;
  };

  // not showing genders and ethnicities in the provider's tag list, that is only for conditions and specialities
  const tagIgnoreKind = ["GENDER", "ETHNICITY", "LANGUAGE"];
  const careTags = [...provider.care_provider_tags].filter(
    (tag) => !tagIgnoreKind.includes(tag.kind),
  );
  const providerLanguages = [...provider.languages];

  const finalCareTags = careTags
    ? careTags
        .sort(function (a, b) {
          return a.name.localeCompare(b.name);
        })
        .map((tag) => <Tag key={tag.id} text={tag.name} />)
    : [];

  const finalLanguages = providerLanguages
    ? providerLanguages
        .sort(function (a, b) {
          return a.localeCompare(b);
        })
        .join(", ")
    : [];

  const providerData = modalData();

  const getNonAcuityContent = () => (
    <NonAcuityContent providerData={providerData} />
  );

  // /* If the provider does not support virtual appointments, and the provider is beyond
  //   the distance limit for in-person appointments, we hide the whole card*/
  if (
    !supportsVirtual &&
    provider.distance_miles > inPersonDistance.limit35 &&
    !shouldShowMedicationManager
  ) {
    return null;
  }

  /* If the provider does not support virtual appointments or
  in-person appointments, we also hide the whole card*/
  if (!supportsVirtual && !supportsInPerson) {
    return null;
  }

  const ref = useRef();
  const inViewport = useInViewportOnce(ref, "0px"); // Trigger as soon as the element becomes visible

  const apptSectionRef = useRef();
  const apptSectionInView = useInViewportOnce(apptSectionRef, "0px");

  let [trackedOnce, setTrackedOnce] = useState(0);

  const [bioExpanded, setBioExpanded] = useState(false);

  const toggleBio = (e) => {
    e.stopPropagation();
    const trackingEventProperties = {
      location: isBrowseOrRecs ? "Provider Card" : "Provider Profile",
      to: isBrowseOrRecs ? "Provider Profile Page" : "",
      provider_id: provider.id,
      provider_order: providerIndex + 1,
      query_request_id: queryRequestId,
    };

    TRACK_EVENT.BUTTON_CLICKED(
      window.location.pathname,
      bioExpanded ? "Hide Bio" : "Show Bio",
      {
        spring_doc_id: "directsched017",
        ...trackingEventProperties,
      },
    );

    if (isBrowseOrRecs) {
      providerProfileClick(provider.id, queryRequestId, "recommended")();
    } else {
      setBioExpanded(!bioExpanded);
    }
  };

  useEffect(() => {
    if (inViewport && trackedOnce === 0) {
      const isRecommendationPage =
        window.location.pathname === "/members/recommendations";

      let topTags = "";
      if (therapyTopSpecialtiesFlag && provider?.top_tags?.length > 0) {
        topTags = provider?.top_tags?.sort()?.join(", ");
      }

      TRACK_EVENT.COMPONENT_VIEWED(
        window.location.pathname,
        isRecommendationPage
          ? "Recommended Provider Profile"
          : "Provider Profile",
        {
          spring_doc_id: "directsched02",
          provider_id: provider.id,
          preference_result: groupName === "full_match",
          top_specialties: topTags,
          browse_page_number: pageNumber,
          provider_order: providerIndex + 1,
          query_request_id: queryRequestId,
          is_associate: provider?.associate_status === "active",
        },
      );
      setTrackedOnce(1);
    }
  }, [inViewport]);

  const translatedRoles = provider.roles.map((role) =>
    t([`careProvider.${camelCase(role)}`, role]),
  );

  const showDirectScheduling =
    shouldshowTherapist ||
    shouldShowMedicationManager ||
    shouldShowCoachingScheduling;

  const showAppointmentSlots = () => (
    <Box
      ref={apptSectionRef}
      mt={isRecsPage ? 0 : 30}
      minW={[null, null, null, "360px"]}
      color="platform.900"
    >
      {!isRecsPage && (
        <Section>
          <Content subheader inheritColor>
            <Heading as={"h3"} fontSize={20} fontWeight={"bold"}>
              {t("schedule")}
            </Heading>
          </Content>
        </Section>
      )}
      <Section>{provider.on_site && <OnsiteBadge />}</Section>

      {apptSectionInView && (
        <Form
          dataCy="appointment-section"
          preserve
          formKey={`care-provider-schedule-${provider.id}`}
          theme="simple"
          onChange={(formData) => {
            if (formData.startTime) {
              schedule(modalData());
            }
          }}
        >
          <NewAppointmentSlots
            fieldKey="startTime"
            initialStartTimeFieldKey="initialStartTime"
            bookings={[Meowth.getUserId(), provider.user_id]}
            stripe={false}
            showMore
            limit={3}
            moreAction={() => schedule(modalData())}
            kind={appointmentKind}
            start={DateTime.local()
              .plus({ hours: 25 })
              .set({ minutes: 0, seconds: 0, milliseconds: 0 })
              .toISO()}
            dayIncrement={3}
            consent={memberConsentData?.user?.member}
            provider={provider}
            inPersonFilterSelected={inPersonFilterSelected}
            upcomingSlotsOnly
            supportsVirtual={supportsVirtual}
            supportsInPerson={supportsInPerson}
            showGlobalExperience={showGlobalExperience}
            isALegacyMinor={isALegacyMinor}
            requestableKind={requestableKind()}
            requestAvailability={requestAvailability}
            modalData={modalData()}
            providerIndex={providerIndex}
            pageNumber={pageNumber}
            showDirectScheduling={showDirectScheduling}
            shouldTrack
            queryRequestId={queryRequestId}
            notTherapist={notTherapist}
            isRecsPage={isRecsPage}
          />
        </Form>
      )}
    </Box>
  );

  const providerCardAppointmentSlots = () => {
    const seeMoreLink = document.getElementById(`see-more-${provider.id}`);

    if (isBrowseOrRecs) {
      providerProfileClick(provider.id, queryRequestId, "recommended")();
    } else if (seeMoreLink) {
      seeMoreLink.click();
    } else {
      schedule(modalData());
    }

    TRACK_EVENT.CARD_CLICKED(
      window.location.pathname,
      isBrowseOrRecs ? "Provider Profile Page" : "Care Provider Schedule Modal",
      isBrowseOrRecs ? "Provider Card" : "Provider Profile",
      {
        spring_doc_id: "directsched016",
        appointment_type: appointmentKind,
      },
    );
  };

  const LanguagesSection = () => {
    return (
      <Box pt={20}>
        {!!providerLanguages.length && (
          <Box className={styles.mobileTagsContainer}>
            <Section size="xsm">
              <Content bold>{t("language")}:</Content>
            </Section>
            {finalLanguages}
          </Box>
        )}
      </Box>
    );
  };

  // This badge indicates a Provider match - only render if groupName is true
  const providerMatchBadge = useMemo(() => {
    const matchText =
      groupName === "full_match"
        ? t("recommendedProviders.preferences.badgeFullMatch")
        : t("recommendedProviders.preferences.badgeDefaultMatch");

    return (
      <SHBadge variant="secondary" text={matchText} mb={24} iconName="Stars" />
    );
  }, [groupName, t]);

  const canShowAppointmentSlots =
    provider.acuity_calendar_id ||
    provider?.status === ProviderStatus.Approved ||
    provider?.status === ProviderStatus.NoNewPatients;

  return (
    <>
      <div
        data-cy={
          provider.name
            ? `provider-card-${provider.name.split(" ").join("-").toLowerCase()}`
            : ""
        }
        className={styles.listing}
        ref={ref}
        onClick={providerCardAppointmentSlots}
      >
        {groupName && providerMatchBadge}

        {provider.dedicated_customer?.name && (
          <DedicatedIcon
            dedicatedText={`${t("dedicatedText")} ${provider.dedicated_customer.name}`}
          />
        )}
        <Hide below="lg">
          <Grid gutter="32px 56px">
            <Col md={7}>
              <FlexCol justification="space-between">
                <div className={styles.nameSection}>
                  <FlexRow>
                    <div className={styles.img}>
                      <Avatar
                        src={provider.avatar}
                        imgProps={{ alt: "" }}
                        height={120}
                        width={120}
                        round
                      />
                    </div>
                    <Section size="md">
                      <Heading as={"h2"} fontWeight={700} fontSize={30}>
                        {provider.name}
                      </Heading>
                      <Section size="xsm">
                        <div className={styles.providerSubtitle}>
                          <CareProviderSubhead
                            roles={translatedRoles}
                            licenses={provider.licenses}
                            categories={provider.categories}
                          />
                        </div>
                        {therapyTopSpecialtiesFlag &&
                          provider?.top_tags?.length > 0 && (
                            <Flex
                              direction={["column", "row"]}
                              textAlign={["center", "left"]}
                              alignItems={"baseline"}
                            >
                              <Text
                                whiteSpace={"nowrap"}
                                variant="bodyBold_v1"
                                width={["100%", "auto"]}
                                mr={4}
                              >
                                {t("topSpecialties")}
                              </Text>
                              <Text variant="body_v1" color="#737778">
                                {[...provider.top_tags]?.sort()?.join(", ")}
                              </Text>
                            </Flex>
                          )}
                      </Section>
                    </Section>
                  </FlexRow>
                </div>

                <ProviderBio
                  provider={provider}
                  toggleBio={toggleBio}
                  bioExpanded={bioExpanded}
                  collapsedBioLength={showLongBioSnippet ? 68 : 18}
                />

                <LanguagesSection />

                <Box style={{ paddingTop: "20px", paddingBottom: "24px" }}>
                  {!!careTags.length && (
                    <Box>
                      <Section size="xsm">
                        <Content bold>{t("help")}</Content>
                      </Section>
                      {finalCareTags}
                    </Box>
                  )}
                </Box>

                {(shouldshowTherapist || shouldShowMedicationManager) && (
                  <InNetworkStatusDisclaimer
                    showCTA={false}
                    inNetwork={member.cohort.in_network}
                    hasFreeSessions={!!member.visits_covered_total}
                    internationalCohort={member.cohort.international}
                    customerName={member.cohort.customer.name}
                    virtualSessionCostTerm={
                      member.cohort.contract_term?.virtual_session_cost_term
                    }
                    inNetworkDisclaimerConfig={{
                      inNetworkFreeSessions: t(
                        "benefitsSummary.additionalInNetworkSessions",
                        { customerName: member.cohort.customer.name },
                      ),
                      inNetworkNoFreeSessions: t(
                        "benefitsSummary.inNetworkSessions",
                        { customerName: member.cohort.customer.name },
                      ),
                      notInNetworkFreeSessions: null,
                      notInNetworkNoFreeSessions: null,
                      default: null,
                    }}
                  />
                )}
              </FlexCol>
            </Col>

            {canShowAppointmentSlots ? (
              <Col md={4}>{showAppointmentSlots()}</Col>
            ) : (
              getNonAcuityContent()
            )}
          </Grid>
        </Hide>

        <Show below="lg">
          <FlexCol alignment="center" justification="center">
            <div className={styles.img}>
              <Avatar
                src={provider.avatar}
                imgProps={{ alt: provider.name, "aria-hidden": "true" }}
                height={120}
                width={120}
                round
              />
            </div>
            <Section>
              <FlexCol alignment="center" justification="center">
                <Heading as={"h2"} fontWeight={700} fontSize={30}>
                  {provider.name}
                </Heading>
                <Section size="sm">
                  <div className={styles.providerSubtitle}>
                    <CareProviderSubhead
                      roles={provider.roles}
                      licenses={provider.licenses}
                      categories={provider.categories}
                    />
                  </div>
                  {therapyTopSpecialtiesFlag &&
                    provider?.top_tags?.length > 0 && (
                      <Flex
                        direction={["column", "row"]}
                        textAlign={["center", "left"]}
                        alignItems={"baseline"}
                      >
                        <Text
                          whiteSpace={"nowrap"}
                          variant="bodyBold_v1"
                          width={["100%", "auto"]}
                          mr={4}
                        >
                          {t("topSpecialties")}
                        </Text>
                        <Text w="100%" variant="body_v1" color="#737778">
                          {[...provider.top_tags]?.sort()?.join(", ")}
                        </Text>
                      </Flex>
                    )}
                </Section>

                {provider.bio && (
                  <Content>
                    {bioExpanded
                      ? provider.bio
                      : truncateWords(30, provider.bio)}
                    <Underlined>
                      <div
                        className={styles.providerBioLink}
                        onClick={toggleBio}
                        {...clickableDivProps({ onClick: toggleBio })}
                      >
                        {bioExpanded ? t("bio.hide") : t("bio.see")}
                      </div>
                    </Underlined>
                  </Content>
                )}
              </FlexCol>

              {LanguagesSection()}

              <Box>
                {!!careTags.length && (
                  <Box mt={20} className={styles.mobileTagsContainer}>
                    <Section size="xsm">
                      <Content bold>{t("help")}</Content>
                      <Box className={styles.conditionsTags}>
                        {finalCareTags}
                      </Box>
                    </Section>
                  </Box>
                )}
              </Box>
            </Section>
          </FlexCol>
          {canShowAppointmentSlots
            ? showAppointmentSlots()
            : getNonAcuityContent()}
        </Show>
      </div>
    </>
  );
};

export default ProviderResult;

ProviderResult.propTypes = {
  appointmentKind: PropTypes.string,
  appointmentMedium: PropTypes.string,
  isALegacyMinor: PropTypes.bool,
  isAMinor: PropTypes.bool,
  inPersonFilterSelected: PropTypes.bool,
  modalData: PropTypes.func,
  provider: PropTypes.object,
  providerIndex: PropTypes.number,
  providerNumber: PropTypes.number,
  requestAvailability: PropTypes.func,
  schedule: PropTypes.func,
  showGlobalExperience: PropTypes.bool,
  supportsInPerson: PropTypes.bool,
  supportsVirtual: PropTypes.bool,
};
