import {
  Box,
  Button,
  Center,
  IconButton,
  VAssessmentIcon,
} from "@springcare/sh-component-library";
import { Icon } from "@spring/smeargle";
import { SpringTextLogo } from "design-system/index";
import { useTranslation } from "react-i18next";
import { useEffect, useRef } from "react";
import { useMemberInfoForNavLinks } from "components/templates/MemberDashboard/PageWrapper/hooks/useMemberInfoForNavLinks";
import { useRouter } from "next/router";
import { useDispatch, useSelector } from "react-redux";
import { toggleSidebar, closeSidebar } from "actions/memberDashboard/actions";
import routes from "routes/index";
import { TRACK_EVENT } from "utils/mixpanel";
import classnames from "classnames";
import LinkItemNav from "./LinkItemNav";
import styles from "./styles.module.scss";
import {
  MobileMenuProps,
  navLinkToggles,
  RootState,
} from "components/templates/MemberDashboard/PageWrapper/types/navLinkTypes";
import { getNavLinks } from "components/templates/MemberDashboard/PageWrapper/utils/getMenuNavLinks/getNavLinks";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";
import { BookAppointmentButton } from "components/molecules/BookAppointmentButton";
import { BookAppointmentButtonTypes } from "components/molecules/BookAppointmentButton/BookAppointmentButton";

export const MobileMenu = ({
  showCheckInButton,
  createCheckInMutation,
}: MobileMenuProps) => {
  const { t } = useTranslation("common");

  const {
    memberInfo,
    isAMinorMember,
    isATeenMember,
    isAnAdultMember,
    isAGlobalMember,
    isAT2Member,
    isAWorkplaceManager,
    isADependent,
    isPrivatePractice,
    canSeeWorkplaceLink,
    viewWorkplaceLinkFeatureFlag,
  } = useMemberInfoForNavLinks();

  const router = useRouter();

  const isSidebarOpen = useSelector((state: RootState) =>
    Boolean(state.memberDashboard.sidebarOpen),
  );
  const dispatch = useDispatch();
  const toggleSidebarAction = () => dispatch(toggleSidebar());
  const closeSidebarAction = () => dispatch(closeSidebar());

  const mobileNavLinkToggles: navLinkToggles = {
    type: "mobile",
    isAT2Member,
    isATeenMember,
    isAnAdultMember,
    isAMinorMember,
    canSeeWorkplaceLink,
    viewWorkplaceLinkFeatureFlag,
    isAWorkplaceManager,
    isAGlobalMember,
    isADependent,
    isPrivatePractice,
  };

  const mobileNavLinks = getNavLinks(mobileNavLinkToggles);

  const handleTeenCheckInClick = () => {
    router.push(routes.TeenCheckIn.as);
    TRACK_EVENT.BUTTON_CLICKED(router.asPath, "Check-in mobile menu", {
      spring_doc_id: "checkin003",
    });
  };

  const handleCheckInClick = isAnAdultMember
    ? createCheckInMutation
    : handleTeenCheckInClick;

  const showNewRightCTA = useFeatureFlag(FLAGS.NEW_RIGHT_CTA);
  const shouldShowCheckInButton =
    showCheckInButton && !isAT2Member && !showNewRightCTA;
  const shouldShowMobileCheckInButton =
    shouldShowCheckInButton && isSidebarOpen;
  const showMobileCheckInIconButton = shouldShowCheckInButton && !isSidebarOpen;

  const sidebarRef = useRef(null);

  const handleClickOutside = (event) => {
    if (
      isSidebarOpen &&
      sidebarRef?.current &&
      !sidebarRef?.current?.contains(event.target)
    ) {
      toggleSidebarAction();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isSidebarOpen]);

  return (
    <div
      id="MemberDashboardSidebar"
      className={classnames(styles.membersSidebar, {
        [styles.open]: isSidebarOpen,
      })}
      ref={sidebarRef}
    >
      <div className={styles.logoArea}>
        <span className={styles.logo}>
          <SpringTextLogo />
        </span>
        <Icon
          type="menu"
          onClick={toggleSidebarAction}
          expanded={isSidebarOpen}
        />
      </div>
      {showNewRightCTA && (
        <Box ml={isSidebarOpen ? 32 : 24}>
          <BookAppointmentButton
            buttonType={
              isSidebarOpen
                ? BookAppointmentButtonTypes.DEFAULT
                : BookAppointmentButtonTypes.ICON
            }
            member={memberInfo}
          />
        </Box>
      )}
      {shouldShowMobileCheckInButton && (
        <Center>
          <Button
            variant="medium-emphasis"
            icon={VAssessmentIcon}
            onClick={handleCheckInClick}
            role="link"
          >
            {t("navigation.mobileCheckInBtn")}
          </Button>
        </Center>
      )}
      <Box className={styles.links}>
        {showMobileCheckInIconButton && (
          <Center marginLeft={10}>
            <IconButton
              aria-label="Check-in on your progress"
              variant="medium-emphasis"
              colorScheme="base"
              size="md"
              role="link"
              icon={VAssessmentIcon}
              onClick={handleCheckInClick}
            />
          </Center>
        )}
        {mobileNavLinks.map((sideBarData, index) => (
          <Box key={index} onClick={closeSidebarAction}>
            <LinkItemNav data={sideBarData} />
          </Box>
        ))}
      </Box>
    </div>
  );
};
