import {
  Box,
  Text,
  VStack,
  HStack,
  Link,
  Image,
} from "@springcare/sh-component-library";
import { DateTime } from "luxon";
import { PlantLeaves } from "design-system/assets";
import routes from "routes";
import { TRACK_EVENT } from "utils/mixpanel";
import { useTranslation } from "react-i18next";

type ProviderDetailsCardProps = {
  providerAvatar: string;
  providerName: string;
  appointmentTime: string;
  appointmentId: string | string[];
  appointmentType: string;
  memberId: string;
};

const ProviderDetailsCard = ({
  providerAvatar,
  providerName,
  appointmentTime,
  appointmentId,
  appointmentType,
  memberId,
}: ProviderDetailsCardProps): React.ReactElement => {
  const { t } = useTranslation("assessments");
  const defaultProviderImageUrl = "/static/DefaultAvatar.png";
  const formattedAppointmentDate: string =
    DateTime.fromISO(appointmentTime).toFormat("LLLL dd");
  const formattedAppointmentTime: string =
    DateTime.fromISO(appointmentTime).toFormat("h:mm a ZZZZ");
  const appointment = `${formattedAppointmentDate} • ${formattedAppointmentTime}`;

  const handleViewDetailsClicked = () => {
    TRACK_EVENT.LINK_CLICKED(
      window.location.pathname,
      routes.MemberAppointmentDetail.as,
      "View Appointment Details",
      {
        appointmend_id: appointmentId,
        member_id: memberId,
        spring_doc_id: "teenassess010",
      },
    );
  };

  return (
    <Box
      borderWidth={1}
      borderRadius="v-lg"
      borderColor="tertiary.light"
      background="v-nuetral.1000"
      mb={45}
      mt={16}
    >
      <VStack w="100%" spacing={0}>
        <Box w="100%">
          <HStack w="100%" h="100%">
            <Image
              fallbackSrc={defaultProviderImageUrl}
              src={providerAvatar}
              borderTopStartRadius="v-md"
              alt={`${t("teenAssessments.providerDetails.imageAltText")}`}
              w={140}
              h={120}
              objectFit="cover"
              data-testid="provider-avatar"
              aria-hidden="true"
            />
            <Box w="100%" px={8}>
              <VStack w="100%" pt={17}>
                <Box w="100%">
                  <Text size="body-small-strong" data-testid="appointment-kind">
                    <Box as="span" textTransform="capitalize">
                      {appointmentType}
                    </Box>
                    <Box as="span" p={8}>
                      <PlantLeaves
                        color={"platform.900"}
                        boxSize={18}
                        aria-hidden={true}
                      />
                    </Box>
                  </Text>
                </Box>

                <Box w="100%">
                  <Text
                    color="platform.900"
                    fontSize="v-14"
                    fontWeight={700}
                    lineHeight="normal"
                    data-testid="provider-name"
                  >
                    {providerName}
                  </Text>
                </Box>
                <Box w="100%" pb={12}>
                  <Text
                    color="platform.900"
                    fontSize="14px"
                    fontWeight={700}
                    lineHeight="normal"
                    data-testid="appointment-time"
                  >
                    {appointment}
                  </Text>
                </Box>
              </VStack>
            </Box>
          </HStack>
        </Box>
        <Box
          w="100%"
          h={45}
          borderTop="1px"
          borderColor="tertiary.light"
          mt={0}
        >
          <Box
            borderInlineEnd="1px"
            borderColor="tertiary.light"
            mt={0}
            pt={13}
            pb={14}
            textAlign="center"
          >
            <Link
              href={`/members/care_visits/${appointmentId}`}
              colorScheme="positive"
              style={{ textDecoration: "none" }}
              color="#068464"
              data-testid="care-visit-link"
              onClick={handleViewDetailsClicked}
            >
              {t("teenAssessments.providerDetails.viewAppointmentLink")}
            </Link>
          </Box>
        </Box>
      </VStack>
    </Box>
  );
};

export default ProviderDetailsCard;
