import { createIcon } from "@springcare/sh-component-library";

export const Swirl = createIcon({
  displayName: "SwirlV1.1",
  viewBox: "0 0 24 24",
  path: (
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.7934 2.67281C12.7717 1.38903 9.39149 2.00263 6.54975 4.0148C0.976279 7.96126 0.588502 16.2958 5.02096 21.3399C5.38553 21.7548 5.34475 22.3866 4.92988 22.7512C4.51502 23.1157 3.88317 23.075 3.5186 22.6601C-1.58064 16.8572 -1.28758 7.11363 5.394 2.38256C8.67843 0.0569184 12.7855 -0.776008 16.5708 0.830075C23.2408 3.58818 26.2168 11.4891 22.1486 18.1751C20.2099 21.4633 16.7589 22.6178 13.5783 22.9931L13.5199 23H13.4611C9.91015 23 6.88691 20.5066 5.40753 17.364L5.39053 17.3279L5.37646 17.2905C4.65232 15.3677 4.26474 13.0072 4.64873 10.968L4.65966 10.91L4.67734 10.8536C5.44398 8.41085 7.34557 6.28793 9.6522 5.26713C12.0023 4.22708 14.8094 4.3271 17.1801 6.42045C19.1704 8.11668 19.9405 10.7315 19.5993 13.0606C19.2552 15.4105 17.7474 17.6124 15.0608 18.2709L15.0172 18.2816L14.9728 18.2884C12.1389 18.7183 9.16813 17.0012 8.72883 13.8424C8.50088 12.5794 8.98703 11.3851 9.73123 10.5382C10.4758 9.69083 11.5848 9.0684 12.7802 9.0684C13.5076 9.0684 14.1488 9.30052 14.6219 9.75026C15.0872 10.1926 15.3146 10.7742 15.3541 11.3415C15.4313 12.4478 14.7984 13.6746 13.5182 14.1144C12.9959 14.2938 12.427 14.0158 12.2476 13.4935C12.0682 12.9712 12.3461 12.4023 12.8685 12.2229C13.1985 12.1095 13.3803 11.7862 13.359 11.4807C13.3493 11.342 13.2992 11.2523 13.2439 11.1998C13.1964 11.1547 13.0741 11.0684 12.7802 11.0684C12.2736 11.0684 11.6804 11.3499 11.2336 11.8584C10.7916 12.3614 10.5985 12.964 10.6992 13.499L10.7041 13.5247L10.7075 13.5507C10.9594 15.4234 12.7209 16.585 14.6284 16.3175C16.341 15.8784 17.371 14.474 17.6204 12.7708C17.8746 11.0353 17.2854 9.13441 15.8773 7.93794L15.8696 7.9314L15.8621 7.9247C14.1494 6.40882 12.194 6.32936 10.4616 7.09604C8.70085 7.87525 7.21047 9.52341 6.60357 11.3961C6.32664 12.9512 6.61402 14.8843 7.23404 16.5481C8.47372 19.1519 10.8604 20.9728 13.404 20.9997C16.325 20.6455 18.9726 19.6288 20.4288 17.1541L20.4327 17.1475L20.4367 17.141C23.8509 11.5367 21.3823 4.98133 15.8027 2.67669L15.7934 2.67286L15.7934 2.67281Z"
    />
  ),
  defaultProps: {
    "aria-label": "Swirl icon",
    role: "img",
    color: "tertiary.700",
  },
});
