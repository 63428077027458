import React, { useRef, useEffect } from "react";
import { useRouter } from "next/router";
import PropTypes from "prop-types";
import {
  Content,
  Section,
  FlexRow,
  FlexCol,
  Breakpoint,
} from "@spring/smeargle";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";
import { Button, useDisclosure } from "@springcare/sh-component-library";
import { useInViewportOnce } from "hooks";

import ProviderOfficeLocation from "./ProviderOfficeLocation";
import { TRACK_EVENT } from "utils/mixpanel";
import routes from "routes";
import { clickableDivProps } from "lib/accessibility_helpers";
import { getOr } from "lodash/fp";
import {
  AppointmentMedium,
  getAppointmentKindForTracking,
  getAppointmentMediumForTracking,
} from "@spring/constants";
import Router from "next/router";
import { HelpFlyout } from "components/flyouts";
import { trackRequestSupportComponentType } from "components/templates/Browse/ProviderBrowsePage/analytics";

const RequestAvailability = (props) => {
  const router = useRouter();
  const requestAvailabilityRef = useRef();
  // Control the help flyout state
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { t } = useTranslation("careProvider");

  const inViewport = useInViewportOnce(requestAvailabilityRef, "0px"); // Trigger as soon as the element becomes visible
  const requestMedium = getAppointmentMediumForTracking(
    getOr("", "medium", props),
  );

  const getTherapistCategory = () => {
    const currentRoute = router?.asPath;

    if (router.query.therapistCategory) {
      return router.query.therapistCategory;
    } else if (
      currentRoute === routes.TherapistsBrowse.as &&
      props.provider?.isBrecsRecommended
    ) {
      return "recommended";
    } else if (currentRoute === routes.TherapistsBrowse.as) {
      return "browse";
    } else if (currentRoute === routes.RecommendedTherapists.as) {
      return "recommended";
    } else {
      return "";
    }
  };

  useEffect(() => {
    if (!props.setHasTracked[requestMedium] && inViewport) {
      props.setHasTracked[requestMedium] = true;

      trackRequestSupportComponentType({
        appointmentMedium: getOr("", "medium", props),
        appointmentKind: getOr("", "kind", props),
        provider: props.provider,
        providerIndex: props.providerIndex,
        pageNumber: props.pageNumber,
        queryRequestId: props.queryRequestId,
        category: getTherapistCategory(),
      });
    }
  }, [inViewport]);

  const handleClick = (e) => {
    e.stopPropagation();
    onOpen();
    const medium = props.inPersonMedium
      ? AppointmentMedium.InPerson
      : AppointmentMedium.Video;

    TRACK_EVENT.BUTTON_CLICKED(
      routes.TherapistsBrowse.as,
      "Request Availability",
      {
        spring_doc_id: "therapy011",
        location: "Provider Profile",
        to: "Request Provider Availability Chat",
        provider_id: props.provider.id,
        appointment_type: getAppointmentKindForTracking(
          getOr("", "kind", props),
        ),
        appointment_medium: getAppointmentMediumForTracking(medium),
      },
    );

    TRACK_EVENT.BUTTON_CLICKED(Router.asPath, "Request Support", {});
  };

  return (
    <div key={props.providerIndex} ref={requestAvailabilityRef}>
      <Section>
        {props.inPersonMedium && <ProviderOfficeLocation {...props} />}
        <Breakpoint md andUp>
          <div style={{ paddingBottom: "24px" }}>
            <Content bold dark style={{ paddingBottom: "5px" }}>
              {t("scheduleModal.appointmentSlots.contactToBook")}{" "}
              {props.provider.name}.
            </Content>
            <Content>
              {t("scheduleModal.appointmentSlots.scheduleHelp")}
            </Content>
          </div>
          <FlexRow justification="space-between">
            <Button
              className={styles.requestSupportCard}
              onClick={(e) => handleClick(e)}
              _focusVisible={{ boxShadow: "0 0 0 3px black" }}
              width="100%"
            >
              <FlexRow alignment="left">
                <FlexCol>
                  {t("scheduleModal.appointmentSlots.requestSupportButtonText")}
                </FlexCol>
              </FlexRow>
            </Button>
          </FlexRow>
        </Breakpoint>

        <Breakpoint md andDown>
          <div style={{ paddingBottom: "24px" }}>
            <Content bold dark style={{ paddingBottom: "4px" }}>
              {props.t("scheduleModal.appointmentSlots.contactToBook")}{" "}
              {props.provider.name}
            </Content>
            <Content>
              {props.t("scheduleModal.appointmentSlots.scheduleHelp")}
            </Content>
          </div>
          <FlexRow alignment="center" justification="center">
            <div
              className={styles.requestSupportCard}
              onClick={(e) => handleClick(e)}
              {...clickableDivProps({ onClick: handleClick })}
            >
              <FlexRow justification="center" alignment="center">
                <FlexCol>
                  {props.t(
                    "scheduleModal.appointmentSlots.requestSupportButtonText",
                  )}
                </FlexCol>
              </FlexRow>
            </div>
          </FlexRow>
        </Breakpoint>
        <HelpFlyout isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
      </Section>
    </div>
  );
};

RequestAvailability.propTypes = {
  inPersonMedium: PropTypes.bool,
  kind: PropTypes.string,
  provider: PropTypes.shape({
    distance_miles: PropTypes.number,
    id: PropTypes.any,
    name: PropTypes.string,
  }),
};

export { RequestAvailability as ContactForAvailability };

export default RequestAvailability;
