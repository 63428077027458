import { useMemo } from "react";
import { API_DOMAIN } from "constants/api";

export const useCaptureConsents = () => {
  const rotomUrl = API_DOMAIN;

  const apiClient = useMemo(
    () => ({
      post: (variables) =>
        fetch(`${rotomUrl}/api/ehr/session_recording_consents`, {
          method: "post",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(variables),
        }).then((res) => res.json()),
    }),
    [],
  );

  return [apiClient];
};
export default useCaptureConsents;
