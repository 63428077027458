import { Flex, Stack, Box } from "@springcare/sh-component-library";
import MainHeader from "components/templates/SHSessionRoom/components/MainHeader";

const SessionDetailsContainer = ({ isMobile, children }) => (
  <Box w="100vw">
    <MainHeader />

    <Flex
      justify="center"
      align={!isMobile ? "center" : "flex-start"}
      as="main"
      id="session-details-container"
    >
      <Stack direction={["column", "column", "row", "row"]}>{children}</Stack>
    </Flex>
  </Box>
);

export default SessionDetailsContainer;
