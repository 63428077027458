import { FLAGS, useFeatureFlag } from "utils/launchdarkly";
import {
  isHighRisk,
  isLowRisk,
  isMediumRisk,
  isMinor,
} from "utils/memberHelpers";
import { useGetAllowDirectScheduling } from "./useGetAllowDirectScheduling";
import { useGetMemberAppointmentAndAssessmentData } from "./useGetMemberAppointmentAndAssessmentData";

// * EPIC: https://springhealth.atlassian.net/browse/MXE-442
export const useOnRampToCareExperiment = () => {
  const { data: memberData } = useGetMemberAppointmentAndAssessmentData();
  const member = memberData?.user?.member;
  const memberId = member?.id;

  const { data: coachingTier } = useGetAllowDirectScheduling({
    memberId,
  });
  // * user is able to directly schedule coaching appointments
  const allowDirectScheduling =
    coachingTier?.member_coaching_tier?.allow_direct_scheduling;

  const onRampToCareFeatureFlag: boolean = useFeatureFlag(
    FLAGS.SHOW_POST_MOMENTS_PHASE_2_AB_TEST,
  );

  const userIsMinor = isMinor(member);

  const hasAttendedAppointmentLast180Days =
    member?.attended_appointment_last_180_days;

  const mostRecentAssessment = member?.most_recent_assessment;

  const userHasUnlimitedCoachingSessions =
    coachingTier?.member_coaching_tier?.unlimited_coaching_direct_scheduling;

  const totalFreeTherapySessions: number =
    memberData?.user?.member?.visits_remaining_basic || 0;

  const totalFreeCoachingSessions: number =
    memberData?.user?.member?.visits_remaining_coaching || 0;

  // * assess acuity/risk level by most recent assessment
  const userIsHighRisk = isHighRisk([mostRecentAssessment]);
  const userIsLowRisk = isLowRisk([mostRecentAssessment]);
  const userIsMediumRisk = isMediumRisk([mostRecentAssessment]);

  const hasBaselineQualifications =
    !hasAttendedAppointmentLast180Days && !userIsMinor;

  // * user is qualified for coaching treatment group if they meet the following criteria:
  const isQualifiedForCoaching =
    allowDirectScheduling &&
    (userIsLowRisk || userIsMediumRisk) &&
    hasBaselineQualifications;

  // * user is qualified for therapy treatment group if they meet the following criteria:
  const isQualifiedForTherapy = userIsHighRisk && hasBaselineQualifications;

  // * user is in the coaching treatment group
  const isCoachingTreatmentUser =
    onRampToCareFeatureFlag && isQualifiedForCoaching;

  // * user is in the therapy treatment group
  const isTherapyTreatmentUser =
    onRampToCareFeatureFlag &&
    (isQualifiedForTherapy || // * check user is qualified for therapy
      (!isCoachingTreatmentUser && hasBaselineQualifications)); // * check user is not qualified for coaching but meets baseline qualifications for therapy

  // * user is in the control group by LD FF or they do not meet the criteria for therapy or coaching treatment groups
  const isControlGroupUser =
    !onRampToCareFeatureFlag ||
    (!isTherapyTreatmentUser && !isCoachingTreatmentUser);

  const calculatedRemainingTherapySessions = isTherapyTreatmentUser
    ? totalFreeTherapySessions
    : null;

  // * if the user has unlimited coaching session, let's return "unlimited" as part of populating i18n string with dynamic values
  const calculatedRemainingCoachingSessions = userHasUnlimitedCoachingSessions
    ? "unlimited"
    : totalFreeCoachingSessions;

  // * if user is in coaching treatment group, return calculated coaching sessions,
  // * otherwise check if they are qualified for the therapy treatment user group
  const remainingSessions = isCoachingTreatmentUser
    ? calculatedRemainingCoachingSessions
    : calculatedRemainingTherapySessions;

  return {
    isControlGroupUser,
    isTherapyTreatmentUser,
    isCoachingTreatmentUser,
    remainingSessions,
  };
};
