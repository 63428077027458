import { links } from "@spring/constants";
import { trackMemberClickedCrisisSupportPhoneNumber } from "modules/MemberDashboard/ManagerExperience/shared/components/ContactUs/utils/ContactUsSectionMixpanelAnalytics";

export const routeToCriticalIncidentResponseForm = () => {
  window.open(links.WorkplaceCriticalIncidentResponse, "_blank");
};

export const routeToManagementConsultForm = () => {
  window.open(links.WorkplaceManagementConsult, "_blank");
};

export const GLOBAL_SUPPORT_EMAIL = "managerconsult@workplaceoptions.com";
export const GLOBAL_SUPPORT_EMAIL_T1_T2 = "rapidresponse@workplaceoptions.com";

export const handleGlobalSupportEmailButtonClick = (emailAddress) => {
  window.location.href = `mailto:${emailAddress}`;
};

export const handleCrisisSupportNumberButtonClick = (crisisSupportNumber) => {
  const telUrl = `tel:${crisisSupportNumber}`;
  trackMemberClickedCrisisSupportPhoneNumber(crisisSupportNumber);
  window.location.href = telUrl;
};
