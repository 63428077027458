import { createIcon } from "@springcare/sh-component-library";

export const Camera = createIcon({
  displayName: "Camera",
  viewBox: "0 0 24 24",
  path: (
    <path
      fill="currentColor"
      d="M12.0002 16.9042C13.1002 16.9042 14.0245 16.5299 14.7731 15.7812C15.5217 15.0326 15.896 14.1007 15.896 12.9854C15.896 11.8854 15.5217 10.9649 14.7731 10.224C14.0245 9.48299 13.1002 9.1125 12.0002 9.1125C10.8849 9.1125 9.95676 9.48299 9.21579 10.224C8.47482 10.9649 8.10433 11.8854 8.10433 12.9854C8.10433 14.1007 8.47482 15.0326 9.21579 15.7812C9.95676 16.5299 10.8849 16.9042 12.0002 16.9042ZM4.2085 20.25C3.84183 20.25 3.521 20.1125 3.246 19.8375C2.971 19.5625 2.8335 19.2417 2.8335 18.875V7.11875C2.8335 6.76736 2.971 6.45035 3.246 6.16771C3.521 5.88507 3.84183 5.74375 4.2085 5.74375H7.57725L8.83766 4.23125C8.95989 4.06319 9.11266 3.94097 9.296 3.86458C9.47933 3.78819 9.67794 3.75 9.89183 3.75H14.1085C14.3224 3.75 14.521 3.78819 14.7043 3.86458C14.8877 3.94097 15.0404 4.06319 15.1627 4.23125L16.4231 5.74375H19.7918C20.1432 5.74375 20.4602 5.88507 20.7429 6.16771C21.0255 6.45035 21.1668 6.76736 21.1668 7.11875V18.875C21.1668 19.2417 21.0255 19.5625 20.7429 19.8375C20.4602 20.1125 20.1432 20.25 19.7918 20.25H4.2085ZM19.7918 18.875V7.11875H4.2085V18.875H19.7918Z"
    />
  ),
  defaultProps: {
    "aria-label": "Camera icon",
    role: "img",
  },
});
