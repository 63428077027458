import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Box, VStack, Center, Spinner } from "@springcare/sh-component-library";
import ProviderDetailsCard from "components/templates/TeenAssessment/MinorAppointmentDetails/ProviderDetailsCard";
import { ErrorScreen } from "components/templates/TeenAssessment/components/ErrorScreen";

import { curriedGetAppointmentKind } from "components/templates/CareVisitDetails/utils";
import { useGetTeenAppointmentInfo } from "components/templates/TeenAssessment/hooks/useGetTeenAppointmentInfo";
import { useGetCareProviderForTeen } from "components/templates/TeenAssessment/hooks/useGetCareProviderForTeen";
import { TEEN_ASSESSMENT_TELEMETRY_TYPES } from "components/templates/TeenAssessment/constants/telemetryValues";
import { TeenOrGuardianAssessmentCTA } from "components/templates/TeenAssessment/MinorAppointmentDetails/TeenOrGuardianAssessmentCTA";
import { TeenAssessmentStartMessage } from "components/templates/TeenAssessment/MinorAppointmentDetails/TeenAssessmentStartMessage";

import { TRACK_EVENT } from "utils/mixpanel";
import {
  showBeforeWeStart,
  showShareWithTeen,
} from "components/templates/TeenAssessment/utils/teenAssessments.utils";

type MinorAppointmentDetailsProps = {
  memberFirstName: string;
  memberId?: string;
  appointmentId: string | string[];
  setRenderBeforeWeStart: React.Dispatch<React.SetStateAction<boolean>>;
  setRenderShareWithYourTeen: React.Dispatch<React.SetStateAction<boolean>>;
  isUnmanagedTeen?: boolean;
  customerId: string;
};

const MinorAppointmentDetails = ({
  memberFirstName,
  memberId,
  appointmentId,
  setRenderBeforeWeStart,
  setRenderShareWithYourTeen,
  isUnmanagedTeen = false,
  customerId,
}: MinorAppointmentDetailsProps): React.ReactElement => {
  useEffect(() => {
    TRACK_EVENT.PAGE_VERSION_VIEWED(
      window.location.pathname,
      TEEN_ASSESSMENT_TELEMETRY_TYPES.SURVEY_CHOOSE_USER,
      {
        member_id: memberId,
        appointment_id: appointmentId,
        page_version: TEEN_ASSESSMENT_TELEMETRY_TYPES.SURVEY_CHOOSE_USER,
        spring_doc_id: "appointmentdetails001",
      },
    );
  }, [memberId, appointmentId]);

  const { t } = useTranslation(["careVisits", "assessments"]);

  const { teenAppointment, teenAppointmentLoading } =
    useGetTeenAppointmentInfo(appointmentId);
  const { careProvider, careProviderLoading } = useGetCareProviderForTeen(
    teenAppointment?.appointment?.provider_id,
  );

  if (teenAppointmentLoading || careProviderLoading) {
    return (
      <Center marginTop={250}>
        <Spinner speed="1s" size="xl" thickness="4px" w="100px" h="100px" />
      </Center>
    );
  }

  if (teenAppointment && careProvider) {
    const { appointment } = teenAppointment;
    const { care_provider } = careProvider;

    const appointmentKindText = curriedGetAppointmentKind(t, customerId);
    const appointmentType = appointmentKindText(appointment.kind);

    return (
      <Center marginTop={["", "90px"]}>
        <VStack w={["327px", "502px"]} alignItems="start">
          <TeenAssessmentStartMessage
            memberFirstName={memberFirstName}
            appointmentId={appointmentId}
          />
          <Box boxSize="100%">
            <ProviderDetailsCard
              providerAvatar={care_provider.avatar}
              providerName={care_provider.name}
              appointmentTime={appointment.start_at}
              appointmentId={appointmentId}
              appointmentType={appointmentType}
              memberId={memberId}
            />
          </Box>
          <TeenOrGuardianAssessmentCTA
            memberFirstName={memberFirstName}
            showBeforeWeStart={() => showBeforeWeStart(setRenderBeforeWeStart)}
            isUnmanagedTeen={isUnmanagedTeen}
            showShareWithTeen={() =>
              showShareWithTeen(setRenderShareWithYourTeen)
            }
          />
        </VStack>
      </Center>
    );
  }

  return <ErrorScreen />;
};

export default MinorAppointmentDetails;
