import PropTypes from "prop-types";
import React from "react";
import { Subtitle, BackgroundCurve } from "@spring/smeargle";
import { useTranslation } from "react-i18next";
import { Heading } from "@springcare/sh-component-library";

const Title = () => {
  const { t } = useTranslation("coachesProvider");

  return t("browseHeader.findACoach");
};

const CoachesHeader = ({ providerStatus }) => {
  const { t } = useTranslation("coachesProvider");

  return (
    <>
      <Heading as="h1" fontSize={48} fontWeight={300} color="#182023">
        <Title />
      </Heading>

      <BackgroundCurve marginTop="-500px" pageRoute="BrowseProviders" />

      {!providerStatus && (
        <Subtitle>{t("browseHeader.noCoachSubtitle")}</Subtitle>
      )}
    </>
  );
};

CoachesHeader.propTypes = {
  providerStatus: PropTypes.any,
};

export default CoachesHeader;
