/* eslint-disable react/jsx-key */
import { createIcon } from "@springcare/sh-component-library";

export const ChevronDown = createIcon({
  displayName: "ChevronDown",
  viewBox: "0 0 26 26",
  path: (
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.29289 7.29289C3.68342 6.90237 4.31658 6.90237 4.70711 7.29289L12 14.5858L19.2929 7.29289C19.6834 6.90237 20.3166 6.90237 20.7071 7.29289C21.0976 7.68342 21.0976 8.31658 20.7071 8.70711L12.7071 16.7071C12.5196 16.8946 12.2652 17 12 17C11.7348 17 11.4804 16.8946 11.2929 16.7071L3.29289 8.70711C2.90237 8.31658 2.90237 7.68342 3.29289 7.29289Z"
    />
  ),
  defaultProps: {
    color: "tertiary.700",
  },
});
