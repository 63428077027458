import { useMutation, useQuery } from "@apollo/client";
import Meowth from "@spring/meowth";
import { addNotification } from "@spring/smeargle/actions";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { Bolded, Section, Subtitle, TryMomentsModal } from "@spring/smeargle";
import {
  Center,
  Heading,
  Spinner,
  useDisclosure,
} from "@springcare/sh-component-library";
import { MemberDashboardPageWrapper } from "components";
import { BeforeYouScheduleModal } from "components/modals";
import { CoveredCareTabs } from "./components";

import CareNavigatorAvailabilityModal from "components/modals/CareNavigatorScheduleModal/CareNavigatorAvailabilityModal";
import { useMemberInfo } from "hooks";
import { sendDownloadAppEmail as sendDownloadAppEmailOperation } from "operations/mutations/general";
import { getMemberCareItems } from "operations/queries/member";

import { CoveredCareTabsT2 } from "modules/MemberDashboard/Benefits/components/CoveredCareTabsT2/CoveredCareTabsT2";
import { isMinorNonTeen, isTeen, isT2Member } from "utils/memberHelpers";
import { isBoeing, isPfizer } from "constants/customers";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";

export const MemberBenefitsWrapper = (props) => {
  const { t } = useTranslation([
    "limitedLangBenefits",
    "limitedLangMoments",
    "t2Benefits",
  ]);
  const apolloOptionsUserId = Meowth.apolloOptionsUserId();
  const dispatch = useDispatch();
  const { data: memberInfo, loading: isMemberInfoLoading } = useMemberInfo({
    fetchPolicy: "network-only",
  });
  const {
    isOpen: isCNScheduleModalOpen,
    onOpen: openCNScheduleModal,
    onClose: closeCNScheduleModal,
  } = useDisclosure();
  const [sendDownloadAppEmailMutation] = useMutation(
    sendDownloadAppEmailOperation,
  );
  const isPeerRecoverySpecialistSupported =
    memberInfo?.user.member.is_peer_recovery_specialist_supported &&
    useFeatureFlag(FLAGS.PEER_RECOVERY_SPECIALIST_EXPERIENCE);
  const { data: careItemsData, loading: isCareItemsDataLoading } = useQuery(
    getMemberCareItems,
    {
      ...apolloOptionsUserId,
      fetchPolicy: "cache-and-network",
    },
  );
  const customerId = memberInfo?.user.member.cohort.customer.id;
  const member = memberInfo?.user.member;
  const isAMinorNonTeen = isMinorNonTeen(member?.date_of_birth);
  const isATeen = isTeen(member?.date_of_birth);
  const userEmail = memberInfo?.user.email;
  const recommendedCareItems =
    careItemsData?.user?.member?.care_items?.recommended_care_items || [];
  const hasSpecializedCareNavigator = recommendedCareItems?.some(
    (item) => item.care_item_name === "specialized_care_navigator",
  );

  const sendDownloadAppEmail = () => {
    dispatch(
      addNotification(
        t("limitedLangBenefits:notificationMessage.info"),
        "info",
      ),
    );
    sendDownloadAppEmailMutation().then((success) => {
      if (success) {
        dispatch(
          addNotification(
            t("limitedLangBenefits:notificationMessage.success"),
            "success",
          ),
        );
      } else {
        dispatch(
          addNotification(
            t("limitedLangBenefits:notificationMessage.error"),
            "error",
          ),
        );
      }
    });
  };

  const sharedBenefitsBody = (props) => {
    const isMemberT2 = isT2Member(member);
    return (
      <>
        {isMemberT2 ? (
          <CoveredCareTabsT2 memberInfo={memberInfo} />
        ) : (
          <CoveredCareTabs {...props} />
        )}
        <TryMomentsModal
          title={t("tryMomentsModal.title", { ns: "limitedLangMoments" })}
          subtitle={t("tryMomentsModal.subtitle", { ns: "limitedLangMoments" })}
          linkText={t("tryMomentsModal.linkText", { ns: "limitedLangMoments" })}
          userEmail={userEmail}
          url={
            (window &&
              window.location &&
              window.location.protocol +
                "//" +
                window.location.host +
                "/download_spring_app") ||
            "https://care.springhealth.com/download_spring_app"
          }
          sendEmail={sendDownloadAppEmail}
        />
      </>
    );
  };

  const getHeadlineTransKey = () => {
    if (isAMinorNonTeen) {
      return t("limitedLangBenefits:minorNonTeenHeadline");
    } else if (isATeen) {
      return t("limitedLangBenefits:teenHeadline");
    } else if (isMemberT2) {
      return t("t2Benefits:headlineT2");
    } else {
      return t("limitedLangBenefits:headline");
    }
  };

  const modalControls = {
    isOpen: isCNScheduleModalOpen,
    onOpen: openCNScheduleModal,
    onClose: closeCNScheduleModal,
  };
  const isMemberT2 = isT2Member(member);
  const visitsEligibilityLimitDate = new Date(
    member?.visits_eligibility_limit_date,
  ).toLocaleString("en-us", { month: "long", day: "numeric", year: "numeric" });

  if (isMemberInfoLoading || isCareItemsDataLoading) {
    return (
      <Center py={20}>
        <Spinner speed="1s" size="xl" />
      </Center>
    );
  }

  return (
    // @ts-ignore
    <MemberDashboardPageWrapper float showT2Background={isMemberT2}>
      <CareNavigatorAvailabilityModal
        isOpen={isCNScheduleModalOpen}
        onClose={closeCNScheduleModal}
        onOpen={openCNScheduleModal}
      />
      <div role="main">
        <div>
          <Heading
            as="h1"
            fontSize={isMemberT2 ? 38 : 48}
            fontWeight={isMemberT2 ? 700 : 300}
            data-cy="benefits-headline"
            size="heading-large"
          >
            {getHeadlineTransKey()}
          </Heading>
        </div>
        {!isBoeing(customerId) && (
          <Section size="sm">
            <Subtitle noMargin>
              <span style={{ fontSize: "1rem" }}>
                {member?.visits_eligibility_limit_date &&
                !isPfizer(customerId) ? (
                  <Trans
                    t={t}
                    ns="limitedLangBenefits"
                    i18nKey="subtitleWithBenefitsDuration"
                    values={{ visitsEligibilityLimitDate }}
                    components={[<Bolded key="subtitleWithBenefitsDuration" />]}
                  />
                ) : (
                  t("limitedLangBenefits:subtitle")
                )}
              </span>
            </Subtitle>
          </Section>
        )}
        {sharedBenefitsBody({
          ...props,
          hasSpecializedCareNavigator,
          modalControls,
          memberInfoData: memberInfo,
          isPeerRecoverySpecialistSupported,
        })}
        <BeforeYouScheduleModal />
      </div>
    </MemberDashboardPageWrapper>
  );
};
