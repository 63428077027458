import { Flex, Text } from "@springcare/sh-component-library";
import { LeftWreath, RightWreath } from "design-system/assets";
import { useTranslation } from "hooks/react-i18next";

type Props = {
  title?: string;
  mb?: string;
};

export const MemberFavoriteBadge = ({ mb = "8px" }: Props) => {
  const { t } = useTranslation("careProvider");

  return (
    <Flex alignItems="center" mb={mb} data-testid="MemberFavoriteBadge">
      <LeftWreath />
      <Text size="body-medium-strong" color="primary-bold" ml="3px" mr="3px">
        {t("benefitsSummary.memberFavorite")}
      </Text>
      <RightWreath />
    </Flex>
  );
};
