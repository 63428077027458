import { useQuery } from "@apollo/client";
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { PersonalizedSuggestionIcon } from "@spring/smeargle";
import { openModal } from "@spring/smeargle/actions";
import Meowth from "@spring/meowth";
import { useTranslation } from "react-i18next";
import { getMemberGoals } from "operations/queries/member";
import { MomentsExerciseErrors } from "./MomentsExerciseErrors";
import { MomentsCardView } from "./MomentsCardView";
import { Heading, Box, Text, Spinner } from "@springcare/sh-component-library";
import { getRecommendedMomentsSectionHeader } from "./momentsForYou.utils";
import { useFilteredRecommendedExerciseData } from "./hooks/useFilteredRecommendedExerciseData";

const MomentsForYou = (props) => {
  const { t } = useTranslation(["moments", "a11y"]);
  const {
    data: recommendedExercisesData,
    loading: recommendedExercisesLoading,
    error: recommendedExerciseErrors,
  } = useFilteredRecommendedExerciseData();
  const {
    data: memberGoalsData,
    loading: memberGoalsLoading,
    error: memberGoalsError,
  } = useQuery(getMemberGoals, {
    variables: {
      id: Meowth.getUserId(),
    },
  });

  const loading = recommendedExercisesLoading || memberGoalsLoading;
  const membersTrackedGoals =
    memberGoalsData?.user?.member?.tracked_goals || [];
  const { momentsOnly } = props;

  return (
    <>
      <Box data-cy="based-on-goal" mb={16}>
        <Heading fontSize={20}>
          <Text as="span" me={10} verticalAlign="middle">
            <PersonalizedSuggestionIcon />
          </Text>
          {getRecommendedMomentsSectionHeader(
            momentsOnly,
            membersTrackedGoals,
            t,
          )}
        </Heading>
      </Box>
      {
        // Loading state
        loading && <Spinner speed="1s" size="xl" />
      }
      {
        // Exercise data error state
        !loading &&
          (recommendedExerciseErrors.length > 0 || memberGoalsError) && (
            <Box>
              <MomentsExerciseErrors
                recommendedExerciseErrors={recommendedExerciseErrors}
                memberGoalsError={memberGoalsError}
                t={t}
              />
            </Box>
          )
      }
      {
        // 'For you' exercise cards
        !loading && recommendedExercisesData && memberGoalsData && (
          <MomentsCardView
            recommendedExercisesData={recommendedExercisesData}
            t={t}
            props={props}
          />
        )
      }
    </>
  );
};

MomentsForYou.propTypes = {
  openModal: PropTypes.func.isRequired,
};

export default connect(null, { openModal })(MomentsForYou);
