/*
 * This component lives on the Help Navbar dropdown. The Menu component was chosen
 * due to accessibility issues with screen reading the unicode flag and text.
 */
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useDisclosure,
} from "design-system/components";
import { colors } from "design-system/theme/base/colors";
import { useOutsideClick } from "@springcare/sh-component-library";
import { SHChevronDown } from "design-system/assets";
import PropTypes from "prop-types";

const HelpLanguageMenu = ({
  countryList,
  selectedCountry,
  setSelectedCountry,
}) => {
  const { isOpen, onOpen, onClose, onToggle } = useDisclosure();
  // custom ref needed because menu's location on the help dropdown causes keyboard accessibility issues
  const selectMenuRef = useRef(null);
  const { t: translatedString } = useTranslation("memberHelpCard");

  /* Following helper methods control dropdown open/close events. These needs to be explicit
   due to keyboard nav issues with the menu located on the Help Dropdown. */
  // Built in method which closes dropdown when clicking outside the menu dropdown
  useOutsideClick({
    ref: selectMenuRef,
    handler: onClose,
  });
  // Handles keydown in the parent container to avoid escaping the help flyover when escaping dropdown
  function handleKeyDown(event) {
    const isMenuOpen = isOpen && selectMenuRef.current;
    if (isMenuOpen && event.key === "Escape") {
      onClose();
      event.stopPropagation();
    }
  }

  function getCountryInfo(countryCode: string) {
    return countryList.find((c) => c.iso2 === countryCode);
  }

  // Handles menu button's keydown to ensure accessibility per W3 with enter and arrow down triggers open.
  function handleMenuButtonKeyDown(event) {
    if (
      (event.key === "Enter" || event.key === "ArrowDown") &&
      selectMenuRef.current
    ) {
      if (isOpen) {
        onClose();
      } else {
        onOpen();
      }
    }
  }

  return (
    <Flex
      direction="column"
      width={"fit-content"}
      minWidth="200"
      onKeyDown={handleKeyDown}
      ref={selectMenuRef}
    >
      <Text color={"#6a6a6a"} fontSize="17px">
        <label htmlFor="help-language-menu">
          {translatedString("getHelpDropdown")}
        </label>
      </Text>
      <Menu id="help-language-menu" isOpen={isOpen}>
        <MenuButton
          data-cy="help-language-menu-button"
          onClick={onToggle}
          onKeyDown={handleMenuButtonKeyDown}
          aria-label={translatedString("selectCountry", {
            country: getCountryInfo(selectedCountry).name,
          })}
          aria-expanded={isOpen}
          as={Button}
          pr=".5rem"
          pl=".5rem"
          ml={-2}
          size={{ base: "md", md: "lg" }}
          variant="ghost"
          rightIcon={<SHChevronDown boxSize={12} />}
          border="2px solid transparent"
          _focus={{
            borderWidth: "2",
            borderColor: "primary.base",
          }}
          _hover={{
            borderWidth: "2",
            borderColor: "primary.base",
          }}
        >
          <Flex>
            <Text mr=".5rem" fontSize="md" aria-hidden={true}>
              {getCountryInfo(selectedCountry).flag}
            </Text>
            <Text fontWeight="bold" fontSize="md" color="#1C6B57">
              {getCountryInfo(selectedCountry).name}
            </Text>
          </Flex>
        </MenuButton>
        {/*Set max height to avoid popover glitching from flyover location*/}
        <MenuList
          sx={{ maxHeight: "60vh" }}
          overflow="scroll"
          data-cy="help-language-menu-dropdown"
        >
          {countryList.map((item, index: number) => (
            <MenuItem
              key={`${item.name}${index}`}
              cursor="pointer"
              onClick={() => {
                setSelectedCountry(item.iso2);
                onClose();
              }}
              py=".25rem"
              pl="1rem"
              _focus={{
                background: `${colors.tertiary.on}`,
              }}
              _hover={{
                background: `${colors.tertiary.on}`,
              }}
            >
              <Flex>
                <Text mr=".75rem" fontSize="md" aria-hidden={true}>
                  {item.flag}
                </Text>
                <Text
                  fontSize="md"
                  fontWeight={item.iso2 === selectedCountry ? "bold" : "normal"}
                >
                  {item.name}
                </Text>
              </Flex>
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </Flex>
  );
};

HelpLanguageMenu.propTypes = {
  countryList: PropTypes.array.isRequired,
  selectedCountry: PropTypes.string.isRequired,
  setSelectedCountry: PropTypes.func.isRequired,
};

export { HelpLanguageMenu };
