import {
  Button,
  ModalSheet,
  Image,
  HStack,
  Text,
  VVideoChatIcon,
  VStack,
  Heading,
} from "@springcare/sh-component-library";
import { useTranslation } from "hooks/react-i18next";

type Exercise = {
  title: string;
  duration: string;
  images: { url: string }[];
};

type MomentsStartModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onStartExercise: () => void;
  exercise: Exercise | null;
};

export const MomentsStartModal: React.FC<MomentsStartModalProps> = ({
  isOpen,
  onClose,
  onStartExercise,
  exercise,
}) => {
  const { t } = useTranslation(["moments", "a11y"]);
  const { title = "", duration = "", images = [] } = exercise ?? {};

  const image_url = images[0]?.url;

  return (
    <ModalSheet
      isOpen={isOpen}
      onClose={onClose}
      placement="center"
      size="sm"
      onBack={() => {}}
      backButton="off"
    >
      <VStack spacing="v-16" mb="v-16">
        <Image
          src={image_url}
          alt={title || t("videoAltText")}
          fallbackSrc="https://d1pwskn5bvutk0.cloudfront.net/lantern-alpha/images/CreatingCalm/CreatingCalmSit_full.svg"
          width="300px"
          objectFit="cover"
        />
        <Heading size="heading-small">{title}</Heading>
        <HStack>
          <Text>{`${duration} ${t("startExerciseModal.min")}`}</Text>
          <VVideoChatIcon width={24} height={24} aria-hidden="true" />
        </HStack>
        <Button onClick={onStartExercise} w="100%">
          {t("startExerciseModal.getStarted")}
        </Button>
      </VStack>
    </ModalSheet>
  );
};
