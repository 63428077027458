import React from "react";
import { MomentsExerciseCard } from "@spring/smeargle";
import { toTitleCase } from "utils/mixpanel";
import { modalIds } from "@spring/constants";
import { SimpleGrid } from "@springcare/sh-component-library";
import { trackMomentExerciseClicked } from "modules/MemberDashboard/Moments/components/analytics";
import { useRouter } from "next/router";
import { addExerciseIdToQueryParams } from "utils/routes";

export const MomentsCardView = ({ recommendedExercisesData, t, props }) => {
  const router = useRouter();
  const iconAriaLabels = {
    book: t("icons.bookAlt", { ns: "a11y" }),
    headphones: t("icons.headphonesAlt", { ns: "a11y" }),
  };

  return (
    <SimpleGrid columns={[1, 1, 2, 3]} gap="v-20" mb={["v-32", "v-32", "96px"]}>
      {recommendedExercisesData?.map(({ exercise }) => (
        <div key={exercise.id}>
          <MomentsExerciseCard
            dataCy={"foryoucard"}
            exercise={exercise}
            iconAriaLabels={iconAriaLabels}
            t={t}
            onClick={() => {
              props.openModal(modalIds.momentsStartExerciseModal, exercise);
              trackMomentExerciseClicked(
                exercise.id,
                exercise.title,
                exercise.categories
                  ?.map((item) => toTitleCase(item.category))
                  .join(", "),
                exercise.content_formats?.join(", "),
              );
              addExerciseIdToQueryParams(router, exercise);
            }}
          />
        </div>
      ))}
    </SimpleGrid>
  );
};
