import {
  Flex,
  VMicOffIcon,
  VMicOnIcon,
} from "@springcare/sh-component-library";

const MicrophoneIndicator = ({ isSmall, isMicrophoneEnabled }) => (
  <Flex
    bg="background-media-overlay"
    borderRadius={isSmall ? "v-sm" : "v-lg"}
    p={isSmall ? "v-4" : "v-8"}
  >
    {isMicrophoneEnabled ? (
      <VMicOnIcon
        height={isSmall ? "12px" : "16px"}
        width={isSmall ? "12px" : "16px"}
        color="white"
        aria-hidden="true"
      />
    ) : (
      <VMicOffIcon
        height={isSmall ? "12px" : "16px"}
        width={isSmall ? "12px" : "16px"}
        color="white"
        aria-hidden="true"
      />
    )}
  </Flex>
);

export default MicrophoneIndicator;
