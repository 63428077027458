import React from "react";
import PropTypes from "prop-types";
import {
  Breakpoint,
  Content,
  FlexCol,
  FlexRow,
  Section,
} from "@spring/smeargle";
import { withTranslation } from "react-i18next";
import styles from "./styles.module.scss";
import ProviderOfficeLocation from "./ProviderOfficeLocation";
import { TRACK_EVENT } from "utils/mixpanel";
import routes from "routes";
import { clickableDivProps } from "lib/accessibility_helpers";
import { getOr } from "lodash/fp";
import {
  AppointmentMedium,
  getAppointmentKindForTracking,
  getAppointmentMediumForTracking,
} from "@spring/constants";
import { Phone, SendMessage } from "components/templates/MemberDashboard/Icons";
import { Box } from "@springcare/sh-component-library";
import { getPhoneStyleForRTL } from "shared/utils/getPhoneStyleForRTL";

import useChatBotHandler from "utils/chatbot/useChatBotHandler";

const ContactForAvailability = (props) => {
  const phoneStyleForRTL = getPhoneStyleForRTL();
  const { handleOpenChat } = useChatBotHandler();

  const handleClick = () => {
    const medium = props.inPersonMedium
      ? AppointmentMedium.InPerson
      : AppointmentMedium.Video;

    TRACK_EVENT.BUTTON_CLICKED(
      routes.TherapistsBrowse.as,
      "Request Availability",
      {
        spring_doc_id: "therapy011",
        location: "Provider Profile",
        to: "Request Provider Availability Chat",
        provider_id: props.provider.id,
        appointment_type: getAppointmentKindForTracking(
          getOr("", "kind", props),
        ),
        appointment_medium: getAppointmentMediumForTracking(medium),
      },
    );

    handleOpenChat();
  };

  return (
    <Section>
      {props.inPersonMedium && <ProviderOfficeLocation {...props} />}
      <Breakpoint md andUp>
        <div style={{ paddingBottom: "24px" }}>
          <Content bold dark style={{ paddingBottom: "5px" }}>
            {props.t("scheduleModal.appointmentSlots.contactToBook")}{" "}
            {props.provider.name}.
          </Content>
          <Content>
            {props.t("scheduleModal.appointmentSlots.scheduleHelp")}
          </Content>
        </div>
        <FlexRow justification="space-between">
          <div
            className={styles.requestSupportCard}
            onClick={handleClick}
            {...clickableDivProps({ onClick: handleClick } as any)}
          >
            <FlexRow alignment="center">
              <FlexCol className={styles.phoneIcon}>
                <SendMessage color={"#182023"} />
              </FlexCol>
              <FlexCol>
                {props.t("scheduleModal.appointmentSlots.liveChatButtonText")}
              </FlexCol>
            </FlexRow>
          </div>

          <div className={styles.phoneCard}>
            <FlexRow alignment="center">
              <FlexCol className={styles.phoneIcon}>
                <Phone color={"#182023"} />
              </FlexCol>
              <FlexCol>
                <Box style={phoneStyleForRTL}>
                  <a href="tel:+18556290554">1 (855) 629-0554</a>
                </Box>
              </FlexCol>
            </FlexRow>
          </div>
        </FlexRow>
      </Breakpoint>

      <Breakpoint md andDown>
        <div style={{ paddingBottom: "24px" }}>
          <Content bold dark style={{ paddingBottom: "4px" }}>
            {props.t("scheduleModal.appointmentSlots.contactToBook")}{" "}
            {props.provider.name}
          </Content>
          <Content>
            {props.t("scheduleModal.appointmentSlots.scheduleHelp")}
          </Content>
        </div>
        <FlexRow alignment="center" justification="center">
          <div
            className={styles.requestSupportCard}
            onClick={handleClick}
            {...clickableDivProps({ onClick: handleClick } as any)}
          >
            <FlexRow justification="center" alignment="center">
              <FlexCol className={styles.phoneIcon}>
                <SendMessage color={"#182023"} />
              </FlexCol>
              <FlexCol>
                {props.t("scheduleModal.appointmentSlots.liveChatButtonText")}
              </FlexCol>
            </FlexRow>
          </div>
        </FlexRow>
        <FlexRow alignment="center" justification="center">
          <div className={styles.phoneCard}>
            <FlexRow
              justification="center"
              alignment="center"
              className={styles.phoneCard}
            >
              <FlexCol className={styles.phoneIcon}>
                <Phone color={"#182023"} />
              </FlexCol>
              <FlexCol>
                <Box style={phoneStyleForRTL}>
                  <a href="tel:+18556290554">1 (855) 629-0554</a>
                </Box>
              </FlexCol>
            </FlexRow>
          </div>
        </FlexRow>
      </Breakpoint>
    </Section>
  );
};

ContactForAvailability.propTypes = {
  inPersonMedium: PropTypes.bool,
  kind: PropTypes.string,
  provider: PropTypes.shape({
    distance_miles: PropTypes.number,
    id: PropTypes.any,
    name: PropTypes.string,
  }),
  t: PropTypes.func,
};

export default withTranslation("careProvider")(ContactForAvailability);
