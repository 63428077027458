export const COST_ESTIMATE_DISPLAY_STATES = {
  LOADING: "LOADING",
  INITIAL_DISPLAY: "INITIAL_DISPLAY",
  REQUEST_STATUS: "REQUEST_STATUS_CHECK",
  INSURANCE_FORM: "INSURANCE_FORM",
  SELF_PAY_DISPLAY: "SELF_PAY_DISPLAY",
  CANCELLATION_POLICY: "CANCELLATION_POLICY",
} as const;

// NOTE: TL;DR this type is for values of object T
type ValuesOf<T> = T[keyof T];
export type ValueOfCostEstimateDisplayStates = ValuesOf<
  typeof COST_ESTIMATE_DISPLAY_STATES
>;
