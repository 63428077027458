import { useTranslation } from "hooks/react-i18next";
import {
  Box,
  Flex,
  Stack,
  useMediaQuery,
  useDisclosure,
  VInfoIcon,
  Text,
  Tooltip,
  VEditIcon,
  Card,
  Spinner,
} from "@springcare/sh-component-library";
import { truncateWords } from "@spring/immutability";
import { useProviderBrowseContext } from "context/ProviderBrowseContext";
import { trackEditAddressClicked } from "components/templates/Browse/ProviderBrowsePage/analytics";
import { UpdateAddressModal } from "components/templates/Browse/ProviderBrowsePage/components/HeaderSection/UpdateAddressModal";

const ActiveAddress = () => {
  const { t } = useTranslation(["careProvider", "common"]);
  const { memberAddressCopy, isCompanyExclusive } = useProviderBrowseContext();
  const [isMobile] = useMediaQuery("(max-width: 767px)");
  const {
    isOpen: addressModalIsOpen,
    onOpen: addressModalOnOpen,
    onClose: addressModalOnClose,
  } = useDisclosure();
  const truncateWordCount = isMobile ? 6 : 8;

  const handleAddressModalAction = () => {
    if (isCompanyExclusive) return;
    trackEditAddressClicked();
    addressModalOnOpen();
  };

  return (
    <Box mb="v-12">
      {memberAddressCopy ? (
        <>
          <Stack direction="row" align="center" mb="v-4">
            <Text
              pb="v-4"
              size="body-medium-strong"
              color={isCompanyExclusive ? "neutral-on-subtle" : "neutral-base"}
            >
              {t("browserPageWrapper.yourLocation")}
            </Text>

            <Tooltip
              colorScheme="elevated"
              placement="top"
              hasArrow
              label={t("browserPageWrapper.tooltipLocation")}
              size="md"
              id="yourLocationTooltip"
              role="tooltip"
            >
              <Box mt="v-2">
                <VInfoIcon
                  width={20}
                  height={20}
                  color="#068262"
                  aria-hidden={true}
                />
              </Box>
            </Tooltip>
          </Stack>

          <Card
            as="button"
            variant="low-emphasis"
            onClick={() => handleAddressModalAction()}
            w="100%"
            size="md"
            border="2px solid #B4B9BB"
            cursor={isCompanyExclusive ? "not-allowed" : "default"}
            _hover={{
              textDecoration: "none",
            }}
          >
            <Stack
              direction="row"
              align="center"
              mb="v-4"
              width="100%"
              justifyContent="space-between"
            >
              <Text
                size="body-medium-strong"
                color={
                  isCompanyExclusive ? "neutral-on-subtle" : "neutral-base"
                }
              >
                {truncateWords(truncateWordCount, memberAddressCopy)}
              </Text>

              {!isCompanyExclusive && (
                <VEditIcon width={22} height={22} aria-hidden={true} />
              )}
            </Stack>
          </Card>
        </>
      ) : (
        <Flex h="72px" align="center" justify="center">
          <Spinner speed="1s" size="lg" />
        </Flex>
      )}

      <UpdateAddressModal
        isOpen={addressModalIsOpen}
        onClose={addressModalOnClose}
      />
    </Box>
  );
};

export default ActiveAddress;
