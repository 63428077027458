import { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Heading,
  Text,
  Input,
  Button,
} from "@springcare/sh-component-library";
import { links } from "@spring/constants";
import MainHeader from "components/templates/SHSessionRoom/components/MainHeader";
import { RatingSection } from "components/templates/SHSessionRoom/components/RatingSection";

import { useParticipantMetadata } from "context/SessionRoomContext";

import {
  trackPageVersionViewed,
  trackProviderFeedbackSent,
} from "components/templates/SHSessionRoom/telemetry/analytics";
import { useTranslation } from "hooks/react-i18next";

const SessionEndedProvider = () => {
  const { appointmentId } = useParticipantMetadata();

  const { t } = useTranslation("livekitExperience", {
    keyPrefix: "sessionEndedProvider",
  });
  const additionalFeedbackLabel = t("additionalFeedback");

  const [rating, setRating] = useState(0);
  const [feedbackComment, setFeedbackComment] = useState("");
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);

  const handleSubmit = () => {
    trackProviderFeedbackSent(rating, feedbackComment, appointmentId);
    setFeedbackSubmitted(true);
  };

  useEffect(() => {
    trackPageVersionViewed(
      feedbackSubmitted ? "thank_you" : "provider_feedback",
    );
  }, [feedbackSubmitted]);

  const redirectToCompass = () => {
    const redirect =
      process.env.APP_ENV === "production"
        ? links.CompassURL
        : links.CompassStagingURL;
    window.open(redirect, "_self");
  };

  return (
    <>
      <MainHeader />
      <Flex h="calc(100vh - 82px)" w="100vw" as="section">
        <Flex flex="1" justify="center" flexDir="column" px={16}>
          <Box m="0 auto">
            {!feedbackSubmitted ? (
              <>
                <Box mb="v-24" as="section">
                  <Heading size="heading-medium" mb="v-8" as="h1">
                    {t("sessionComplete")}
                  </Heading>
                  <Text size="body-medium">
                    {t("tellUsAboutYourExperience")}
                  </Text>
                </Box>

                <Box mb="v-24" as="section">
                  <RatingSection rating={rating} setRating={setRating} />
                </Box>

                <Box mb="v-24" as="section">
                  <Input
                    id="comment"
                    name="comment"
                    label={additionalFeedbackLabel}
                    aria-label={additionalFeedbackLabel}
                    optionalText={t("optional")}
                    placeholder={t("typeYourFeedbackHere")}
                    onChange={(e) => setFeedbackComment(e.target.value)}
                    autoComplete="off"
                    ps="v-12"
                  />
                </Box>
                <Box pb="34px">
                  <Button
                    onClick={handleSubmit}
                    isDisabled={rating === 0}
                    _focus={{ outline: "0px solid transparent !important" }}
                    _focusVisible={{ outline: "2px solid black !important" }}
                  >
                    {t("submit")}
                  </Button>
                </Box>
              </>
            ) : (
              <>
                <Box mb="v-24" as="section" aria-live="polite">
                  <Heading size="heading-medium" mb="v-8" as="h1">
                    {t("thanks")}
                  </Heading>
                  <Text size="body-medium" mb="v-8">
                    {t("yourInputHelps")}
                  </Text>
                </Box>

                <Box>
                  <Button
                    role="link"
                    onClick={redirectToCompass}
                    _focus={{ outline: "0px solid transparent !important" }}
                    _focusVisible={{ outline: "2px solid black !important" }}
                  >
                    {t("backToCompass")}
                  </Button>
                </Box>
              </>
            )}
          </Box>
        </Flex>
      </Flex>
    </>
  );
};

export default SessionEndedProvider;
