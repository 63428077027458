import {
  Box,
  Button,
  Flex,
  Link,
  useBreakpointValue,
} from "@springcare/sh-component-library";
import NextLink from "next/link";

import { useTranslation } from "hooks/react-i18next";
import {
  trackBrowseCoachesLinkOnMomentsCompletionModal,
  trackCoachingPromptLoaded,
  trackMaybeLaterOnMomentsCompletionModal,
} from "modules/MemberDashboard/Moments/components/analytics";
import routes from "routes/BrowseRoutes";
import { useEffect } from "react";

type CoachingPromptProps = {
  closeModal: () => void;
  exerciseId: string;
  exerciseTitle: string;
  exerciseCategory: unknown;
  exerciseMediaType: unknown;
  feeling: string;
};

export const CoachingPrompt: React.FC<CoachingPromptProps> = ({
  closeModal,
  exerciseId,
  exerciseTitle: exerciseName,
  exerciseCategory,
  exerciseMediaType,
  feeling,
}) => {
  const maybeLaterButtonVariant = useBreakpointValue([
    "no-emphasis",
    null,
    "low-emphasis",
  ]);
  const { t } = useTranslation("moments");

  const handleFindCoachLinkClick = () => {
    trackBrowseCoachesLinkOnMomentsCompletionModal(
      feeling,
      exerciseId,
      exerciseName,
      exerciseCategory,
      exerciseMediaType,
    );

    closeModal();
  };

  const handleMaybeLaterButtonClick = () => {
    trackMaybeLaterOnMomentsCompletionModal(
      feeling,
      "coaching",
      exerciseId,
      exerciseName,
      exerciseCategory,
      exerciseMediaType,
    );

    closeModal();
  };

  useEffect(() => {
    trackCoachingPromptLoaded();
  }, []);

  return (
    <Box>
      <Flex
        flexDirection={["column-reverse", null, "row"]}
        justifyContent="flex-end"
      >
        <Button
          variant={maybeLaterButtonVariant}
          alignItems="center"
          mr={["v-none", null, "v-8"]}
          mt={["v-8", null, "v-none"]}
          _focusVisible={{ boxShadow: "0 0 0 3px black" }}
          onClick={handleMaybeLaterButtonClick}
        >
          {t("completeExerciseModal.maybeLater")}
        </Button>
        <NextLink href={{ pathname: routes.CoachesBrowse.as }} passHref>
          <Button
            as={Link}
            alignItems="center"
            _focusVisible={{ boxShadow: "0 0 0 3px black" }}
            sx={{
              _active: { color: "primary-on-base" },
              _focus: { color: "primary-on-base" },
            }}
            onClick={handleFindCoachLinkClick}
          >
            {t("completeExerciseModal.findACoach")}
          </Button>
        </NextLink>
      </Flex>
    </Box>
  );
};
