import {
  Button,
  VStack,
  useMediaQuery,
  Flex,
} from "@springcare/sh-component-library";
import { SHStickyFooter } from "design-system/components";
import { useTranslation } from "react-i18next";

type TeenOrGuardianAssessmentCTAProps = {
  memberFirstName: string;
  showBeforeWeStart: () => void;
  showShareWithTeen: () => void;
  isUnmanagedTeen?: boolean;
};

export const TeenOrGuardianAssessmentCTA = ({
  memberFirstName,
  showBeforeWeStart,
  isUnmanagedTeen,
  showShareWithTeen,
}: TeenOrGuardianAssessmentCTAProps): React.ReactElement => {
  const { t } = useTranslation("assessments");
  const [isMobile] = useMediaQuery("(max-width: 416px)");

  return (
    <VStack alignItems="start" width="100%" spacing={8}>
      {!isMobile ? (
        <>
          <Button
            width="100%"
            onClick={showBeforeWeStart}
            mt="v-24"
            mb="v-8"
            data-testid="i-am-teen-button"
          >
            {t("teenAssessments.minorAppointmentDetails.iAmSelf", {
              ns: "assessments",
              memberFirstName: memberFirstName,
            })}
          </Button>
          {!isUnmanagedTeen && (
            <Button
              variant="low-emphasis"
              width="100%"
              onClick={showShareWithTeen}
              data-testid="i-am-guardian-button"
            >
              {t("teenAssessments.minorAppointmentDetails.iAmGuardian", {
                ns: "assessments",
                memberFirstName: memberFirstName,
              })}
            </Button>
          )}
        </>
      ) : (
        <SHStickyFooter left={"0"} height={160}>
          <Flex
            flexDirection="column"
            justifyContent="space-evenly"
            alignItems="center"
          >
            <Button
              width="327px"
              onClick={showBeforeWeStart}
              mt="v-24"
              mb="v-8"
              data-testid="teen-mobile-button"
            >
              {t("teenAssessments.minorAppointmentDetails.iAmSelf", {
                ns: "assessments",
                memberFirstName: memberFirstName,
              })}
            </Button>
            {!isUnmanagedTeen && (
              <Button
                variant="low-emphasis"
                width="327px"
                onClick={showShareWithTeen}
                data-testid="guardian-mobile-button"
              >
                {t("teenAssessments.minorAppointmentDetails.iAmGuardian", {
                  ns: "assessments",
                  memberFirstName: memberFirstName,
                })}
              </Button>
            )}
          </Flex>
        </SHStickyFooter>
      )}
    </VStack>
  );
};
