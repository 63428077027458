import { useEffect, useState } from "react";
import { Box, Button, Text } from "@springcare/sh-component-library";
import { TeenAssessmentTextArea } from "components/templates/TeenAssessment/components/TeenAssessmentTextArea";
import { RadioOptions } from "./RadioOptions";
import {
  handleTelemetryForQuestions,
  parseAndBoldBetweenAsterisks,
} from "components/templates/TeenAssessment/utils";
import { SHOutlinedCircleCheckBox } from "design-system/components";
import { AnswerProps, PromptProps } from "./types";

type FormDataProps = {
  prompt_id: string;
  question_responses: string;
};

type TeenAssessmentDataProps = {
  teenAssessmentData: {
    id: string;
    num_prompts_total: number;
    kind: string;
    num_prompts_answered: number;
    current_questionnaire: {
      kind: string;
    };
  };
};

type AssessmentPromptProps = {
  prompt: PromptProps;
  handleAnswerSelection: (formData: FormDataProps) => void;
  answers: Array<AnswerProps>;
  questionnaireKind: string;
  teenAssessmentData: TeenAssessmentDataProps;
};

const AssessmentPrompt = ({
  prompt,
  handleAnswerSelection,
  answers,
  questionnaireKind,
  teenAssessmentData,
}: AssessmentPromptProps): React.ReactElement => {
  const [value, setValue] = useState("");
  const [multiSelectDictionary, setMultiSelectDictionary] = useState({});
  const { newSubtitle, questions, id } = prompt;
  const questionKind = prompt?.questions[0]?.element?.kind;
  const questionKey = prompt?.questions[0]?.key;

  useEffect(() => {
    handleTelemetryForQuestions({
      teenAssessmentData,
      prompt: prompt,
    });
  }, [prompt, questionnaireKind, teenAssessmentData]);

  const subtitle = parseAndBoldBetweenAsterisks(newSubtitle);

  const submitQuestionResponse = (response) => {
    let answersDictionary;
    if (questionKind !== "TEXTAREA") {
      answersDictionary = { [questionKey]: Number(response.value) };
    } else {
      answersDictionary = { [questionKey]: response };
    }
    const formData = {
      prompt_id: id,
      question_responses: JSON.stringify(answersDictionary),
    };
    handleAnswerSelection(formData);
  };

  const handleMultiSelect = (question) => {
    const previousValue = multiSelectDictionary[question.key];
    const newValue = previousValue === 1 ? 0 : 1;
    const newDictionary = Object.assign(multiSelectDictionary, {
      [question.key]: newValue,
    });
    setMultiSelectDictionary(newDictionary);
  };

  const renderQuestionView = (question) => {
    if (questionKind === "TEXTAREA") {
      return (
        <TeenAssessmentTextArea
          key={question.id}
          value={value}
          setValue={setValue}
          submitQuestionResponse={submitQuestionResponse}
        />
      );
    }

    return (
      <>
        {
          <RadioOptions
            key={question.id}
            question={question}
            answers={answers}
            prompt={prompt}
            submitQuestionResponse={submitQuestionResponse}
          />
        }
      </>
    );
  };

  const renderMultiselectView = () => {
    return prompt.questions.map((q) => {
      const prevAnswer = answers.find((data) => data.prompt_id === prompt.id);
      let isSelected = false;

      if (prevAnswer) {
        const prevSelections = JSON.parse(prevAnswer.question_responses);
        isSelected = prevSelections[q.key] === 1;
      }

      return (
        <SHOutlinedCircleCheckBox
          checked={isSelected}
          onChange={() => handleMultiSelect(q)}
          key={q.id}
          fieldKey={q.element.label}
          label={q.element.label}
        />
      );
    });
  };

  const handleNextClicked = () => {
    const formData = {
      prompt_id: id,
      question_responses: JSON.stringify(multiSelectDictionary),
    };
    handleAnswerSelection(formData);
  };

  const isMultiSelectPrompt = prompt.questions[0].element.kind === "CHECKBOX";

  useEffect(() => {
    const hasBeenInitialized = Object.keys(multiSelectDictionary).length > 0;

    if (!isMultiSelectPrompt || hasBeenInitialized) {
      return;
    }

    const answersDictionary = prompt.questions.reduce(
      (acc, currentQuestion) => ({
        ...acc,
        [currentQuestion.key]: 0,
      }),
      {},
    );

    setMultiSelectDictionary(answersDictionary);
  }, [isMultiSelectPrompt, prompt.questions, multiSelectDictionary]);

  return (
    <>
      <Box>
        <Box pb="32px">
          <Text
            aria-live="assertive"
            dangerouslySetInnerHTML={{ __html: subtitle }}
          />
        </Box>
        {isMultiSelectPrompt
          ? renderMultiselectView()
          : questions.map((question) => renderQuestionView(question))}
      </Box>
      {isMultiSelectPrompt && (
        <Button size="lg" w="100%" onClick={handleNextClicked}>
          Next
        </Button>
      )}
    </>
  );
};

export default AssessmentPrompt;
