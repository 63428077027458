import React from "react";
import { modalIds } from "@spring/constants";
import { Trans } from "react-i18next";
import {
  Box,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Modal,
} from "@springcare/sh-component-library";
import { useTranslation } from "hooks/react-i18next";
import { useNoShowPolicyConfig } from "components/templates/CareVisitDetails/components/ConfirmationCopy/hooks";

const CustomerPayForNoShowCopy = ({ customerName, customerCostForNoShow }) => {
  return (
    <Trans
      ns="careVisits"
      i18nKey="cancelAppointment.cancelPolicyModal.customerSpecificPolicy"
      values={{
        customerName: customerName,
        costOfMissedAppointment: customerCostForNoShow,
      }}
    />
  );
};

const MemberPayForNoShowCopy = ({
  hasGracePeriodMissedAppointments,
  memberCostForNoShow,
}) =>
  memberCostForNoShow ? (
    <Trans
      ns="careVisits"
      i18nKey={
        hasGracePeriodMissedAppointments
          ? "cancelAppointment.cancelPolicyModal.memberMayPayForNoShow"
          : "cancelAppointment.cancelPolicyModal.memberWillPayForNoShow"
      }
      values={{
        costOfMissedAppointment: memberCostForNoShow,
      }}
    />
  ) : (
    <Trans
      ns="careVisits"
      i18nKey={
        hasGracePeriodMissedAppointments
          ? "cancelAppointment.cancelPolicyModal.memberMayPayForNoShowNoCost"
          : "cancelAppointment.cancelPolicyModal.memberWillPayForNoShowNoCost"
      }
      values={{
        costOfMissedAppointment: memberCostForNoShow,
      }}
    />
  );

const CancellationDetailsModal = ({ isOpen, onClose }) => {
  const { t } = useTranslation("careVisits");

  const {
    config: {
      customerName,
      customerPayForNoShow,
      customerCostForNoShow,
      requireConfirmOrCancel,
      memberPayForNoShow,
      memberCostForNoShow,
      showDefaultCopy,
      hasGracePeriodMissedAppointments,
      isPayer,
    },
  } = useNoShowPolicyConfig();

  const NoShowPolicyCopy = () => {
    if (showDefaultCopy) {
      return (
        <Trans
          ns="careVisits"
          i18nKey={
            isPayer
              ? "cancelAppointment.cancelPolicyModal.policyPayer"
              : "cancelAppointment.cancelPolicyModal.policyEmployer"
          }
        />
      );
    }

    if (memberPayForNoShow) {
      return (
        <MemberPayForNoShowCopy
          hasGracePeriodMissedAppointments={hasGracePeriodMissedAppointments}
          memberCostForNoShow={memberCostForNoShow}
        />
      );
    }

    if (customerPayForNoShow) {
      return (
        <CustomerPayForNoShowCopy
          customerName={customerName}
          customerCostForNoShow={customerCostForNoShow}
        />
      );
    }
  };

  return (
    <Modal
      size="xl"
      id={modalIds.cancellationDetailsModal}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {t("cancelAppointment.cancelPolicyModal.header")}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pt={16} pb={32}>
          <Box mb={16}>
            <NoShowPolicyCopy />
          </Box>
          {requireConfirmOrCancel && (
            <Box>
              <Trans
                ns="careVisits"
                i18nKey="cancelAppointment.cancelPolicyModal.autoCancelPolicy"
              />
            </Box>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default CancellationDetailsModal;
