import {
  Icon,
  Text,
  VNightIcon,
  VSolitudeIcon,
  VVisitsIcon,
} from "@springcare/sh-component-library";
import { useTranslation } from "hooks/react-i18next";
import { useEffect, useState } from "react";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";
import { TRACK_EVENT } from "utils/mixpanel";
import { useSeasonalityWarningConfig } from "shared/hooks/useSeasonalityWarningConfig";

export const SeasonalityWarning = ({ appointmentTime }) => {
  const { t } = useTranslation("careProvider");
  const [eventTracked, setEventTracked] = useState(false);
  const seasonalityWarningEnabled = useFeatureFlag(
    FLAGS.SEASONALITY_APPOINTMENT_TIME_WARNING,
  );

  const { isWeekend, isOutsideOfNormalHours, isHoliday } =
    useSeasonalityWarningConfig(appointmentTime);

  useEffect(() => {
    if (!eventTracked && (isWeekend || isOutsideOfNormalHours || isHoliday)) {
      TRACK_EVENT.COMPONENT_VIEWED(
        window.location.pathname,
        "Seasonality Warning",
        {
          isWeekend: isWeekend,
          isOutsideOfNormalHours: isOutsideOfNormalHours,
          isHoliday: isHoliday,
        },
      );

      setEventTracked(true);
    }
  }, [
    appointmentTime,
    eventTracked,
    isWeekend,
    isOutsideOfNormalHours,
    isHoliday,
  ]);

  const SeasonalityIcon = () => {
    if (isWeekend && !isHoliday) {
      return VVisitsIcon;
    }
    if (isOutsideOfNormalHours && !isWeekend && !isHoliday) {
      return VNightIcon;
    }
    if (isHoliday) {
      return VSolitudeIcon;
    }

    return null;
  };

  const SeasonalityWarning = () => {
    if (isWeekend && !isHoliday) {
      return t("scheduleModal.appointmentSlots.isWeekendSlot");
    }

    if (isOutsideOfNormalHours && !isWeekend && !isHoliday) {
      return t("scheduleModal.appointmentSlots.isOffHoursSlot");
    }

    if (isHoliday) {
      return t("scheduleModal.appointmentSlots.isHolidaySlot");
    }

    return "";
  };

  if (!seasonalityWarningEnabled || !appointmentTime) return null;

  return (
    <>
      {SeasonalityIcon() && <Icon as={SeasonalityIcon()} mr={4} />}
      <Text size="body-medium-strong">
        <SeasonalityWarning />
      </Text>
    </>
  );
};
