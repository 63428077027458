import {
  Flex,
  Box,
  Image,
  Text,
  useBreakpointValue,
} from "@springcare/sh-component-library";

interface MobilePreviewCarouselProps {
  handleCardClick: (cardTitle: string) => void;
}

const MobilePreviewCarousel: React.FC<MobilePreviewCarouselProps> = ({
  handleCardClick,
}) => {
  const items = [
    {
      title: "Top provider matches",
      image: "/static/provider_match_preview_mobile.png",
      alt: "Preview of top provider matches",
      styleProps: {
        pb: "0",
      },
    },
    {
      title: "Personalized tools",
      image: "/static/moments_preview_mobile.png",
      alt: "Preview of personalized tools",
      styleProps: {
        pb: "v-12",
      },
    },
    {
      title: "Recommended plans",
      image: "/static/recommended_plans_preview_mobile.png",
      alt: "Preview of recommended plans",
      styleProps: {
        pb: "0",
        px: "v-24",
      },
    },
  ];

  const itemWidthVW = useBreakpointValue({ base: 90, md: 80 }) as number; // Item width in vw units
  const containerPadding = useBreakpointValue({ base: "4vw", md: "8vw" });

  return (
    <Flex w="100vw" overflow="hidden" position="relative">
      <Flex
        w="100%"
        overflowX="scroll"
        alignItems="stretch"
        style={{
          scrollSnapType: "x mandatory", // Enable scroll snapping
          scrollbarWidth: "none", // Hide scrollbar (Firefox)
          msOverflowStyle: "none", // Hide scrollbar (IE/Edge)
        }}
        sx={{
          "::-webkit-scrollbar": { display: "none" }, // Hide scrollbar (Safari/Chrome)
        }}
        data-testid="carousel-container"
      >
        {items.map((item, index) => (
          <Box
            key={index}
            w={`${itemWidthVW}vw`}
            flex={`0 0 ${itemWidthVW}vw`}
            display="flex"
            alignItems="center"
            justifyContent="center"
            scrollSnapAlign="center"
            data-testid={`carousel-slide-${index}`}
            ml={containerPadding}
            mr={index === items.length - 1 ? containerPadding : 0}
            onClick={() => handleCardClick(item.title)}
          >
            <Box
              w="100%"
              h="100%"
              bg="background-subtle"
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              alignItems="center"
              borderRadius="24px"
              pt="v-16"
              {...item.styleProps}
            >
              <Text
                w="100%"
                mb="v-8"
                fontSize="v-16"
                fontWeight="bold"
                textAlign="center"
              >
                {item.title}
              </Text>

              <Image
                src={item.image}
                w="100%"
                borderRadius="md"
                alt={item.title}
              />
            </Box>
          </Box>
        ))}
      </Flex>
    </Flex>
  );
};

export default MobilePreviewCarousel;
