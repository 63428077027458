import { createIcon } from "@springcare/sh-component-library";

export const Check = createIcon({
  displayName: "Check",
  viewBox: "0 0 20 14",
  // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
  path: (
    <path
      d="M1 7L6.72727 13L19 1"
      fill="none"
      stroke="#182023"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
  defaultProps: {
    "aria-label": "Check icon",
    role: "img",
    "aria-hidden": "true",
  },
});
