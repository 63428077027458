import { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Input,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  GridItem,
  Select,
  SimpleGrid,
  Spinner,
  Text,
  VerdantVariantsType,
} from "@springcare/sh-component-library";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { useMemberInfo } from "hooks";
import {
  InsuranceMemberIdInput,
  OnValidationChangeEvent,
  ValidationState,
} from "components/templates/CostEstimation/components/shared/InsuranceMemberIdInput";
import { DocumentUploadButton } from "components/templates/CostEstimation/components/shared/DocumentUploadButton";
import { MemberInsuranceData } from "components/templates";
import { ResponsiveValue } from "@chakra-ui/styled-system";

type RequestCostEstimateFormData = {
  primaryInsuranceCarrier: string;
  insuranceMemberId: string;
  planName: string;
  groupId: string;
};

type InsuranceValidationFormProps = {
  memberInsuranceInfo: MemberInsuranceData;
  renderInputProps?: InputStylingProps;
  subHeading?: string;
  submitButtonProps?: ButtonProps;
  submitButtonText?: string;
};

type ButtonProps = {
  width?: string;
  float?: ResponsiveValue<"left" | "right" | "none">;
};

type InputStylingProps = {
  borderRadius?: string;
  borderWidth?: string;
  border?: string;
  variant: VerdantVariantsType;
};

export const InsuranceValidationForm = ({
  memberInsuranceInfo,
  renderInputProps,
  subHeading,
  submitButtonProps,
  submitButtonText,
}: InsuranceValidationFormProps) => {
  const { t } = useTranslation("insurance");
  subHeading = subHeading || t("form.subHeading_InsuranceValidation");
  submitButtonText =
    submitButtonText || t("form.viewCostEstimateButton_InsuranceValidation");

  const ref = useRef();

  const [insurancePolicyInfo] = useState(
    memberInsuranceInfo?.user?.member?.insurance_policy,
  );

  const [insuranceMemberIdState, setInsuranceMemberIdState] = useState(
    ValidationState.INITIAL,
  );

  const [, setFrontOfCardUrlObjectKey] = useState(null);
  const [frontOfCardFileIsLoading, setFrontOfCardFileIsLoading] =
    useState(false);
  const [, setBackOfCardUrlObjectKey] = useState(null);
  const [backOfCardFileIsLoading, setBackOfCardFileIsLoading] = useState(false);
  const [, setShowFileUploadDisclaimer] = useState(false);

  const {
    watch,
    register,
    handleSubmit,
    formState: { isSubmitting, errors, isValid },
    setValue,
    setError,
    clearErrors,
    trigger,
  } = useForm<RequestCostEstimateFormData>({
    defaultValues: {
      primaryInsuranceCarrier: null,
      planName: null,
      groupId: null,
      insuranceMemberId: null,
    },
    mode: "onBlur",
  });

  const primaryInsuranceCarrier = watch("primaryInsuranceCarrier");

  const { data: memberInfoData, loading } = useMemberInfo();
  const payerOptions = memberInfoData?.user?.member?.insurance_payers?.map(
    (payer) => {
      return { value: payer.id, label: payer.name };
    },
  );

  const onError = (_errors) => {
    // eslint-disable-next-line no-console
    console.log("onError", _errors);
  };

  const onSubmit = async (data: RequestCostEstimateFormData) => {
    // eslint-disable-next-line no-console
    console.log("onSubmit", data);
  };

  const handleValidationChange = (e: OnValidationChangeEvent) => {
    const { state, data } = e;
    const resetFields = () => {
      setValue("planName", "");
      setValue("groupId", "");
    };
    if (
      [ValidationState.FALLBACK, ValidationState.INDETERMINATE].includes(state)
    ) {
      if (insuranceMemberIdState === ValidationState.SUCCESS) resetFields();
      setInsuranceMemberIdState(state);
      return;
    }
    if (state === ValidationState.SUCCESS) {
      setInsuranceMemberIdState(state);
      setValue("planName", data?.eligibility?.plan_name || "");
      setValue("groupId", data?.eligibility?.insurance_group_id || "");
      trigger(["planName", "groupId"]);
      return;
    }
    if (insuranceMemberIdState !== ValidationState.INITIAL) {
      setInsuranceMemberIdState(state);
    }
  };

  useEffect(() => {
    if (!primaryInsuranceCarrier) {
      setInsuranceMemberIdState(ValidationState.INITIAL);
      setValue("insuranceMemberId", null);
      setValue("planName", null);
      setValue("groupId", null);
      trigger(["planName", "groupId", "insuranceMemberId"]);
    }
  }, [primaryInsuranceCarrier]);

  if (!loading && !payerOptions?.length) {
    return <Box>No insurance payers found</Box>;
  }
  if (loading) {
    return <Spinner data-testid={"loading"} />;
  }

  const isInitialForm = insuranceMemberIdState === ValidationState.INITIAL;
  const isFallbackForm = insuranceMemberIdState === ValidationState.FALLBACK;
  const isIndeterminate =
    insuranceMemberIdState === ValidationState.INDETERMINATE;
  const isInsuranceValidated =
    insuranceMemberIdState === ValidationState.SUCCESS;
  const isCheckingEligibility =
    insuranceMemberIdState === ValidationState.CHECKING;

  return (
    <Flex direction="column" gap="v-24">
      <Flex gap="v-8" direction="column">
        <Text size="body-medium-strong">
          {t("form.heading_InsuranceValidation")}
        </Text>
        <Text size="body-medium-regular">{subHeading}</Text>
      </Flex>
      <form
        id="requestCostEstimateForm"
        aria-label={t("a11y.costEstimateFormLabel")}
        ref={ref}
      >
        <SimpleGrid columns={[1, 1, 2]} columnGap={3} rowGap={16}>
          <GridItem colSpan={1}>
            <FormControl
              isRequired
              isInvalid={Boolean(errors.primaryInsuranceCarrier)}
            >
              <FormLabel fontWeight="normal" htmlFor="primaryInsuranceCarrier">
                {t("form.primaryInsuranceCarrier")}
              </FormLabel>
              <Select
                data-cy="primary-insurance-carrier"
                height={48}
                name="primaryInsuranceCarrier"
                id="primaryInsuranceCarrier"
                {...renderInputProps}
                {...register("primaryInsuranceCarrier", {
                  required: t("form.errors.requiredField"),
                })}
                placeholder={t("form.selectPlaceholder")}
                backgroundColor={"accent-subtle"}
              >
                {payerOptions.map((payer, index) => (
                  <option key={index} value={payer.value}>
                    {payer.label.charAt(0).toUpperCase() + payer.label.slice(1)}
                  </option>
                ))}
              </Select>
              <ErrorMessage
                errors={errors}
                name="primaryInsuranceCarrier"
                render={({ message }) => (
                  <FormErrorMessage>{message}</FormErrorMessage>
                )}
              />
            </FormControl>
          </GridItem>

          {!!primaryInsuranceCarrier && (
            <GridItem colSpan={1}>
              <InsuranceMemberIdInput
                name={"insuranceMemberId"}
                label={t("form.memberId")}
                placeholder={t("form.memberIdPlaceholder_InsuranceValidation")}
                register={register}
                setValue={setValue}
                errors={errors}
                onValidationChange={handleValidationChange}
                primaryInsuranceCarrier={primaryInsuranceCarrier}
                memberId={memberInfoData.user.member.id}
              />
            </GridItem>
          )}

          {!isInitialForm &&
            !isCheckingEligibility &&
            !!primaryInsuranceCarrier && (
              <>
                <GridItem colSpan={1}>
                  <FormControl
                    isRequired
                    isInvalid={Boolean(errors.planName)}
                    isDisabled={isInsuranceValidated}
                  >
                    <FormLabel fontWeight="normal" htmlFor="planName">
                      {t("form.planName")}
                    </FormLabel>
                    <Input
                      data-cy="plan-name"
                      type="text"
                      height={48}
                      name="planName"
                      id="planName"
                      isDisabled={isInsuranceValidated}
                      {...renderInputProps}
                      {...register("planName", {
                        required: t("form.errors.requiredField"),
                      })}
                      color={isInsuranceValidated && "content-disabled"}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="planName"
                      render={({ message }) => (
                        <FormErrorMessage>{message}</FormErrorMessage>
                      )}
                    />
                  </FormControl>
                </GridItem>

                <GridItem colSpan={1}>
                  <FormControl
                    isRequired
                    isInvalid={Boolean(errors.groupId)}
                    isDisabled={isInsuranceValidated}
                  >
                    <FormLabel fontWeight="normal" htmlFor="groupId">
                      {t("form.groupId")}
                    </FormLabel>
                    <Input
                      data-cy="group-id"
                      type="text"
                      height={48}
                      name="groupId"
                      id="groupId"
                      isDisabled={isInsuranceValidated}
                      {...renderInputProps}
                      {...register("groupId", {
                        required: t("form.errors.requiredField"),
                      })}
                      color={isInsuranceValidated && "content-disabled"}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="groupId"
                      render={({ message }) => (
                        <FormErrorMessage>{message}</FormErrorMessage>
                      )}
                    />
                  </FormControl>
                </GridItem>

                {(isIndeterminate || isFallbackForm) && (
                  <>
                    <GridItem colSpan={[1, 1, 2]}>
                      <Flex direction="column" mt={"v-16"}>
                        <Flex>
                          <Text size="body-medium-strong" me={"v-4"}>
                            {t("form.insuranceCardUploadHeading")}{" "}
                          </Text>
                          <Text size="body-medium-regular">
                            {t("form.insuranceCardUploadHeadingFileTypes")}
                          </Text>
                        </Flex>
                        <Text size="body-medium-regular">
                          {t("form.insuranceCardUploadSubHeading")}
                        </Text>
                      </Flex>
                    </GridItem>

                    <GridItem colSpan={1}>
                      <DocumentUploadButton
                        userId={memberInsuranceInfo?.user.id}
                        data-cy="front-of-card"
                        label={t("form.frontOfCardLabel")}
                        buttonId="frontOfCardButton"
                        inputId="frontOfCard"
                        register={register}
                        setValue={setValue}
                        setError={setError}
                        clearErrors={clearErrors}
                        errors={errors}
                        documentId={
                          insurancePolicyInfo?.insurance_card_front_id
                        }
                        deactivateFormSubmitButton={setFrontOfCardFileIsLoading}
                        setDocumentUrlObjectKey={setFrontOfCardUrlObjectKey}
                        isDisabled={false}
                        setShowFileUploadDisclaimer={
                          setShowFileUploadDisclaimer
                        }
                        trigger={!backOfCardFileIsLoading && trigger}
                      />
                    </GridItem>

                    <GridItem colSpan={1}>
                      <DocumentUploadButton
                        userId={memberInsuranceInfo?.user.id}
                        data-cy="back-of-card"
                        label={t("form.backOfCardLabel")}
                        buttonId="backOfCardButton"
                        inputId="backOfCard"
                        register={register}
                        setValue={setValue}
                        setError={setError}
                        clearErrors={clearErrors}
                        errors={errors}
                        documentId={insurancePolicyInfo?.insurance_card_back_id}
                        deactivateFormSubmitButton={setBackOfCardFileIsLoading}
                        setDocumentUrlObjectKey={setBackOfCardUrlObjectKey}
                        isDisabled={false}
                        setShowFileUploadDisclaimer={
                          setShowFileUploadDisclaimer
                        }
                        trigger={!frontOfCardFileIsLoading && trigger}
                      />
                    </GridItem>
                  </>
                )}
              </>
            )}
          <GridItem colSpan={[1, 1, 2]}>
            <Button
              width="100%"
              marginTop={isFallbackForm ? 8 : 32}
              type="button"
              id="requestCostEstimateSubmitButton"
              form="requestCostEstimateForm"
              {...submitButtonProps}
              onClick={handleSubmit(
                (data) => onSubmit(data),
                (errors) => onError(errors),
              )}
              disabled={
                !(
                  isSubmitting ||
                  isInsuranceValidated ||
                  (!isInsuranceValidated && isValid)
                )
              }
              mb={10}
            >
              {isFallbackForm || isIndeterminate
                ? t("title")
                : submitButtonText}
            </Button>
          </GridItem>
        </SimpleGrid>
      </form>
    </Flex>
  );
};
