import { useEffect, useRef, useState } from "react";
import {
  Button,
  Input,
  FormControl,
  FormLabel,
  SimpleGrid,
  GridItem,
  FormErrorMessage,
  VerdantVariantsType,
  useCustomToast,
} from "@springcare/sh-component-library";
import { useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import {
  updateMemberInsurance,
  updateMember,
} from "operations/mutations/member";
import { getMemberInsurance, getMemberInfo } from "operations/queries/member";
import { ValueOfCostEstimateDisplayStates } from "components/templates/CostEstimation/constants";
import { CostEstimateInsuranceTypeEnum } from "constants/insurance";
import { MemberInsuranceData } from "components/templates/CostEstimation/CostEstimationTemplate";
import { TRACK_EVENT } from "utils/mixpanel";
import { isEmpty } from "lodash";
import { useInViewportOnce } from "hooks";
import Router from "next/router";
import routes from "routes";

type RequestCostEstimateFormData = {
  primaryInsuranceCarrier: string;
  planName: string;
  groupId: string;
  insuranceMemberId: string;
  frontOfCard: File;
  backOfCard: File;
  finishLater?: boolean;
};

type DisplayStates = { [key: string]: ValueOfCostEstimateDisplayStates };

type InsuranceFormProps = {
  memberInsuranceInfo: MemberInsuranceData;
  changeDisplay?: (displayState: ValueOfCostEstimateDisplayStates) => void;
  displayState?: DisplayStates;
  isSaveInsuranceOperation?: boolean;
  onSuccess?: () => void;
};

type InputStylingProps = {
  borderRadius?: string;
  borderWidth?: string;
  border?: string;
  variant: VerdantVariantsType;
};

const getSubmitClickTelemetryType = (
  isSaveInsuranceOperation: boolean,
  finishLater: boolean,
) => {
  if (isSaveInsuranceOperation) {
    return "Save Insurance Info";
  } else {
    return !finishLater ? "Submit cost estimate" : "Save and finish later";
  }
};

export const HighmarkInsuranceForm = ({
  memberInsuranceInfo,
  changeDisplay,
  displayState,
  isSaveInsuranceOperation = false,
  onSuccess,
}: InsuranceFormProps) => {
  const { t } = useTranslation("insurance");
  const [insurancePolicyInfo, setInsurancePolicyInfo] = useState(
    memberInsuranceInfo?.user?.member?.insurance_policy,
  );
  const ref = useRef();
  const inViewport = useInViewportOnce(ref, "0px");
  const [trackedOnce, setTrackedOnce] = useState(false);

  const initialFormValues = {
    planName: insurancePolicyInfo?.plan_name,
    groupId: insurancePolicyInfo?.insurance_group_id,
    insuranceMemberId: insurancePolicyInfo?.insurance_member_id,
  };

  // if all fields are already populated, we should disable the form
  const disableFields = Object.keys(initialFormValues).reduce((acc, key) => {
    if (!initialFormValues[key]) {
      acc = false;
    }
    return acc;
  }, true);

  useEffect(() => {
    if (inViewport && !trackedOnce) {
      TRACK_EVENT.COMPONENT_VIEWED(
        window.location.pathname,
        "Verify Insurance",
        {},
      );
      setTrackedOnce(true);
    }
  }, [inViewport, trackedOnce, memberInsuranceInfo]);

  const successToast = useCustomToast({
    type: "success",
    message: t("form.insuranceUpdateSuccess"),
    layout: "fit-content",
    duration: 5000,
  });

  const errorToast = useCustomToast({
    type: "error",
    message: t("form.errors.somethingWentWrong"),
    layout: "fit-content",
    duration: 5000,
  });

  const [updateMemberMutation] = useMutation(updateMember, {
    onError: (err) => errorToast(),
  });

  const [updateInsurance] = useMutation(updateMemberInsurance, {
    onCompleted: (data) => {
      // reset object keys and update insurance policy info with saved data
      setInsurancePolicyInfo(
        data.updateMemberInsurancePolicy.member_insurance_policy,
      );
      onSuccess && onSuccess();
    },
    refetchQueries: [getMemberInsurance, getMemberInfo],
    onError: (err) => errorToast(),
  });

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    setError,
    clearErrors,
    formState: { isSubmitting, errors, isValid },
  } = useForm<RequestCostEstimateFormData>({
    defaultValues: initialFormValues,
  });

  const getEmptyFields = (data: Record<string, any>): string[] => {
    return Object.entries(data).reduce((accumulator, [key, value]) => {
      if (!value) {
        return [...accumulator, key];
      }
      return accumulator;
    }, []);
  };

  const onError = (_errors) => {
    TRACK_EVENT.BUTTON_CLICKED(
      window.location.pathname,
      "Submit cost estimate",
      {
        page_version: "Add insurance info",
        is_submitted: String(false),
        missing_fields: getEmptyFields(getValues()),
      },
    );
  };

  const onSubmit = async (data: RequestCostEstimateFormData) => {
    const { planName, groupId, insuranceMemberId, finishLater } = data;

    try {
      const formDataObject = {
        member_id: memberInsuranceInfo.user.member.id,
        plan_name: planName,
        insurance_group_id: groupId,
        insurance_member_id: insuranceMemberId,
        trigger_cost_estimate_zendesk_ticket: !finishLater,
        insurance_type: CostEstimateInsuranceTypeEnum.insurance,
      };

      TRACK_EVENT.BUTTON_CLICKED(
        window.location.pathname,
        getSubmitClickTelemetryType(isSaveInsuranceOperation, finishLater),
        {
          location: "Verify Insurance",
          page_version: "Add Insurance Info",
          is_submitted: String(!finishLater),
          missing_fields: getEmptyFields(getValues()),
          ...(!isSaveInsuranceOperation
            ? { page_version: "Add Insurance Info" }
            : { is_self_pay: false }),
        },
      );

      if (isSaveInsuranceOperation) {
        const insurancePolicyPayload = {
          member_id: memberInsuranceInfo.user.member.id,
          plan_name: planName,
          insurance_group_id: groupId,
          insurance_member_id: insuranceMemberId,
          trigger_cost_estimate_zendesk_ticket: false,
          insurance_type: CostEstimateInsuranceTypeEnum.insurance,
        };

        /**
         * Lifting functionality from admin-portal. See https://github.com/SpringCare/admin-portal/blob/ede906cf43ce4ef81c8bb93c5a9e9cc2262558e1/src/components/form/EditMemberDetailForm/EditMemberDetailForm.tsx#L108
         * - TL; DR use Promise.all to bulk send the 2 update requests and wait for both to settle
         */
        await Promise.all([
          updateMemberMutation({
            variables: {
              input: {
                id: memberInsuranceInfo?.user?.member?.id,
                patch: {
                  payment_preference: "INSURANCE",
                },
              },
            },
          }),
          /**
           * If payment_preference = false, it means member's payment_preference
           * is Insurance. This line will only run if payment_preference = Insurance.
           * Otherwise, if payment_preference = true, member's payment_preference =
           * 'OUT_OF_POCKET'
           */
          isEmpty(errors) &&
            updateInsurance({
              variables: insurancePolicyPayload,
              refetchQueries: [getMemberInsurance, getMemberInfo],
            }),
        ]);

        successToast();
        // End form submission as we're only updating a member's insurance and NOT submitting a cost estimate
        return;
      }

      await updateInsurance({
        variables: {
          ...formDataObject,
        },
        refetchQueries: !finishLater && [getMemberInsurance, getMemberInfo],
      });
      !finishLater
        ? changeDisplay(displayState.REQUEST_STATUS)
        : Router.replace(routes.MemberHome.to, routes.MemberHome.as);
    } catch (_err) {
      errorToast();
    }
  };

  const inputStyling: InputStylingProps = isSaveInsuranceOperation
    ? {
        borderRadius: "v-sm",
        borderWidth: "1px",
        variant: "low-emphasis",
      }
    : {
        border: "hidden",
        variant: "medium-emphasis",
      };
  return (
    <form
      id="requestCostEstimateForm"
      aria-label={
        isSaveInsuranceOperation
          ? t("a11y.updateInsuranceFormLabel")
          : t("a11y.costEstimateFormLabel")
      }
      ref={ref}
    >
      <SimpleGrid columns={[1, 1, 3]} columnGap={3} rowGap={1}>
        <GridItem colSpan={1}>
          <FormControl
            isRequired
            isInvalid={Boolean(errors.planName)}
            isDisabled={disableFields}
          >
            <FormLabel fontWeight="normal" htmlFor="planName">
              {t("form.planName")}
            </FormLabel>
            <Input
              color={disableFields && "#6A7378"}
              paddingLeft={disableFields ? 48 : 16}
              data-cy="plan-name"
              type="text"
              height={50}
              name="planName"
              id="planName"
              {...inputStyling}
              {...register("planName", {
                required: t("form.errors.requiredField"),
              })}
              isDisabled={disableFields}
              defaultValue={initialFormValues.planName}
            />
            <ErrorMessage
              errors={errors}
              name="planName"
              render={({ message }) => (
                <FormErrorMessage>{message}</FormErrorMessage>
              )}
            />
          </FormControl>
        </GridItem>

        <GridItem colSpan={1}>
          <FormControl
            isRequired
            isInvalid={Boolean(errors.groupId)}
            isDisabled={disableFields}
          >
            <FormLabel fontWeight="normal" htmlFor="groupId">
              {t("form.groupId")}
            </FormLabel>
            <Input
              color={disableFields && "#6A7378"}
              paddingLeft={disableFields ? 48 : 16}
              data-cy="group-id"
              type="text"
              height={50}
              name="groupId"
              id="groupId"
              {...inputStyling}
              {...register("groupId", {
                required: t("form.errors.requiredField"),
              })}
              isDisabled={disableFields}
              defaultValue={initialFormValues.groupId}
            />
            <ErrorMessage
              errors={errors}
              name="groupId"
              render={({ message }) => (
                <FormErrorMessage>{message}</FormErrorMessage>
              )}
            />
          </FormControl>
        </GridItem>

        <GridItem colSpan={1}>
          <FormControl
            isRequired
            isInvalid={Boolean(errors.insuranceMemberId)}
            isDisabled={disableFields}
          >
            <FormLabel fontWeight="normal" htmlFor="insuranceMemberId">
              {t("form.memberId")}
            </FormLabel>
            <Input
              color={disableFields && "#6A7378"}
              paddingLeft={disableFields ? 48 : 16}
              data-cy="insurance-member-id"
              type="text"
              height={50}
              name="insuranceMemberId"
              id="insuranceMemberId"
              {...inputStyling}
              {...register("insuranceMemberId", {
                required: t("form.errors.requiredField"),
              })}
              isDisabled={disableFields}
              placeholderText={initialFormValues.insuranceMemberId}
            />
            <ErrorMessage
              errors={errors}
              name="insuranceMemberId"
              render={({ message }) => (
                <FormErrorMessage>{message}</FormErrorMessage>
              )}
            />
          </FormControl>
        </GridItem>

        <GridItem colSpan={[1, 1, 3]}>
          <Button
            width="100%"
            marginTop={48}
            type="button"
            id="requestCostEstimateSubmitButton"
            form="requestCostEstimateForm"
            onClick={handleSubmit(
              (data) => onSubmit(data),
              (errors) => onError(errors),
            )}
            disabled={isSubmitting}
            mb={10}
          >
            {t("form.requestCostEstimateSubmitButton")}
          </Button>
        </GridItem>

        {/* Conditionally render secondary CTA only if we're not saving insurance info */}
        {!isSaveInsuranceOperation && (
          <GridItem colSpan={[1, 1, 3]}>
            <Button
              width="100%"
              variant="medium-emphasis"
              id="requestCostEstimateForm"
              type="button"
              onClick={() =>
                onSubmit({
                  finishLater: true,
                  ...getValues(),
                })
              }
              form="requestCostEstimateForm"
              disabled={isSubmitting}
            >
              {t("form.goToHomepageCta")}
            </Button>
          </GridItem>
        )}
      </SimpleGrid>
    </form>
  );
};
