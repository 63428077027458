import {
  Avatar,
  Box,
  Button,
  Flex,
  Heading,
} from "@springcare/sh-component-library";

type SpecialtyCarePrimerProps = {
  ModalImg: React.ElementType;
  title: string;
  subtitle: string;
  nextTitle: string;
  nextList: string[];
  ctaButton: string;
  cancelButton: string;
  ctaAction: () => void;
  cancelAction: () => void;
};
type SpecialtyCarePrimerBodyProps = {
  ModalImg: React.ElementType;
  title: string;
  subtitle: string;
  nextTitle: string;
  nextList: string[];
};

export const SpecialtyCarePrimerBody = ({
  ModalImg,
  title,
  subtitle,
  nextList,
  nextTitle,
}: SpecialtyCarePrimerBodyProps) => {
  return (
    <>
      <ModalImg width="80px" aria-hidden={true} />
      <Box>
        <Box>
          <Heading title={title} tabIndex={0} size="heading-medium">
            {title}
          </Heading>
          <Heading tabIndex={0} size="sm" mt={8}>
            {subtitle}
          </Heading>
        </Box>
        <Flex mt={16} gap={16} flexDir="column">
          <Box fontWeight={600}>{nextTitle}</Box>
          {nextList.map((text, index) => (
            <Flex
              gap={8}
              flexDir={"row"}
              key={"next-list-item-" + (index + 1).toLocaleString()}
            >
              <Avatar
                name={(index + 1).toLocaleString()}
                colorScheme={"neutral"}
              />
              <Box>{text}</Box>
            </Flex>
          ))}
        </Flex>
      </Box>
    </>
  );
};
export const SpecialtyCarePrimer = ({
  ModalImg,
  title,
  subtitle,
  nextTitle,
  nextList,
  ctaButton,
  cancelButton,
  ctaAction,
  cancelAction,
}: SpecialtyCarePrimerProps) => {
  return (
    <>
      <Box maxW={614} h={"39%"} m="auto">
        <Flex flexDirection={"column"} gap={16}>
          <SpecialtyCarePrimerBody
            ModalImg={ModalImg}
            title={title}
            subtitle={subtitle}
            nextTitle={nextTitle}
            nextList={nextList}
          />
        </Flex>
        <Flex gap={8} flexDir="row" mt={24}>
          <Button variant="high-emphasis" size="md" onClick={ctaAction}>
            {ctaButton}
          </Button>
          <Button variant="medium-emphasis" size="md" onClick={cancelAction}>
            {cancelButton}
          </Button>
        </Flex>
      </Box>
    </>
  );
};
