import { datadogAddAction } from "lib/datadog-setup";

export const logPrejoinInitDevicePermissionsError = (error) => {
  datadogAddAction("prejoin_init_device_permissions_error", {
    error,
  });
};

export const logPrejoinInitDevicesNotConnected = () => {
  datadogAddAction("prejoin_init_device_connect_error", {
    reason: "no peripheral devices detected",
  });
};

export const logSessionJoinError = (
  error,
  jwt,
  appointmentId,
  participantId,
  virtualSessionType,
) => {
  datadogAddAction("join_meeting_error", {
    error,
    jwt,
    appointmentId,
    participantId,
    virtualSessionType,
  });
};

export const logSessionAdmitParticipantError = (
  error,
  appointmentId,
  participantId,
) => {
  datadogAddAction("session_admit_participant_error", {
    error,
    appointmentId,
    participantId,
  });
};

export const logSessionViewStateChanged = (
  viewState,
  appointmentId,
  participantId,
) => {
  datadogAddAction("session_view_state_changed", {
    viewState,
    appointmentId,
    participantId,
  });
};

export const logSessionPageReloaded = (
  viewBeforeReload,
  viewAfterReload,
  appointmentId,
  participantId,
) => {
  datadogAddAction("session_page_reloaded", {
    viewBeforeReload,
    viewAfterReload,
    appointmentId,
    participantId,
  });
};

export const logSessionRoomUnmounted = (appointmentId, participantId) => {
  datadogAddAction("session_room_component_unmount", {
    appointmentId,
    participantId,
  });
};

export const logSessionEndForAllError = (
  error,
  appointmentId,
  participantId,
) => {
  datadogAddAction("session_end_for_all_error", {
    error,
    appointmentId,
    participantId,
  });
};

export const logSessionStreamStartError = (
  error,
  appointmentId,
  participantId,
) => {
  datadogAddAction("session_stream_start_error", {
    error,
    appointmentId,
    participantId,
  });
};

export const logSessionInitialDeviceSelection = (
  appointmentId,
  participantId,
  selectedAudioInputDevice,
  selectedVideoDevice,
  selectedAudioOutputDevice,
  devices,
) => {
  datadogAddAction("session_inital_device_selection", {
    appointmentId,
    participantId,
    selectedAudioInputDevice,
    selectedVideoDevice,
    selectedAudioOutputDevice,
    devices,
  });
};

export const logSessionDeviceSelected = (
  appointmentId,
  participantId,
  selectedAudioInputDevice,
  deviceType,
) => {
  datadogAddAction("session_device_selected", {
    appointmentId,
    participantId,
    selectedAudioInputDevice,
    deviceType,
  });
};

export const logAudioSilenceDetected = (
  appointmentId,
  participantId,
  sessionDevices,
) => {
  const { devices = [], selectedAudioInputDevice = null } =
    sessionDevices || {};

  datadogAddAction("session_audio_silence_detected", {
    appointmentId,
    participantId,
    devices,
    selectedAudioInputDevice,
  });
};

export const logMediaDeviceError = (appointmentId, participantId, error) => {
  datadogAddAction("session_device_error", {
    appointmentId,
    participantId,
    error,
  });
};

export const logReconnecting = (appointmentId, participantId) => {
  datadogAddAction("session_reconnecting", {
    appointmentId,
    participantId,
  });
};

export const logMediaDevicesChanged = (appointmentId, participantId) => {
  datadogAddAction("session_media_device_changed", {
    appointmentId,
    participantId,
  });
};

export const logActiveDeviceChanged = (
  kind,
  sessionDevices,
  viewState,
  calledBy, // file name - function name
) => {
  const {
    devices = [],
    selectedAudioInputDevice = null,
    selectedAudioOutputDevice = null,
    selectedVideoDevice = null,
  } = sessionDevices || {};

  datadogAddAction("session_active_device_changed", {
    kind,
    devices,
    selectedAudioInputDevice,
    selectedAudioOutputDevice,
    selectedVideoDevice,
    viewState,
    calledBy,
  });
};

export const logConnectionQualityChanged = (
  appointmentId,
  participantId,
  connectionQuality,
) => {
  datadogAddAction("session_connection_quality_changed", {
    connectionQuality,
    appointmentId,
    participantId,
  });
};

export const logStreamStateChanged = (
  appointmentId,
  participantId,
  pub,
  streamState,
  participant,
) => {
  datadogAddAction("session_stream_state_changed", {
    pub,
    streamState,
    participant,
    appointmentId,
    participantId,
  });
};

export const logNoiseFilterInitializationError = (
  appointmentId,
  participantId,
  error,
) => {
  datadogAddAction("session_noise_filter_initialization_error", {
    appointmentId,
    participantId,
    error,
  });
};

export const logNoiseFilterDeactivationError = (
  appointmentId,
  participantId,
  error,
) => {
  datadogAddAction("session_noise_filter_deactivation_error", {
    appointmentId,
    participantId,
    error,
  });
};

export const logSessionTimeOutByMemberIdle = (careSessionType) => {
  // this will be deprecated once disconnect issue is confirmed gone
  datadogAddAction("session_time_out_by_member_idle", {
    careSessionType,
  });
};

export const logAudioSenderStats = (
  appointmentId,
  participantId,
  senderAudioStats,
) => {
  datadogAddAction("session_audio_sender_stats", {
    appointmentId,
    participantId,
    senderAudioStats,
  });
};

export const logVideoSenderStats = (
  appointmentId,
  participantId,
  senderVideoStats,
) => {
  datadogAddAction("session_video_sender_stats", {
    appointmentId,
    participantId,
    senderVideoStats,
  });
};

export const logLiveKitLog = (level, message, context) => {
  datadogAddAction("session_livekit_log", {
    level,
    message,
    context,
  });
};
