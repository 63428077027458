/* eslint-disable react/jsx-key */
import { createIcon } from "@springcare/sh-component-library";

export const Evening = createIcon({
  displayName: "Evening",
  viewBox: "0 0 24 24",
  path: [
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.8757 1.30593C19.5853 0.521056 18.8369 0 18 0C17.1631 0 16.4147 0.521056 16.1243 1.30593L15.3631 3.36308L13.3059 4.1243C12.5211 4.41473 12 5.16312 12 6C12 6.83688 12.5211 7.58527 13.3059 7.8757L15.3631 8.63692L16.1243 10.6941C16.4147 11.4789 17.1631 12 18 12C18.8369 12 19.5853 11.4789 19.8757 10.6941L20.6369 8.63692L22.6941 7.8757C23.4789 7.58527 24 6.83688 24 6C24 5.16312 23.4789 4.41473 22.6941 4.1243L20.6369 3.36308L19.8757 1.30593ZM16.9196 4.91964L18 2L19.0804 4.91964L22 6L19.0804 7.08036L18 10L16.9196 7.08036L14 6L16.9196 4.91964Z"
    />,
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.2868 2.08882C9.49119 1.72705 9.45057 1.27658 9.18476 0.957215C8.91895 0.637844 8.48335 0.516121 8.09048 0.651437C3.38384 2.27257 0 6.73977 0 11.9999C0 18.6273 5.37258 23.9999 12 23.9999C16.4865 23.9999 20.3966 21.5373 22.4545 17.8948C22.6589 17.533 22.6183 17.0826 22.3525 16.7632C22.0867 16.4438 21.6511 16.3221 21.2582 16.4574C20.2382 16.8087 19.1425 16.9999 18 16.9999C12.4772 16.9999 8 12.5228 8 6.99991C8 5.21307 8.46776 3.53851 9.2868 2.08882ZM2 11.9999C2 8.52309 3.77472 5.45916 6.46916 3.66706C6.16365 4.72573 6 5.84415 6 6.99991C6 13.6273 11.3726 18.9999 18 18.9999C18.4046 18.9999 18.8046 18.9799 19.1992 18.9407C17.3797 20.8276 14.8264 21.9999 12 21.9999C6.47715 21.9999 2 17.5227 2 11.9999Z"
    />,
  ],
  defaultProps: {
    color: "tertiary.700",
  },
});
