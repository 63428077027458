import { useState } from "react";
import { useVideoPlayer } from "modules/shared/components/VideoPlayer/VideoPlayer/context/videoPlayerContext";

import {
  Flex,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  VVolumeHighIcon,
  VVolumeLowIcon,
  VVolumeOffIcon,
  IconButton,
} from "@springcare/sh-component-library";
import { useTranslation } from "hooks/react-i18next";

export const Volume = () => {
  const { volume, setVolume, isMuted, setIsMuted, setShowControls } =
    useVideoPlayer();

  const [showVolumeSlider, setShowVolumeSlider] = useState<boolean>(false);
  const isLowVolume = volume < 0.5;

  const toggleMute = () => {
    setIsMuted(!isMuted);
  };

  const handleOnFocus = () => setShowControls(true);
  const handleOnBlur = () => setShowControls(false);

  const handleVolumeChange = (val: number): void => {
    const newVolume = val / 100;
    setVolume(newVolume);
  };

  const { t } = useTranslation("videoPlayer");
  const muteUnmuteAriaLabel = isMuted ? t("unmute") : t("mute");

  return (
    <Flex
      justifyContent={"start"}
      alignItems={"center"}
      width={100}
      onMouseEnter={() => setShowVolumeSlider(true)}
      onMouseLeave={() => setShowVolumeSlider(false)}
    >
      <IconButton
        icon={
          isMuted
            ? VVolumeOffIcon
            : isLowVolume
              ? VVolumeLowIcon
              : VVolumeHighIcon
        }
        aria-label={muteUnmuteAriaLabel}
        size="sm"
        variant="no-emphasis"
        colorScheme="media"
        onClick={toggleMute}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
      />
      <Flex w={90} alignItems={"center"}>
        {showVolumeSlider && (
          <Slider
            aria-label="volume-slider"
            role="slider"
            value={isMuted ? 0 : volume * 100}
            min={0}
            max={100}
            onChange={handleVolumeChange}
          >
            <SliderTrack bg="var(--Content-Media-disabled, rgba(255, 255, 255, 0.16))">
              <SliderFilledTrack bg="#ffffff" />
            </SliderTrack>
          </Slider>
        )}
      </Flex>
    </Flex>
  );
};

Volume.displayName = "Volume";
