import React from "react";
import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  IconButton,
  Spinner,
  useDisclosure,
  useMediaQuery,
  useTheme,
  VFilterIcon,
  VGlobalDuotoneSpot,
  VImprovementDuotoneSpot,
} from "@springcare/sh-component-library";
import { useTranslation } from "hooks/react-i18next";
import { ResourceCard } from "./components/ResourceCard";
import { useRouter } from "next/router";
import routes from "routes";
import Meowth from "@spring/meowth";
import { useQuery } from "@apollo/client";
import { getAvailableCareItems } from "./helpers";
import { links } from "@spring/constants";
import type { CareItem } from "modules/shared/graphql-codegen/graphql";
import { getMemberCareItems } from "modules/shared/graphql/queries/getMemberCareItems";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";
import { useMemberInfo } from "hooks";
import { isEmpty } from "lodash";
import { TRACK_EVENT } from "utils/mixpanel";
import BenefitPreferencesModal from "./components/BenefitPreferencesModal";
import getMemberConnectedBenefits from "operations/queries/connectedBenefits/getMemberConnectedBenefits";

interface LoaderProps {
  isLoading: boolean;
  children: React.ReactNode;
}

function Loader({ children, isLoading }: LoaderProps) {
  if (isLoading) {
    return (
      <Center my={60}>
        <Spinner speed="1s" size="xl" />
      </Center>
    );
  }

  return <>{children}</>;
}

export function ResourcesTab() {
  const { breakpoints } = useTheme();
  const [isMobile] = useMediaQuery(`(max-width: ${breakpoints.sm})`);
  const { t } = useTranslation("careVisits");
  const router = useRouter();
  const showConnectedBenefitsSection = useFeatureFlag(
    FLAGS.SHOW_CONNECTED_BENEFITS,
  );
  const { data: memberData } = useMemberInfo();
  const showLocalResourcesFlag = useFeatureFlag(FLAGS.SDOH_LOCAL_RESOURCES);
  const showLocalResourcesSection =
    memberData?.user?.member?.community_care_supported ||
    showLocalResourcesFlag;
  const {
    isOpen: isPreferencesModalOpen,
    onOpen: openPreferencesModal,
    onClose: closePreferencesModal,
  } = useDisclosure();

  const { data: careItemsData, loading: isCareItemsDataLoading } = useQuery(
    getMemberCareItems,
    {
      ...Meowth.apolloOptionsUserId(),
    },
  );

  const availableCareItems: CareItem[] | undefined =
    getAvailableCareItems(careItemsData);

  const hasWorkplaceItem = availableCareItems?.some(
    ({ care_item_name }) =>
      care_item_name === "sage_content_to_develop_workplace_skills",
  );

  const isPayer = memberData?.user?.member?.cohort?.customer?.is_health_plan;
  const customerName = memberData?.user?.member?.cohort?.customer?.name || "";

  const { data: memberBenefitsData, loading: isMemberBenefitsDataLoading } =
    useQuery(getMemberConnectedBenefits, {
      variables: {
        member_id: memberData?.user?.member.id,
        recommended_only: false,
      },
    });

  const connectedBenefitsList =
    memberBenefitsData?.member_connected_benefits?.benefits || [];

  const handlePreferencesClick = () => {
    TRACK_EVENT.MODAL_OPENED(
      window.location.pathname,
      "Connected Benefit Preferences",
    );
    openPreferencesModal();
  };

  return (
    <>
      {showLocalResourcesSection && (
        <Box mb="32">
          <Heading size="heading-small" mb="24">
            {t("resourcesTab.fromYourCommunityHeading")}
          </Heading>
          <Flex wrap="wrap" gap="16">
            <ResourceCard
              dataCy="sdoh_local_resources"
              description={t("resourcesTab.sdohLocalResources.description")}
              heading={t("resourcesTab.sdohLocalResources.heading")}
              icon={VGlobalDuotoneSpot}
              onClick={() =>
                router.push(routes.LocalResources.to, routes.LocalResources.as)
              }
              trackingAction="Resources tab"
            />
          </Flex>
        </Box>
      )}
      <Loader isLoading={isCareItemsDataLoading}>
        {hasWorkplaceItem ? (
          <Box as="section" mb="32">
            <Heading size="heading-small" mb="24">
              {t("resourcesTab.fromSpringHeading")}
            </Heading>
            <Flex wrap="wrap" gap="16">
              <ResourceCard
                dataCy="sage_content_to_develop_workplace_skills"
                description={t("resourcesTab.sageContent.description")}
                heading={t("resourcesTab.sageContent.heading")}
                icon={VImprovementDuotoneSpot}
                isExternal
                onClick={() =>
                  window.open(links.TargetSageContentURL, "_blank")
                }
                trackingAction={"External link"}
              />
            </Flex>
          </Box>
        ) : null}
      </Loader>
      {showConnectedBenefitsSection && (
        <Loader isLoading={isMemberBenefitsDataLoading}>
          {!isEmpty(connectedBenefitsList) && (
            <Box as="section" mb="32">
              <Flex
                justifyContent="space-between"
                alignItems="center"
                mb="24"
                maxW={["325px", "100%", "100%", "1007px"]}
              >
                {isPayer ? (
                  <Heading size="heading-small">
                    {t("resourcesTab.benefits.payerHeading")}
                  </Heading>
                ) : (
                  <Heading size="heading-small">
                    {t("resourcesTab.benefits.nonPayerHeading", {
                      ns: "careVisits",
                      customerName: customerName,
                    } as { ns: string })}
                  </Heading>
                )}
                {isMobile ? (
                  <IconButton
                    aria-label={t("resourcesTab.benefits.preferencesButton")}
                    icon={VFilterIcon}
                    variant="low-emphasis"
                    onClick={handlePreferencesClick}
                    _active={{ textColor: "accent-base" }}
                    _focus={{ textColor: "accent-base" }}
                  />
                ) : (
                  <Button
                    variant="low-emphasis"
                    size="sm"
                    icon={VFilterIcon}
                    onClick={handlePreferencesClick}
                    _active={{ textColor: "accent-base" }}
                    _focus={{ textColor: "accent-base" }}
                  >
                    {t("resourcesTab.benefits.preferencesButton")}
                  </Button>
                )}
              </Flex>
              <Flex wrap="wrap" gap="16">
                {connectedBenefitsList.map((benefit) => (
                  <Box key={benefit.name}>
                    <ResourceCard
                      style={{ padding: "24px" }}
                      dataCy={benefit.name}
                      description={benefit.description}
                      heading={`${benefit.benefits_type} support`}
                      icon={benefit.logo_url}
                      isExternal
                      onClick={() => {
                        window.open(benefit.benefits_url, "_blank");
                      }}
                      trackingAction="Resources tab"
                      trackingProps={{
                        benefit_name: benefit.name,
                        benefit_type: benefit.benefits_type,
                        recommended: benefit.recommended,
                      }}
                      badgeText={benefit.recommended && "Recommended"}
                    />
                  </Box>
                ))}
              </Flex>
              <BenefitPreferencesModal
                isOpen={isPreferencesModalOpen}
                onClose={closePreferencesModal}
                memberId={memberData?.user?.member.id}
              />
            </Box>
          )}
        </Loader>
      )}
    </>
  );
}
