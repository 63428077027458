import { useTranslation } from "react-i18next";
import {
  Heading,
  Flex,
  Box,
  CircularProgress,
  VisuallyHidden,
} from "@springcare/sh-component-library";

export const LoadingDisplay = ({ isMemberInsuranceLoading }) => {
  const { t } = useTranslation("costEstimate");
  return (
    <Flex
      width="100%"
      minHeight={["100vh", "768px", "768px"]}
      justifyContent="center"
      alignItems="center"
      aria-busy={isMemberInsuranceLoading}
      aria-live="polite"
    >
      <VisuallyHidden>
        <Heading as="h1">{t("loadingCostEstimateHeading")}</Heading>
      </VisuallyHidden>
      <Box>
        <CircularProgress size="100px" isIndeterminate color="primary-base" />
      </Box>
    </Flex>
  );
};
