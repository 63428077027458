import { useMemberInfoForNavLinks } from "components/templates/MemberDashboard/PageWrapper/hooks/useMemberInfoForNavLinks";
import { useRouter } from "next/router";
import { getNavLinks } from "components/templates/MemberDashboard/PageWrapper/utils/getMenuNavLinks/getNavLinks";
import { NavItem } from "@spring/smeargle";
import classnames from "classnames";
import styles from "./styles.module.scss";
import LinkItem from "components/templates/MemberDashboard/PageWrapper/LinkItem";
import { navLinkToggles } from "components/templates/MemberDashboard/PageWrapper/types/navLinkTypes";

const PAGE_NAME = "Top Navigation";

export const WebMenu = () => {
  const {
    isAMinorMember,
    isATeenMember,
    isAnAdultMember,
    isAGlobalMember,
    isAT2Member,
    isAWorkplaceManager,
    isADependent,
    hasViewedWorkplacePage,
    canSeeWorkplaceLink,
    viewWorkplaceLinkFeatureFlag,
  } = useMemberInfoForNavLinks();

  const router = useRouter();

  const webNavLinkToggles: navLinkToggles = {
    type: "web",
    isAT2Member,
    isATeenMember,
    isAMinorMember,
    canSeeWorkplaceLink,
    viewWorkplaceLinkFeatureFlag,
    isAWorkplaceManager,
    isAnAdultMember,
    isAGlobalMember,
    isADependent,
  };

  const webNavLinks = getNavLinks(webNavLinkToggles);

  if (!router) {
    return null;
  }

  return (
    <>
      {webNavLinks.map((link) => (
        <div
          key={link.navText}
          className={classnames(styles.breakpoint, styles.link)}
          data-testid="Menu"
        >
          <NavItem
            isRouterPath={router.asPath === link.path}
            active={router.asPath.includes(link.path)}
          >
            <LinkItem
              data={link}
              PAGE_NAME={PAGE_NAME}
              isWorkplacePageViewed={hasViewedWorkplacePage}
            />
          </NavItem>
        </div>
      ))}
    </>
  );
};
