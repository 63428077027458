import {
  Box,
  Flex,
  Toggle,
  VIndividualIcon,
  VSmsIcon,
} from "@springcare/sh-component-library";
import {
  useSessionChatMessages,
  useSessionRoomDrawer,
} from "context/SessionRoomContext";
import { DrawerTabTypes } from "components/templates/SHSessionRoom/types";
import { trackSessionDrawerTabToggle } from "components/templates/SHSessionRoom/telemetry/analytics";
import { useTranslation } from "hooks/react-i18next";

const DrawerTabsSection = () => {
  const { activeDrawerTab, setActiveDrawerTab } = useSessionRoomDrawer();
  const { setNumberOfUnreadChatMessages } = useSessionChatMessages();
  const { t } = useTranslation("livekitExperience", {
    keyPrefix: "sessionRoomDrawer.drawerTabsSection",
  });

  const handleDrawerTabToggle = (selected: string) => {
    trackSessionDrawerTabToggle(selected);
    setActiveDrawerTab(selected);
    if (selected === DrawerTabTypes.Chat) {
      setNumberOfUnreadChatMessages(0);
    }
  };

  return (
    <Box px={24}>
      <Toggle.Group
        selected={activeDrawerTab}
        role="tablist"
        aria-label="Drawer Tab Options"
        bg="accent-subtle"
        onToggle={(selected) => handleDrawerTabToggle(selected)}
        width="fill"
      >
        <Flex>
          <Toggle.Button
            value="participants"
            icon={VIndividualIcon}
            aria-label={t("attendeesTabAriaLabel")}
            role="tab"
            height="v-lg"
          >
            {t("attendees")}
          </Toggle.Button>

          <Toggle.Button
            value="chat"
            icon={VSmsIcon}
            aria-label={t("chatTabAriaLabel")}
            role="tab"
            height="v-lg"
          >
            {t("chat")}
          </Toggle.Button>
        </Flex>
      </Toggle.Group>
    </Box>
  );
};

export default DrawerTabsSection;
