import { useMutation, useQuery } from "@apollo/client";
import {
  AppointmentAction,
  AppointmentKind,
  getAppointmentKindForTracking,
  getAppointmentMediumForTracking,
  getModalNameForTracking,
  modalIds,
} from "@spring/constants";
import Meowth from "@spring/meowth";
import { addNotification, openModal } from "@spring/smeargle/actions";
import {
  Box,
  Heading,
  Hide,
  HStack,
  useBreakpointValue,
  useDisclosure,
} from "@springcare/sh-component-library";
import { UpNextCompleted, UpNextEmpty } from "components";
import {
  CareProviderScheduleModalV2,
  ChangeCareProviderModal,
} from "components/modals";
import ChangePeerRecoverySpecialistModal from "components/modals/ChangePeerRecoverySpecialistModal";
import {
  CareNavigationFlyout,
  CoachingFlyout,
  MedicationManagementFlyout,
  MomentsFlyout,
  SpecializedCareNavigationFlyout,
  SpecialtyCareFlyout,
  SubstanceUseFlyout,
  TherapyFlyout,
  TobaccoCessationFlyout,
} from "components/organisms/CareInfoFlyout/components";
import { CoverageCard } from "components/organisms/UpNextSection/CoverageCard";
import { isBrecsVariant } from "components/templates/Browse/ProviderBrowsePage/utils";
import { CareNavigator } from "design-system/assets";
import {
  useAllUpcomingAppointmentSlots,
  useCareInfoFlyoutDisclosures,
} from "hooks";
import { DateTime } from "luxon";
import TRACKING from "modules/MemberDashboard/LocalResources/constants/tracking";
import Router from "next/router";
import { createSUDZendeskTicket } from "operations/mutations/member";
import { getMemberGoalsAndIssues } from "operations/queries/member";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { connect } from "react-redux";
import routes from "routes";
import { MemberGreeting, UpNextCard } from "shared/components";
import { UpcomingAppointmentCard } from "shared/components/UpcomingAppointmentCard";
import { useScheduleModalWithProps } from "shared/hooks";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";
import { TRACK_EVENT } from "utils/mixpanel";
import { getAppointmentSlotStartTimeAndMedium } from "utils/schedulingHelpers";
import { PopoverComponent } from "./PopoverComponent";
import {
  getCheckInAssessmentUrl,
  includeUpcomingAppointment,
  saveCareItemCountToLocalStorage,
} from "./upNextSection.util";

const getSUDCardContent = (memberGoalTypes, memberIssues, t) => {
  const alcoholSelected =
    memberGoalTypes?.includes("alcohol_use") ||
    memberIssues?.includes("alcohol");
  const drugSelected =
    memberGoalTypes?.includes("drugs") || memberIssues?.includes("drugs");

  let sudTranslationKey = "";
  if (alcoholSelected && drugSelected) {
    sudTranslationKey = "substance_use";
  } else if (drugSelected) {
    sudTranslationKey = "drugs";
  } else if (alcoholSelected) {
    sudTranslationKey = "alcohol_use";
  }

  const sudCardOverline =
    sudTranslationKey === ""
      ? t("homepage:upNextSection.substanceUse.overline")
      : t(
          `homepage:upNextSection.substanceUse.overlineDynamic.${sudTranslationKey}`,
        );
  const sudCardTitle =
    sudTranslationKey === ""
      ? t("homepage:upNextSection.substanceUse.cardTitle")
      : t(
          `homepage:upNextSection.substanceUse.cardTitleDynamic.${sudTranslationKey}`,
        );

  return { sudCardOverline, sudCardTitle };
};

const openSUDLandingPage = () => {
  TRACK_EVENT.LINK_CLICKED(
    window.location.pathname,
    routes.SubstanceUseSupport.as,
    "View alcohol and substance use stand alone page",
    {
      location: "Alcohol and substance use up next card CTA",
    },
  );

  Router.push(routes.SubstanceUseSupport.as);
};

const openSpecialtyCalendarPage = () => {
  TRACK_EVENT.LINK_CLICKED(
    window.location.pathname,
    routes.SUDCareNavigationScheduling.as,
    "View substance use disorder care navigation calendar page",
    {
      location: "Alcohol and substance use up next card CTA",
    },
  );

  Router.push(routes.SUDCareNavigationScheduling.as);
};

export const UpNextSection = ({
  careTeam,
  memberFirstName,
  memberId,
  memberHasInPersonSupport,
  memberCountry,
  recommendedCareItems,
  openModal,
  sessionSnapshot,
  hasEverCompletedARecommendedCareItem,
  addNotification,
  showGlobalExperience,
  therapyRecsEligible,
  coachVisitsCovered,
  tobaccoCtaCallBack,
  hasManagedDependents,
  openSpecializedCNScheduleModal,
  upcomingAppointments,
  showNewCoachingExperience = false,
  initialSUDRisk,
  latestSUDRisk,
  isSUDLoading,
  shouldShowSUDCNCalendar,
}) => {
  const { t } = useTranslation(["carePlan", "homepage", "limitedLangHomepage"]);
  const {
    isOpen: isScheduleModalOpen,
    onOpen: onScheduleModalOpen,
    onClose: onScheduleModalClose,
    modalProps: scheduleModalProps,
  } = useScheduleModalWithProps();

  const memberGreetingTranslated = t("limitedLangHomepage:hi");
  const sudMxV2Flag = useFeatureFlag(FLAGS.SUD_MX_V2);
  const shouldShowNewInsuranceModal = useFeatureFlag(FLAGS.NEW_INSURANCE_MODAL);
  const { data: memberGoalsAndIssues } = useQuery(getMemberGoalsAndIssues, {
    ...Meowth.apolloOptionsUserId(),
    skip: !sudMxV2Flag,
  });
  const userId = Meowth.apolloOptionsUserId().variables.id;

  const memberGoalTypes =
    memberGoalsAndIssues?.user?.member?.list_goal_types ?? [];
  const memberIssues = memberGoalsAndIssues?.user?.member?.list_issues ?? [];
  const therapist = careTeam?.user?.member?.care_team?.therapist;
  const coach = careTeam?.user?.member?.care_team?.coach;
  const careNavigator = careTeam?.user?.member?.care_team?.care_navigator;
  const medicationManager =
    careTeam?.user?.member?.care_team?.medication_manager;
  const peerRecoverySpecialist =
    careTeam?.user?.member?.care_team?.peer_recovery_specialist;
  const checkInAssessmentUrl = getCheckInAssessmentUrl(recommendedCareItems);

  const {
    isOpen: isPeerRecoverySpecialistFormOpen,
    onOpen: openPeerRecoverySpecialistForm,
    onClose: closePeerRecoverySpecialistForm,
  } = useDisclosure();

  const { data: upcomingTherapyAppointmentSlots } =
    useAllUpcomingAppointmentSlots(therapist, AppointmentKind.Therapy, 1);
  const { data: upcomingCoachingAppointmentSlots } =
    useAllUpcomingAppointmentSlots(
      coach,
      AppointmentKind.Coaching,
      1,
      false,
      true,
    );
  const { data: upcomingMedicationManagerSlots } =
    useAllUpcomingAppointmentSlots(
      medicationManager,
      AppointmentKind.FollowUpMedicationManagement,
      1,
      false,
      false,
    );

  const {
    therapy: therapyDisclosure,
    medicationManagement: medicationManagementDisclosure,
    careNavigation: careNavigationDisclosure,
    moments: momentsDisclosure,
    coaching: coachingDisclosure,
    substanceUse: substanceUseDisclosure,
    tobaccoCessation: tobaccoCessationDisclosure,
    specializedCareNavigation: specializeCareNavigationDisclosure,
    specialtyCare: specialtyCareDisclosure,
  } = useCareInfoFlyoutDisclosures();

  const showSudProgramPage = useFeatureFlag(FLAGS.SHOW_SUD_PROGRAM_PAGE);

  const [sudZendeskMutation] = useMutation(createSUDZendeskTicket, {
    onCompleted: () => {
      substanceUseDisclosure.onClose();
      window.localStorage.setItem("sud_requested", true);

      addNotification(
        t("homepage:upNextSection.substanceUse.successNotification"),
        "success",
      );
    },
    onError: () => {
      substanceUseDisclosure.onClose();

      addNotification(
        t("homepage:upNextSection.substanceUse.errorNotification"),
        "error",
      );
    },
  });

  const openCoachingModal = () => {
    if (coach) {
      if (shouldShowNewInsuranceModal) {
        onScheduleModalOpen({
          kind: AppointmentKind.Coaching,
          provider: coach,
          buttonText: t("carePlanSteps.confirmAppointment"),
          action: AppointmentAction.Create,
          telemetryProps: {
            springDocId: "HPR_006",
            mpLocation: "Up Next",
            location: `${cardConfig.coach_follow_up.cardTrackingName}`,
            headerText,
          },
        });
      } else {
        const {
          initialStartTime: coachingInitialStartTime,
          medium: coachingMedium,
        } = getAppointmentSlotStartTimeAndMedium(
          upcomingCoachingAppointmentSlots,
          coach,
        );
        TRACK_EVENT.LINK_CLICKED(
          window.location.pathname,
          getModalNameForTracking(modalIds.careProviderScheduleModal),
          headerText,
          {
            spring_doc_id: "HPR_006",
            location: `${cardConfig.coach_follow_up.cardTrackingName}`,
            appointment_type: getAppointmentKindForTracking(
              AppointmentKind.Coaching,
            ),
            appointment_medium: getAppointmentMediumForTracking(coachingMedium),
            provider_id: coach.id,
          },
        );
        openModal(modalIds.careProviderScheduleModal, {
          ...coach,
          kind: AppointmentKind.Coaching,
          medium: coachingMedium,
          providerRole: "Coach",
          buttonText: t("carePlanSteps.confirmAppointment"),
          action: AppointmentAction.Create,
          initialStartTime: coachingInitialStartTime,
        });
      }
    }
  };

  const openTherapistModal = () => {
    if (therapist) {
      if (shouldShowNewInsuranceModal) {
        onScheduleModalOpen({
          kind: AppointmentKind.Therapy,
          provider: therapist,
          buttonText: t("carePlanSteps.confirmAppointment"),
          action: AppointmentAction.Create,
          telemetryProps: {
            springDocId: "HPR_005",
            location: `${cardConfig.therapist_follow_up.cardTrackingName} card`,
            mpLocation: "Up Next",
            headerText,
          },
        });
      } else {
        const {
          initialStartTime: therapyInitialStartTime,
          medium: therapyMedium,
        } = getAppointmentSlotStartTimeAndMedium(
          upcomingTherapyAppointmentSlots,
          therapist,
          memberHasInPersonSupport,
          memberCountry,
        );
        TRACK_EVENT.LINK_CLICKED(
          window.location.pathname,
          getModalNameForTracking(modalIds.careProviderScheduleModal),
          headerText,
          {
            spring_doc_id: "HPR_005",
            location: `${cardConfig.therapist_follow_up.cardTrackingName} card`,
            appointment_type: getAppointmentKindForTracking(
              AppointmentKind.Therapy,
            ),
            appointment_medium: getAppointmentMediumForTracking(therapyMedium),
            provider_id: therapist.id,
          },
        );
        openModal(modalIds.careProviderScheduleModal, {
          ...therapist,
          kind: AppointmentKind.Therapy,
          medium: therapyMedium,
          mpLocation: "Up Next",
          providerRole: "Therapist",
          buttonText: t("carePlanSteps.confirmAppointment"),
          action: AppointmentAction.Create,
          initialStartTime: therapyInitialStartTime,
        });
      }
    }
  };

  const openCareNavigationModal = () => {
    if (careNavigator) {
      return Router.push(routes.CareNavigation.as);
    }
  };

  const openMedicationManagerModal = () => {
    if (medicationManager) {
      if (shouldShowNewInsuranceModal) {
        onScheduleModalOpen({
          kind: AppointmentKind.FollowUpMedicationManagement,
          provider: medicationManager,
          buttonText: t("carePlanSteps.confirmAppointment"),
          action: AppointmentAction.Create,
          telemetryProps: {
            springDocId: "HPR_007",
            mpLocation: "Up Next",
            location: `${cardConfig.medication_manager_follow_up.cardTrackingName}`,
            headerText,
          },
        });
      } else {
        const {
          initialStartTime: medicationManagementInitialStartTime,
          medium: medicationManagementMedium,
        } = getAppointmentSlotStartTimeAndMedium(
          upcomingMedicationManagerSlots,
          medicationManager,
        );
        TRACK_EVENT.LINK_CLICKED(
          window.location.pathname,
          getModalNameForTracking(modalIds.careProviderScheduleModal),
          headerText,
          {
            spring_doc_id: "HPR_007",
            location: `${cardConfig.medication_manager_follow_up.cardTrackingName}`,
            appointment_type: getAppointmentKindForTracking(
              AppointmentKind.FollowUpMedicationManagement,
            ),
            appointment_medium: getAppointmentMediumForTracking(
              medicationManagementMedium,
            ),
            provider_id: medicationManager.id,
          },
        );
        openModal(modalIds.careProviderScheduleModal, {
          ...medicationManager,
          mpLocation: "Up Next",
          kind: AppointmentKind.FollowUpMedicationManagement,
          medium: medicationManagementMedium,
          providerRole: "Medication Manager",
          buttonText: t("carePlanSteps.confirmAppointment"),
          action: AppointmentAction.Create,
          initialStartTime: medicationManagementInitialStartTime,
        });
      }
    }
  };

  const shouldUseBrecsRoute = isBrecsVariant(
    useFeatureFlag(FLAGS.BRECS_ABC_EXPERIMENT),
  );
  const currentCareItemCount = recommendedCareItems?.length;
  const careItemCountAtBeginningOfDay = JSON.parse(
    window.localStorage.getItem(`careItemCountAtBeginningOfDay_${memberId}`),
  );
  const currentDate = DateTime.local().toLocaleString(); // in North America e.g. 9/6/2022
  const isMissingTodaysCareItemCountAtBeginningOfDay =
    careItemCountAtBeginningOfDay === null ||
    careItemCountAtBeginningOfDay.date !== currentDate;
  let headerText = "";

  if (isMissingTodaysCareItemCountAtBeginningOfDay) {
    saveCareItemCountToLocalStorage(
      memberId,
      currentCareItemCount,
      currentDate,
    );
  }

  if (!hasEverCompletedARecommendedCareItem) {
    headerText = t("homepage:ourRecommendations");
  } else if (
    hasEverCompletedARecommendedCareItem &&
    currentCareItemCount !== 0
  ) {
    headerText = t("homepage:upNext");
  }

  const filteredUpcomingAppointments = (upcomingAppointments || []).filter(
    includeUpcomingAppointment,
  );
  const hasUpcomingAppointments = filteredUpcomingAppointments?.length > 0;

  const shouldShowUpNextList =
    currentCareItemCount > 0 || hasUpcomingAppointments;
  const shouldShowUpNextSectionCompleted =
    careItemCountAtBeginningOfDay?.count > 0 &&
    currentCareItemCount === 0 &&
    !hasUpcomingAppointments;
  const shouldShowUpNextSectionEmpty =
    careItemCountAtBeginningOfDay?.count === 0 &&
    currentCareItemCount === 0 &&
    !hasUpcomingAppointments;

  const upNextVariant = useBreakpointValue([
    "md_v1",
    "md_v1",
    "lg_v1",
    "lg_v1",
  ]);

  const handleFlyoutOpenCNAppointmentModal = () => {
    // Close CN Info Flyout first before opening the other modal
    // TODO: A1ly CONSIDERATION: figure out how to force focus on the CN Scheduling modal on Info Flyout close
    careNavigationDisclosure.onClose();
    openCareNavigationModal();
  };

  const handleFlyoutOpenSpecializedCNAppointmentModal = () => {
    specializeCareNavigationDisclosure.onClose();
    openSpecializedCNScheduleModal();
  };

  let therapistItems = {
    cardTitle: t("homepage:upNextSection.therapistAssignment.cardTitle"),
    primaryCTAURL: "/browse/therapists",
    cardTrackingName: "Find Therapist",
  };

  if (therapyRecsEligible) {
    const ROUTE_URL = shouldUseBrecsRoute
      ? "/browse/therapists"
      : "/members/recommendations";
    therapistItems = {
      cardTitle: t(
        "homepage:upNextSection.therapistAssignment.recommendedCardTitle",
      ),
      primaryCTAURL: ROUTE_URL,
      cardTrackingName: "We Handpicked A Few Therapists For You",
    };
  }

  const { sudCardOverline, sudCardTitle } = getSUDCardContent(
    memberGoalTypes,
    memberIssues,
    t,
  );

  const [openSpecialtyCareModal, setOpenSpecialtyCareModal] = useState(false);

  // TODO: Callbacks to open the cost estimation modal are done in a WIP manner
  // ... fully integrate in MXENG-3643
  const cardConfig = {
    bam_assessment: {
      overline: t("homepage:upNextSection.specialtyCare.overline"),
      cardTitle: t("homepage:upNextSection.specialtyCare.cardTitle"),
      subtitle: t("homepage:upNextSection.subtitleTime", { time: "3-5" }),
      primaryCTAType: "button",
      primaryCTAText: t("homepage:upNextSection.specialtyCare.primaryCTAText"),
      primaryCTACallback: () => setOpenSpecialtyCareModal(true),
      secondaryCTAType: "button",
      secondaryCTAText: t(
        "homepage:upNextSection.specialtyCare.secondaryCTAText",
      ),
      secondaryCTACallback: specialtyCareDisclosure.onOpen,
      cardTrackingName: "Start BAM Assessment",
    },
    pcl5_assessment: {
      overline: t("homepage:upNextSection.specialtyCare.overline"),
      cardTitle: t("homepage:upNextSection.specialtyCare.cardTitle"),
      subtitle: t("homepage:upNextSection.subtitleTime", { time: "10-15" }),
      primaryCTAType: "button",
      primaryCTAText: t("homepage:upNextSection.specialtyCare.primaryCTAText"),
      primaryCTACallback: () => setOpenSpecialtyCareModal(true),
      secondaryCTAType: "button",
      secondaryCTAText: t(
        "homepage:upNextSection.specialtyCare.secondaryCTAText",
      ),
      secondaryCTACallback: specialtyCareDisclosure.onOpen,
      cardTrackingName: "Start PCL5 Assessment",
    },
    medication_manager_assignment: {
      overline: t(
        "homepage:upNextSection.medicationManagerAssignment.overline",
      ),
      cardTitle: t(
        "homepage:upNextSection.medicationManagerAssignment.cardTitle",
      ),
      subtitle: t("homepage:upNextSection.subtitle"),
      primaryCTAType: "link",
      primaryCTAText: t(
        "homepage:upNextSection.medicationManagerAssignment.primaryCTAText",
      ),
      primaryCTAURL: "/browse/medication_managers",
      secondaryCTAType: "button",
      secondaryCTAText: t(
        "homepage:upNextSection.medicationManagerAssignment.secondaryCTAText",
      ),
      secondaryCTACallback: medicationManagementDisclosure.onOpen,
      cardTrackingName: "Find Medication Manager",
    },
    medication_manager_follow_up: {
      overline: t("homepage:upNextSection.medicationManagerFollowUp.overline"),
      cardTitle: `${t("homepage:upNextSection.medicationManagerFollowUp.cardTitle")} ${medicationManager?.first_name}`,
      imageUrl: medicationManager?.avatar,
      subtitle: t("homepage:upNextSection.subtitle"),
      primaryCTAType: "button",
      primaryCTAText: t(
        "homepage:upNextSection.medicationManagerFollowUp.primaryCTAText",
      ),
      primaryCTACallback: openMedicationManagerModal,
      secondaryCTAType: "link",
      secondaryCTAText: t(
        "homepage:upNextSection.medicationManagerFollowUp.secondaryCTAText",
      ),
      secondaryCTAURL: "/browse/medication_managers",
      cardTrackingName: "Schedule Follow-Up Medication Management",
    },
    therapist_assignment: {
      overline: t("homepage:upNextSection.therapistAssignment.overline"),
      subtitle: t("homepage:upNextSection.subtitle"),
      primaryCTAType: "link",
      primaryCTAText: t(
        "homepage:upNextSection.therapistAssignment.primaryCTAText",
      ),
      secondaryCTAType: "button",
      secondaryCTAText: t(
        "homepage:upNextSection.therapistAssignment.secondaryCTAText",
      ),
      secondaryCTACallback: therapyDisclosure.onOpen,
      ...therapistItems,
    },
    therapist_follow_up: {
      overline: t("homepage:upNextSection.therapistFollowUp.overline"),
      cardTitle: `${t("homepage:upNextSection.therapistFollowUp.cardTitle")} ${therapist?.first_name}`,
      imageUrl: therapist?.avatar,
      subtitle: t("homepage:upNextSection.subtitle"),
      primaryCTAType: "button",
      primaryCTAText: t("homepage:upNextSection.primaryCTAText"),
      primaryCTACallback: openTherapistModal,
      secondaryCTAType: "link",
      secondaryCTAText: t(
        "homepage:upNextSection.therapistFollowUp.secondaryCTAText",
      ),
      secondaryCTAURL: "/browse/therapists",
      cardTrackingName: "Schedule Follow-Up Therapy",
    },
    care_navigator_scheduling: {
      overline: hasManagedDependents
        ? t("homepage:upNextSection.careNavigatorScheduling.familyOverline")
        : t("homepage:upNextSection.careNavigatorScheduling.overline"),
      cardTitle: hasManagedDependents
        ? t("homepage:upNextSection.careNavigatorScheduling.familyCardTitle")
        : t("homepage:upNextSection.careNavigatorScheduling.cardTitle"),
      imageUrl: <CareNavigator aria-hidden="true" width="40" height="40" />,
      subtitle: t("homepage:upNextSection.subtitle"),
      primaryCTAType: "button",
      primaryCTAText: t("homepage:upNextSection.primaryCTAText"),
      primaryCTACallback: openCareNavigationModal,
      secondaryCTAType: "button",
      secondaryCTAText: t(
        "homepage:upNextSection.careNavigatorScheduling.secondaryCTAText",
      ),
      secondaryCTACallback: careNavigationDisclosure.onOpen,
      cardTrackingName: "Schedule Care Navigation",
    },
    specialized_care_navigator: {
      overline: t(
        "homepage:upNextSection.specializedCareNavigatorScheduling.overline",
      ),
      cardTitle: t(
        "homepage:upNextSection.specializedCareNavigatorScheduling.cardTitle",
      ),
      subtitle: t("homepage:upNextSection.subtitle"),
      primaryCTAType: "button",
      primaryCTAText: t(
        "homepage:upNextSection.specializedCareNavigatorScheduling.getSupport",
      ),
      primaryCTACallback: openSpecialtyCalendarPage,
      secondaryCTAType: "button",
      secondaryCTAText: t(
        "homepage:upNextSection.specializedCareNavigatorScheduling.secondaryCTAText",
      ),
      secondaryCTACallback: specializeCareNavigationDisclosure.onOpen,
      cardTrackingName: "Schedule Specialized Care Navigation",
    },
    coach_assignment: {
      overline: t("homepage:upNextSection.coachAssignment.overline"),
      cardTitle: (
        <Trans
          ns="homepage"
          i18nKey="upNextSection.coachAssignment.cardTitleNewCoachingFlyout"
          components={[
            <PopoverComponent key="upNextSection.coachAssignment.cardTitleNewCoachingFlyout" />,
          ]}
        />
      ),
      subtitle: t("homepage:upNextSection.subtitle"),
      primaryCTAType: "link",
      primaryCTAText: t(
        "homepage:upNextSection.coachAssignment.primaryCTAText",
      ),
      primaryCTAURL: "/browse/coaches",
      secondaryCTAType: "button",
      secondaryCTAText: t(
        "homepage:upNextSection.coachAssignment.secondaryCTATextNewCoachingFlyout",
      ),
      secondaryCTACallback: coachingDisclosure.onOpen,
      cardTrackingName: "Find Coach",
    },
    coach_follow_up: {
      overline: t("homepage:upNextSection.coachFollowUp.overline"),
      cardTitle:
        coachVisitsCovered > 2
          ? coach
            ? `${t("homepage:upNextSection.coachFollowUp.cardTitle")} ${coach?.first_name}`
            : t("homepage:upNextSection.coachFollowUp.cardTitleDefault")
          : t("homepage:upNextSection.coachFollowUp.cardTitleDefault"),
      imageUrl: coach?.avatar,
      subtitle: t("homepage:upNextSection.subtitle"),
      primaryCTAType: "button",
      primaryCTAText: t("homepage:upNextSection.primaryCTAText"),
      primaryCTACallback: openCoachingModal,
      secondaryCTAType: "link",
      secondaryCTAText: t(
        "homepage:upNextSection.coachFollowUp.secondaryCTAText",
      ),
      secondaryCTAURL: "/browse/coaches",
      cardTrackingName: "Schedule Follow-Up Coaching",
    },
    moments: {
      overline: t("homepage:upNextSection.moments.overline"),
      cardTitle: t("homepage:upNextSection.moments.cardTitle"),
      subtitle: t("homepage:upNextSection.subtitle"),
      primaryCTAType: "link",
      primaryCTAText: t("homepage:upNextSection.moments.primaryCTAText"),
      primaryCTAURL: "/members/moments",
      secondaryCTAType: "button",
      secondaryCTAText: t("homepage:upNextSection.moments.secondaryCTAText"),
      secondaryCTACallback: momentsDisclosure.onOpen,
      cardTrackingName: "Start Moments",
    },
    check_in: {
      overline: t("homepage:upNextSection.checkIn.overline"),
      cardTitle: t("homepage:upNextSection.checkIn.cardTitle"),
      subtitle: t("homepage:upNextSection.subtitle"),
      primaryCTAType: "link",
      primaryCTAText: t("homepage:upNextSection.checkIn.primaryCTAText"),
      primaryCTAURL: checkInAssessmentUrl,
      cardTrackingName: "Start Check-In",
    },
    substance_use: {
      overline: sudCardOverline,
      cardTitle: sudCardTitle,
      primaryCTAType: "button",
      primaryCTAText: t("homepage:upNextSection.substanceUse.primaryCTAText"),
      primaryCTACallback: () => {
        if (showSudProgramPage) {
          openSUDLandingPage();
        } else {
          TRACK_EVENT.BUTTON_CLICKED(
            window.location.pathname,
            "View alcohol and substance use stand alone flyout",
            {
              location: "Alcohol and substance use up next card CTA",
            },
          );

          substanceUseDisclosure.onOpen();
        }
      },
      cardTrackingName: "Start Substance Use",
    },
    tobacco_cessation: {
      overline: t("homepage:upNextSection.tobaccoCessation.overline"),
      cardTitle: t("homepage:upNextSection.tobaccoCessation.cardTitle"),
      subtitle: t("homepage:upNextSection.subtitle"),
      primaryCTAType: "button",
      primaryCTAText: t(
        "homepage:upNextSection.tobaccoCessation.primaryCTAText",
      ),
      primaryCTACallback: tobaccoCessationDisclosure.onOpen,
    },
    sdoh_local_resources: {
      overline: t("homepage:upNextSection.sdohLocalResources.overline"),
      cardTitle: t("homepage:upNextSection.sdohLocalResources.cardTitle"),
      subtitle: t("homepage:upNextSection.subtitle"),
      primaryCTAType: "button",
      primaryCTAText: t(
        "homepage:upNextSection.sdohLocalResources.primaryCTAText",
      ),
      primaryCTACallback: () => {
        TRACK_EVENT.BUTTON_CLICKED(
          window.location.pathname,
          TRACKING.sdohCard,
          {
            location: "Up Next section",
          },
        );
        Router.push(routes.LocalResources.as);
      },
      cardTrackingName: TRACKING.sdohCard,
    },
    peer_recovery_specialist_follow_up: {
      overline: t("homepage:upNextSection.peerRecoverySupport.overline"),
      cardTitle: `${t("homepage:upNextSection.peerRecoverySupport.cardTitle")} ${peerRecoverySpecialist?.first_name}`,
      subtitle: t("homepage:upNextSection.subtitle"),
      imageUrl: peerRecoverySpecialist?.avatar,
      primaryCTAType: "button",
      primaryCTAText: t(
        "homepage:upNextSection.peerRecoverySupport.primaryCTAText",
      ),
      primaryCTACallback: () => {
        TRACK_EVENT.BUTTON_CLICKED(
          window.location.pathname,
          TRACKING.peerRecoverySpecialistFollowUp,
          {
            location: "Up Next section",
          },
        );
        Router.push(
          routes["ScheduleAppointmentProviderDetail"].as.replace(
            ":id",
            peerRecoverySpecialist.id,
          ),
        );
      },
      secondaryCTAType: "button",
      secondaryCTAText: t(
        "homepage:upNextSection.peerRecoverySupport.secondaryCTAText",
      ),
      secondaryCTACallback: openPeerRecoverySpecialistForm,
      cardTrackingName: TRACKING.peerRecoverySpecialistFollowUp,
    },
    coverage: {
      overline: t("homepage:upNextSection.coverage.overline"),
      cardTitle: t("homepage:upNextSection.coverage.cardTitle"),
      cardTrackingName: "Coverage",
      subtitle: t("homepage:upNextSection.coverage.subtitle"),
      primaryCTAType: "link",
      primaryCTAText: t("homepage:upNextSection.coverage.primaryCTAText"),
      primaryCTAURL: "/members/billing-and-payments/coverage",
    },
  };

  const flyoutMap = {
    care_navigator_scheduling: {
      component: CareNavigationFlyout,
      props: {
        callback: handleFlyoutOpenCNAppointmentModal,
        hasManagedDependents,
        isOpen: careNavigationDisclosure.isOpen,
        onClose: careNavigationDisclosure.onClose,
        showGlobalExperience,
      },
    },
    coach_assignment: {
      component: CoachingFlyout,
      props: {
        isOpen: coachingDisclosure.isOpen,
        onClose: coachingDisclosure.onClose,
      },
    },
    medication_manager_assignment: {
      component: MedicationManagementFlyout,
      props: {
        isOpen: medicationManagementDisclosure.isOpen,
        onClose: medicationManagementDisclosure.onClose,
        sessionSnapshot,
      },
    },
    moments: {
      component: MomentsFlyout,
      props: {
        isOpen: momentsDisclosure.isOpen,
        onClose: momentsDisclosure.onClose,
      },
    },
    specialized_care_navigator: {
      component: SpecializedCareNavigationFlyout,
      props: {
        callback: shouldShowSUDCNCalendar
          ? openSUDLandingPage
          : handleFlyoutOpenSpecializedCNAppointmentModal,
        isOpen: specializeCareNavigationDisclosure.isOpen,
        onClose: specializeCareNavigationDisclosure.onClose,
      },
    },
    substance_use: {
      component: SubstanceUseFlyout,
      props: {
        callback: sudZendeskMutation,
        isOpen: substanceUseDisclosure.isOpen,
        onClose: substanceUseDisclosure.onClose,
      },
    },
    therapist_assignment: {
      component: TherapyFlyout,
      props: {
        isOpen: therapyDisclosure.isOpen,
        onClose: therapyDisclosure.onClose,
        sessionSnapshot,
        therapyRecsEnabled: therapyRecsEligible,
      },
    },
    tobacco_cessation: {
      component: TobaccoCessationFlyout,
      props: {
        callback: tobaccoCtaCallBack,
        isOpen: tobaccoCessationDisclosure.isOpen,
        onClose: tobaccoCessationDisclosure.onClose,
      },
    },
    bam_assessment: {
      component: SpecialtyCareFlyout,
      props: {
        isOpen: specialtyCareDisclosure.isOpen,
        onClose: specialtyCareDisclosure.onClose,
        questionnaireKind: "BAM",
        showSpecialtyCareModal: openSpecialtyCareModal,
        hideSpecialtyCareModal: () => setOpenSpecialtyCareModal(false),
      },
    },
    pcl5_assessment: {
      component: SpecialtyCareFlyout,
      props: {
        isOpen: specialtyCareDisclosure.isOpen,
        onClose: specialtyCareDisclosure.onClose,
        questionnaireKind: "PCL5",
        showSpecialtyCareModal: openSpecialtyCareModal,
        hideSpecialtyCareModal: () => setOpenSpecialtyCareModal(false),
      },
    },
  };

  return (
    <>
      <HStack>
        <Box>
          {memberFirstName && (
            <MemberGreeting
              memberFirstName={memberFirstName}
              memberGreeting={memberGreetingTranslated}
              size="heading-small"
              as="p"
            />
          )}
          {shouldShowUpNextSectionCompleted && <UpNextCompleted />}
          {shouldShowUpNextSectionEmpty && <UpNextEmpty />}
        </Box>

        <Hide below="993px">
          {shouldShowUpNextSectionCompleted && (
            <Box>
              <img
                src="/static/womanPlantOverhead.svg"
                alt="Woman Plant Overhead"
                style={{ "max-width": "none" }}
              />
            </Box>
          )}
          {shouldShowUpNextSectionEmpty && (
            <Box>
              <img
                src="/static/womanMeditatingInCircle.svg"
                alt="Woman Meditating In Circle"
                style={{ "max-width": "none" }}
              />
            </Box>
          )}
        </Hide>
      </HStack>
      <section>
        {shouldShowUpNextList && (
          <Box>
            <Heading as="h1" variant={upNextVariant} pb={24}>
              {headerText}
            </Heading>
            <UpNextList
              cardConfig={cardConfig}
              hasEverCompletedARecommendedCareItem={
                hasEverCompletedARecommendedCareItem
              }
              headerText={headerText}
              initialSUDRisk={initialSUDRisk}
              isSUDLoading={isSUDLoading}
              latestSUDRisk={latestSUDRisk}
              recommendedCareItems={recommendedCareItems}
              showNewCoachingCard={showNewCoachingExperience}
              upcomingAppointments={filteredUpcomingAppointments}
              memberId={memberId}
              userId={userId}
            />
          </Box>
        )}
      </section>
      {recommendedCareItems?.map((item) => {
        if (flyoutMap[item.care_item_name]) {
          const { component: FlyoutComponent, props: flyoutProps } =
            flyoutMap[item.care_item_name];

          return (
            <FlyoutComponent
              key={`${item.care_item_name}_flyout`}
              {...flyoutProps}
            />
          );
        }
      })}
      <ChangeCareProviderModal />
      <CareProviderScheduleModalV2
        isOpen={isScheduleModalOpen}
        onClose={onScheduleModalClose}
        {...scheduleModalProps}
      />
      <ChangePeerRecoverySpecialistModal
        isOpen={isPeerRecoverySpecialistFormOpen}
        onClose={closePeerRecoverySpecialistForm}
        onOpen={openPeerRecoverySpecialistForm}
      />
    </>
  );
};

const UpNextList = ({
  recommendedCareItems,
  cardConfig,
  hasEverCompletedARecommendedCareItem,
  headerText,
  upcomingAppointments,
  showNewCoachingCard,
  isSUDLoading,
  initialSUDRisk,
  latestSUDRisk,
  memberId,
  userId,
}) => {
  // only show appts <= 72 hours
  const upcomingAppointmentsCardsFlag = useFeatureFlag(
    FLAGS.UPCOMING_APPOINTMENT_CARDS,
  );

  const showSpecializedCoach = useFeatureFlag(
    FLAGS.SPECIALIZED_COACHING_CARD_V2_EXPERIENCE,
  );

  const showPeerRecoverySpecialistExp = useFeatureFlag(
    FLAGS.PEER_RECOVERY_SPECIALIST_EXPERIENCE,
  );

  return (
    <>
      {upcomingAppointmentsCardsFlag &&
        upcomingAppointments?.map((appointment, index) => (
          <Box key={index} pb="24px" data-testid="upcoming-appointment-card">
            <UpcomingAppointmentCard
              appointment={appointment}
              memberId={memberId}
              userId={userId}
            />
          </Box>
        ))}
      <CoverageCard cardConfig={cardConfig} />
      {recommendedCareItems &&
        recommendedCareItems.map((recommended_care_item, index) => (
          <Box key={recommended_care_item.care_item_name} pb="24">
            {recommended_care_item.care_item_name ===
            "peer_recovery_specialist_follow_up" ? (
              showPeerRecoverySpecialistExp && (
                <UpNextCard
                  {...cardConfig[recommended_care_item.care_item_name]}
                  buttonVariant={isFirstCard(index) ? "solid" : "outline"}
                  showFlag={
                    isFirstCard(index) && !hasEverCompletedARecommendedCareItem
                  }
                  showNewCoachingExperience={
                    showSpecializedCoach &&
                    recommended_care_item.care_item_name ===
                      "coach_assignment" &&
                    showNewCoachingCard
                  }
                  showPeerRecoverySpecilaistExp={
                    showPeerRecoverySpecialistExp &&
                    recommended_care_item.care_item_name ===
                      "peer_recovery_specialist_follow_up"
                  }
                  cardOrder={index}
                  headerText={headerText}
                  initialSUDRisk={initialSUDRisk}
                  latestSUDRisk={latestSUDRisk}
                  isSUDLoading={isSUDLoading}
                />
              )
            ) : (
              <UpNextCard
                {...cardConfig[recommended_care_item.care_item_name]}
                buttonVariant={isFirstCard(index) ? "solid" : "outline"}
                showFlag={
                  isFirstCard(index) && !hasEverCompletedARecommendedCareItem
                }
                showNewCoachingExperience={
                  showSpecializedCoach &&
                  recommended_care_item.care_item_name === "coach_assignment" &&
                  showNewCoachingCard
                }
                cardOrder={index}
                headerText={headerText}
                initialSUDRisk={initialSUDRisk}
                latestSUDRisk={latestSUDRisk}
                isSUDLoading={isSUDLoading}
              />
            )}
          </Box>
        ))}
    </>
  );
};

const isFirstCard = (index) => index === 0;

const mapStateToProps = ({ global: { showGlobalExperience } }) => ({
  showGlobalExperience,
});

export default connect(mapStateToProps, { openModal, addNotification })(
  UpNextSection,
);
