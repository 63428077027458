import React, { createContext, useContext, useState } from "react";
import type { Dispatch, SetStateAction } from "react";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";

import { useQuery } from "@apollo/client";
import type { ApolloError } from "@apollo/client";
import { getCoveredLifeInfo } from "modules/shared/graphql/queries/getCoveredLifeInfo";
import { useMemberInfo } from "hooks";

/**
 * Ideally, this provider will eventually support a debug panel so that we can
 * edit values on the fly, and will be a primary source of truth for member data.
 *
 * ENDPOINT DETAILS: @TODO
 *
 * isCareEnabled: false if the member is a CHIP guardian, meaning that they can
 *      manage their dependents' accounts, but do not have care enabled for themselves.
 */

interface MemberContextProps {
  isCareEnabled: boolean;
  isChip: boolean;
  setCoveredLifeId: Dispatch<SetStateAction<string>>;
  hasResolved: boolean;
  loading: boolean;
  error?: ApolloError;
}

const MemberContext = createContext<MemberContextProps | undefined>(undefined);

export const MemberProvider = ({ children }) => {
  const isChipGuardianEnabled = useFeatureFlag(FLAGS.CHIP_GUARDIAN_RELEASE);
  const [coveredLifeId, setCoveredLifeId] = useState(undefined);
  const [hasResolved, setHasResolved] = useState(false);

  // @TODO: don't fire this if we don't need to
  const { data: memberData } = useMemberInfo();

  const { data, loading, error } = useQuery(getCoveredLifeInfo, {
    variables: {
      id: coveredLifeId || memberData?.user?.member?.covered_life?.id,
    },
    skip:
      !isChipGuardianEnabled ||
      (!coveredLifeId && !memberData?.user?.member?.covered_life?.id),
    onCompleted: () => setHasResolved(true),
    onError: () => setHasResolved(true),
  });
  // @TODO: fix this check
  const isCareEnabled = isChipGuardianEnabled
    ? data?.get_covered_life_info?.is_care_enabled
    : true;
  const isChip = isChipGuardianEnabled && data?.get_covered_life_info?.is_chip;

  return (
    <MemberContext.Provider
      value={{
        isCareEnabled,
        isChip,
        setCoveredLifeId,
        hasResolved,
        loading,
        error,
      }}
    >
      {children}
    </MemberContext.Provider>
  );
};

export const useMember = (): MemberContextProps => {
  const context = useContext(MemberContext);
  if (!context) {
    throw new Error("useMember must be used within a MemberProvider");
  }
  return context;
};
