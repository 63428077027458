import { FC } from "react";

import {
  Checklist,
  Col,
  Content,
  FlexCol,
  Grid,
  Section,
} from "@spring/smeargle";
import {
  BenefitsTracker,
  CareProviderDetailModal,
  CareProviderScheduleModal,
  ChangeCareProviderModal,
  MomentsBenefit,
} from "components";
import { Trans, useTranslation } from "react-i18next";
import ConnectText from "./ConnectText";
import styles from "./styles.module.scss";

import { VirtualSessionCostTerm } from "components/molecules/InNetworkStatusDisclaimer/dataContract";
import { Flex, Heading } from "design-system/components";
import { CareProvider } from "shared/contexts";
import { ScheduleModalOpenOptions } from "shared/hooks";
import AvatarBlock from "./AvatarBlock";
import CallToAction from "./CallToAction";
import CostEstimateDisclaimer, {
  CostEstimateDisclaimerTypes,
} from "components/molecules/CostEstimateDisclaimer/CostEstimateDisclaimer";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";

// NOTE: this component is more than likely doing way too much and should be decomposed
interface CoveredCareContentProps {
  title?: string;
  description?: string;
  callToActionRoute?: string;
  callToActionText?: string;
  careNavigator?: CareProvider;
  checklist?: any[];
  isCoach?: boolean;
  coachingVisitsRemaining?: number;
  coachingVisitsCovered?: Number;
  coachingVisitsUsed?: Number;
  coachesHelpDesc?: string;
  followUpApptReleaseFF?: boolean;
  image?: JSX.Element;
  initialStartTime?: string;
  isGlobalTherapy?: boolean;
  isMedicationManager?: boolean;
  isTherapy?: boolean;
  medicationManager?: CareProvider;
  medium?: string;
  needHelpText?: string;
  paidCoaching?: boolean;
  supportType?: string;
  showTherapyCost?: boolean;
  specialistVisitsCovered?: Number;
  specialistVisitsRemaining?: Number;
  specialistVisitsUsed?: Number;
  therapist?: CareProvider;
  therapyVisitsCovered?: Number;
  therapyVisitsRemaining?: Number;
  therapyVisitsUsed?: Number;
  isInNetwork?: boolean;
  internationalCohort?: boolean;
  customerName?: string;
  customerId?: string;
  virtualSessionCostTerm?: VirtualSessionCostTerm;
  sessions?: string;
  atCost?: string;
  modalControls?: { isOpen: boolean; onClose: () => void; onOpen: () => void };
  isMoments?: boolean;
  isAMinor?: boolean;
  onScheduleModalOpen?: (props: ScheduleModalOpenOptions) => void;
  memberCostOfCoaching?: number;
  hasSpecializedCareNavigator?: boolean;
  isPeerRecoverySpecialistSupported?: boolean;
  showCTA?: boolean;
  isPrivatePractice?: boolean;
  onClickCallToAction?: () => void;
}

const CoveredCareContent: FC<CoveredCareContentProps> = ({
  callToActionRoute,
  callToActionText,
  careNavigator,
  checklist,
  isCoach,
  modalControls,
  coachingVisitsRemaining,
  coachingVisitsCovered,
  coachingVisitsUsed,
  description,
  image,
  initialStartTime,
  isGlobalTherapy,
  isMedicationManager,
  isTherapy,
  medium,
  medicationManager,
  needHelpText,
  paidCoaching,
  specialistVisitsCovered,
  specialistVisitsRemaining,
  specialistVisitsUsed,
  therapist,
  therapyVisitsCovered,
  therapyVisitsRemaining,
  therapyVisitsUsed,
  title,
  isInNetwork,
  internationalCohort,
  customerName,
  customerId,
  virtualSessionCostTerm,
  isMoments,
  isAMinor,
  onScheduleModalOpen,
  memberCostOfCoaching,
  hasSpecializedCareNavigator = false,
  showCTA = true,
  isPrivatePractice = false,
  isPeerRecoverySpecialistSupported = false,
  onClickCallToAction,
}) => {
  const LeftPanel = () => {
    const { t } = useTranslation("benefits");
    const isHighmark = useFeatureFlag(FLAGS.HIGHMARK_COST_TRANSPARENCY_COPY);
    const usingHighmarkBanners = useFeatureFlag(FLAGS.HIGHMARK_COST_BANNERS);
    const showHighmarkCard = isHighmark && usingHighmarkBanners;
    const { enable_show: costTransparencyEnableShow } =
      virtualSessionCostTerm || {};
    const showMedicationManager =
      isMedicationManager && specialistVisitsCovered === 0;
    const showTherapy = isTherapy && therapyVisitsCovered === 0;
    const showAtCostInfo =
      (!costTransparencyEnableShow && showMedicationManager) || showTherapy;
    const renderRemainingSessions = (
      therapyVisitsCovered,
      therapyVisitsRemaining = null,
      specialistVisitsCovered,
      specialistVisitsRemaining = null,
      therapyVisitsUsed = null,
      specialistVisitsUsed = null,
      isMedicationManager = null,
      isTherapy = null,
    ) => {
      if (therapyVisitsUsed || specialistVisitsUsed) {
        return (
          <BenefitsTracker
            therapyVisitsCovered={therapyVisitsCovered}
            therapyVisitsRemaining={therapyVisitsRemaining}
            specialistVisitsCovered={specialistVisitsCovered}
            specialistVisitsRemaining={specialistVisitsRemaining}
            therapyVisitsUsed={therapyVisitsUsed}
            specialistVisitsUsed={specialistVisitsUsed}
            isMedicationManager={isMedicationManager}
            isTherapy={isTherapy}
            isInNetwork={isInNetwork}
            internationalCohort={internationalCohort}
            customerName={customerName}
            virtualSessionCostTerm={virtualSessionCostTerm}
            customerId={customerId}
            isAMinor={isAMinor}
          />
        );
      }

      return (
        <BenefitsTracker
          therapyVisitsCovered={therapyVisitsCovered}
          specialistVisitsCovered={specialistVisitsCovered}
          therapyVisitsRemaining={therapyVisitsRemaining}
          isMedicationManager={isMedicationManager}
          isTherapy={isTherapy}
          isInNetwork={isInNetwork}
          internationalCohort={internationalCohort}
          customerName={customerName}
          virtualSessionCostTerm={virtualSessionCostTerm}
          customerId={customerId}
          isAMinor={isAMinor}
        />
      );
    };

    return (
      <Col md={6}>
        <Flex justifyContent="space-between">
          <Heading variant="sm_v1">{title}</Heading>
        </Flex>
        <div className={styles.contentWrapper}>
          <Content>{description}</Content>
        </div>
        {!hasSpecializedCareNavigator && !isPrivatePractice && (
          <ConnectText
            modalControls={modalControls}
            careNavigator={careNavigator}
            isGlobalTherapy={isGlobalTherapy}
            needHelpText={needHelpText}
            isCoach={isCoach}
            onScheduleModalOpen={onScheduleModalOpen}
          />
        )}

        {showAtCostInfo && (
          <Flex justifyContent="space-between" className={styles.atCostWrapper}>
            <span className={styles.atCostSession}>
              <b>{t("sessions.sessions")}</b>
            </span>
            <span className={styles.atCostText}>
              <b>{t("sessions.atCost")}</b>
            </span>
          </Flex>
        )}
        {isTherapy && (
          <div className={styles.disclaimerContainer}>
            <CostEstimateDisclaimer
              variant={CostEstimateDisclaimerTypes.CARD}
            />
          </div>
        )}
        {/* Render sessions tracker on Coaching tab. paidCoaching prop checks the flag. */}
        {isCoach && paidCoaching ? (
          <BenefitsTracker
            coachingVisitsRemaining={coachingVisitsRemaining}
            coachingVisitsCovered={coachingVisitsCovered}
            coachingVisitsUsed={coachingVisitsUsed}
            isCoaching={isCoach}
            customerId={customerId}
            memberCostOfCoaching={memberCostOfCoaching}
          />
        ) : null}
        {/* Render sessions tracker on Therapy and Medication Management Tabs */}
        {/* This condition MUST check which tab is active and ONLY show for Therapy and MM. */}
        {(isMedicationManager || isTherapy) && !showHighmarkCard
          ? renderRemainingSessions(
              therapyVisitsCovered,
              therapyVisitsRemaining,
              specialistVisitsCovered,
              specialistVisitsRemaining,
              therapyVisitsUsed,
              specialistVisitsUsed,
              isMedicationManager,
              isTherapy,
            )
          : null}
        {hasSpecializedCareNavigator && !isPrivatePractice && (
          <>
            <div className={styles.connectTextWrapper}>
              <Content>
                <b>
                  <Trans
                    ns="benefits"
                    i18nKey="coveredCareContent.specializeCareNavigatorNoCost"
                  />
                </b>
              </Content>
            </div>
            <div>
              <Content>{t("careNavigation.specializedPlanSubText")}</Content>
              <span style={{ marginTop: "1rem", display: "block" }}>
                <Content>
                  <b>{t("careNavigation.specializedFooter")}</b>
                </Content>
              </span>
            </div>
            <br />
            <ConnectText
              modalControls={modalControls}
              careNavigator={careNavigator}
              isGlobalTherapy={isGlobalTherapy}
              hideCostText={true}
              needHelpText={needHelpText}
              isCoach={isCoach}
              onScheduleModalOpen={onScheduleModalOpen}
            />
          </>
        )}

        {isPeerRecoverySpecialistSupported && (
          <>
            <div className={styles.connectTextWrapper}>
              <Content>
                <b>
                  <Trans
                    ns="benefits"
                    i18nKey="coveredCareContent.peerRecoverySupport"
                  />
                </b>
              </Content>
            </div>
            <div>
              <Content>{t("careNavigation.peerRecoverySupportText")}</Content>
              <span style={{ marginTop: "1rem", display: "block" }}></span>
            </div>
            <br />
          </>
        )}
      </Col>
    );
  };

  const RightPanel = () => {
    return (
      <Col md={6}>
        <FlexCol>
          <AvatarBlock image={image} careNavigator={careNavigator} />
          <Checklist listItems={checklist} listStyle="bulleted" />
          {showCTA && (
            <CallToAction
              modalControls={modalControls}
              callToActionText={callToActionText}
              careNavigator={careNavigator}
              therapist={therapist}
              medium={medium}
              isTherapy={isTherapy}
              initialStartTime={initialStartTime}
              callToActionRoute={callToActionRoute}
              onClickCallToAction={onClickCallToAction}
              isCoach={isCoach}
              medicationManager={medicationManager}
              isMedicationManager={isMedicationManager}
              onScheduleModalOpen={onScheduleModalOpen}
              hasSpecializedCareNavigator={hasSpecializedCareNavigator}
            />
          )}
        </FlexCol>
      </Col>
    );
  };

  return (
    <>
      {!isMoments && (
        <Section size="xlg">
          <Grid gutter="0 36px">
            <>
              <LeftPanel />
              <RightPanel />
            </>
          </Grid>
          <CareProviderDetailModal />
          <CareProviderScheduleModal />
          <ChangeCareProviderModal />
        </Section>
      )}
      {isMoments && <MomentsBenefit isAMinor={isAMinor} />}
    </>
  );
};

export default CoveredCareContent;
