import { Box, Button, VArrowLeftIcon } from "@springcare/sh-component-library";
import { useTranslation } from "react-i18next";
import { useRouter } from "next/router";
import useHighmarkExperience from "hooks/useHighmarkExperience";

import { COST_ESTIMATE_DISPLAY_STATES } from "components/templates/CostEstimation/constants";
import routes from "routes";

export const BackCta = ({ widthValueToUse, displayState, updateDisplay }) => {
  const { t } = useTranslation("costEstimate");
  const router = useRouter();

  const { showHighmarkInsuranceForm } = useHighmarkExperience();
  const isOnFormDisplay =
    displayState === COST_ESTIMATE_DISPLAY_STATES.INSURANCE_FORM ||
    displayState === COST_ESTIMATE_DISPLAY_STATES.SELF_PAY_DISPLAY;

  const handleBackButtonClick = () => {
    if (showHighmarkInsuranceForm) {
      return router.replace(routes.MemberHome.to, routes.MemberHome.as);
    } else if (isOnFormDisplay) {
      updateDisplay(COST_ESTIMATE_DISPLAY_STATES.INITIAL_DISPLAY);
    } else {
      router.back();
    }
  };

  return (
    <>
      {displayState !== COST_ESTIMATE_DISPLAY_STATES.REQUEST_STATUS && (
        <Box width={widthValueToUse}>
          <Button
            variant="no-emphasis"
            size="sm"
            paddingX={0}
            icon={VArrowLeftIcon}
            onClick={handleBackButtonClick}
          >
            {t("backButtonText")}
          </Button>
        </Box>
      )}
    </>
  );
};
