import { useState } from "react";
import { isMobileOnly } from "react-device-detect";
import {
  Box,
  Flex,
  Text,
  Button,
  ModalSheet,
  VWelcomeBackDuotoneSpot,
} from "@springcare/sh-component-library";

import { trackRequestDevicePermissionsModalAllowed } from "components/templates/SHSessionRoom/telemetry/analytics";

import { useTranslation } from "hooks/react-i18next";

const RequestDevicePermissionsModal = ({ startMedia, isOpen, onClose }) => {
  const { t } = useTranslation("livekitExperience", {
    keyPrefix: "preJoin",
  });
  const [hasPressedAllow, setHasPressedAllow] = useState(false);

  const handleAllowBtnClick = async () => {
    trackRequestDevicePermissionsModalAllowed();
    setHasPressedAllow(true);
    onClose();
    await startMedia();
  };

  return (
    <Box>
      {/* @ts-ignore */}
      <ModalSheet
        isOpen={isOpen}
        placement={isMobileOnly ? "bottom" : "center"}
        size="sm"
        title={t("browserPermissionsRequestModal.header")}
        showCloseButton={false}
      >
        <Flex justify="center" mb="v-16">
          <VWelcomeBackDuotoneSpot />
        </Flex>
        <Box mb="v-16">
          <Text>{t("browserPermissionsRequestModal.firstSubHeader")}</Text>
        </Box>
        <Box mb="v-16">
          <Text>{t("browserPermissionsRequestModal.secondSubHeader")}</Text>
        </Box>
        <Box textAlign="right">
          <Button
            onClick={handleAllowBtnClick}
            isLoading={hasPressedAllow}
            tabIndex={1}
            aria-label={"request-device-permissions"}
          >
            {t("browserPermissionsRequestModal.allowAccessBtn")}
          </Button>
        </Box>
      </ModalSheet>
    </Box>
  );
};

export default RequestDevicePermissionsModal;
