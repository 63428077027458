import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Text } from "@springcare/sh-component-library";
import {
  curriedGetTips,
  curriedGetHeadline,
} from "modules/MemberDashboard/CareVisits/AppointmentDetails/utils/appointmentDetails.util";
import { NumberedSteps } from "modules/MemberDashboard/CareVisits/AppointmentDetails/components";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";
import { useMember } from "context/MemberContext";

interface AppointmentTipsProps {
  medium: string;
  instructions: string[];
  isAMinor: boolean;
  kind: string;
  isInHouseVideo: boolean;
}

const AppointmentTips = ({
  medium,
  instructions,
  isAMinor,
  kind,
  isInHouseVideo,
}: AppointmentTipsProps) => {
  const { t } = useTranslation("careVisits");
  const getHeadline = curriedGetHeadline(t);
  const getTips = curriedGetTips(t);
  const couplesTherapyFlag = useFeatureFlag(FLAGS.ENABLE_COUPLES_THERAPY);
  const { isChip } = useMember();

  return (
    <Box>
      {getHeadline(medium, kind) && (
        <Text size="body-medium-strong" mb={20}>
          {getHeadline(medium, kind)}
        </Text>
      )}
      <Box>
        <NumberedSteps
          isChip={isChip}
          steps={getTips(
            medium,
            instructions,
            isAMinor,
            kind,
            isInHouseVideo,
            couplesTherapyFlag,
            isChip,
          )}
        />
      </Box>
    </Box>
  );
};

export default AppointmentTips;
