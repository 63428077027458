import { Tab, Text } from "@springcare/sh-component-library";

interface TabHeaderProps {
  heading: string;
  onClick: () => void;
  testId: string;
}

export const TabHeader = ({ onClick, testId, heading }: TabHeaderProps) => (
  <Tab
    p={8}
    mr={16}
    borderRadius={8}
    color="content-secondary"
    bg="white"
    _hover={{
      bg: "hover-base-low-emphasis",
      color: "primary-content",
    }}
    _selected={{
      bg: "primary-subtle",
      color: "primary-on-subtle",
    }}
    _focusVisible={{ boxShadow: "0 0 0 2px black" }}
    _focus={{
      focusBorderColor: "transparent",
      borderColor: "none",
    }}
    onClick={onClick}
    data-testid={testId}
    data-cy={testId}
  >
    <Text size="body-medium-strong">{heading}</Text>
  </Tab>
);
