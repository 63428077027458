import React from "react";
import {
  Heading,
  ListItem,
  Text,
  UnorderedList,
  VCareNavigatorDuotoneSpot,
  VCareNavigatorFlatSpot,
} from "@springcare/sh-component-library";
import { InformationFlyout } from "components";
import { useTranslation } from "react-i18next";

type SpecializedCareNavigationFlyoutType = {
  callback: () => void;
  isAlsoAvailableTile?: boolean;
  isOpen: boolean;
  onClose: () => void;
};

const SpecializedCareNavigationFlyoutBody = ({
  isAlsoAvailableTile,
}: {
  isAlsoAvailableTile: boolean;
}) => {
  const { t } = useTranslation("homepage", {
    keyPrefix: "careInfoFlyout.specializeCareNavigation",
  });

  return (
    <>
      {isAlsoAvailableTile ? (
        ""
      ) : (
        <>
          <Heading variant="sm_v1" mb={8}>
            {t("whyIsThisRecommendedHeader")}
          </Heading>
          <Text mb={24}>{t("specializedWhyIsThisRecommededBody")}</Text>
        </>
      )}
      <Heading variant="sm_v1" mb={8}>
        {t("takeTheFistStepHeader")}
      </Heading>
      <Text mb={24}>{t("takeTheFistStepBody")}</Text>
      <Heading variant="sm_v1" mb={8}>
        {t("whatToExpect.header")}
      </Heading>
      <Text mb={24}>{t("whatToExpect.specializedBody")}</Text>
      <Heading variant="sm_v1" mb={8}>
        {t("ScheduleTimeWithSpecializedNavigator.header")}
      </Heading>
      <UnorderedList mb={24}>
        <ListItem>{t("ScheduleTimeWithSpecializedNavigator.item1")}</ListItem>
        <ListItem>{t("ScheduleTimeWithSpecializedNavigator.item2")}</ListItem>
        <ListItem>{t("ScheduleTimeWithSpecializedNavigator.item3")}</ListItem>
        <ListItem>{t("ScheduleTimeWithSpecializedNavigator.item4")}</ListItem>
      </UnorderedList>
    </>
  );
};

export const SpecializedCareNavigationFlyout = ({
  callback,
  isAlsoAvailableTile = false,
  isOpen,
  onClose,
}: SpecializedCareNavigationFlyoutType) => {
  const { t } = useTranslation("homepage", {
    keyPrefix: "careInfoFlyout.specializeCareNavigation",
  });

  return (
    <InformationFlyout
      headerText={t("heading")}
      headerIcon={<VCareNavigatorFlatSpot />}
      heroIllustration={VCareNavigatorDuotoneSpot}
      isOpen={isOpen}
      onClose={onClose}
      ctaText={t("ctaText")}
      ctaType="button"
      ctaCallback={callback}
    >
      <SpecializedCareNavigationFlyoutBody
        isAlsoAvailableTile={isAlsoAvailableTile}
      />
    </InformationFlyout>
  );
};
