import { Button, Flex, useMediaQuery } from "@springcare/sh-component-library";
import { ConfidentialityBanner } from "components/assessments/Elements";

type ActionType = {
  label: string;
  onClick: () => void;
  isDisabled: boolean;
  dataCy: string;
  variant: string;
  isPCL5Header?: boolean;
};

const AssessmentFooter = ({ actions }: { actions?: ActionType[] }) => {
  const [isMobile] = useMediaQuery("(max-width: 450px)");
  return (
    <>
      <Flex
        p={isMobile ? "16px 24px 36px" : "12px 24px"}
        maxWidth="780px"
        width="100%"
        margin="0 auto"
        flexDir={isMobile ? "column" : "row"}
        alignItems="center"
        position="fixed"
        bottom="0px"
        left="0px"
        right="0px"
        zIndex={10}
        backgroundColor="#FFFFFFCC"
        backdropFilter="blur(24px)"
      >
        <Flex
          height="48px"
          width="100%"
          margin={isMobile ? "0 12px 12px" : "0 12px"}
          justifyContent={isMobile ? "center" : "start"}
          alignItems="center"
          flex="1 0 0"
        >
          <ConfidentialityBanner />
        </Flex>
        {actions != null && (
          <Flex
            height="48px"
            margin={"0 auto"}
            width="100%"
            flexDir="row-reverse"
            justifyContent="flex-start"
            alignItems="center"
            gap="8px"
            flex="1 0 0"
          >
            {actions.map((action, index) => {
              return (
                <FooterAction key={`footer-action-${index}`} {...action} />
              );
            })}
          </Flex>
        )}
      </Flex>
    </>
  );
};

const FooterAction = ({
  label,
  dataCy,
  isDisabled = false,
  isPCL5Header = false,
  ...other
}: ActionType) => (
  <Button
    {...other}
    data-testid={`button_${label}`}
    data-cy={dataCy}
    isDisabled={isPCL5Header ? false : isDisabled}
    _focusVisible={{
      boxShadow: "0 0 0 3px black",
    }}
  >
    {label}
  </Button>
);

export default AssessmentFooter;
