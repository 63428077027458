import { useQuery } from "@apollo/client";
import { getWorkplaceCustomization } from "modules/MemberDashboard/ManagerExperience/Workplace/grapqhql/queries/getWorkplaceCustomization";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";

export const useGetWorkplaceCustomization = (customer_id: string) => {
  const isManagerExperienceMilestone2Enabled: boolean = useFeatureFlag(
    FLAGS.MANAGER_EXPERIENCE_MILESTONE_2,
  );

  const {
    data: workplaceCustomizationData,
    loading: isWorkplaceCustomizationLoading,
    error: hasWorkplaceCustomizationError,
  } = useQuery(getWorkplaceCustomization, {
    variables: { customer_id },
    skip: !isManagerExperienceMilestone2Enabled, //TODO: Temp and will be removed as part of https://springhealth.atlassian.net/browse/MXE-784
  });

  return {
    workplaceCustomizationData,
    isWorkplaceCustomizationLoading,
    hasWorkplaceCustomizationError,
    isManagerExperienceMilestone2Enabled,
  };
};
