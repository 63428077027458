export const TRACKING_CONSTANTS = {
  familyPageView: "Family Care Navigation Page",
  pageView: "Care Navigation Page",
  scheduleClick: "Care Navigator Schedule Card Clicked",
  browseClick: "Browse our FAQ Card",
  onDemandClick: "Schedule a call clicked",
  viewAppointmentDetailsClick: "View Appointment Details Link",
  cnScheduleModal: "Care Navigation Schedule Modal",
  cnScheduleCalendar: "Care Navigation Schedule Calendar",
};

export const ON_DEMAND_NUMBER = "+1 (855) 305 5533";
