import {
  ActionChip,
  HStack,
  NotificationBadge,
  Text,
  VChildIcon,
  VIndividualIcon,
} from "@springcare/sh-component-library";
import { MenuButton } from "design-system/components";

export const ProfileAvatar = ({
  firstName,
  lastName,
  isMobile,
  isAT2Member,
  isAMinor,
  links,
}) => {
  const NameText = () => {
    let nameText = "";
    if (!isMobile && !isAT2Member) {
      nameText = firstName;
    } else if (firstName && lastName) {
      nameText =
        firstName.charAt(0).toUpperCase() + lastName.charAt(0).toUpperCase();
    }
    return (
      <Text as="span" size="body-medium-strong">
        {nameText}
      </Text>
    );
  };

  const MenuButtonBody = () => {
    const showNotificationBubble = links?.some(
      (link) => link.showNotificationBubble === true,
    );

    return (
      <HStack as="span" spacing={4} p={0}>
        {isAMinor ? (
          <VChildIcon aria-label={firstName} data-testid="v-child-icon" />
        ) : (
          <VIndividualIcon
            aria-label={firstName}
            data-testid="v-individual-adult-icon"
          />
        )}
        <NameText />
        {showNotificationBubble && (
          <NotificationBadge
            type="indicator"
            colorScheme="negative"
            variant="high-emphasis"
            data-testid="notification-badge"
          />
        )}
      </HStack>
    );
  };

  return (
    <MenuButton
      data-cy="member-right-dropdown"
      as={ActionChip}
      aria-label={
        isMobile || isAT2Member ? firstName + " " + lastName : firstName
      }
      fontWeight={600}
      _focusVisible={{
        boxShadow: "focus-visible",
      }}
      _focus={{}}
    >
      <MenuButtonBody />
    </MenuButton>
  );
};
