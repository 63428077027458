import { useToast } from "@chakra-ui/react";
import { SHNotification } from "design-system/index";
import { isEmpty } from "lodash";
import { useRouter } from "next/router";
import { useCallback, useEffect } from "react";
import routes from "routes";
import { ageValidation } from "schemas/hookFormSchema";
import { useRegisterContext } from "../context/RegisterContext";
import { trackButtonClicked } from "components/templates/RegisterPage/analytics";

export const useRegister = ({ authUser }) => {
  const router = useRouter();
  const toast = useToast();
  const {
    setUser,
    setMember,
    accountCreation,
    setCurrentView,
    notification,
    setNotification,
    setSignUpSource,
    setRegistrationStrategy,
    currentView,
    ssoData,
    setSsoData,
    STEPS,
    signupSource,
    REGISTRATION_STRATEGIES,
    potentialCL,
    setCoveredLife,
    setIsDependentObj,
    setPotentialCL,
    setSsoAttributesTransformed,
    SIGNUP_SOURCE,
    user,
  } = useRegisterContext();

  // checks for the presence of the "inviteToken" key in the router.query object
  const isWarmSignup = !!(!isEmpty(router.query) && router.query.invite_token);

  const doGoBack = (currentView) => {
    trackButtonClicked("Back", currentView, "register001", "Header");
    router.push(routes.Register.to, routes.Register.as);
  };

  const doRouteUser = useCallback(
    (authUser) => {
      const hasAddress =
        authUser?.member?.postal_address?.street_address_1 &&
        authUser?.member?.postal_address?.country;
      const hasVerifiedEmail = authUser?.is_confirmed;
      const hasPrevisit = authUser?.member?.previsit?.id;
      const managedDependents = authUser?.member?.managed_dependents ?? [];
      const adult = ageValidation(
        authUser?.date_of_birth?.split("/"),
        "birthdate",
        true,
        18,
      );

      if (!hasPrevisit && !adult && !hasAddress) {
        setCurrentView(STEPS.EMERGENCY_CONTACT);
      } else if (!hasAddress) {
        setCurrentView(STEPS.ACCOUNT_CREATED_CONTACT_INFO);
      } else if (hasAddress && hasVerifiedEmail) {
        if (managedDependents.length === 0) {
          router.push(routes.MemberHome.to, routes.MemberHome.as);
        } else {
          router.push(routes.ChooseUser.to, routes.ChooseUser.as);
        }
      } else if (hasAddress && !hasVerifiedEmail) {
        localStorage.setItem("fromSignIn", "true");
        router.push(
          routes.CodeConfirmationEmail.to,
          routes.CodeConfirmationEmail.as +
            "?email=" +
            encodeURIComponent(authUser?.email),
        );
      }
    },
    [STEPS, authUser, setCurrentView],
  );

  useEffect(() => {
    if (notification?.text && notification?.status) {
      toast({
        duration: null,
        containerStyle: {
          maxWidth: "100%",
          width: "80%",
        },
        // eslint-disable-next-line react/display-name
        render: () => (
          <SHNotification
            notification={notification}
            setNotification={setNotification}
          />
        ),
      });
    }
  }, [notification]);

  useEffect(() => {
    // happens in CREATE_ACCOUNT || ACCOUNT_CREATED_CONTACT_INFO
    if (accountCreation?.msg && accountCreation?.status) {
      setNotification({
        text: accountCreation.msg,
        status: accountCreation.status,
      });
    }
    // accountCreation.view to setCurrentView
    if (accountCreation?.view) {
      setCurrentView(accountCreation.view);
    }
    // accountCreation.route to change route
    if (accountCreation?.route) {
      router.push(accountCreation.route);
    }
  }, [accountCreation]);

  useEffect(() => {
    setSsoData({
      provider: router.query?.provider || null,
      external_user_id: router.query?.external_user_id || null,
      relay_token: router.query?.relay_token || null,
      auth_message: router.query?.auth_message || null,
    });
  }, []);

  return {
    authUser,
    accountCreation,
    currentView,
    doGoBack,
    doRouteUser,
    isWarmSignup,
    notification,
    REGISTRATION_STRATEGIES,
    setCurrentView,
    setMember,
    setNotification,
    setRegistrationStrategy,
    setSignUpSource,
    setSsoData,
    setUser,
    signupSource,
    ssoData,
    STEPS,
    potentialCL,
    setCoveredLife,
    setIsDependentObj,
    setPotentialCL,
    setSsoAttributesTransformed,
    SIGNUP_SOURCE,
    user,
  };
};
