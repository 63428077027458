//

import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { compose } from "redux";
import { graphql } from "@apollo/client/react/hoc";
import Router from "next/router";
import classnames from "classnames";
import { withTranslation } from "react-i18next";
import { YourAssessmentResults } from "components";
import { Box } from "@springcare/sh-component-library";
import {
  Card,
  Floaty,
  Subtitle,
  Grid,
  Col,
  Section,
  Icon,
  Breakpoint,
} from "@spring/smeargle";
import Meowth from "@spring/meowth";

import styles from "./styles.module.scss";

import routes from "routes";
import { getMemberGoals } from "operations/queries/member";
import { track } from "utils/mixpanel";
import { clickableDivProps } from "lib/accessibility_helpers";
import { ProgressTrackingGraph } from "components/molecules";
import { latestInitialAssessment } from "operations/selectors/member";
import { MobileDropdownMenu } from "./MobileDropdownMenu";

const ProgressTracking = (props) => {
  const memberGoals = props.memberGoals?.user?.member?.tracked_goals;

  const [activeGoalId, setActiveGoalId] = useState(null);
  const [goals, setGoals] = useState(null);
  const [questionnaires, setQuestionnaires] = useState(null);
  const [selectedAssessment, setSelectedAssessment] = useState(null);

  const { migratedAssessment, t } = props;

  useEffect(() => {
    if (migratedAssessment?.assessments?.data[0]) {
      setSelectedAssessment(migratedAssessment?.assessments?.data[0]);
    }
  }, [migratedAssessment?.assessments]);

  useEffect(() => {
    if (props.memberGoals?.user?.member?.tracked_goals) {
      setGoals(props.memberGoals?.user?.member?.tracked_goals);
    }
  }, [props.data]);

  useEffect(() => {
    setQuestionnaires(migratedAssessment?.assessments?.data[0]?.questionnaires);
  }, [migratedAssessment?.assessments?.data[0]?.questionnaires]);

  useEffect(() => {
    setActiveGoalId(props.memberGoals?.user?.member?.tracked_goals[0]?.id);
  }, [props.memberGoals?.user?.member?.tracked_goals[0]?.id]);

  function handleClick(data) {
    setActiveGoalId(data.id);
  }

  function goalCols() {
    return memberGoals.map((memberGoal) => {
      const memberGoalId = memberGoal.id;
      const active = memberGoalId === activeGoalId;

      return (
        <Col key={memberGoalId} lg={3} md={4} sm={6}>
          <div
            onClick={(e) => {
              e.preventDefault();
              handleClick(memberGoal);
            }}
            {...clickableDivProps({
              onClick: (e) => {
                e.preventDefault();
                handleClick(memberGoal);
              },
              pressed: active,
            })}
          >
            <Floaty radius={3} float={active ? 2 : 0} hover={2}>
              <Card>
                <div
                  data-testid="goal-card"
                  className={classnames(styles.card, {
                    [styles.active]: active,
                  })}
                >
                  <Subtitle bold noMargin inheritColor>
                    {memberGoal.goal.description}
                  </Subtitle>
                  <div className={styles.underline}></div>
                </div>
              </Card>
            </Floaty>
          </div>
        </Col>
      );
    });
  }

  const addGoal = () => {
    track("Your Journey -- Edit Goals");
    Router.replace(routes.MemberSetGoals.to, routes.MemberSetGoals.as);
  };

  function cardView() {
    return (
      <Grid>
        {goalCols()}
        <Col md={2} sm={4} xs={6}>
          <Floaty radius={3} float={0} hover={2}>
            <Box
              borderRadius={8}
              width={150}
              border="1px solid #a9a9a9"
              height={167}
            >
              <div
                onClick={addGoal}
                role="button"
                // eslint-disable-next-line react/prop-types
                aria-label={props.t("addGoal")}
                className={classnames(styles.addGoal)}
                {...clickableDivProps({ onClick: addGoal })}
              >
                <Icon type="plus" />
              </div>
            </Box>
          </Floaty>
        </Col>
      </Grid>
    );
  }

  if (props.memberGoals.loading) {
    return null;
  }

  return (
    <div>
      <Section>
        <Breakpoint sm andUp>
          {cardView()}
        </Breakpoint>
        <Breakpoint sm andDown>
          {memberGoals?.length && (
            <MobileDropdownMenu
              memberGoals={memberGoals}
              handleClick={handleClick}
            />
          )}
        </Breakpoint>
      </Section>

      {activeGoalId && <ProgressTrackingGraph memberGoalId={activeGoalId} />}

      {selectedAssessment && questionnaires && (
        <Section size="xlg">
          <YourAssessmentResults
            inline={false}
            selectedAssessment={selectedAssessment}
            goals={goals}
            questionnaires={questionnaires}
            t={t}
          />
        </Section>
      )}
    </div>
  );
};

ProgressTracking.propTypes = {
  memberGoals: PropTypes.shape({
    loading: PropTypes.any,
  }),
};
export default compose(
  latestInitialAssessment(),
  graphql(getMemberGoals, {
    name: "memberGoals",
    options: Meowth.apolloOptionsUserId,
    skip: Meowth.apolloSkipUserId,
  }),
  withTranslation("a11y"),
  withTranslation("assessmentResults"),
)(ProgressTracking);
