import { createIcon } from "@springcare/sh-component-library";

export const Hug = createIcon({
  displayName: "Hug",
  viewBox: "0 0 24 22",
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.03829 5.62432C7.01282 5.41979 6.99969 5.21143 6.99969 5C6.99969 2.23858 9.23827 0 11.9997 0C14.7611 0 16.9997 2.23858 16.9997 5C16.9997 5.21143 16.9866 5.4198 16.9611 5.62433L17.8784 4.70705C19.0499 3.53547 20.9494 3.53548 22.121 4.70705L22.2926 4.87862C23.4642 6.05019 23.4642 7.94969 22.2926 9.12126L18.9997 12.4142V20.9999C18.9997 21.5522 18.552 21.9999 17.9997 21.9999C17.4474 21.9999 16.9997 21.5522 16.9997 20.9999V11.9999C16.9997 11.7347 17.1051 11.4804 17.2926 11.2928L20.8784 7.70705C21.2689 7.31652 21.2689 6.68336 20.8784 6.29283L20.7068 6.12126C20.3163 5.73074 19.6831 5.73074 19.2926 6.12126L15.7068 9.70705C15.5193 9.89458 15.2649 9.99994 14.9997 9.99994H12.0241C12.016 9.99998 12.0078 10 11.9997 10C11.9916 10 11.9834 9.99998 11.9753 9.99994H8.9997C8.73448 9.99994 8.48013 9.89458 8.29259 9.70705L4.70681 6.12126C4.31628 5.73074 3.68312 5.73074 3.29259 6.12126L3.12102 6.29284C2.73049 6.68336 2.73049 7.31652 3.12102 7.70705L6.7068 11.2928C6.89434 11.4804 6.9997 11.7347 6.9997 11.9999V20.9999C6.9997 21.5522 6.55198 21.9999 5.9997 21.9999C5.44741 21.9999 4.9997 21.5522 4.9997 20.9999V12.4142L1.7068 9.12126C0.53523 7.94969 0.535233 6.05019 1.70681 4.87862L1.87838 4.70705C3.04995 3.53548 4.94945 3.53548 6.12102 4.70705L7.03829 5.62432ZM8.99969 5C8.99969 3.34315 10.3428 2 11.9997 2C13.6565 2 14.9997 3.34315 14.9997 5C14.9997 6.65056 13.6667 7.98978 12.0186 7.99994H11.9808C10.3326 7.98978 8.99969 6.65056 8.99969 5Z"
      fill="#414379"
    />
  ),
  defaultProps: {
    "aria-label": "Hug icon",
    role: "img",
  },
});
