// TODO: https://springhealth.atlassian.net/browse/MXE-507
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { DateTime } from "luxon";
import {
  AppointmentAction,
  AppointmentKind,
  AppointmentMedium,
  modalIds,
} from "@spring/constants";
import Meowth from "@spring/meowth";
import { Modal } from "@spring/smeargle";
import { connect } from "react-redux";
import { closeModal, openModal } from "@spring/smeargle/actions";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { getStartSpan } from "utils/schedulingHelpers";
import { getAppointmentSlots } from "operations/queries/appointment";
import { getCareTeam } from "operations/queries/careProvider";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";
import { MomentsCompletionContent } from "modules/MemberDashboard/Moments/components/MomentsCompletionContent";
import { MomentsCompletionModalHeader } from "modules/MemberDashboard/Moments/components/MomentsCompletionModalHeader";
import {
  trackModalClosedABTest,
  trackBrowseLinkOnMomentsModal,
  trackScheduleTherapyLinkOnMomentsModal,
  trackModalOpenedABTestEnd,
} from "modules/MemberDashboard/Moments/components/analytics";
import {
  VCelebrationConfettiDuotoneSpot,
  Flex,
  useMediaQuery,
  Heading,
  Text,
  VStack,
} from "@springcare/sh-component-library";
import { useRouter } from "next/router";
import routes from "routes/MemberDashboardRoutes";

const MomentsCompleteExerciseModal = ({
  title,
  exerciseId,
  exerciseCategory,
  exerciseMediaType,
  closeModal,
  openModal,
  isOpen,
  isParentingMoment,
  onScheduleModalOpen,
}) => {
  const [isMobile] = useMediaQuery("(max-width:600px)");
  const { t } = useTranslation(["moments", "carePlan"]);
  const memberHasAccessToMomentsOnly =
    Meowth.getUserScopes().includes("group:moments_only");
  const userId = Meowth.getUserId();

  const [feeling, setFeeling] = useState(null);

  const [doGetCareTeam, { data: careTeam }] = useLazyQuery(getCareTeam, {
    fetchPolicy: "network-only",
    ...Meowth.apolloOptionsUserId(),
  });
  const [
    doGetUpcomingTherapyAppointmentSlots,
    { data: upcomingTherapyAppointmentSlots },
  ] = useLazyQuery(getAppointmentSlots, { fetchPolicy: "network-only" });
  const shouldShowNewInsuranceModal = useFeatureFlag(FLAGS.NEW_INSURANCE_MODAL);

  const therapist = careTeam?.user?.member?.care_team?.therapist;
  const medium =
    therapist?.supported_appointment_mediums?.[0] || AppointmentMedium.Video;

  const router = useRouter();

  useEffect(() => {
    if (feeling) {
      trackModalOpenedABTestEnd(
        exerciseId,
        title,
        exerciseCategory,
        exerciseMediaType,
      );
    }
  }, [feeling]);

  useEffect(() => {
    if (isOpen && userId && !memberHasAccessToMomentsOnly) {
      doGetCareTeam();
    }
  }, [isOpen, doGetCareTeam, memberHasAccessToMomentsOnly, userId]);

  useEffect(() => {
    if (
      isOpen &&
      userId &&
      careTeam?.user.member.care_team.therapist &&
      !memberHasAccessToMomentsOnly
    ) {
      const startTime = getStartSpan(AppointmentKind.Therapy);

      doGetUpcomingTherapyAppointmentSlots({
        variables: {
          user_ids: [userId, careTeam.user.member.care_team.therapist.user_id],
          start_span: startTime,
          end_span: DateTime.fromISO(startTime)
            .plus({ days: 29 })
            .set({ hours: 23, minutes: 59, seconds: 59, milliseconds: 0 })
            .toISO(),
          slot_increment: 1,
          kind: AppointmentKind.Therapy,
          upcoming_slots_only: true,
          upcoming_slots_count: 1,
          medium: medium,
        },
      });
    }
  }, [
    isOpen,
    doGetUpcomingTherapyAppointmentSlots,
    careTeam,
    memberHasAccessToMomentsOnly,
    userId,
    medium,
  ]);

  const therapistHasFamilySpecialty = therapist?.care_provider_tags
    .filter((tag) => tag.kind === "SPECIALTY")
    .some((specialty) => specialty.name === "Families");

  const showFamilyTherapyMessage =
    !memberHasAccessToMomentsOnly && isParentingMoment;
  const showScheduleTherapyButton =
    !memberHasAccessToMomentsOnly &&
    isParentingMoment &&
    therapist &&
    therapistHasFamilySpecialty;
  const showBrowseFamilyTherapistsLink =
    !memberHasAccessToMomentsOnly &&
    isParentingMoment &&
    (!therapist || !therapistHasFamilySpecialty);

  const handleScheduleTherapy = () => {
    trackScheduleTherapyLinkOnMomentsModal(
      exerciseId,
      title,
      exerciseCategory,
      exerciseMediaType,
    );

    if (shouldShowNewInsuranceModal) {
      onScheduleModalOpen({
        kind: AppointmentKind.Therapy,
        provider: therapist,
        buttonText: t("carePlanSteps.confirmAppointment", { ns: "carePlan" }),
        action: AppointmentAction.Create,
        telemetryProps: {
          location: "Appointment Confirmation",
        },
      });
    } else if (
      upcomingTherapyAppointmentSlots?.appointment_slots.available[0]
    ) {
      openModal(modalIds.careProviderScheduleModal, {
        ...therapist,
        kind: AppointmentKind.Therapy,
        medium: medium,
        providerRole: "Therapist",
        buttonText: t("carePlanSteps.confirmAppointment", { ns: "carePlan" }),
        action: AppointmentAction.Create,
        initialStartTime:
          upcomingTherapyAppointmentSlots.appointment_slots.available[0],
      });
    }
    closeModal(modalIds.momentsCompleteExerciseModal);
  };

  const handleCloseModal = () => {
    trackBrowseLinkOnMomentsModal(
      exerciseId,
      title,
      exerciseCategory,
      exerciseMediaType,
    );
    closeModal(modalIds.momentsCompleteExerciseModal);
  };

  return (
    <Modal
      id={modalIds.momentsCompleteExerciseModal}
      size="md"
      title={`${title} ${t("completeExerciseModal.completed")}`}
      closeRedirect={() => {
        trackModalClosedABTest(
          exerciseId,
          title,
          exerciseCategory,
          exerciseMediaType,
        );
        setFeeling(null);
        router.push(routes.MemberMoments.to, routes.MemberMoments.as, {
          shallow: true,
        });
      }}
    >
      <VStack
        spacing="v-32"
        w="100%"
        p="v-none"
        m="v-none"
        gap={isMobile ? undefined : "v-24"}
        justifyContent={isMobile ? "space-between" : undefined}
        height={isMobile ? "559px" : ""}
        mt={isMobile ? "36px" : ""}
      >
        <MomentsCompletionModalHeader feeling={feeling} exerciseTitle={title} />
        <MomentsCompletionContent
          exerciseId={exerciseId}
          exerciseTitle={title}
          exerciseCategory={exerciseCategory}
          exerciseMediaType={exerciseMediaType}
          onClose={() => closeModal(modalIds.momentsCompleteExerciseModal)}
          feeling={feeling}
          setFeeling={setFeeling}
        />
      </VStack>
    </Modal>
  );
};

MomentsCompleteExerciseModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  title: PropTypes.string,
  exerciseId: PropTypes.string,
  exerciseCategory: PropTypes.any,
  exerciseMediaType: PropTypes.any,
  isParentingMoment: PropTypes.bool,
  isOpen: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  title:
    state.modal?.[modalIds.momentsCompleteExerciseModal]?.data?.title || null,
  exerciseId:
    state.modal?.[modalIds.momentsCompleteExerciseModal]?.data?.exerciseId ||
    null,
  exerciseCategory:
    state.modal?.[modalIds.momentsCompleteExerciseModal]?.data
      ?.exerciseCategory || null,
  exerciseMediaType:
    state.modal?.[modalIds.momentsCompleteExerciseModal]?.data
      ?.exerciseMediaType || null,
  isParentingMoment:
    state.modal?.[modalIds.momentsCompleteExerciseModal]?.data
      ?.isParentingMoment || null,
  isOpen:
    state.modal?.[modalIds.momentsCompleteExerciseModal]?.data?.isOpen || null,
});

export default connect(mapStateToProps, { closeModal, openModal })(
  MomentsCompleteExerciseModal,
);
