import React, { useContext, useEffect } from "react";
import { useTranslation, Trans } from "react-i18next";
import { connect } from "react-redux";
import { QRCodeSVG } from "qrcode.react";
import { addNotification } from "@spring/smeargle/actions";
import { Reader } from "@spring/smeargle";
import {
  EnableMfaHeading,
  EnableMfaStepCard,
  VerifyPinForm,
  MfaContext,
} from "components";

import { Link, Text } from "@springcare/sh-component-library";

import { useMfaSetup } from "hooks";

const stepOneDescription = () => (
  <Trans
    i18nKey="multiFactorAuthentication.byAppStepOneSubtitle"
    ns="limitedLangSettings"
  >
    Download and install&nbsp;
    <Link
      href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
      color="primary.base"
      textStyle="body1"
      fontWeight={700}
    >
      Google Authenticator
    </Link>
    &nbsp; or&nbsp;
    <Link
      href="https://www.microsoft.com/en-us/security/mobile-authenticator-app"
      color="primary.base"
      textStyle="body1"
      fontWeight={700}
    >
      Microsoft Authenticator
    </Link>
    &nbsp; app.
  </Trans>
);

const stepTwoDescription = () => (
  <Trans
    i18nKey="multiFactorAuthentication.byAppStepTwoSubtitle"
    ns="limitedLangSettings"
  >
    <Text>Open the authentication app.</Text>
    <Text>Tap "+" icon on the top-right corner.</Text>
    <Text>Scan the barcode below using your phone's camera.</Text>
  </Trans>
);

const MfaByApp = (props) => {
  const { t } = useTranslation("limitedLangSettings", {
    keyPrefix: "multiFactorAuthentication",
  });
  const { setPage, routeToBackupCodes } = useContext(MfaContext);
  const { otpAuthURI, registerMFADevice, setBackupCodesToStorage } =
    useMfaSetup();

  useEffect(() => {
    generateOTPAuthURI();
  }, []);

  const generateOTPAuthURI = async () => {
    try {
      await registerMFADevice();
    } catch (_err) {
      // TODO: Log the error to a service
      const errorMessage = t("errorRegisterMfaDevice");
      props.addNotification(errorMessage, "error");
    }
  };

  const onSubmitVerificationCode = async (values) => {
    try {
      await setBackupCodesToStorage(values);
      routeToBackupCodes();
    } catch (e) {
      props.addNotification(e.message, "error");
    }
  };

  return (
    <>
      <Reader>
        <EnableMfaHeading onClick={() => setPage("options")} mfaOption="app" />
        <EnableMfaStepCard
          stepNumber={1}
          stepTitle={t("byAppStepOneTitle")}
          stepDescription={stepOneDescription()}
          mb={24}
        />
        <EnableMfaStepCard
          stepNumber={2}
          stepTitle={t("byAppStepTwoTitle")}
          stepDescription={stepTwoDescription()}
          mb={24}
          childrenFlexDirection="row"
        >
          {otpAuthURI && <QRCodeSVG value={otpAuthURI} />}
        </EnableMfaStepCard>
        <EnableMfaStepCard
          stepNumber={3}
          stepTitle="Enter verification code"
          stepDescription="Once the barcode above is scanned, enter the 6-digit verification code generated by the app."
        >
          <VerifyPinForm
            onSubmit={onSubmitVerificationCode}
            formLabel={t("submitPinLabel")}
            formSubmit={t("submitPinButton")}
            pinInputWidth={"44px"}
            pinInputHeight={{ base: "43px", md: "48px" }}
          />
        </EnableMfaStepCard>
      </Reader>
    </>
  );
};

export default connect(null, { addNotification })(MfaByApp);
