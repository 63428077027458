import { useState, FC } from "react";
import {
  Box,
  Center,
  Image,
  Text,
  VStack,
  VVideoChatIcon,
  HStack,
  useDisclosure,
  VPlayFilledIcon,
} from "@springcare/sh-component-library";
import { useTranslation } from "react-i18next";
import { toTitleCase } from "utils/mixpanel";
import {
  VideoMomentModalOverlay,
  ExitMomentModal,
} from "modules/MemberDashboard/Moments/components";
import { Exercise } from "modules/MemberDashboard/Moments/types/exerciseType";
import { trackVideoMomentExerciseClicked } from "modules/MemberDashboard/Moments/components/analytics";
import { handleRedictToMoments } from "utils/routes";

type VideoMomentCardProps = {
  exercise: Exercise;
  locationForTracking?: string;
};

export const VideoMomentCard: FC<VideoMomentCardProps> = ({
  exercise,
  locationForTracking,
}) => {
  const { t } = useTranslation(["moments", "a11y"]);
  const {
    title = "",
    duration = "",
    images = [],
    activity_type = "",
  } = exercise ?? {};
  const image_url = images[0]?.url;
  const [isHovered, setisHovered] = useState(false);
  const {
    isOpen: isVideoModalOpen,
    onOpen: onOpenVideoModal,
    onClose: onCloseVideoModal,
  } = useDisclosure();
  const {
    isOpen: isExitModalOpen,
    onOpen: onOpenExitModal,
    onClose: onCloseExitModal,
  } = useDisclosure();

  const handleOnClick = () => {
    trackVideoMomentExerciseClicked(
      exercise.id,
      exercise.title,
      exercise.categories?.map((item) => toTitleCase(item.category)).join(", "),
      exercise.content_formats?.join(", "),
      locationForTracking,
    );

    // Updates the URL with the exercise id
    const newUrl = `${window.location.origin}${window.location.pathname}?id=${exercise.id}`;
    window.history.pushState({ path: newUrl }, "", newUrl);

    onOpenVideoModal();
  };

  const handleMouseEnter = () => setisHovered(true);
  const handleMouseLeave = () => setisHovered(false);

  const handleVideoClose = () => {
    onOpenExitModal();
  };

  const handleExitMoment = () => {
    onCloseExitModal();
    onCloseVideoModal();

    // Restores the URL to its original state
    handleRedictToMoments();
  };

  return (
    <>
      <Box
        maxH="240px"
        borderWidth="1px"
        borderRadius="lg"
        overflow="hidden"
        position="relative"
        background="platform.light"
        onClick={handleOnClick}
        cursor="pointer"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        role="button"
        aria-label={title}
        w="100%"
        tabIndex={0}
        onKeyDown={(e) => {
          e.code === "Enter" && handleOnClick();
        }}
      >
        <Box>
          <Image
            src={image_url}
            alt={title || t("videoAltText")}
            fallbackSrc="https://d1pwskn5bvutk0.cloudfront.net/lantern-alpha/images/CreatingCalm/CreatingCalmSit_full.svg"
            width="100%"
            objectFit="cover"
            height="173px"
          />
          {isHovered && (
            <Center
              position="absolute"
              top="0"
              left="0"
              right="0"
              bottom="0"
              maxHeight="calc(240px - 67px)"
              bg="background-overlay"
              transition="background 0.3s ease"
            >
              <VPlayFilledIcon
                width={24}
                height={24}
                color="white"
                aria-hidden="true"
              />
            </Center>
          )}
        </Box>
        <HStack justifyContent="space-between" px="v-16" py="v-8">
          <VStack align="stretch" spacing={0}>
            <Text size="body-medium-strong" noOfLines={1}>
              {title}
            </Text>
            <Text mt={0} noOfLines={1}>
              {t("activityTypes." + activity_type, { ns: "moments" })}
              {" • "}
              {`${duration} ${t("startExerciseModal.min")}`}
            </Text>
          </VStack>
          <HStack>
            <VVideoChatIcon width={24} height={24} aria-hidden="true" />
          </HStack>
        </HStack>
      </Box>
      <VideoMomentModalOverlay
        isOpen={isVideoModalOpen}
        onClose={handleVideoClose}
        exercise={exercise}
        onOpenExitModal={onOpenExitModal}
        onCloseVideoModal={onCloseVideoModal}
      />
      <ExitMomentModal
        isOpen={isExitModalOpen}
        onClose={onCloseExitModal}
        onCloseMomentModal={handleExitMoment}
        exercise={exercise}
      />
    </>
  );
};
