import React from "react";
import PropTypes from "prop-types";
import { BackgroundCurve } from "@spring/smeargle";
import { getOr } from "lodash/fp";
import { Trans, useTranslation } from "react-i18next";
import { isMinor } from "utils/memberHelpers";
import { Heading } from "design-system/components";
import { Text } from "@springcare/sh-component-library";
import { Link } from "components";
import memberDashboardRoutes from "routes/MemberDashboardRoutes";
import { useSelector } from "react-redux";

const toProviderCopyFromType = (type) => {
  switch (type) {
    case "medication_manager":
      return "Medication Manager";

    case "therapist":
      return "Therapy";

    default:
      return "Providers";
  }
};

const BrowseHeader = ({ memberInfo, providerType }) => {
  const { showGlobalExperience } = useSelector((state) => state.global);
  const { t } = useTranslation("careProvider");

  const Title = () => {
    const first_name = getOr("", "first_name", memberInfo);
    const country_display_name = getOr(
      "",
      "member.postal_address.country_display_name",
      memberInfo,
    );
    const isAMinor = isMinor(getOr({}, "member", memberInfo));

    if (providerType === "medication_manager") {
      return t("browseHeader.medicationManager");
    }

    if (isAMinor)
      return t("browseHeader.scheduleDependent", {
        providerType: toProviderCopyFromType(providerType),
        first_name: first_name,
      });
    if (showGlobalExperience)
      return t("browseHeader.scheduleGlobal", {
        memberCountry: country_display_name,
      });

    return t("browseHeader.schedule");
  };

  const GlobalSubtitle = () => {
    const accountSettingsRoute = memberDashboardRoutes.MemberSettings.as;

    return (
      <Text fontSize="xl" m="24px 0">
        {
          <Trans
            ns={"careProvider"}
            i18nKey={"browseHeader.subtitleGlobal"}
            components={[<Link key={"0"} to={accountSettingsRoute} />]}
          />
        }
      </Text>
    );
  };

  return (
    <>
      <Heading as="h1" fontSize={48} fontWeight={300} color="#182023">
        {<Title />}
      </Heading>

      {showGlobalExperience && <GlobalSubtitle />}

      <BackgroundCurve marginTop="-500px" pageRoute="BrowseProviders" />
    </>
  );
};

BrowseHeader.propTypes = {
  memberInfo: PropTypes.any,
  providerType: PropTypes.oneOf(["medication_manager", "therapist", null]),
  validSchedulingAccess: PropTypes.bool,
};

export default BrowseHeader;
