import { createIcon } from "@springcare/sh-component-library";

export const Home = createIcon({
  displayName: "Home",
  viewBox: "0 0 24 24",
  path: (
    <path
      d="M21 10.8L12 4L4.63636 9.56364M3 10.8L4.63636 9.56364M4.63636 9.56364V20H19.3636V5.6"
      stroke="#182023"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  ),
  defaultProps: {
    "aria-label": "Home icon",
    role: "img",
  },
});
