import { createIcon } from "@springcare/sh-component-library";

const RightArrowIcon = createIcon({
  displayName: "RightArrow",
  viewBox: "0 0 24 24",
  // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
  path: (
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.2929 4.70711C9.90237 4.31658 9.90237 3.68342 10.2929 3.29289C10.6834 2.90237 11.3166 2.90237 11.7071 3.29289L19.7071 11.2929C19.8946 11.4804 20 11.7348 20 12C20 12.2652 19.8946 12.5196 19.7071 12.7071L11.7071 20.7071C11.3166 21.0976 10.6834 21.0976 10.2929 20.7071C9.90237 20.3166 9.90237 19.6834 10.2929 19.2929L16.5858 13L4 13C3.44772 13 3 12.5523 3 12C3 11.4477 3.44772 11 4 11L16.5858 11L10.2929 4.70711Z"
    />
  ),
  defaultProps: {
    "aria-label": "Right arrow icon",
    role: "img",
    "aria-hidden": "true",
  },
});

export const RightArrow = () => (
  <span data-icon={"right"}>
    <RightArrowIcon />
  </span>
);
