import { createIcon } from "@springcare/sh-component-library";

export const Eye = createIcon({
  displayName: "Eye",
  viewBox: "0 0 24 24",
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 0C12.5523 0 13 0.447715 13 1V3.03508C14.1716 3.11756 15.3017 3.34379 16.3665 3.69532L17.634 1.5C17.9101 1.02171 18.5217 0.857833 19 1.13398C19.4783 1.41012 19.6422 2.02171 19.366 2.5L18.2286 4.47014C20.8715 5.81472 22.9307 7.99487 23.9351 10.6457C24.0216 10.874 24.0216 11.126 23.9351 11.3543C22.2294 15.8557 17.4822 19 12 19C6.51781 19 1.77057 15.8557 0.0648829 11.3543C-0.0216273 11.126 -0.0216277 10.874 0.0648821 10.6457C1.11822 7.86584 3.33151 5.60357 6.16152 4.27976L5.13397 2.5C4.85783 2.02171 5.02171 1.41012 5.5 1.13397C5.97829 0.857832 6.58988 1.02171 6.86602 1.5L8.05716 3.56311C8.99642 3.28674 9.98256 3.10671 11 3.03508V1C11 0.447715 11.4477 0 12 0ZM2.07871 11C3.57168 14.4567 7.40593 17 12 17C16.5941 17 20.4283 14.4567 21.9213 11C20.4283 7.54333 16.5941 5 12 5C7.40593 5 3.57168 7.54334 2.07871 11ZM15 11C15 12.6569 13.6569 14 12 14C10.3431 14 9 12.6569 9 11C9 9.34315 10.3431 8 12 8C13.6569 8 15 9.34315 15 11Z"
      fill="currentColor"
    />
  ),
  defaultProps: {
    "aria-label": "Eye icon",
    role: "img",
  },
});
