import { ReactElement, useEffect } from "react";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";
import experiences from "./experiences";
import { TRACK_EVENT } from "utils/mixpanel";
import SUDSignalDrawerMobile from "components/templates/HomePage/components/SUDSignalSection/SUDSignalDrawerMobile";
import {
  useDisclosure,
  useMediaQuery,
  useTheme,
  VSubstanceUseDisorderDuotoneSpot,
} from "@springcare/sh-component-library";
import AccordionWithBullets from "./AccordionWithBullets/AccordionWithBullets";
import { useSelector } from "react-redux";

interface GlobalExperienceType {
  global: {
    showGlobalExperience: boolean;
  };
}

interface SUDSignalSectionProps {
  sudSupported: boolean;
  openSpecializedCNScheduleModal: () => void;
  setIsFromSUDSignalExperience: (isFromSUDSignalExperience: boolean) => void;
}

const SUDSignalSection = ({
  sudSupported,
  openSpecializedCNScheduleModal,
  setIsFromSUDSignalExperience,
}: SUDSignalSectionProps): ReactElement => {
  const sudSignalExperimentValue: string = useFeatureFlag(
    FLAGS.SUD_SIGNAL_EXPERIMENT,
  );
  const { showGlobalExperience } = useSelector(
    (state: GlobalExperienceType) => state.global,
  );

  const shouldShowSUDSignal =
    sudSignalExperimentValue !== "off" && sudSupported && !showGlobalExperience;

  const {
    isOpen: isSUDSignalModalOpen,
    onOpen: openSUDSignalModal,
    onClose: closeSUDSignalModal,
  } = useDisclosure();

  const { breakpoints } = useTheme();
  const [isMobile] = useMediaQuery(`(max-width: ${breakpoints.sm})`);

  useEffect(() => {
    if (shouldShowSUDSignal) {
      TRACK_EVENT.COMPONENT_VIEWED(
        window.location.pathname,
        "SUD Signal Section",
        {
          experience_type: sudSignalExperimentValue,
        },
      );
    }
  }, [shouldShowSUDSignal]);

  if (!shouldShowSUDSignal) {
    return <></>;
  }

  const experience = experiences[sudSignalExperimentValue];

  if (!experience) {
    return <></>;
  }

  const onScheduleCTAClick = () => {
    if (isMobile) {
      closeSUDSignalModal();
    }

    setIsFromSUDSignalExperience(true);
    openSpecializedCNScheduleModal();
  };

  return (
    <>
      <AccordionWithBullets
        LeftIcon={VSubstanceUseDisorderDuotoneSpot}
        title={experience.title}
        subtitle={experience.subtitle}
        bullets={experience.bullets}
        endText={experience.endText}
        ctaText={experience.ctaText}
        onAccordionAction={() => {
          if (isMobile) {
            return openSUDSignalModal();
          }

          TRACK_EVENT.BUTTON_CLICKED(
            window.location.pathname,
            "SUD Signal Section see more",
            {
              experience_type: sudSignalExperimentValue,
            },
          );
        }}
        onCTAClick={onScheduleCTAClick}
      />
      <SUDSignalDrawerMobile
        isOpen={isSUDSignalModalOpen}
        onClose={closeSUDSignalModal}
        onScheduleCTAClick={onScheduleCTAClick}
      />
    </>
  );
};

export default SUDSignalSection;
