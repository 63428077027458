import { Box } from "@springcare/sh-component-library";
import { BaseLineChart, ScreenReaderOnly } from "modules/shared";
import { useTranslation } from "react-i18next";
import { useTheme } from "@springcare/sh-component-library";

export const GoalsGraph = ({ memberGoalProgress }) => {
  const { t } = useTranslation("journey");
  const series = memberGoalProgress?.data?.series;
  const questionnaires = memberGoalProgress?.goal?.questionnaires;
  const goalDescription = memberGoalProgress?.goal?.description;
  const baseTheme = useTheme();

  if (!series) {
    return null;
  }

  // Nivo draws the graph in reverse order, so we need to reverse the series to ensure correct taborder
  const lineData =
    series.length === 0
      ? [{ y: 0, x: 0 }]
      : series.map((d, index) => ({ y: d.value, x: index + 1 })).reverse();

  const nivoData = [
    {
      id: "journey",
      data: lineData,
    },
  ];
  let maxY = 40;
  if (questionnaires.includes("GAD7") || questionnaires.includes("GAD2")) {
    maxY = 21;
  }
  if (questionnaires.includes("PHQ9") || questionnaires.includes("PHQ2")) {
    maxY = 27;
  }
  if (questionnaires.includes("AUDIT") || questionnaires.includes("AUDITC")) {
    maxY = 40;
  }

  return (
    <Box height={272} width={"100%"}>
      <ScreenReaderOnly>
        {t("a11y:progressGraphValues", {
          values: series.map((d) => d.value).join(", "),
        })}
      </ScreenReaderOnly>
      <BaseLineChart
        theme={{
          axis: {
            ticks: {
              text: {
                fill: baseTheme.semanticTokens.colors["content-secondary"]
                  .default,
                fontSize: baseTheme.textStyles["body-small-strong"].fontSize,
              },
            },
          },
          grid: {
            line: {
              stroke:
                baseTheme.semanticTokens.colors["border-interactive"].default,
              strokeWidth: 1,
            },
          },
        }}
        ariaLabel={t("a11y:progressGraphLabel", { goal: goalDescription })}
        ariaDescribedby="your-journey-leadin"
        curve="linear"
        data={nivoData}
        xScale={{
          type: "linear",
          min: "auto",
          max: Math.max(series.length, 9),
        }}
        yScale={{
          type: "linear",
          min: 0,
          max: maxY,
        }}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legendOffset: 48,
        }}
        axisLeft={{
          tickSize: 0,
          tickPadding: 5,
          tickRotation: 0,
          legendOffset: -48,
          legendPosition: "middle",
        }}
        lineWidth={3}
        pointSize={6}
        pointBorderWidth={5}
        pointBorderColor={{ from: "serieColor" }}
        enableGridX={false}
        isInteractive={false}
      />
    </Box>
  );
};
