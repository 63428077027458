import { gql } from "@apollo/client";

const sessionCheckIn = gql`
  query sessionCheckIn($appointmentId: ID!, $participantId: ID) {
    session_check_in(
      appointmentId: $appointmentId
      participantId: $participantId
    ) {
      jwt
      livekit_url
      member_name
      provider_name
      participant_name
      participant_type
      virtual_session_type
      sdk_client_id
      zoom_meeting_number
      zoom_meeting_password
      start_at
      join_window_start
      join_window_end
    }
  }
`;

export default sessionCheckIn;
