import { combineReducers } from "redux";
import {
  form,
  modal,
  flyout,
  notifications,
  table,
} from "@spring/smeargle/reducers";
import { rootReducer as growlithe } from "@spring/growlithe";

import app from "./app";
import assessment from "./assessment";
import auth from "./auth";
import memberDashboard from "./memberDashboard";
import signUp from "./signUp";
import global from "./global";
import { logoutState as authLogoutState } from "./auth/auth";

import { resetSession, track } from "utils/mixpanel";
import { LOGOUT, AUTH_VERIFIED_TOKEN } from "actions/auth/types";
import { zendeskLogout } from "utils/zendesk";

const combined = combineReducers({
  app,
  assessment,
  auth,
  form,
  flyout,
  growlithe,
  memberDashboard,
  modal,
  notifications,
  signUp,
  table,
  global,
});

let actions = [];

const rootReducer = (state, action) => {
  const loggingOut = action.type === LOGOUT;
  const sessionExpired = action.type === AUTH_VERIFIED_TOKEN && !action.valid;
  const unauthenticated = loggingOut || sessionExpired;

  if (loggingOut) {
    track("Auth -- Logout");
  }

  // Make sure integrations are reset or deactivated when session expires or logout happens
  if (unauthenticated) {
    state = { auth: authLogoutState };
    zendeskLogout();
    resetSession();
    return combined(state, action);
  }

  if (actions.length > 100) {
    actions.shift();
  }

  actions.push(action);

  return combined(state, action);
};

const getActions = () => actions;

export { getActions };
export default rootReducer;
