import { createIcon } from "@springcare/sh-component-library";

export const Flag = createIcon({
  displayName: "FlagV1.1",
  viewBox: "0 0 24 24",
  path: (
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 0C11.4477 0 11 0.46718 11 1.04348V9.03638L1.20736 22.3203C0.870631 22.777 0.952524 23.4322 1.39028 23.7836C1.82803 24.1349 2.45588 24.0495 2.79261 23.5927L6.33854 18.7826H7.63794L10.375 21.1626L13.3401 18.7826H17.6614C17.6614 18.7826 20.4744 22.5984 21.2074 23.5927C21.5441 24.0495 22.1719 24.1349 22.6097 23.7836C23.0475 23.4322 23.1293 22.777 22.7926 22.3203L13 9.03637V6.26086H18C18.3466 6.26086 18.6684 6.0736 18.8506 5.76597C19.0328 5.45833 19.0494 5.07419 18.8944 4.75072L18.118 3.13043L18.8944 1.51013C19.0494 1.18667 19.0328 0.802522 18.8506 0.494888C18.6684 0.187254 18.3466 0 18 0H12ZM16.382 4.1739H13V2.08695H16.382L15.882 3.13043L16.382 4.1739ZM12 11.1027L16.123 16.6956H12.6599L10.425 18.4895L8.36204 16.6956H7.87701L12 11.1027Z"
      fill="#182023"
    />
  ),
  defaultProps: {
    "aria-label": "Flag icon",
    role: "img",
    color: "tertiary.700",
  },
});
