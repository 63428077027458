/* eslint-disable react/jsx-key */
import { createIcon } from "@springcare/sh-component-library";

export const Lock = createIcon({
  displayName: "Lock",
  viewBox: "0 0 22 22",
  path: (
    <>
      <path
        d="M12.8 13C12.8 13.7403 12.3979 14.3866 11.8 14.7324V17C11.8 17.5523 11.3523 18 10.8 18C10.2478 18 9.80005 17.5523 9.80005 17V14.7324C9.20225 14.3866 8.80005 13.7403 8.80005 13C8.80005 11.8954 9.69548 11 10.8 11C11.9046 11 12.8 11.8954 12.8 13Z"
        fill="currentColor"
      />
      <path
        d="M10.8 0C8.03863 0 5.80005 2.23858 5.80005 5V7.58152C2.86822 8.76829 0.800049 11.6426 0.800049 15V21C0.800049 21.5523 1.24776 22 1.80005 22H12.8C17.2183 22 20.8 18.4183 20.8 14V8C20.8 7.44772 20.3523 7 19.8 7H15.8V5C15.8 2.23858 13.5615 0 10.8 0ZM13.8 7H7.80005V5C7.80005 3.34315 9.14319 2 10.8 2C12.4569 2 13.8 3.34315 13.8 5V7ZM8.80005 9H18.8V14C18.8 17.3137 16.1138 20 12.8 20H2.80005V15C2.80005 11.6863 5.48634 9 8.80005 9Z"
        fill="currentColor"
      />
    </>
  ),
  defaultProps: {
    "aria-label": "Lock icon",
    role: "img",
    color: "tertiary.700",
  },
});
