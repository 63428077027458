import React from "react";
import {
  Badge,
  Flex,
  Text,
  VVisitsIcon,
  VNightIcon,
  VSolitudeIcon,
} from "@springcare/sh-component-library";
import { clickableDivProps } from "lib/accessibility_helpers";
import { useSeasonalityWarningConfig } from "shared/hooks/useSeasonalityWarningConfig";
import { useTranslation } from "react-i18next";

interface SlotProps {
  appt: string;
  dataId: string;
  active: any;
  dataCy?: string;
  onClick?: any;
  time: string;
  day: string;
  t: (key: string) => string;
}

const Slot: React.FC<SlotProps> = ({
  appt,
  dataId,
  active,
  dataCy,
  onClick,
  time,
  day,
}) => {
  const { t } = useTranslation("careProvider");

  const { isWeekend, isOutsideOfNormalHours, isHoliday } =
    useSeasonalityWarningConfig(appt);

  let icon;
  let text;

  if (isWeekend && !isHoliday) {
    icon = VVisitsIcon;
    text = t("scheduleModal.appointmentSlots.weekend");
  }

  if (isOutsideOfNormalHours && !isWeekend && !isHoliday) {
    icon = VNightIcon;
    text = t("scheduleModal.appointmentSlots.offHours");
  }

  if (isHoliday) {
    icon = VSolitudeIcon;
    text = t("scheduleModal.appointmentSlots.holiday");
  }

  return (
    <Flex
      data-id={dataId}
      data-cy={dataCy ? `${dataCy}-click` : "click"}
      onClick={onClick}
      aria-label={`${day} - ${time}`}
      {...clickableDivProps({
        onClick: onClick,
        pressed: active,
        disabled: false,
        expanded: false,
      })}
      direction="column"
      height="50px"
      justify="center"
      align="center"
      px="16px"
      py="4px"
      transition="border-color 0.2s linear, color 0.2s linear"
      _hover={{
        borderColor: "#068464",
        bg: "rgba(6, 132, 100, 0.08)",
        color: "#068464",
      }}
      border="1px solid #b4b9bb"
      borderRadius="8px"
      mb="8px"
      sx={
        active
          ? {
              background: "accent-subtle",
              borderColor: "border-interactive",
            }
          : {}
      }
    >
      <Flex align="center" justify="center" color="accent-base">
        <Text size="body-medium-strong">{time}</Text>
      </Flex>
      {text && icon && (
        <Flex align="center" justify="center">
          <Badge icon={icon} background="none" p="0px">
            <Text>{text}</Text>
          </Badge>
        </Flex>
      )}
    </Flex>
  );
};

export default Slot;
