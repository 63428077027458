import { FC } from "react";
import { ProviderRole } from "@spring/constants";

import {
  VMedicationIcon,
  VPersonalizedCareIcon,
  VCoachingIcon,
  VTherapyIcon,
} from "@springcare/sh-component-library";
import { SocialFactors } from "design-system/assets";

const MAP_PROVIDERS_TO_ICONS = {
  [ProviderRole.CareNavigator]: <VPersonalizedCareIcon aria-hidden="true" />,
  [ProviderRole.SpecialtyCareNavigator]: (
    <VPersonalizedCareIcon aria-hidden="true" />
  ),
  [ProviderRole.Therapist]: <VTherapyIcon aria-hidden="true" />,
  [ProviderRole.MedicationManager]: <VMedicationIcon aria-hidden="true" />,
  [ProviderRole.Coach]: <VCoachingIcon aria-hidden="true" />,
  [ProviderRole.CareAdvocate]: <VPersonalizedCareIcon aria-hidden="true" />,
  [ProviderRole.CareConsultant]: <VPersonalizedCareIcon aria-hidden="true" />,
  [ProviderRole.PeerRecoverySpecialist]: <SocialFactors width="16" />,
};

const MAP_PROVIDERS_TO_SVG = {
  [ProviderRole.CareNavigator]: VPersonalizedCareIcon,
  [ProviderRole.Therapist]: VTherapyIcon,
  [ProviderRole.MedicationManager]: VMedicationIcon,
  [ProviderRole.Coach]: VCoachingIcon,
  [ProviderRole.CareAdvocate]: VPersonalizedCareIcon,
  [ProviderRole.CareConsultant]: VPersonalizedCareIcon,
  [ProviderRole.PeerRecoverySpecialist]: SocialFactors,
};

interface VMappedProviderIconProps {
  careProviderType: keyof typeof MAP_PROVIDERS_TO_ICONS;
}

// TODO: move to Verdant component library?
export const VMappedProviderIcon: FC<VMappedProviderIconProps> = ({
  careProviderType,
}) => {
  return MAP_PROVIDERS_TO_ICONS[careProviderType];
};

// TODO: refactor so both can use the base but original VMappedProperIcon can componentize the SVG
export const VMappedProviderIconSVG = (careProviderType) => {
  return MAP_PROVIDERS_TO_SVG[careProviderType];
};
