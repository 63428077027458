import {
  Button,
  Flex,
  Heading,
  Link,
  Text,
  useDisclosure,
} from "@springcare/sh-component-library";
import { CoachingFlyout } from "components/flyouts/CoachingFlyout";
import { DependentsFlyout } from "components/flyouts/DependentsFlyout";
import { TherapyBenefitFlyout } from "components/flyouts/TherapyBenefitFlyout";
import { WorkLifeSupportFlyout } from "components/flyouts/WorkLifeSupportFlyout";
import { Call, Mobile } from "design-system/assets";
import {
  tabTypes,
  benefitsPageAnalyticsMapping,
} from "modules/MemberDashboard/Benefits/constants/coveredCareTabTypes";
import { useTranslation } from "react-i18next";
import { getDirectDialForT2, getFreePhoneForT2 } from "utils/memberHelpers";
import { TRACK_EVENT } from "utils/mixpanel";
import { getPhoneStyleForRTL } from "shared/utils/getPhoneStyleForRTL";

export const RightPanelT2 = ({ tabType, cohort }) => {
  const { t } = useTranslation([
    "limitedLangBenefits",
    "t2Benefits",
    "memberHelpCard",
  ]);
  const {
    isOpen: isFlyoutOpen,
    onOpen: onOpenFlyout,
    onClose: closeFlyout,
  } = useDisclosure();

  const directDialNumber = getDirectDialForT2(cohort);

  const freePhoneNumber = getFreePhoneForT2(cohort);

  const RenderFlyout = () => {
    switch (tabType) {
      case tabTypes.THERAPY:
        return (
          <TherapyBenefitFlyout
            isOpen={isFlyoutOpen}
            onClose={closeFlyout}
            callback={onOpenFlyout}
            ctaText={directDialNumber}
          />
        );
      case tabTypes.COACH:
        return (
          <CoachingFlyout
            isOpen={isFlyoutOpen}
            onClose={closeFlyout}
            ctaText={directDialNumber}
            callback={onOpenFlyout}
          />
        );
      case tabTypes.WORK_LIFE_SUPPORT:
        return (
          <WorkLifeSupportFlyout
            isOpen={isFlyoutOpen}
            onClose={closeFlyout}
            ctaText={directDialNumber}
            callback={onOpenFlyout}
          />
        );
      case tabTypes.DEPENDENT_ACCESS:
        return (
          <DependentsFlyout
            isOpen={isFlyoutOpen}
            onClose={closeFlyout}
            ctaText={directDialNumber}
            callback={onOpenFlyout}
          />
        );

      default:
        return null;
    }
  };

  return (
    <Flex flexDirection="column">
      <Flex flexDirection="column">
        <Heading variant="sm_v1" mb="16px">
          {t("t2Benefits:rightPanel.title")}
        </Heading>
        {tabType !== tabTypes.CRISIS && (
          <Flex flexDirection="column">
            <Flex gap="8px" mb="16px" alignItems="center" flexWrap="wrap">
              <Call color="#068262" w="18px" h="18px" />
              <Text fontWeight="600">
                {t("limitedLangBenefits:EAPBenefit.directDial")}
              </Text>
              <Text style={getPhoneStyleForRTL()}>{directDialNumber}</Text>
            </Flex>
            <Flex gap="8px" alignItems="center" flexWrap="wrap">
              <Mobile color="#068262" w="18px" h="18px" />
              <Text fontWeight="600">
                {t("limitedLangBenefits:EAPBenefit.freephone")}
              </Text>
              <Text style={getPhoneStyleForRTL()}>{freePhoneNumber}</Text>
            </Flex>
          </Flex>
        )}
        {tabType === tabTypes.CRISIS && (
          <Text>{t("t2Benefits:rightPanel.crisisSupportVerbiage")}</Text>
        )}
      </Flex>
      <Flex flexDirection="column">
        <Button
          mt="24px"
          mb="16px"
          icon={Call}
          alignSelf="stretch"
          gap="4px"
          width="100%"
          aria-label={t("memberHelpCard:generalSupportText", {
            number: directDialNumber,
          })}
          onClick={() => {
            TRACK_EVENT.BUTTON_CLICKED(
              window.location.pathname,
              `Benefits Page Direct Dial - ${benefitsPageAnalyticsMapping[tabType]}`,
            );
            window.location.href = `tel:${directDialNumber}`;
          }}
        >
          <Text style={getPhoneStyleForRTL()}>{directDialNumber}</Text>
        </Button>
        <RenderFlyout />
        {tabType !== tabTypes.CRISIS && (
          <Link
            as="button"
            alignSelf="center"
            onClick={() => {
              onOpenFlyout();
              TRACK_EVENT.LINK_CLICKED(
                window.location.pathname,
                `Benefits Page Flyout - ${benefitsPageAnalyticsMapping[tabType]}`,
              );
            }}
          >
            {t("t2Benefits:rightPanel.learnMore")}
          </Link>
        )}
      </Flex>
    </Flex>
  );
};
