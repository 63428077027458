import {
  Box,
  Flex,
  useBreakpointValue,
  VStack,
  HStack,
  Text,
  Heading,
  useMediaQuery,
  Grid,
} from "@springcare/sh-component-library";
import { LevelUpCoachingCard } from "./LevelUpCoachingCard";
import { useTranslation } from "react-i18next";
import { TRACK_EVENT } from "utils/mixpanel";
import { useEffect } from "react";

export const LevelUpCoachingSection = ({ width }) => {
  const cardTitleSize = useBreakpointValue([
    "heading-small",
    "heading-small",
    "heading-medium",
    "heading-medium",
  ]);
  const cards = [
    "healthWellness",
    "personalDevelopment",
    "professionalGrowth",
    "parenting",
  ];
  const { t } = useTranslation("homepage");
  const [isDesktop] = useMediaQuery("(min-width: 1140px)");
  const [isBetweenMobileAndTablet] = useMediaQuery(
    "(min-width: 625px) and (max-width: 800px)",
  );
  const [isMobile] = useMediaQuery("(max-width: 624px)");

  useEffect(() => {
    TRACK_EVENT.COMPONENT_VIEWED(window.location.pathname, "coaching_topic");
  }, []);

  return (
    <Flex
      justifyContent="space-between"
      width={width}
      pt={24}
      direction={isDesktop ? "row" : "column"}
    >
      <Box
        ms={24}
        width={isDesktop ? "174px" : "inherit"}
        height={isDesktop ? "128px" : "92px"}
      >
        <VStack
          align="stretch"
          spacing="4px"
          height={isDesktop ? "100%" : "92px"}
        >
          <Box>
            <HStack spacing="8px">
              <Box display="flex" flexDirection="row">
                <Text variant="bodyBold_v1" mt="6px">
                  {t("upNextSection.coachAssignment.overline")}
                </Text>
              </Box>
              <Box ps={"0px"}>
                <Box bg="primary.light" borderRadius={6}>
                  <Box ps={"12px"} pe={"12px"}>
                    <Text variant="body_v1" color="success.base">
                      {t("upNextSection.coachAssignment.startHere")}
                    </Text>
                  </Box>
                </Box>
              </Box>
            </HStack>
          </Box>
          <Box h="fit-content">
            <HStack>
              <Flex w={isDesktop ? "174px" : "100%"}>
                <Heading size={cardTitleSize} color="platform.900">
                  {t("upNextSection.coachingV2Experience.cardTitle")}
                </Heading>
              </Flex>
            </HStack>
          </Box>
          <Box>
            <Text variant="bodyBold_v1" color="platform.500">
              {t("upNextSection.coachingV2Experience.subtitle")}
            </Text>
          </Box>
        </VStack>
      </Box>
      <Box ms={isDesktop ? "48px" : "16px"}>
        <Flex direction={isDesktop ? "row" : "column"}>
          {isDesktop ? (
            <>
              {cards.map((cardTitle, index) => (
                <Box key={index} width="190px" me="24px" mb="24px">
                  <LevelUpCoachingCard
                    width="190px"
                    height="234px"
                    cardTitle={cardTitle}
                    isDesktop={isDesktop}
                    isBetweenMobileAndTablet={isBetweenMobileAndTablet}
                    isMobile={isMobile}
                  />
                </Box>
              ))}
            </>
          ) : isBetweenMobileAndTablet ? (
            <Box mt="24px">
              <Grid templateColumns="repeat(2, 1fr)">
                {cards.map((cardTitle, index) => (
                  <Box key={index} me={16} mb={16}>
                    <LevelUpCoachingCard
                      width="100%"
                      height="92px"
                      cardTitle={cardTitle}
                      isDesktop={isDesktop}
                      isBetweenMobileAndTablet={isBetweenMobileAndTablet}
                      isMobile={isMobile}
                    />
                  </Box>
                ))}
              </Grid>
            </Box>
          ) : (
            <Box mt="24px">
              <Grid templateColumns="repeat(2, 1fr)">
                {cards.map((cardTitle, index) => (
                  <Box key={index} me={16} mb={16}>
                    <LevelUpCoachingCard
                      width="100%"
                      height={isMobile ? "148px" : "92px"}
                      cardTitle={cardTitle}
                      isDesktop={isDesktop}
                      isBetweenMobileAndTablet={isBetweenMobileAndTablet}
                      isMobile={isMobile}
                    />
                  </Box>
                ))}
              </Grid>
            </Box>
          )}
        </Flex>
      </Box>
    </Flex>
  );
};
