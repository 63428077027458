import {
  Box,
  FormControl,
  FormLabel,
  Heading,
  Spinner,
  Text,
} from "@springcare/sh-component-library";
import { ConfidentialityBanner } from "components/assessments/Elements";
import { useCallback, useEffect, useState } from "react";

import { getOr } from "lodash/fp";
import { connect, useDispatch } from "react-redux";
import { compose } from "redux";

import { useMutation, useQuery } from "@apollo/client";
import Meowth from "@spring/meowth";
import { addNotification } from "@spring/smeargle/actions";
import { setField } from "actions/assessment/actions";
import { Checkbox } from "components/assessments/Elements";
import AssessmentFooter from "components/assessments/AssessmentFooter";
import { updateMemberGoals } from "operations/mutations/member";
import { getGoals } from "operations/queries/goal";
import { getMemberGoals } from "operations/queries/member";
import { useTranslation } from "react-i18next";
import { getFirstError } from "utils/apollo/errorHandler";
import {
  EVENT_TYPE,
  makeEventString,
  track,
  TRACK_EVENT,
} from "utils/mixpanel";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";
import { VTempFooter } from "design-system/components";

export const NewMemberGoalForm = (props) => {
  const { t } = useTranslation("goals");
  const [showResults, setShowResults] = useState(false);
  const MEMEBER_GOAL_FORM_KEY = "memberGoalForm";
  const dispatch = useDispatch();
  const breakpoints = {
    sm: "(min-width : 540px)",
    md: "(min-width : 720px)",
    lg: "(min-width : 960px)",
    xlg: "(min-width : 1140px)",
  };

  const showNewFooter = useFeatureFlag(FLAGS.FOOTER_REPOSITION);

  const { data: memberGoalsData, loading: memberGoalsDataLoading } = useQuery(
    getMemberGoals,
    {
      skip: props.form?.memberGoalForm,
      variables: {
        id: Meowth.getUserId(),
      },
    },
  );

  const memberGoals = getOr([], "user.member.tracked_goals", memberGoalsData);

  useEffect(() => {
    // Scroll down on mobile devices that support it
    // makes sure questions are in view on mount.
    if (typeof window !== "undefined" && window.scrollBy) {
      if (typeof window.matchMedia === "undefined") {
        // eslint-disable-next-line no-console
        console.error("This browser does not support matchMedia");
      } else {
        const isSmallScreen = !window.matchMedia(breakpoints.md).matches;
        if (isSmallScreen) {
          setTimeout(() => {
            window.scrollBy({
              top: -50,
              behavior: "smooth",
            });
          }, 100);
        }
      }
    }
  }, []);

  useEffect(() => {
    memberGoals?.forEach((data) => {
      dispatch(setField(MEMEBER_GOAL_FORM_KEY, data.goal.id, true, "boolean"));
    });
  }, [memberGoals]);

  useEffect(() => {
    if (!memberGoalsDataLoading && memberGoals.length === 0) {
      setShowResults(true);
    }
    if (props.form?.memberGoalForm?.data !== undefined) {
      setShowResults(true);
    }
  }, [props.form?.memberGoalForm?.data, memberGoalsDataLoading]);

  const { data: goalData, loading: goalsLoading } = useQuery(getGoals, {
    fetchPolicy: "cache-and-network",
  });

  const goals = getOr([], "goals.data", goalData);

  const [submitMemberGoals] = useMutation(updateMemberGoals);

  const isDisabled = () => {
    const { memberGoalForm } = props.form;
    if (memberGoalForm === undefined) {
      return true;
    }
    return !Object.values(memberGoalForm.data).some((val) => val === true);
  };

  const getGoalsCount = (): number => {
    if (props.form?.memberGoalForm === undefined) return 0;
    return Object.values(
      props.form?.memberGoalForm?.data as Record<string, boolean>,
    ).reduce((acc: number, val: boolean) => (val ? acc + 1 : acc), 0);
  };

  const handleSubmit = useCallback(() => {
    const payload = {
      variables: {
        input: {
          goal_ids: Object.keys(props.form.memberGoalForm.data).filter(
            (goal) => props.form.memberGoalForm.data[goal],
          ),
        },
      },
    };

    submitMemberGoals(payload)
      .then((res) => {
        if (!res.data.updateMemberGoals.success) {
          props.addNotification(getFirstError(res.errors), "error");
        } else {
          trackGoalsClick("Saved");
          trackSudEligibility(res.data.updateMemberGoals);
          props.advance();
        }
      })
      .catch((err) => {
        props.addNotification(getFirstError(err), "error");
      });
  }, [props, submitMemberGoals, props.advance, props.addNotification]);

  const handleSkip = useCallback(() => {
    trackGoalsClick("Skipped");
    props.advance();
  }, [props.advance]);

  const trackGoalsClick = (saved_or_skipped) => {
    const type = `Goals ${saved_or_skipped}`;
    track("Goals -- Initial Assessment Saved", {
      assessment_version: props.assessmentVersion,
      deprecated: true,
      replaced_with: makeEventString(EVENT_TYPE.BUTTON_CLICKED, {
        page: window.location.pathname,
        type: type,
      }),
    });
    TRACK_EVENT.BUTTON_CLICKED(window.location.pathname, type);
  };

  const trackSudEligibility = (res) => {
    const sudGoalsId = goals
      .filter(
        ({ goal_type }) => goal_type === "alcohol_use" || goal_type === "drugs",
      )
      .map(({ id }) => id);
    const submittedOptions = res?.member_goals?.map(({ goal }) => goal?.id);

    const isSudEligible = sudGoalsId.some((id) =>
      submittedOptions.includes(id),
    );

    if (isSudEligible) {
      TRACK_EVENT.FORM_SUBMITTED(
        window.location.pathname,
        "Members goals have SUD eligibility",
      );
    }
  };

  const getPrimaryFooterActionLabel = () => {
    if (getGoalsCount() === 0) {
      return t("memberGoalForm.setGoal");
    } else if (getGoalsCount() === 1) {
      return t("memberGoalForm.countSingleGoal", { count: getGoalsCount() });
    } else {
      return t("memberGoalForm.countGoal", { count: getGoalsCount() });
    }
  };

  if (!showResults || goalsLoading) {
    return <Spinner />;
  }

  const footerActions = [
    {
      label: getPrimaryFooterActionLabel(),
      onClick: handleSubmit,
      isDisabled: isDisabled(),
      dataCy: "initial-assessment-goals-form-submit-button",
      variant: "high-emphasis",
    },
    {
      label: t("memberGoalForm.skip"),
      onClick: handleSkip,
      dataCy: "initial-assessment-goals-form-skip-button",
      variant: "medium-emphasis",
      isDisabled: false,
    },
  ];

  const legacyFooterActions = {
    primary: footerActions[0],
    secondary: footerActions[1],
  };

  return (
    <>
      <Box
        position="relative"
        maxWidth="780px"
        overflow="hidden"
        width="100%"
        margin="32px auto 128px"
        p="8px 24px"
      >
        <main style={{ padding: 0 }}>
          <FormControl as="fieldset">
            <FormLabel as="legend">
              <Heading
                as={"p"}
                tabIndex={0}
                fontWeight={400}
                mb="8px"
                fontSize="16px"
              >
                {props.showGoalsLast
                  ? t("memberGoalForm.subheadV2")
                  : t("memberGoalForm.subhead")}
              </Heading>
              <Text
                fontSize={21}
                tabIndex={0}
                fontWeight={600}
                mt={"5px"}
                mb={"24px"}
                lineHeight="28px"
              >
                {props.showGoalsLast
                  ? t("memberGoalForm.goalsQuestionV2")
                  : t("memberGoalForm.goalsQuestion")}
              </Text>
            </FormLabel>
            <Box>
              {goals.map((g) => {
                return (
                  <Checkbox
                    label={g.description}
                    key={g.id}
                    fieldKey={g.id}
                    formKey={MEMEBER_GOAL_FORM_KEY}
                    dataCy={"initial-assessment-goals-checkbox"}
                  />
                );
              })}
            </Box>
            {!showNewFooter && <ConfidentialityBanner />}
          </FormControl>
        </main>
        {showNewFooter ? (
          <AssessmentFooter actions={footerActions} />
        ) : (
          <VTempFooter actions={legacyFooterActions} />
        )}
      </Box>
    </>
  );
};

const mapStateToProps = (state) => ({
  form: state.form,
});

export default compose(connect(mapStateToProps, { addNotification }))(
  NewMemberGoalForm,
);
