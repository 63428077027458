import { BenefitsCardSummary } from "components";
import { openModal } from "@spring/smeargle/actions";
import NextLink from "next/link";
import { Button, Link, Text } from "@springcare/sh-component-library";
import { Box, Stack } from "design-system/components";
import { compose } from "redux";
import { connect } from "react-redux";
import { useTranslation, Trans } from "react-i18next";
import { isTeen } from "utils/memberHelpers";
import { TRACK_EVENT } from "utils/mixpanel";
import routes from "routes";
import { useMemberBenefits } from "hooks/useMemberBenefits";
import CostEstimateDisclaimer, {
  CostEstimateDisclaimerTypes,
} from "components/molecules/CostEstimateDisclaimer/CostEstimateDisclaimer";
import { isPfizer } from "constants/customers";

const BenefitsSummarySection = ({ member, openModal, children: heading }) => {
  const {
    visits_covered_coaching,
    visits_covered_total,
    visits_remaining_coaching,
    visits_remaining_total,
    visits_covered_specialist,
    visits_remaining_specialist,
    cohort,
  } = member;

  const virtual_session_cost_term =
    member?.cohort?.contract_term?.virtual_session_cost_term;

  const { showCoachingPaidExperience } = useMemberBenefits(member?.id);
  const showCoachingSummaryCard = showCoachingPaidExperience;
  const customerId = cohort?.customer?.id;

  const { t } = useTranslation(["homepage"]);
  const visitsEligibilityLimitDate = new Date(
    member?.visits_eligibility_limit_date,
  ).toLocaleString("en-us", {
    month: "short",
    year: "numeric",
  });
  const isATeen = isTeen(member?.date_of_birth);

  return (
    <Box w={"100%"}>
      <Box justifyContent="space-between" display="flex">
        {heading}
        {member?.visits_eligibility_limit_date && !isPfizer(customerId) && (
          <Stack
            direction="row"
            mt="1"
            ml="5"
            minWidth="135px"
            justifyContent="flex-end"
          >
            <Text variant="body_v1">
              <Trans
                t={t}
                i18nKey="benefitsEndDate"
                values={{ visitsEligibilityLimitDate }}
                components={[
                  <Text
                    variant="bodyBold_v1"
                    as="span"
                    key="benefitsEndDate"
                  />,
                ]}
              />
            </Text>
          </Stack>
        )}
      </Box>
      <Box w="100%" mb={16}>
        <CostEstimateDisclaimer variant={CostEstimateDisclaimerTypes.CARD} />
      </Box>
      <Box w="100%" mb={16}>
        <BenefitsCardSummary
          type="therapist"
          visits_covered={visits_covered_total}
          visits_remaining={visits_remaining_total}
          visits_covered_specialist={visits_covered_specialist}
          visits_remaining_specialist={visits_remaining_specialist}
          virtual_session_cost_term={virtual_session_cost_term}
          openModal={openModal}
          inNetwork={member?.cohort?.in_network}
          internationalCohort={member?.cohort?.international}
          customerName={member?.cohort?.customer?.name}
          virtualSessionCostTerm={
            member?.cohort?.contract_term?.virtual_session_cost_term
          }
          isATeen={isATeen}
        />
      </Box>
      {showCoachingSummaryCard && (
        <Box w="100%" my={16}>
          <BenefitsCardSummary
            type="coaching"
            visits_covered={visits_covered_coaching}
            visits_remaining={visits_remaining_coaching}
            openModal={openModal}
            memberCostOfCoaching={
              member?.cohort?.contract_term?.member_cost_of_coaching
            }
          />
        </Box>
      )}
      <Box maxW={{ base: "100%" }} my={16}>
        <NextLink href="/members/benefits" passHref>
          <Button
            colorScheme="base"
            variant="low-emphasis"
            as={Link}
            size="md"
            width="100%"
            onClick={() => {
              TRACK_EVENT.LINK_CLICKED(
                window.location.pathname,
                routes.MemberBenefits.as,
                "Review Full Benefits",
                {
                  spring_doc_id: "HPR_019",
                  location: "Benefit Summary",
                },
              );
            }}
          >
            <Text size="body-medium-strong">{t("reviewFullBenefits")}</Text>
          </Button>
        </NextLink>
      </Box>
    </Box>
  );
};

export default compose(connect(null, { openModal }))(BenefitsSummarySection);
