import {
  Box,
  Modal,
  ModalContent,
  ModalBody,
  ModalOverlay,
  ModalCloseButton,
  SHStickyFooter,
  Button,
  Flex,
} from "design-system/components";

import { Heading } from "@springcare/sh-component-library";

type Props = {
  ModalImg: React.ElementType;
  title: string;
  content: React.ReactNode;
  btnCopy: string;
  ctaBtn: React.ReactNode;
  isOpen: boolean;
  dataCy: string;
  onClose: () => void;
  children?: React.ReactNode;
  modalTitle?: string;
  finalFocusRef?: React.RefObject<HTMLElement>;
  bodyDisplay?: string;
};

export const SHFullScreenModal = ({
  isOpen,
  onClose,
  title,
  dataCy,
  content,
  btnCopy,
  ctaBtn,
  ModalImg,
  bodyDisplay = "block",
}: Props) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      scrollBehavior={"inside"}
      size={"full"}
    >
      <ModalOverlay />
      <ModalContent height="min-content" borderRadius={0}>
        <Box py={32}>
          <ModalCloseButton
            w={"24"}
            h={"24"}
            me={["15", "15", "92"]}
            mt={["19", "19", "40"]}
          />
        </Box>
        <ModalBody box-shadow="none" display={bodyDisplay}>
          <Box maxW="606px" m="auto">
            <Box
              w={["329px", "460px", "560px", "606px"]}
              m="auto"
              mb="20vh"
              height={["100%", "min-content"]}
              overflowY="scroll"
            >
              <ModalImg width="80px" aria-hidden={true} />
              <Heading title={title} tabIndex={0} size="heading-medium">
                {title}
              </Heading>
              <Box tabIndex={0} mt="10px">
                {content}
              </Box>
              <Box tabIndex={0} mb="20px">
                {ctaBtn}
              </Box>
            </Box>

            <SHStickyFooter left="0">
              <Flex
                flexDirection="column"
                justifyContent="space-evenly"
                alignItems="center"
                height={150}
                mb="8"
              >
                <Button
                  w={["329px", "397px", "397px", "397px"]}
                  colorScheme="primary"
                  size="lg"
                  variant="solid"
                  onClick={onClose}
                  data-cy={dataCy}
                  tabIndex={0}
                >
                  {btnCopy}
                </Button>
              </Flex>
            </SHStickyFooter>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
