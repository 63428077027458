import React from "react";

import { Box, useTheme } from "@springcare/sh-component-library";
import type { LineProps } from "@nivo/line";
import dynamic from "next/dynamic";
import { useChartTheme } from "modules/shared/hooks/useChartTheme";
import AutoSizer from "react-virtualized-auto-sizer";

// Need to use dynamic import here because of open issue with nivo and Next.js
// https://github.com/plouc/nivo/issues/2310#issuecomment-1552663752
const Line = dynamic(() => import("@nivo/line").then((m) => m.Line), {
  ssr: false,
});

type AriaProps = {
  ariaLabelledby?: string;
  ariaLabel?: string;
  ariaDescription?: string;
  ariaDescribedby?: string;
};

/**
 * The "Base level" Line chart
 *
 * Currently the idea here is that this chart has the same exact API (props)
 * as the ResponsiveLineChart from nivo, but with defaults that developers don't
 * have to override in most cases.
 *
 * This is tricky because *many* particulars just aren't going to be the same
 * chart to chart, so for now this is mostly just colors, and base level
 * settings
 */
export const BaseLineChart = (props: LineProps & AriaProps) => {
  const chartTheme = useChartTheme();
  const shTheme = useTheme();

  const { ariaLabel, ariaLabelledby, ariaDescribedby } = props;

  return (
    <Box h="100%" position="relative">
      {/* 
    // Adding aria-* attributes to the svg element after it loads
    // asynchronously causes issues in several browser/screen reader combos.
    // As a workaround, we add the aria attributes to an adjacent element 
    // and then position it behind the svg element.
  */}
      <Box
        aria-label={ariaLabel}
        aria-labelledby={ariaLabelledby}
        aria-describedby={ariaDescribedby}
        role="img"
        position="absolute"
        zIndex={-1}
        top={0}
        left={0}
        right={0}
        bottom={0}
      />
      <Box h="100%" aria-hidden="true">
        <AutoSizer>
          {({ height, width }: { height: number; width: number }) => (
            <Line
              height={height}
              width={width}
              colors={[
                shTheme.semanticTokens.colors["primary-base"].default,
                shTheme.semanticTokens.colors["content-secondary"].default,
              ]}
              lineWidth={2}
              margin={{ top: 10, right: 10, bottom: 30, left: 25 }}
              pointSize={6}
              theme={chartTheme}
              // @ts-ignore
              useMesh={true}
              {...props}
            />
          )}
        </AutoSizer>
      </Box>
    </Box>
  );
};

export default BaseLineChart;
