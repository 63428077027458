import { useEffect, useState } from "react";
import Cookies from "universal-cookie";

const buildCookies = () => {
  const compassOriginAccessToken = `${window.location.hostname
    .replace(
      process.env.APP_ENV === "production" ? "care" : "members",
      "compass",
    )
    .replaceAll(".", "_")}_access_token`;
  const cookieExpiration = new Date();
  cookieExpiration.setFullYear(cookieExpiration.getFullYear() + 1);

  const cookies = new Cookies(null, {
    path: "/",
    domain: window.location.host.includes("localhost")
      ? undefined
      : `.${window.location.host.split(".").slice(-2).join(".")}`,
    expires: cookieExpiration,
  });

  return { compassOriginAccessToken, cookies };
};

export const useCompassAuthSync = (enabled: boolean) => {
  const [authenticatedViaCompass, setAuthenticatedViaCompass] = useState(false);
  useEffect(() => {
    if (enabled && !authenticatedViaCompass) {
      const { cookies, compassOriginAccessToken } = buildCookies();
      const compassCookieValue = cookies.get(compassOriginAccessToken);
      if (compassCookieValue !== null) {
        setAuthenticatedViaCompass(true);
        localStorage.setItem("access_token", compassCookieValue);
      }
    }
  }, [enabled]);

  return authenticatedViaCompass;
};
