import { useState, useEffect } from "react";
import {
  Button as VButton,
  IconButton,
  Text,
  Menu,
  Avatar,
  MenuButton,
  MenuList,
  MenuItem,
  Divider,
  VPlusIcon,
  VBookSessionIcon,
  Flex,
} from "@springcare/sh-component-library";
import Router from "next/router";
import { useQuery } from "@apollo/client";

import Meowth from "@spring/meowth";
import routes from "routes";

import { getCareTeam } from "operations/queries/careProvider";
import { useTranslation } from "hooks/react-i18next";
import { providerProfileClick } from "components/templates/Browse/ProviderBrowsePage/utils";
import { TRACK_EVENT } from "utils/mixpanel";

export const BookAppointmentButtonTypes = {
  DEFAULT: "default",
  ICON: "icon",
};

type Member = {
  id: string;
};

interface BookAppointmentButtonProps {
  member: Member;
  buttonType: string;
  borderRadius?: number;
}

const PlusIcon = () => {
  return (
    <Flex pr={"5px"}>
      <VPlusIcon />
    </Flex>
  );
};

// NOTE: This is rendered for 'bundled' or 'billable' members
// TODO: Render Button types based on passed props
const BookAppointmentButton = ({
  member,
  buttonType,
  borderRadius,
}: BookAppointmentButtonProps) => {
  const [careTeamList, setCareTeamList] = useState([]);
  const { t } = useTranslation("common");

  const { data: careTeamData, loading } = useQuery(getCareTeam, {
    variables: {
      id: Meowth.getUserId(),
    },
    skip: !member,
  });

  const filterCareTeamArray = () => {
    if (!careTeamData?.user?.member?.care_team) {
      return [];
    }
    return Object.entries(careTeamData.user.member.care_team)
      .filter(
        ([key, value]) =>
          key !== "id" && key !== "__typename" && value !== null,
      )
      .map(([, value]) => value);
  };

  useEffect(() => {
    if (!loading && careTeamData != null) {
      setCareTeamList(filterCareTeamArray());
    }
  }, [loading, careTeamData]);

  const handleBtnClick = () => {
    if (careTeamList.length === 0) {
      openNewCarePage();
    } else if (careTeamList.length === 1) {
      openProviderProfile(careTeamList[0].id);
    }
  };

  const openProviderProfile = (providerId: string) => {
    TRACK_EVENT.BUTTON_CLICKED(Router.asPath, "Provider profile opened", {
      location: "Book Appointment Dropdown Menu",
    });
    providerProfileClick(providerId, "")();
  };

  const openNewCarePage = () => {
    TRACK_EVENT.BUTTON_CLICKED(Router.asPath, "Find new care", {
      location: "Book Appointment Dropdown Menu",
    });
    Router.push({
      pathname: routes.MemberCareVisits.as,
      query: { findNewCare: "true" },
    });
  };

  const trackDropdownClick = () => {
    TRACK_EVENT.BUTTON_CLICKED(
      Router.asPath,
      "Book appointment dropdown opened",
      {
        location: "Book Appointment Dropdown Menu",
      },
    );
  };

  const BookButton = () => {
    return (
      <>
        {buttonType === BookAppointmentButtonTypes.DEFAULT && (
          <VButton
            onClick={handleBtnClick}
            p={"7px 8px"}
            borderRadius={borderRadius}
            //@ts-ignore
            leftIcon={<PlusIcon />}
            disabled={loading}
            _focusVisible={{
              outline: "2px solid #4b4b4b !important",
            }}
          >
            {t("navigation.bookButton.bookAppointment")}
          </VButton>
        )}
        {buttonType === BookAppointmentButtonTypes.ICON && (
          <IconButton
            onClick={handleBtnClick}
            icon={VBookSessionIcon}
            aria-label="Book Appointment Icon Button"
            disabled={loading}
            _focusVisible={{
              textColor: "accent-on-subtle",
              outline: "2px solid #4b4b4b !important",
            }}
          />
        )}
      </>
    );
  };

  const ProviderMenu = () => (
    // this is the menu that appears when there are multiple care providers
    <Menu>
      <MenuButton onClick={trackDropdownClick}>
        <BookButton />
      </MenuButton>
      <MenuList pos={"absolute"} overflow={"visible"} w={272}>
        <Text
          textStyle="body-small-strong"
          fontWeight={600}
          mb={12}
          color="content-secondary"
          ml={12}
        >
          {t("navigation.bookButton.yourCareProviders")}
        </Text>
        {careTeamList.map((provider) => (
          <MenuItem
            key={provider.id}
            onClick={() => openProviderProfile(provider.id)}
            _focusVisible={{
              outline: "2px solid #4b4b4b !important",
            }}
          >
            <Avatar
              w={24}
              mr={8}
              name={`${provider.first_name} ${provider.last_name}`}
              src={provider.avatar}
            />
            {provider.first_name} {provider.last_name}
          </MenuItem>
        ))}
        <Divider pr={"10px"} pl={"10px"} w={"100%"} />
        <MenuItem
          onClick={openNewCarePage}
          _focusVisible={{
            outline: "2px solid #4b4b4b !important",
          }}
        >
          {t("navigation.bookButton.findNewCare")}
        </MenuItem>
      </MenuList>
    </Menu>
  );

  return careTeamList.length <= 1 ? <BookButton /> : <ProviderMenu />;
};

export default BookAppointmentButton;
