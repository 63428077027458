import { useMutation } from "@apollo/client";
import { addNotification } from "@spring/smeargle/actions";
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@springcare/sh-component-library";
import { useTranslation } from "hooks/react-i18next";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getFirstError } from "utils/apollo/errorHandler";
import { createProviderRequestAvailabilityZendeskTicket } from "operations/mutations/careProvider";
import TRACKING from "modules/MemberDashboard/LocalResources/constants/tracking";
import { TRACK_EVENT } from "utils/mixpanel";
import { getModalNameForTracking, modalIds } from "@spring/constants";

const ChangePeerRecoverySpecialistModal = ({ isOpen, onClose }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation("careVisits");
  const [createZendeskTicket] = useMutation(
    createProviderRequestAvailabilityZendeskTicket,
    {
      onCompleted: () => {
        dispatch(
          addNotification(
            t(
              "careNavigatorScheduleModal.requestAdditionalAvailability.notificationMessage.success",
            ),
            "success",
          ),
        );
        onClose();
      },
      onError: (err) => dispatch(addNotification(getFirstError(err), "error")),
    },
  );
  const [reason, setReason] = useState("");
  const handleReasonInput = (e) => {
    setReason(e.target.value);
  };
  const modalType = getModalNameForTracking(
    modalIds.changePeerRecoverySpecialistModal,
  );
  const trackingProperties = {
    location: `${TRACKING.peerRecoverySpecialistFollowUp} card`,
  };

  useEffect(() => {
    if (isOpen) {
      TRACK_EVENT.MODAL_OPENED(
        window.location.pathname,
        modalType,
        trackingProperties,
      );
    }
  }, [isOpen]);

  const handleClose = () => {
    TRACK_EVENT.MODAL_CLOSED(
      window.location.pathname,
      modalType,
      trackingProperties,
    );
    onClose();
  };

  const handleSubmit = () => {
    TRACK_EVENT.BUTTON_CLICKED(
      window.location.pathname,
      "Change Peer Recovery Specialist Submit Request",
      trackingProperties,
    );

    createZendeskTicket({
      variables: {
        input: {
          additional_note: reason,
          provider_role: "Peer Recovery Specialist",
        },
      },
    });
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size="2xl" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Heading as="h2" fontSize={21} fontWeight={600} marginTop={6}>
            {t(
              "careNavigatorScheduleModal.requestAdditionalAvailability.subtitlePeerRecoverySpecialist",
            )}
          </Heading>
          <Text fontSize={12} fontWeight={400} mt={8}>
            {t(
              "careNavigatorScheduleModal.requestAdditionalAvailability.description",
            )}
          </Text>
        </ModalHeader>
        <ModalCloseButton
          backgroundColor="#5F4B191A"
          h={40}
          w={40}
          my={"24px"}
          mr={"24px"}
          borderRadius={8}
        />
        <ModalBody>
          <FormControl mb={16} mt={24}>
            <FormLabel htmlFor="availability-reason-input">
              <Flex justify="space-between" mx={8}>
                <Text fontSize={16} fontWeight={400}>
                  {t(
                    "careNavigatorScheduleModal.requestAdditionalAvailability.reason",
                  )}
                </Text>
                <Text fontSize={16} fontWeight={400}>
                  {t(
                    "careNavigatorScheduleModal.requestAdditionalAvailability.optional",
                  )}
                </Text>
              </Flex>
            </FormLabel>
            <Input
              height={"48px"}
              name={t(
                "careNavigatorScheduleModal.requestAdditionalAvailability.reason",
              )}
              id="availability-reason-input"
              backgroundColor="#5F4B191A"
              border="none"
              onChange={handleReasonInput}
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Flex justify="flex-end" mt={15.5} mb={20.5}>
            <Button
              size="sm"
              variant="high-emphasis"
              colorScheme="primary"
              onClick={handleSubmit}
            >
              {t(
                "careNavigatorScheduleModal.requestAdditionalAvailability.submit",
              )}
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
export default ChangePeerRecoverySpecialistModal;
