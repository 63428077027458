const API_PATH = "/api";
const GQL_PATH = "/graphql";

const badURLEndings = /[/\s]+$/; // Trailing slash and/or whitespace

let rotom = process.env.APP_API?.replace(badURLEndings, "");
if (typeof window !== "undefined") {
  rotom =
    localStorage.getItem("rotom")?.replace(badURLEndings, "") ||
    process.env.APP_API?.replace(badURLEndings, "");
}

let pikachu = process.env.AUTH_API_DOMAIN?.replace(badURLEndings, "");
if (typeof window !== "undefined") {
  pikachu =
    localStorage.getItem("pikachu")?.replace(badURLEndings, "") ||
    process.env.AUTH_API_DOMAIN?.replace(badURLEndings, "");
}

export const DEFAULT_API = `${rotom}${API_PATH}${GQL_PATH}`;
export const API_BASE = `${rotom}${API_PATH}`;
export const API_GRAPHQL = "/graphql";
export const API_REST = "";
export const API_WEBSOCKET = `${rotom?.replace("https://", "wss://")}/websocket`;

export const API_DOMAIN = rotom;
export const AUTH_API_DOMAIN = pikachu;

// Debug constants
export const PIVOTAL_BASE =
  "https://www.pivotaltracker.com/services/v5/projects";
