import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Box, Flex, Input, Text } from "@springcare/sh-component-library";
import { colors } from "design-system/theme/base/colors";

export const ReasonForScheduling = ({ reasonForScheduling = "", onChange }) => {
  const { t } = useTranslation(["careProvider"]);
  const [charsLeft, setCharsLeft] = useState(88);

  useEffect(() => {
    const remainingChars = 88 - reasonForScheduling.length;
    setCharsLeft(remainingChars);
  }, [reasonForScheduling]);

  return (
    <Box>
      <Text color={colors.platform.dark} marginBottom={20}>
        {t("scheduleModal.reasonForVisitHeader")}
      </Text>
      <label
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: ".75em",
        }}
        htmlFor="reasonForScheduling"
      >
        <span>{t("scheduleModal.reasonForVisitLabel")}</span>
      </label>
      <Input
        name="reasonForScheduling"
        value={reasonForScheduling}
        onChange={onChange}
        maxLength={88}
        aria-label={t("scheduleModal.reasonForVisitAriaLabel")}
        aria-placeholder={t("scheduleModal.reasonForVisitSubLabel")}
        placeholder={t("scheduleModal.reasonForVisitSubLabel")}
        backgroundColor={colors.tertiary.light}
        height={56}
        variant="high-emphasis"
        _focus={{ border: "2px solid #06C292" }}
      />
      <Flex
        style={{
          display: "flex",
          marginBottom: "32px",
          color: "#556065",
          marginTop: "8px",
        }}
      >
        <Text textStyle="body-medium-regular">
          {charsLeft}/88 characters remaining
        </Text>
      </Flex>
    </Box>
  );
};
