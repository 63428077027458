import { useTranslation, Trans } from "react-i18next";
import {
  Box,
  Text,
  Link,
  useCustomToast,
  useMediaQuery,
  VCircleCheckIcon,
  CopyIcon,
} from "@springcare/sh-component-library";
import { formatPhoneNumber } from "components/templates/Browse/ProviderBrowsePage/utils";
import React from "react";
import { TRACK_EVENT } from "utils/mixpanel";

const useLinkCopyToast = (message) => {
  return useCustomToast({
    type: "success",
    message,
    icon: VCircleCheckIcon,
    layout: "fit-content",
  });
};

export const Consultations = ({ provider, memberName, isCard = false }) => {
  const { t } = useTranslation("careProvider");
  const [hoveringPhone, setHoveringPhone] = React.useState(false);
  const hasEitherTypeEnabled =
    provider?.allows_email_consults || provider?.allows_phone_consults;

  React.useEffect(() => {
    if (hasEitherTypeEnabled) {
      TRACK_EVENT.COMPONENT_VIEWED(
        window.location.pathname,
        "Consultations component viewed",
        {
          allowsEmailConsults: provider?.allows_email_consults,
          allowsPhoneConsults: provider?.allows_phone_consults,
        },
      );
    }
  }, [hasEitherTypeEnabled, provider]);

  const [isMobile] = useMediaQuery("(max-width: 450px)");
  const openLinkCopyToast = useLinkCopyToast(t("consultation.numberCopied"));

  const handlePhoneClick = (e) => {
    TRACK_EVENT.BUTTON_CLICKED(
      window.location.pathname,
      "Consultation phone link clicked",
      {
        copiedToClipboard: !isMobile,
      },
    );
    if (isMobile) {
      window.open(`tel:${provider?.phone}`);
      return;
    }
    navigator.clipboard.writeText(provider?.phone);
    setHoveringPhone(false);
    openLinkCopyToast();
  };

  const handleEmailClick = () => {
    TRACK_EVENT.BUTTON_CLICKED(
      window.location.pathname,
      "Consultation email link clicked",
    );
  };

  if (!hasEitherTypeEnabled) {
    return null;
  }

  const getPhoneLinkComponent = (key: string) => {
    return (
      <span key={key} style={{ position: "relative" }}>
        <Link
          // @ts-ignore
          onClick={handlePhoneClick}
          onMouseOver={() => setHoveringPhone(true)}
          onMouseOut={() => setHoveringPhone(false)}
        >
          {formatPhoneNumber(provider?.phone)}
        </Link>
        <Box
          pos="absolute"
          right="-50%"
          top="125%"
          backgroundColor="#171E22"
          color="white"
          width="200px"
          textAlign="center"
          padding="4px 8px"
          borderRadius="8px"
          opacity={hoveringPhone ? 1 : 0}
          transition="opacity .3s"
        >
          <CopyIcon marginRight="8px" />
          {t("consultation.copyNumber")}
        </Box>
      </span>
    );
  };

  const getEmailLinkComponent = (key: string) => {
    return (
      <Link
        key={key}
        onClick={handleEmailClick}
        href={`mailto:${provider?.email}?subject=${t("consultation.emailSubject")}: ${memberName}`}
      >
        {t("consultation.emailing")}
      </Link>
    );
  };

  return (
    <Box
      pt={isCard && 24}
      textAlign={{ base: "center", sm: "start" }}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      {provider?.allows_email_consults && !provider?.allows_phone_consults && (
        <Text size="body-medium" as="span">
          <Trans
            t={t}
            i18nKey="consultation.emailOnlyCopy"
            values={{ name: provider?.first_name }}
            components={[
              <Text
                key="consultation.emailOnlyCopy.bolded"
                as="span"
                size="body-medium-strong"
              />,
              getEmailLinkComponent("consultation.emailOnlyCopy.email"),
            ]}
          />
        </Text>
      )}

      {!provider?.allows_email_consults && provider?.allows_phone_consults && (
        <Text size="body-medium" as="span">
          <Trans
            t={t}
            i18nKey="consultation.phoneOnlyCopy"
            values={{ name: provider?.first_name }}
            components={[
              <Text
                key="consultation.phoneOnlyCopy.bolded"
                as="span"
                size="body-medium-strong"
              />,
              getPhoneLinkComponent("consultation.phoneOnlyCopy.phone"),
            ]}
          />
        </Text>
      )}

      {provider?.allows_email_consults && provider?.allows_phone_consults && (
        <Text size="body-medium" as="span">
          <Trans
            t={t}
            i18nKey="consultation.bothSupportedCopy"
            values={{ name: provider?.first_name }}
            components={[
              <Text
                key="consultation.bothSupportedCopy.bolded"
                as="span"
                size="body-medium-strong"
              />,
              getPhoneLinkComponent("consultation.bothSupportedCopy.phone"),
              getEmailLinkComponent("consultation.bothSupportedCopy.email"),
            ]}
          />
        </Text>
      )}
    </Box>
  );
};

export default Consultations;
