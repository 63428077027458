import {
  Box,
  Button,
  ButtonProps,
  Flex,
  Heading,
  Text,
  useMediaQuery,
} from "@springcare/sh-component-library";
import { useTranslation } from "hooks/react-i18next";
import {
  trackCompletedExerciseWorse,
  trackCompletedExerciseNoChange,
  trackCompletedExerciseBetter,
  trackFinishedExerciseDoneClicked,
} from "modules/MemberDashboard/Moments/components/analytics";
import React, { useState } from "react";
import { EfficacySurveyFeeling } from "../MomentsCompletionModal";

interface EfficacySurveyProps {
  exerciseId?: string;
  exerciseTitle?: string;
  exerciseCategory?: unknown;
  exerciseMediaType?: unknown;
  setFeeling: (value: EfficacySurveyFeeling) => void;
}

export const EfficacySurvey = ({
  exerciseId,
  exerciseTitle,
  exerciseCategory,
  exerciseMediaType,
  setFeeling,
}: EfficacySurveyProps) => {
  const [isMobile] = useMediaQuery("(max-width:600px)");
  const { t } = useTranslation("moments");
  const [selectedFeeling, setSelectedFeeling] = useState(null);

  const handleFeelingsButtonOnClick = (value: EfficacySurveyFeeling) => {
    setSelectedFeeling(value);
    setTimeout(() => {
      setFeeling(value);
    }, 1000);
  };

  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      gap="v-16"
      pt="v-16"
      pb={isMobile ? "v-none" : "34px"}
      px="v-24"
    >
      <Heading size="heading-small">
        {t("completeExerciseModal.feelings")}
      </Heading>
      <Flex
        flexDirection={isMobile ? "column-reverse" : "row"}
        gap="v-8"
        w={isMobile ? "295px" : "512px"}
      >
        <StyledButton
          selected={selectedFeeling === EfficacySurveyFeeling.Worse}
          onClick={() => {
            handleFeelingsButtonOnClick(EfficacySurveyFeeling.Worse);
            trackCompletedExerciseWorse(
              exerciseId,
              exerciseTitle,
              exerciseCategory,
              exerciseMediaType,
            );
          }}
          text={t("completeExerciseModal.worse")}
        />
        <StyledButton
          selected={selectedFeeling === EfficacySurveyFeeling.Same}
          onClick={() => {
            handleFeelingsButtonOnClick(EfficacySurveyFeeling.Same);
            trackCompletedExerciseNoChange(
              exerciseId,
              exerciseTitle,
              exerciseCategory,
              exerciseMediaType,
            );
          }}
          data-cy="same-button"
          text={t("completeExerciseModal.same")}
        />
        <StyledButton
          selected={selectedFeeling === EfficacySurveyFeeling.Better}
          onClick={() => {
            handleFeelingsButtonOnClick(EfficacySurveyFeeling.Better);
            trackCompletedExerciseBetter(
              exerciseId,
              exerciseTitle,
              exerciseCategory,
              exerciseMediaType,
            );
          }}
          text={t("completeExerciseModal.better")}
        />
      </Flex>
    </Flex>
  );
};

const StyledButton = ({
  text,
  selected,
  ...props
}: {
  text: string;
  selected: boolean;
} & ButtonProps) => {
  const variant = selected ? "high-emphasis" : "low-emphasis";

  return (
    <Button
      colorScheme="base"
      py="v-12"
      px="v-16"
      variant={variant}
      {...props}
      width="100%"
      sx={{
        ["@media screen and (min-width: 48em)"]: {
          width: "100%",
        },
      }}
    >
      <Text fontWeight="600">{text}</Text>
    </Button>
  );
};
