import { createIcon } from "@springcare/sh-component-library";

export const HeartShield = createIcon({
  displayName: "HeartShield",
  viewBox: "0 0 34 41",
  // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
  path: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.2648 0.125843C16.5668 -0.0419476 16.9341 -0.0419476 17.2361 0.125843L32.9866 8.8761C33.304 9.05247 33.5009 9.38709 33.5009 9.75026C33.5009 26.2959 22.7261 37.0213 17.2719 40.3544C16.9518 40.55 16.5491 40.55 16.229 40.3544C10.7748 37.0213 0 26.2959 0 9.75026C0 9.38709 0.196892 9.05247 0.514357 8.8761L16.2648 0.125843ZM2.00478 10.336C2.24692 25.1416 11.6226 34.9251 16.7505 38.3159C21.8783 34.9251 31.254 25.1416 31.4961 10.336L16.7505 2.14396L2.00478 10.336Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.0835 15.8998C12.2796 14.6989 14.2187 14.6989 15.4147 15.8998L16.7492 17.2397L18.0837 15.8998C19.2798 14.6989 21.2189 14.6989 22.4149 15.8998C23.6109 17.1006 23.6109 19.0476 22.4149 20.2484L16.7492 25.937L11.0835 20.2484C9.88752 19.0476 9.88752 17.1006 11.0835 15.8998Z"
        fill="currentColor"
      />
    </>
  ),
  defaultProps: {
    "aria-hidden": true,
    "aria-label": "Heart shield icon",
    role: "img",
    "aria-hidden": "true",
  },
});
