import {
  Box,
  Heading,
  Image,
  keyframes,
  VStack,
} from "@springcare/sh-component-library";
import { TRACK_EVENT } from "utils/mixpanel";
import MobilePreviewCarousel from "./MobilePreviewCarousel";

interface SpringPreviewProps {
  isMobile: boolean;
}

const fadeInUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const SpringPreview = ({ isMobile }: SpringPreviewProps) => {
  const handleCardClick = (cardTitle: String) => {
    TRACK_EVENT.CARD_CLICKED(
      window.location.pathname,
      cardTitle,
      "Spring Preview on Expectations Page",
    );
  };

  return (
    <>
      {isMobile ? (
        <MobilePreviewCarousel handleCardClick={handleCardClick} />
      ) : (
        <>
          <VStack
            w={["100%", "100%", "10%", "20%"]}
            flex="1"
            align="flex-start"
            bg="background-subtle"
            ml="v-24"
            p="v-16"
            pb={0}
            borderRadius="24px"
            opacity={0}
            animation={`${fadeInUp} 0.8s ease-in-out forwards`}
            onClick={() => handleCardClick("Personalized tools")}
          >
            <Heading
              w="100%"
              mb="v-8"
              fontSize="v-16"
              textAlign="center"
              variant="md_v1"
            >
              Personalized tools
            </Heading>

            <Image
              src="/static/moments_preview.png"
              alt="Preview of personalized tools"
            />
          </VStack>

          <VStack w={["100%", "100%", "25%", "30%"]} align="flex-start">
            <Box
              w="100%"
              p="v-24"
              mb={["v-16", "v-16", "v-4", "v-16"]}
              bg="background-subtle"
              borderRadius="24px"
              opacity={0}
              animation={`${fadeInUp} 0.6s ease-in-out 0.4s forwards`}
              onClick={() => handleCardClick("Recommended plans")}
            >
              <Heading
                w="100%"
                mb="v-16"
                fontSize="v-16"
                textAlign="center"
                variant="md_v1"
              >
                Recommended plans
              </Heading>
              <Image
                src="/static/recommended_plans_preview.png"
                alt="Preview of recommended plans"
              />
            </Box>

            <Box
              w="100%"
              py="v-24"
              bg="background-subtle"
              borderRadius="24px"
              opacity={0}
              animation={`${fadeInUp} 0.6s ease-in-out 0.6s forwards`}
              onClick={() => handleCardClick("Top provider matches")}
            >
              <Heading
                w="100%"
                mb="v-16"
                fontSize="v-16"
                textAlign="center"
                variant="md_v1"
              >
                Top provider matches
              </Heading>
              <Image
                src="/static/provider_match_preview.png"
                alt="Preview of top provider matches"
              />
            </Box>
          </VStack>
        </>
      )}
    </>
  );
};

export default SpringPreview;
