/* eslint-disable react/jsx-key */
import { createIcon } from "@springcare/sh-component-library";

export const Plus = createIcon({
  displayName: "Plus",
  viewBox: "0 0 24 24",
  path: [
    <rect width="24" height="24" fill="white" />,
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.0003 3C13.0003 2.44772 12.5525 2 12.0003 2C11.448 2 11.0003 2.44772 11.0003 3V11.0024H2.99805C2.44576 11.0024 1.99805 11.4502 1.99805 12.0024C1.99805 12.5547 2.44576 13.0024 2.99805 13.0024H11.0003V21.0042C11.0003 21.5565 11.448 22.0042 12.0003 22.0042C12.5525 22.0042 13.0003 21.5565 13.0003 21.0042V13.0024H21.0022C21.5545 13.0024 22.0022 12.5547 22.0022 12.0024C22.0022 11.4502 21.5545 11.0024 21.0022 11.0024H13.0003V3Z"
      fill="currentColor"
    />,
  ],
  defaultProps: {
    color: "platform.900",
  },
});
