import { MouseEventHandler } from "react";
import { useVideoPlayer } from "modules/shared/components/VideoPlayer/VideoPlayer/context/videoPlayerContext";

import {
  VPlayIcon,
  VPauseIcon,
  VCaptionsIcon,
  VCaptionsFilledIcon,
  VForward10Icon,
  VBackward10Icon,
  VExpandIcon,
  VCollapseIcon,
  IconButton,
} from "@springcare/sh-component-library";
import { useTranslation } from "hooks/react-i18next";

export interface ToggleButtonProps {
  variant?: "no-emphasis" | "high-emphasis";
  type:
    | "playPause"
    | "captions"
    | "fastForward"
    | "fastBackward"
    | "fullScreen";
}

export const ToggleButton: React.FC<ToggleButtonProps> = ({
  variant = "no-emphasis",
  type,
}) => {
  const {
    isPlaying,
    setIsPlaying,
    isCaptionOn,
    captionButtonTapped,
    forward10,
    backward10,
    setShowControls,
    isFullScreen,
    fullScreenButtonTapped,
  } = useVideoPlayer();

  let icon: object,
    toggleFunction: MouseEventHandler<HTMLButtonElement> | undefined,
    ariaLabel: string;

  const handleOnFocus = () => setShowControls(true);
  const handleOnBlur = () => setShowControls(false);

  const { t } = useTranslation("videoPlayer");
  const playPauseAriaLabel = isPlaying ? t("pause") : t("play");
  const fastForwardAriaLabel = t("fastForward");
  const fastBackwardAriaLabel = t("fastBackward");
  const enableDisableCaptionsAriaLabel = isCaptionOn
    ? t("disableCaptions")
    : t("enableCaptions");
  const enterExitFullScreenAriaLabel = isFullScreen
    ? t("exitFullScreenMode")
    : t("enterFullScreenMode");

  switch (type) {
    case "playPause":
      icon = isPlaying ? VPauseIcon : VPlayIcon;
      toggleFunction = () => {
        setIsPlaying(!isPlaying);
      };
      ariaLabel = playPauseAriaLabel;
      break;
    case "fastForward":
      icon = VForward10Icon;
      toggleFunction = () => {
        forward10();
      };
      ariaLabel = fastForwardAriaLabel;
      break;
    case "fastBackward":
      icon = VBackward10Icon;
      toggleFunction = () => {
        backward10();
      };
      ariaLabel = fastBackwardAriaLabel;
      break;
    case "captions":
      icon = isCaptionOn ? VCaptionsFilledIcon : VCaptionsIcon;
      toggleFunction = () => {
        captionButtonTapped();
      };
      ariaLabel = enableDisableCaptionsAriaLabel;
      break;
    case "fullScreen":
      icon = isFullScreen ? VCollapseIcon : VExpandIcon;
      toggleFunction = () => {
        fullScreenButtonTapped();
      };
      ariaLabel = enterExitFullScreenAriaLabel;
      break;
  }

  return (
    <IconButton
      icon={icon}
      aria-label={ariaLabel}
      size="sm"
      variant={variant}
      colorScheme="media"
      onClick={toggleFunction}
      onFocus={handleOnFocus}
      onBlur={handleOnBlur}
    />
  );
};

ToggleButton.displayName = "ToggleButton";
