import {
  Heading,
  ListItem,
  Text,
  UnorderedList,
  VRelationshipsDuotoneSpot,
  VStonesFlatSpot,
} from "@springcare/sh-component-library";
import { InformationFlyout } from "components";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "hooks/react-i18next";
import { useInViewportOnce } from "hooks/useInViewport";
import { TRACK_EVENT } from "utils/mixpanel";

type CouplesTherapyFlyoutType = {
  isAlsoAvailableTile?: boolean;
  isOpen: boolean;
  onClose: () => void;
};

const CouplesTherapyFlyoutBody = ({
  isAlsoAvailableTile,
}: {
  isAlsoAvailableTile: boolean;
}) => {
  const { t } = useTranslation("homepage", {
    keyPrefix: "careInfoFlyout.couplesTherapy",
  });

  const ref = useRef();
  const inViewport = useInViewportOnce(ref);
  const [viewedOnce, setViewedOnce] = useState(false);

  useEffect(() => {
    if (inViewport && !viewedOnce) {
      TRACK_EVENT.COMPONENT_VIEWED(
        window.location.pathname,
        "Informational fly-out - Couples Therapy",
        { spring_doc_id: "couples_therapy_002" },
      );
      setViewedOnce(true);
    }
  }, [inViewport, viewedOnce]);

  return (
    <>
      <Heading size="heading-small" mb="v-8" ref={ref}>
        {t("benefits.header")}
      </Heading>
      <UnorderedList mb={24}>
        <ListItem>{t("benefits.item1")}</ListItem>
        <ListItem>{t("benefits.item2")}</ListItem>
      </UnorderedList>
      <Heading size="heading-small" mb="v-8">
        {t("whatToExpect.header")}
      </Heading>
      <Text mb={24}>{t("whatToExpect.body")}</Text>
      <Heading size="heading-small" mb="v-8">
        {t("commonConcerns.header")}
      </Heading>
      <UnorderedList mb={24}>
        <ListItem>
          <Text display="inline" size="body-medium-strong">
            {t("commonConcerns.item1Q")}
          </Text>{" "}
          {t("commonConcerns.item1A")}
        </ListItem>
        <ListItem>
          <Text display="inline" size="body-medium-strong">
            {t("commonConcerns.item2Q")}
          </Text>{" "}
          {t("commonConcerns.item2A")}
        </ListItem>
      </UnorderedList>
    </>
  );
};

export const CouplesTherapyFlyout = ({
  isAlsoAvailableTile = false,
  isOpen,
  onClose,
}: CouplesTherapyFlyoutType) => {
  const { t } = useTranslation("homepage", {
    keyPrefix: "careInfoFlyout.couplesTherapy",
  });

  return (
    <InformationFlyout
      headerText={t("heading")}
      headerIcon={<VStonesFlatSpot />}
      heroIllustration={VRelationshipsDuotoneSpot}
      isOpen={isOpen}
      onClose={onClose}
      ctaText={t("ctaText")}
      ctaLink="/browse/therapists/?Couples=true"
      ctaLinkAs="/browse/therapists"
    >
      <CouplesTherapyFlyoutBody isAlsoAvailableTile={isAlsoAvailableTile} />
    </InformationFlyout>
  );
};
