import React, { FC } from "react";
import { Text, useMediaQuery } from "design-system/components";
import { isACareNavigator } from "./CareProviderScheduleContent.utils";
import { useTranslation } from "react-i18next";
import { Heading } from "@springcare/sh-component-library";

// TODO: Add defaults for the optional props
interface ModalHeadingProps {
  as?: string;
  variant?: string;
  pb?: number;
  pt?: number;
  display?: string;
  fontSize?: number;
  fontWeight?: string;
  providerName: string;
  careProvider: { [key: string]: any };
  size?: string;
}

const ModalHeading: FC<ModalHeadingProps> = ({
  providerName,
  careProvider,
  size,
}) => {
  const { t } = useTranslation(["careProvider"]);
  const [isUnder978] = useMediaQuery("(max-width: 978px)");

  if (isACareNavigator(careProvider)) {
    return (
      <>
        <Text as={"h1"} variant="bodyBold_v1" pb={isUnder978 ? 0 : 12}>
          {t("scheduleModal.cnHeading", {
            defaultValue: "You are scheduling a care navigation call with:",
          })}
          <Heading
            as="span"
            pt={isUnder978 ? 8 : 12}
            display="block"
            variant="md_v1"
          >
            {providerName}
          </Heading>
        </Text>
      </>
    );
  }
  return (
    <Heading
      as={"h1"}
      size={size != null ? size : "heading-small"}
      id={providerName?.replaceAll(" ", "")}
      data-cy="providerName"
    >
      {providerName}
    </Heading>
  );
};

export default ModalHeading;
