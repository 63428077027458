import { createIcon } from "@springcare/sh-component-library";

export const Medication = createIcon({
  displayName: "MedicationV1.1",
  viewBox: "0 0 18 24",
  path: (
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 0C0.895431 0 0 0.895431 0 2V4C0 5.10457 0.895431 6 2 6V23C2 23.5523 2.44772 24 3 24H15C15.5523 24 16 23.5523 16 23V6C17.1046 6 18 5.10457 18 4V2C18 0.895431 17.1046 0 16 0H2ZM4 19H8C8.55229 19 9 18.5523 9 18C9 17.4477 8.55229 17 8 17H4V15H8C8.55229 15 9 14.5523 9 14C9 13.4477 8.55229 13 8 13H4V11H8C8.55229 11 9 10.5523 9 10C9 9.44771 8.55229 9 8 9H4V6H14V22H4V19ZM4 4H2V2H4V4ZM8 4H6V2H8V4ZM12 4H10V2H12V4ZM14 4V2H16V4H14Z"
    />
  ),
  defaultProps: {
    "aria-label": "Medication icon",
    role: "img",
    color: "tertiary.700",
    "aria-hidden": "true",
  },
});
