import { VideoPlayerProps } from "./types";
import { VideoPlayerProvider } from "./context/videoPlayerContext";
import { PlayerControls } from "./components/playerControls/PlayerControls";
import { PlayerContainer } from "./components/PlayerContainer";
import { PlayerCaption } from "./components/PlayerCaption";
import { Flex, useMediaQuery } from "@springcare/sh-component-library";
import { forwardRef } from "react";
import dynamic from "next/dynamic";
import { mobileAndTabletCheck } from "utils/displayHelpers";

const DynamicVideoJSPlayerWrapper = dynamic(
  () => import("./videoJs/VideoJSWrapper").then((m) => m.VideoJSPlayerWrapper),
  {
    ssr: false,
  },
);

// By default, `VideoPlayer` renders its own Provider. To give components outside of `VideoPlayer` access to its
// methods, pass `shouldWrapWithProvider={false}` to `VideoPlayer`, and place `VideoPlayerProvider` higher up the tree.
// E.g, VideoMomentModalOverlayCloseButton component needs to pause the video when the close button is clicked.
const ContainerComponent = ({
  shouldWrapWithProvider,
  isMobileOrTabletDevice,
  listenerCallbacks,
  children,
}) =>
  shouldWrapWithProvider ? (
    <VideoPlayerProvider
      mobile={isMobileOrTabletDevice}
      listenerCallbacks={listenerCallbacks}
      children={children}
    />
  ) : (
    <>{children}</>
  );

const MobileControls: React.FC = () => {
  const isIos = /iPad|iPhone/.test(navigator.userAgent);

  return (
    <PlayerControls>
      <PlayerControls.Header>
        <PlayerControls.Row>
          <PlayerControls.Group justifyContent="start" />
          <PlayerControls.Group justifyContent="end">
            <PlayerControls.ToggleButton type="captions" />
            {!isIos && <PlayerControls.ToggleButton type="fullScreen" />}
          </PlayerControls.Group>
        </PlayerControls.Row>
      </PlayerControls.Header>
      <Flex position="absolute" alignSelf={"center"} gap="v-8">
        <PlayerControls.ToggleButton type="fastBackward" />
        <PlayerControls.ToggleButton variant="high-emphasis" type="playPause" />
        <PlayerControls.ToggleButton type="fastForward" />
      </Flex>
      <PlayerControls.Footer></PlayerControls.Footer>
    </PlayerControls>
  );
};

const DesktopControls: React.FC = () => {
  return (
    <PlayerControls>
      <PlayerControls.Footer>
        <PlayerControls.Row>
          <PlayerControls.Group justifyContent="start">
            <PlayerControls.ToggleButton type="playPause" />
            <PlayerControls.Volume />
          </PlayerControls.Group>
          <PlayerControls.Group justifyContent="end">
            <PlayerControls.ToggleButton type="captions" />
            <PlayerControls.ToggleButton type="fullScreen" />
          </PlayerControls.Group>
        </PlayerControls.Row>
      </PlayerControls.Footer>
    </PlayerControls>
  );
};

export const VideoPlayer = forwardRef<HTMLVideoElement, VideoPlayerProps>(
  (
    { src, captionUrl, listenerCallbacks, shouldWrapWithProvider = true },
    ref,
  ) => {
    // Note: Always lazy import this VideoPlayer component.
    // const VideoPlayer = lazy(() => import("./VideoPlayer.tsx"));

    const isMobileOrTabletDevice = mobileAndTabletCheck();
    const [isLandscape] = useMediaQuery("(orientation: landscape)");

    return (
      <ContainerComponent
        shouldWrapWithProvider={shouldWrapWithProvider}
        isMobileOrTabletDevice={isMobileOrTabletDevice}
        listenerCallbacks={listenerCallbacks}
      >
        <PlayerContainer>
          <DynamicVideoJSPlayerWrapper src={src} />
          <PlayerCaption captionUrl={captionUrl} />
          {!isLandscape || (isMobileOrTabletDevice && isLandscape) ? (
            <MobileControls />
          ) : (
            <DesktopControls />
          )}
        </PlayerContainer>
      </ContainerComponent>
    );
  },
);

VideoPlayer.displayName = "VideoPlayer";
