import React, { useEffect, useRef, useState } from "react";
import { getOr } from "lodash/fp";
import { useTranslation } from "hooks/react-i18next";
import {
  AppointmentMedium,
  AppointmentKind,
  isTherapy,
  isMedicationManagement,
  getAppointmentKindForTracking,
  getAppointmentMediumForTracking,
  isCoaching,
} from "@spring/constants";
import { useMemberInfo } from "hooks";
import {
  getShouldShowPaymentCopy,
  formatAppointmentTime,
} from "components/templates/CareVisitDetails/utils";
import {
  PaymentDetails,
  VideoCopy,
  InPersonCopy,
  PhoneCopy,
  MakeChangeCopy,
  CancellationPolicy,
  RebookCopy,
} from "components/templates/CareVisitDetails/components/ConfirmationCopy";
import { Box, Link, Text } from "@springcare/sh-component-library";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";
import { useInViewportOnce } from "hooks/useInViewport";
import { TRACK_EVENT } from "utils/mixpanel";
import envUtils from "utils/environment";
import { Trans } from "react-i18next";
import { useMember } from "context/MemberContext";

interface Provider {
  name: string;
  email: string;
  dedicated_customer: {
    name: string;
  };
}

interface ConfirmationCopyProps {
  appointmentId?: string;
  bookAnotherAppointmentCb: () => {};
  cancelled: boolean;
  duration: string | number;
  isAMinor?: boolean;
  isCoveredForMember: boolean;
  kind: string;
  location?: string;
  medium: string;
  memberCohort: any;
  openCouplesTherapyModal?: () => void;
  provider: Provider;
  time: string;
}

const ConfirmationCopy = ({
  appointmentId,
  bookAnotherAppointmentCb,
  cancelled,
  duration,
  isAMinor,
  isCoveredForMember,
  kind,
  location = "",
  medium,
  provider,
  time,
  openCouplesTherapyModal,
}: ConfirmationCopyProps) => {
  const { data: memberInfo, loading: memberInfoLoading } = useMemberInfo();
  const { t } = useTranslation("careVisits");
  const isTherapyAppt = isTherapy(kind);
  const { isChip } = useMember();

  const isCoachingProvider = isCoaching(kind);
  const couplesTherapyFlag = useFeatureFlag(FLAGS.ENABLE_COUPLES_THERAPY);
  const showCouplesTherapy = couplesTherapyFlag && !isAMinor;
  const isCouplesTherapy = kind === AppointmentKind.CouplesTherapy;
  const isMedicationManager = isMedicationManagement(kind);
  const showCancellationPolicy =
    !isChip && (isMedicationManager || isTherapyAppt || isCoachingProvider);

  const isPaymentCopyVisible = !isChip && getShouldShowPaymentCopy(kind);

  const couplesSessionInfoRef = useRef();
  const couplesSessionInfoInViewport = useInViewportOnce(couplesSessionInfoRef);
  const [couplesSessionInfoViewedOnce, setCouplesSessionInfoViewedOnce] =
    useState(false);

  useEffect(() => {
    if (couplesSessionInfoInViewport && !couplesSessionInfoViewedOnce) {
      TRACK_EVENT.COMPONENT_VIEWED(
        window.location.pathname,
        "Learn about couples sessions button",
        {
          location: "Appointment details page",
          spring_doc_id: "couples_therapy_006",
          appointment_kind: getAppointmentKindForTracking(kind),
          appointment_medium: getAppointmentMediumForTracking(medium),
        },
      );
      setCouplesSessionInfoViewedOnce(true);
    }
  }, [couplesSessionInfoInViewport, couplesSessionInfoViewedOnce]);

  const { member } = getOr({}, "user", memberInfo);
  const { cohort } = getOr({}, "user.member", memberInfo);
  const { id: customerId } = getOr(
    {},
    "user.member.cohort.customer",
    memberInfo,
  );
  const member_cost_of_coaching =
    cohort?.contract_term?.member_cost_of_coaching;

  const handleCouplesSessionsLearnMoreClick = () => {
    openCouplesTherapyModal();

    TRACK_EVENT.BUTTON_CLICKED(
      window.location.pathname,
      "Learn about couples sessions button",
      {
        location: "Appointment details page",
        spring_doc_id: "couples_therapy_007",
        appointment_kind: getAppointmentKindForTracking(kind),
        appointment_medium: getAppointmentMediumForTracking(medium),
      },
    );
  };

  const directQuestionsToProvider =
    member?.is_private_practice || isCoachingProvider;
  // coaches still email care team.
  const makeChangeEmail = member?.is_private_practice
    ? provider?.email
    : envUtils.careTeamEmail;

  const isSpecialtyCareNavigationAppointment =
    kind === AppointmentKind.InitialSpecialtyCareNavigation ||
    kind === AppointmentKind.FollowUpSpecialtyCareNavigation;

  return (
    <div aria-busy={memberInfoLoading}>
      {member && cohort && (
        <>
          {!cancelled && (
            <>
              {medium === AppointmentMedium.Video && (
                <VideoCopy
                  time={time}
                  duration={duration}
                  provider={provider}
                  kind={kind}
                  isAMinor={isAMinor}
                />
              )}
              {medium === AppointmentMedium.InPerson && (
                <InPersonCopy
                  time={time}
                  duration={duration}
                  provider={provider}
                  location={location}
                />
              )}
              {medium === AppointmentMedium.Phone && (
                <PhoneCopy
                  time={time}
                  duration={duration}
                  provider={provider}
                />
              )}
              {isChip && (
                <>
                  <Box>
                    <Text variant="body_v1" mb={16}>
                      <Trans
                        ns="careVisits"
                        i18nKey="appointmentDetails.chipConfirmation.zoomDescription"
                        components={[
                          <Link href="https://www.zoom.us/download" key="0" />,
                          <Link
                            href="https://itunes.apple.com/us/app/id546505307"
                            key="1"
                          />,
                          <Link
                            href="https://play.google.com/store/apps/details?id=us.zoom.videomeetings"
                            key="2"
                          />,
                        ]}
                      />
                    </Text>
                  </Box>
                  <Box>
                    <Text variant="body_v1" mb={16}>
                      <Trans
                        ns="careVisits"
                        i18nKey="appointmentDetails.chipConfirmation.helpDescription"
                        components={[
                          <Link
                            href="mailto:careteam@springhealth.com"
                            key="0"
                          />,
                        ]}
                      />
                    </Text>
                  </Box>
                </>
              )}
              {isPaymentCopyVisible && (
                <>
                  <PaymentDetails
                    coaching={isCoachingProvider}
                    therapy={isTherapyAppt}
                    isCoveredForMember={isCoveredForMember}
                    isMedicationManager={isMedicationManager}
                    appointmentId={appointmentId ? appointmentId : null}
                    member={member}
                    kind={kind}
                    medium={medium}
                    time={time}
                    isDedicatedCustomer={!!provider?.dedicated_customer?.name}
                    customerId={customerId}
                    memberCostOfCoaching={member_cost_of_coaching}
                  />
                  {isCouplesTherapy && showCouplesTherapy && (
                    <Box mb={20} ref={couplesSessionInfoRef}>
                      <Link
                        as="button"
                        onClick={handleCouplesSessionsLearnMoreClick}
                        data-testid="coupleSessionLearnMore"
                      >
                        {t("appointmentDetails.couplesTherapy.learnAboutLink")}
                      </Link>
                    </Box>
                  )}
                </>
              )}

              {showCancellationPolicy && (
                <CancellationPolicy
                  defaultCopy={t("cancelAppointment.avoidCancellationFee")}
                  showViewPolicyCTA={true}
                  showDollarIcon={true}
                />
              )}

              {isTherapyAppt && (
                <RebookCopy
                  bookAnotherAppointmentCb={bookAnotherAppointmentCb}
                  isChip={isChip}
                />
              )}

              {isChip ? (
                <Box>
                  <Text variant="body_v1" mb={16}>
                    <Trans
                      ns="careVisits"
                      i18nKey="appointmentDetails.chipConfirmation.questionsDescription"
                      components={[
                        <Link
                          href="mailto:careteam@springhealth.com"
                          key="0"
                        />,
                      ]}
                    />
                  </Text>
                </Box>
              ) : (
                <MakeChangeCopy
                  time={formatAppointmentTime(time)}
                  directQuestionsToProvider={directQuestionsToProvider}
                  contactEmail={makeChangeEmail}
                  isSpecialtyCareNavigationAppointment={
                    isSpecialtyCareNavigationAppointment
                  }
                />
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default ConfirmationCopy;
