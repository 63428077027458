import { Box, Button, Flex, Heading } from "@springcare/sh-component-library";

type SpecialtyCareClosingBodyProps = {
  ModalImg: React.ElementType;
  title: string;
  subtitle: string;
};

export const SpecialtyCareClosingBody = ({
  ModalImg,
  title,
  subtitle,
}: SpecialtyCareClosingBodyProps) => {
  return (
    <>
      <ModalImg width="80px" aria-hidden={true} />
      <Box>
        <Heading title={title} tabIndex={0} size="heading-medium">
          {title}
        </Heading>
        <Heading tabIndex={0} size="sm" mt={8}>
          {subtitle}
        </Heading>
      </Box>
    </>
  );
};

type SpecialtyCareClosingProps = {
  ModalImg: React.ElementType;
  title: string;
  subtitle: string;
  ctaButton: string;
  ctaAction: () => void;
  skipButton?: string;
  skipAction?: () => void;
};

export const SpecialtyCareClosing = ({
  ModalImg,
  title,
  subtitle,
  ctaButton,
  ctaAction,
  skipButton,
  skipAction,
}: SpecialtyCareClosingProps) => {
  return (
    <>
      <Box maxW={614} h={"25%"} m="auto">
        <Flex flexDirection={"column"} gap={16}>
          <SpecialtyCareClosingBody
            ModalImg={ModalImg}
            title={title}
            subtitle={subtitle}
          />
        </Flex>
        <Flex gap={8} flexDir="row" mt={24}>
          <Button variant="high-emphasis" size="md" onClick={ctaAction}>
            {ctaButton}
          </Button>
          {skipButton && skipAction && (
            <Button variant="medium-emphasis" size="md" onClick={skipAction}>
              {skipButton}
            </Button>
          )}
        </Flex>
      </Box>
    </>
  );
};
