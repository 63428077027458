import {
  Heading,
  ListItem,
  Text,
  UnorderedList,
  useMediaQuery,
  VAssessmentIcon,
  VCarePlanDuotoneSpot,
} from "@springcare/sh-component-library";
import { InformationFlyout } from "components";
import { Call } from "design-system/index";
import { Trans, useTranslation } from "react-i18next";

type WorkLifeSupportFlyoutType = {
  callback?: () => void;
  isOpen: boolean;
  onClose: () => void;
  ctaText: string;
};

const WorkLifeSupportFlyoutBody = () => {
  const { t } = useTranslation("t2Homepage", {
    keyPrefix: "workLifeSupportFlyout",
  });

  return (
    <>
      <Heading size="heading-small" mb="v-8">
        {" "}
        {t("benefitsHeader")}
      </Heading>
      <UnorderedList mb={24}>
        <ListItem>{t("benefitsBody.benefit1")}</ListItem>
        <ListItem>{t("benefitsBody.benefit2")}</ListItem>
        <ListItem>{t("benefitsBody.benefit3")}</ListItem>
      </UnorderedList>
      <Heading size="heading-small" mb="v-8">
        {t("whatToExpectHeader")}
      </Heading>
      <UnorderedList mb={24}>
        <ListItem>
          <Trans
            t={t}
            i18nKey="whatToExpectBody.item1"
            components={[<Text key="whatToExpectBody.item1" />]}
          />
        </ListItem>
        <ListItem>
          <Trans
            t={t}
            i18nKey="whatToExpectBody.item2"
            components={[<Text key="whatToExpectBody.item2" />]}
          />
        </ListItem>
      </UnorderedList>

      <Heading size="heading-small" mb="v-8" mt="v-24">
        {" "}
        {t("commonConcernsHeader")}
      </Heading>
      <UnorderedList mb={24}>
        <ListItem>
          <Trans
            t={t}
            i18nKey="commonConcernsBody.concern1"
            components={[<Text key="commonConcernsBody.concern1" />]}
          />
        </ListItem>
      </UnorderedList>
    </>
  );
};

export const WorkLifeSupportFlyout = ({
  callback,
  isOpen,
  onClose,
  ctaText,
}: WorkLifeSupportFlyoutType) => {
  const { t } = useTranslation("t2Homepage", {
    keyPrefix: "workLifeSupportFlyout",
  });
  const [isMobile] = useMediaQuery("(max-width: 450px)");

  return (
    <InformationFlyout
      headerText={t("heading")}
      headerIcon={<VAssessmentIcon />}
      heroIllustration={VCarePlanDuotoneSpot}
      isOpen={isOpen}
      padding={isMobile ? "24px 0" : "24px 40px"}
      onClose={onClose}
      ctaIcon={<Call />}
      ctaText={ctaText}
      ctaLink={`tel:${ctaText}`}
      ctaType={callback && "link"}
      ctaCallback={callback}
    >
      <WorkLifeSupportFlyoutBody />
    </InformationFlyout>
  );
};
