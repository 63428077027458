import { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import NextLink from "next/link";
import { TRACK_EVENT } from "utils/mixpanel";
import { links } from "@spring/constants";
import { AppointmentKind, AppointmentMedium } from "@spring/constants";
import { Box, Text, Link, Button } from "@springcare/sh-component-library";

import {
  isCareNavigation,
  isTherapy,
  isCoaching,
  isMedicationManagement,
  ProviderRole,
  isCareAdvocateAppointment,
  isCareConsultantAppointment,
} from "@spring/constants";
import { getNormalizedProviderRoleByKind } from "utils/providers";
import { getAppointmentMediumForTracking } from "@spring/constants";
import routes from "routes";
import {
  getFormLinkWPMHC,
  getIsUniqueCCACopy,
  getIsUniqueWPMCHCopy,
} from "utils/employerServicesHelpers";

interface CancellationCopyProps {
  kind: (typeof AppointmentKind)[keyof AppointmentKind];
  medium: (typeof AppointmentMedium)[keyof AppointmentMedium];
  provider: { [key: string]: any };
  openScheduleModalCallback: () => void;
  isWithin24Hours: boolean;
  customerId: string;
}

const getBrowseRoute = (providerKind) => {
  const { Therapist, MedicationManager, Coach } = ProviderRole;
  switch (providerKind) {
    case Therapist:
      return routes.TherapistsBrowse.as;
    case MedicationManager:
      return routes.Medication_ManagersBrowse.as;
    case Coach:
      return routes.CoachesBrowse.as;
    default:
      return routes.MemberCareVisits.as;
  }
};

export const CancellationCopy: FC<CancellationCopyProps> = ({
  kind,
  medium,
  provider,
  openScheduleModalCallback,
  isWithin24Hours,
  customerId,
}) => {
  const { t } = useTranslation("careVisits");

  const isTherapyAppt = isTherapy(kind);
  const isMedicationManagementAppt = isMedicationManagement(kind);
  const isCoachingAppt = isCoaching(kind);
  const isCareNavigatorAppt = isCareNavigation(kind);
  const isCareAdvocateAppt = isCareAdvocateAppointment(kind);
  const isCareConsultantAppt = isCareConsultantAppointment(kind);

  const showLookForADifferentProvider =
    !isCareNavigatorAppt && !isCareAdvocateAppt && !isCareConsultantAppt;

  function apptKind() {
    if (isTherapyAppt) {
      return "Therapy";
    } else if (isMedicationManagementAppt) {
      return "Medication Manager";
    } else if (isCoachingAppt) {
      return "Coaching";
    } else if (isCareNavigatorAppt) {
      return "Care Navigator";
    } else if (isCareAdvocateAppt) {
      return "Team Member Care Advocate";
    } else if (isCareConsultantAppt) {
      return "Leadership Care Consultant";
    }
    return "";
  }

  const formLinkSDOH = getFormLinkWPMHC(customerId);
  const isUniqueCCACopy = getIsUniqueCCACopy(customerId);
  const isUniqueWPMCHCopy = getIsUniqueWPMCHCopy(customerId);

  const handleOpenLCCForm = useCallback(() => {
    return window.open(formLinkSDOH, "_blank", "noopener noreferrer");
  }, []);

  const handleReschedule = useCallback(() => {
    openScheduleModalCallback();
    TRACK_EVENT.BUTTON_CLICKED(
      window.location.pathname,
      "Reschedule Appointment",
      {
        location: "Appointment Canceled Page",
        provider_id: provider.id,
        appointment_type: apptKind(),
        appointment_medium: getAppointmentMediumForTracking(medium),
      },
    );
  }, [provider?.id, kind]);

  const buttonClickCallback = () => {
    if (isCareConsultantAppt) {
      return handleOpenLCCForm;
    } else {
      return handleReschedule;
    }
  };

  const getFindBetterTimeText = (providerKind, customerId) => {
    let findBetterTimeText;
    if (
      providerKind === "care_advocate" ||
      providerKind === "care_consultant"
    ) {
      if (isUniqueCCACopy || isUniqueWPMCHCopy) {
        findBetterTimeText = t(
          `appointmentDetails.cancelledAppointment.findABetterTime.${providerKind}.${customerId}`,
        );
      } else {
        findBetterTimeText = t(
          `appointmentDetails.cancelledAppointment.findABetterTime.${providerKind}.default`,
        );
      }
    } else {
      findBetterTimeText = t(
        `appointmentDetails.cancelledAppointment.findABetterTime.${providerKind}`,
      );
    }
    return findBetterTimeText;
  };

  const normalizedProviderRoleByKind = getNormalizedProviderRoleByKind(kind);
  const providerKind =
    normalizedProviderRoleByKind === "specialty_care_navigator"
      ? "care_navigator"
      : normalizedProviderRoleByKind;

  return (
    <Box display="inline-block">
      <Text size="body-medium-strong">
        {getFindBetterTimeText(providerKind, customerId)}
      </Text>
      <Box mt="9px" mb={32}>
        <Button
          variant="high-emphasis"
          minHeight="54px"
          minWidth={["100%", "100%", "291px"]}
          lineHeight="20px"
          size="md"
          onClick={buttonClickCallback()}
          data-testid="reschedule-appointment-button"
        >
          {t("appointmentDetails.ctas.scheduleAnother")}
        </Button>
      </Box>
      {showLookForADifferentProvider && (
        <>
          <Text size="body-medium-strong">
            {t(
              `appointmentDetails.cancelledAppointment.lookForADifferentProvider.${providerKind}`,
            )}
          </Text>
          <Box mt="9px" mb={32}>
            <NextLink href={getBrowseRoute(providerKind)} passHref>
              <Button
                variant="medium-emphasis"
                as="a"
                minHeight="54px"
                minWidth={["100%", "100%", "291px"]}
                lineHeight="20px"
                size="md"
                onClick={() => {
                  TRACK_EVENT.BUTTON_CLICKED(
                    window.location.pathname,
                    "Browse Providers",
                    {
                      location: "Appointment Canceled Page",
                      provider_id: provider.id,
                      appointment_type: apptKind(),
                      appointment_medium:
                        getAppointmentMediumForTracking(medium),
                    },
                  );
                }}
                data-testid="look-for-provider-link"
              >
                {t(
                  `appointmentDetails.ctas.browseOtherProvider.${providerKind}`,
                )}
              </Button>
            </NextLink>
          </Box>
        </>
      )}
      {!isCareNavigatorAppt && isWithin24Hours && (
        <Box width="min-content" minWidth="full">
          <Text size="body-medium-strong">
            {t("appointmentDetails.cancelledAppointment.contactSupport.copy")}{" "}
            <Link
              href={links.Support}
              isExternal
              onClick={() => {
                TRACK_EVENT.LINK_CLICKED(
                  window.location.pathname,
                  links.Support,
                  "Contact Support",
                  {
                    location: "Contact Support Page",
                    to: links.Support,
                  },
                );
              }}
              whiteSpace="nowrap"
            >
              {t("appointmentDetails.cancelledAppointment.contactSupport.link")}
            </Link>
          </Text>
        </Box>
      )}
    </Box>
  );
};
