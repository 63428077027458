import { useTranslation } from "react-i18next";

import { Flex, Link, Text } from "@springcare/sh-component-library";
import { TRACK_EVENT } from "utils/mixpanel";

const CancellationPolicy = ({ appointment }) => {
  const { t } = useTranslation("loginless", {
    keyPrefix: "confirmation.cancellationPolicy",
  });

  const href = `/members/care_visits/${appointment.id}`;

  return (
    <Flex align="start" data-testid="cancellationPolicy" direction="column">
      <Text size="body-medium-strong">{t("header")}</Text>
      <Text size="body-medium-regular" mt={8}>
        {t("body")}
      </Text>
      <Link
        href={href}
        onClick={() =>
          TRACK_EVENT.LINK_CLICKED(
            window.location.pathname,
            href,
            "Manage appointment",
            {
              location: "CancellationPolicy",
              spring_doc_id: "loginlessConfirmManage02",
            },
          )
        }
      >
        {t("manageLink")}
      </Link>
    </Flex>
  );
};

export default CancellationPolicy;
