import {
  Flex,
  IconButton,
  VMicOffIcon,
  VMicOnIcon,
  VVideoChatIcon,
  VVideoOffIcon,
} from "@springcare/sh-component-library";
import { MediaType } from "components/templates/SHSessionRoom/types";
import { useTranslation } from "hooks/react-i18next";

interface PreJoinVideoCallControlsProps {
  isMobile: boolean;
  isCameraEnabled?: boolean;
  isMicrophoneEnabled?: boolean;
  toggleVideo: () => void;
  toggleAudio: () => void;
}

const PreJoinVideoCallControls = ({
  isCameraEnabled,
  isMicrophoneEnabled,
  toggleVideo,
  toggleAudio,
}: PreJoinVideoCallControlsProps) => {
  const toggleMediaEnabled = (mediaType: MediaType) => {
    if (mediaType === MediaType.Microphone) {
      toggleAudio();
    } else if (mediaType === MediaType.Camera) {
      toggleVideo();
    }
  };

  const { t } = useTranslation("livekitExperience", {
    keyPrefix: "preJoinVideoCallControls",
  });

  const CallControlButton = ({ mediaType, isEnabled }) => {
    const icons =
      mediaType === MediaType.Microphone
        ? [VMicOnIcon, VMicOffIcon]
        : [VVideoChatIcon, VVideoOffIcon];

    return (
      <IconButton
        variant="medium-emphasis"
        onClick={(e) => {
          e.currentTarget.blur();
          toggleMediaEnabled(mediaType);
        }}
        icon={isEnabled ? icons[0] : icons[1]}
        aria-label={`${t("toggle")} ${mediaType}`}
        bg={"white"}
        color={"black"}
        sx={{
          _active: {
            backgroundColor: "white !important",
            color: "black !important",
          },
          "@media (hover: none)": {
            // Disable hover styles for touch devices
            _hover: {
              backgroundColor: "white !important",
              color: "black !important",
            },
          },
        }}
      />
    );
  };

  return (
    <Flex
      mt={0}
      p="v-8"
      gap="v-8"
      justify="center"
      position="relative"
      bg="black"
      borderBottomRadius={16}
    >
      <CallControlButton
        mediaType={MediaType.Microphone}
        isEnabled={isMicrophoneEnabled}
      />
      <CallControlButton
        mediaType={MediaType.Camera}
        isEnabled={isCameraEnabled}
      />
    </Flex>
  );
};

export default PreJoinVideoCallControls;
