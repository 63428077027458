import { useEffect } from "react";
import { useMutation } from "@apollo/client";
import Router, { useRouter } from "next/router";
import Head from "next/head";
import routes from "routes";
import { useTranslation } from "react-i18next";
import { loginlessUpdateUnattendanceReason } from "operations/mutations/appointment/";
import { TRACK_EVENT } from "utils/mixpanel";
import {
  Heading,
  VStack,
  VTherapyDuotoneSpot,
} from "@springcare/sh-component-library";
import { AcknowledgeLateCancelContent } from "components/templates/NoShowReschedulePage/AcknowledgeLateCancelContent";

export const NoShowReschedulePage = () => {
  const [updateUnattendanceReason] = useMutation(
    loginlessUpdateUnattendanceReason,
  );
  const routerImp = useRouter();
  const {
    id: apptId,
    member_id: memberId,
    unattendance_reason: reason,
  } = routerImp.query;
  const { t } = useTranslation(["careVisits"]);

  useEffect(() => {
    if (apptId?.length && memberId?.length && reason?.length) {
      updateUnattendanceReason({
        variables: {
          input: {
            id: apptId,
            member_id: memberId,
            unattendance_reason: reason,
          },
        },
      }).catch((_err) => {
        // TODO: add datadog error handling
      });
    }
    TRACK_EVENT.COMPONENT_VIEWED(
      window.location.pathname,
      "No Show Reschedule Page",
      { source: "email" },
    );
  }, [apptId, memberId, reason]);

  return (
    <VStack
      maxW={600}
      spacing={16}
      mx={36}
      justifyContent="center"
      alignItems="flex-start"
      flex="1"
    >
      <Head>
        <title>{t("noShowlandingPage.title")}</title>
      </Head>
      <VTherapyDuotoneSpot />

      <Heading as="h1" size="heading-medium">
        {t("noShowlandingPage.friendlyReminder")}
      </Heading>

      <AcknowledgeLateCancelContent
        memberId={memberId}
        trackingType="Acknowledge policy button"
        trackingProps={{
          location: "No Show Reschedule Page",
          to: "Browse Care Provider Page",
        }}
        onComplete={() => {
          localStorage.setItem("initialTarget", routes.MemberCareVisits.as);
          Router.push(routes.MemberCareVisits.as);
        }}
        buttonText={t("noShowlandingPage.acknowledge")}
      />
    </VStack>
  );
};
