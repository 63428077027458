import React, { useEffect, useRef, useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import { CareProviderTagKind } from "@spring/constants/src/enums/careProviderTagKind";
import { Text } from "@springcare/sh-component-library";

import { useInViewportOnce } from "hooks/useInViewport";
import { TRACK_EVENT } from "utils/mixpanel";
import { ValuesOf } from "utils/typescriptHelpers";

type TherapyTypeOfferedProps = {
  careProviderTags: Array<{
    kind: ValuesOf<typeof CareProviderTagKind>;
    name: string;
  }>;
};

export const TherapyTypeOffered: React.FC<TherapyTypeOfferedProps> = ({
  careProviderTags,
}) => {
  const { t } = useTranslation("careProvider");

  const ref = useRef();
  const inViewport = useInViewportOnce(ref);
  const [viewedOnce, setViewedOnce] = useState(false);

  const offersCouplesTherapy = careProviderTags.some(
    (tag) => tag.kind === "SPECIALTY" && tag.name === "Couples",
  );

  useEffect(() => {
    if (careProviderTags && inViewport && !viewedOnce) {
      TRACK_EVENT.COMPONENT_VIEWED(
        window.location.pathname,
        "Therapy Type offered text",
        {
          spring_doc_id: "couples_therapy_003",
          location: "Provider Profile -- Appointment Section",
          therapy_types_offered: offersCouplesTherapy
            ? "Individual and Couples"
            : "Individual",
        },
      );
      setViewedOnce(true);
    }
  }, [inViewport, viewedOnce, careProviderTags]);

  const translationKey = offersCouplesTherapy
    ? "offersIndividualAndCouplesTherapy"
    : "offersIndividualTherapy";

  return (
    <Text pt="v-8" ref={ref}>
      <Trans
        t={t}
        i18nKey={translationKey}
        components={{ BoldText: <Text as="span" size="body-medium-strong" /> }}
      />
    </Text>
  );
};
