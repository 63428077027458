import { useEffect } from "react";
import {
  useLocalParticipant,
  useRemoteParticipants,
  useRoomContext,
} from "@livekit/components-react";
import {
  RoomEvent,
  RemoteParticipant,
  Track,
  LocalAudioTrack,
  LocalVideoTrack,
} from "livekit-client";
import { links } from "@spring/constants";

import { useToast, Box } from "@springcare/sh-component-library";

import {
  useSessionViewStatus,
  useSessionDevices,
  useParticipantMetadata,
} from "context/SessionRoomContext";
import { ParticipantType } from "components/templates/SHSessionRoom/types";

import {
  logAudioSilenceDetected,
  logMediaDeviceError,
  logReconnecting,
  logMediaDevicesChanged,
  logActiveDeviceChanged,
  logConnectionQualityChanged,
  logStreamStateChanged,
  logVideoSenderStats,
  logAudioSenderStats,
} from "components/templates/SHSessionRoom/telemetry/datadog";

import { useTranslation } from "hooks/react-i18next";

export const RoomEventHandler = () => {
  const { t } = useTranslation("livekitExperience", {
    keyPrefix: "videoCallControls.sessionRoomUtils",
  });
  const { sessionViewStatus } = useSessionViewStatus();

  const remoteParticipants = useRemoteParticipants();

  const { localParticipant } = useLocalParticipant();
  const { sessionDevices } = useSessionDevices();
  const { selectedAudioOutputDevice } = sessionDevices;

  const {
    participantType,
    localParticipantName,
    remoteParticipantName,
    appointmentId,
    participantId,
  } = useParticipantMetadata();

  const localAudioTrack = localParticipant.getTrackPublication(
    Track.Source.Microphone,
  )?.track as LocalAudioTrack;

  const localVideoTrack = localParticipant.getTrackPublication(
    Track.Source.Camera,
  )?.track as LocalVideoTrack;

  const trackAudioSenderStats = async (localAudioTrack) => {
    if (localAudioTrack) {
      logAudioSenderStats(
        appointmentId,
        participantId,
        await localAudioTrack.getSenderStats(),
      );
    }
  };

  const trackVideoSenderStats = async (localVideoTrack) => {
    if (localVideoTrack) {
      logVideoSenderStats(
        appointmentId,
        participantId,
        await localVideoTrack.getSenderStats(),
      );
    }
  };

  const toast = useToast({
    position: "top",
    isClosable: false,
  });

  const playSound = async (audioUrl: string) => {
    const audio = new Audio(audioUrl);
    // @ts-ignore: Suppress TypeScript error for setSinkId
    if (audio.setSinkId && selectedAudioOutputDevice?.deviceId) {
      // @ts-ignore: Suppress TypeScript error for setSinkId
      await audio.setSinkId(selectedAudioOutputDevice.deviceId);
    }
    audio.play();
  };

  const participantConnected = async (participant: RemoteParticipant) => {
    await new Promise((r) => setTimeout(r, 1600)); // needed for provider participant permissions to adjust
    const participantName = participant.isLocal
      ? localParticipantName
      : remoteParticipantName;
    // member is in waiting room. If local participant is a provider, play waiting room sound
    if (!participant.permissions?.canPublish) {
      if (participantType === ParticipantType.Provider)
        await playSound(links.WaitingRoomSoundURL);
    } else {
      // participant goes straight to the session. Only play toast for people in the session.
      if (localParticipant?.permissions?.canPublish) {
        toast({
          position: "top",
          isClosable: false,
          render: () => (
            <Box
              mt="v-16"
              borderRadius="v-lg"
              px="v-8"
              py="v-4"
              w="fit-content"
              backgroundColor="neutral-bold"
              color="neutral-on-bold"
            >
              {participantName ?? t("aParticipant")} has joined the session
            </Box>
          ),
        });
        await playSound(links.JoinSessionSoundURL);
      }
    }
  };

  const participantDisconnected = async (participant) => {
    // only play the sound if the departing participant is NOT in the waiting room
    // and if the local participant is not in the waiting room
    const participantName = participant.isLocal
      ? localParticipantName
      : remoteParticipantName;
    if (
      participant.permissions?.canPublish &&
      localParticipant?.permissions?.canPublish
    ) {
      toast({
        position: "top",
        isClosable: false,
        render: () => (
          <Box
            mt="v-16"
            borderRadius="v-lg"
            px="v-8"
            py="v-4"
            w="fit-content"
            backgroundColor="neutral-bold"
            color="neutral-on-bold"
          >
            {participantName ?? t("aParticipant")} has left the session
          </Box>
        ),
      });

      await playSound(links.LeaveSessionSoundURL);
    }
  };

  const audioSilenceDetected = async () => {
    // log the occurrence
    logAudioSilenceDetected(appointmentId, participantId, sessionDevices);
    // Show a toast to inform the user that their microphone isn’t detecting audio input
    toast({
      position: "top",
      duration: 8000, // 8s default is 5s
      render: () => (
        <Box
          mt="v-16"
          borderRadius="v-lg"
          px="v-8"
          py="v-4"
          w="fit-content"
          backgroundColor="neutral-bold"
          color="neutral-on-bold"
        >
          {t("audioSilenceDetected")}
        </Box>
      ),
    });
  };

  const mediaDeviceError = async (error) => {
    logMediaDeviceError(appointmentId, participantId, error);

    await trackAudioSenderStats(localAudioTrack);
    await trackVideoSenderStats(localVideoTrack);
  };

  const reconnecting = async () => {
    logReconnecting(appointmentId, participantId);

    await trackAudioSenderStats(localAudioTrack);
    await trackVideoSenderStats(localVideoTrack);
  };

  const reconnected = async () => {
    await trackAudioSenderStats(localAudioTrack);
    await trackVideoSenderStats(localVideoTrack);
  };

  const mediaDevicesChanged = async () => {
    logMediaDevicesChanged(appointmentId, participantId);
  };

  const activeDeviceChanged = async (kind) => {
    logActiveDeviceChanged(
      kind,
      sessionDevices,
      sessionViewStatus,
      "SHSessionRoomUtils - activeDeviceChanged()",
    );
    if (kind === "audiooutput") {
      await trackAudioSenderStats(localAudioTrack);
    } else if (kind === "videoinput") {
      await trackVideoSenderStats(localVideoTrack);
    }
  };

  const connectionQualityChanged = async (connectionQuality, participant) => {
    logConnectionQualityChanged(
      appointmentId,
      participantId,
      connectionQuality,
    );

    await trackAudioSenderStats(localAudioTrack);
    await trackVideoSenderStats(localVideoTrack);
  };

  const streamStateChanged = async (pub, streamState, participant) => {
    logStreamStateChanged(
      appointmentId,
      participantId,
      pub,
      streamState,
      participant,
    );

    if (pub.kind === Track.Kind.Audio) {
      await trackAudioSenderStats(localAudioTrack);
    } else if (pub.kind === Track.Kind.Video) {
      await trackVideoSenderStats(localVideoTrack);
    }
  };

  const localTrackSubscribed = async (track) => {
    if (track.kind === Track.Kind.Audio) {
      await trackAudioSenderStats(localAudioTrack);
    } else if (track.kind === Track.Kind.Video) {
      await trackVideoSenderStats(localVideoTrack);
    }
  };

  const room = useRoomContext();

  useEffect(() => {
    // refactor to only play sound and toast for calls with <= 3 participants;
    // all other logging should still occur regardless of number of participants
    if (remoteParticipants.length < 3) {
      room?.on(RoomEvent.ParticipantConnected, participantConnected);
      room?.on(RoomEvent.ParticipantDisconnected, participantDisconnected);
      room?.on(RoomEvent.LocalAudioSilenceDetected, audioSilenceDetected);
      room?.on(RoomEvent.MediaDevicesError, mediaDeviceError);
      room?.on(RoomEvent.Reconnecting, reconnecting);
      room?.on(RoomEvent.Reconnected, reconnected);
      room?.on(RoomEvent.MediaDevicesChanged, mediaDevicesChanged);
      room?.on(RoomEvent.ActiveDeviceChanged, activeDeviceChanged);
      room?.on(RoomEvent.ConnectionQualityChanged, connectionQualityChanged);
      room?.on(RoomEvent.TrackStreamStateChanged, streamStateChanged);
      room?.on(RoomEvent.LocalTrackSubscribed, localTrackSubscribed);
    }

    return () => {
      room?.off(RoomEvent.ParticipantConnected, participantConnected);
      room?.off(RoomEvent.ParticipantDisconnected, participantDisconnected);
      room?.off(RoomEvent.LocalAudioSilenceDetected, audioSilenceDetected);
      room?.off(RoomEvent.MediaDevicesError, mediaDeviceError);
      room?.off(RoomEvent.Reconnecting, reconnecting);
      room?.off(RoomEvent.Reconnected, reconnected);
      room?.off(RoomEvent.MediaDevicesChanged, mediaDevicesChanged);
      room?.off(RoomEvent.ActiveDeviceChanged, activeDeviceChanged);
      room?.off(RoomEvent.ConnectionQualityChanged, connectionQualityChanged);
      room?.off(RoomEvent.TrackStreamStateChanged, streamStateChanged);
      room?.off(RoomEvent.LocalTrackSubscribed, localTrackSubscribed);
    };
  }, [room, remoteParticipants]);

  return null;
};
