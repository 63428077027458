import { useEffect, useRef } from "react";
import Router, { useRouter } from "next/router";
import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { DateTime } from "luxon";
import classnames from "classnames";
import { isCoaching } from "@spring/constants";

// Components
import { Grid, Col, Banner } from "@spring/smeargle";
import {
  useDimensions,
  useToast,
  VCalendarIcon,
  Menu,
  MenuGroup,
  MenuList,
  MenuItem,
  MenuButton,
  VStack,
  ButtonGroup,
  Link,
  useDisclosure,
  useMediaQuery,
} from "@springcare/sh-component-library";
import { Alert, AlertIcon, AlertTitle } from "@chakra-ui/react";
// TODO: Refactor component usage here so the imports on line 21 can be updated and ultimately
// moved into line 17
import {
  Button,
  Heading,
  Text,
  SHNotification,
} from "design-system/components";
import {
  CareProviderScheduleModal,
  AvailabilityRequestModal,
  ChangeCareProviderModal,
} from "components/modals";
import { MinorAppointmentCallout } from "components/molecules";
import {
  GoogleSvgIcon,
  CalendarSvgIcon,
  OutlookSvgIcon,
} from "components/atoms/Assets/svgs";
import {
  AppointmentDetailsCard,
  ConfirmationCopy,
  DownloadMessage,
  ProviderInstructions,
} from "components/templates/CareVisitDetails/components";
import { getFormLinkWPMHC } from "utils/employerServicesHelpers";

// Constants
import Meowth from "@spring/meowth";
import { API_DOMAIN } from "constants/api";
import { openModal, addNotification } from "@spring/smeargle/actions";
import {
  modalIds,
  getAppointmentKindForTracking,
  getAppointmentMediumForTracking,
  AppointmentKind,
  AppointmentAction,
  AppointmentMedium,
  isCareNavigation,
  isCareConsultantAppointment,
} from "@spring/constants";

// Operations
import { getUserInfo } from "operations/queries/user";
import { useUpcomingAppointmentSlots } from "hooks/useUpcomingAppointmentSlots";

// Other utils
import {
  EVENT_TYPE,
  makeEventString,
  track,
  TRACK_EVENT,
} from "utils/mixpanel";
import {
  curriedGetAppointmentKind,
  curriedGetAppointmentType,
  curriedGetHeadline,
  checkIfWithin24Hours,
} from "components/templates/CareVisitDetails/utils";
import {
  isCurrentTimeWithin24HoursOfAppointment,
  isPastAppointment,
} from "utils/time/time";
import envUtils from "utils/environment";
import { getSchedulableProviderRole } from "utils/providers/";
import styles from "./styles.module.scss";
import { CouplesTherapyFAQModal } from "modules/MemberDashboard/CareVisits/AppointmentDetails/components";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";
import { CoachingSessionType } from "components/templates/CareVisitDetails/components/AppointmentDetails/CoachingSessionType";
import routes from "routes/index";
import { useOpenConversationWithUser } from "@springcare/sh-messaging-library";

const AppointmentDetails = ({
  provider,
  kind,
  time,
  duration,
  cancelled,
  appointmentLocation,
  medium,
  sessionType,
  openModal,
  appointmentId,
  isAMinor,
  memberFirstName,
  isGlobalMember,
  reasonForScheduling,
  isInHouseVideo,
  isCoveredForMember,
}) => {
  const [isMobile] = useMediaQuery("(max-width: 719px)");
  const couplesTherapyFlag = useFeatureFlag(FLAGS.ENABLE_COUPLES_THERAPY);
  const showCouplesTherapy = couplesTherapyFlag && !isAMinor;

  const providerMemberMessagingLDFlag = useFeatureFlag(
    FLAGS.PROVIDER_MEMBER_MESSAGING,
  );

  const addToCalendarRef = useRef();
  const toast = useToast();
  const menuDimensions = useDimensions(addToCalendarRef);
  const router = useRouter();
  const {
    isOpen: isCouplesTherapyModalOpen,
    onOpen: openCouplesTherapyModal,
    onClose: closeCouplesTherapyModal,
  } = useDisclosure();
  const isCoachingProvider = isCoaching(kind);
  const rescheduleButtonString = isCoachingProvider
    ? "appointmentDetails.reschedule.rescheduleCoachingButtonText"
    : "appointmentDetails.reschedule.rescheduleButtonText";
  const isCouplesTherapy = kind === AppointmentKind.CouplesTherapy;
  const isCareConsultant = isCareConsultantAppointment(kind);
  const isInPersonSession = medium === AppointmentMedium.InPerson;

  const allowModificationWithin24Hours = isCareNavigation(kind);
  const GOOGLE_CALENDAR_ENUM = "GOOGLE_CALENDAR";
  const { data: userInfo } = useQuery(getUserInfo, {
    ...Meowth.apolloOptionsUserId(),
  });

  const customerId = userInfo?.user?.customer?.id;

  const formLinkSDOH = getFormLinkWPMHC(customerId);

  const { t } = useTranslation("careVisits");

  const { data: firstAvailableSlot } = useUpcomingAppointmentSlots(
    provider,
    kind,
    medium,
    1,
  );

  const getTimeToAppointment = (appointmentStartTime) => {
    const appointmentTime = DateTime.fromISO(appointmentStartTime);
    const currentTime = DateTime.fromISO(new Date().toISOString());
    const appointmetTimeDifference = appointmentTime.diff(currentTime, "hours");
    return parseFloat(appointmetTimeDifference.hours).toFixed(2);
  };

  const currentTime = DateTime.fromISO(new Date().toISOString());
  const appointmentTime = DateTime.fromISO(time);
  const isWithin24Hours = isCurrentTimeWithin24HoursOfAppointment(
    currentTime,
    appointmentTime,
  );
  const isAPastAppointment = isPastAppointment(currentTime, appointmentTime);

  // Helper method opening the cancel modals and tracking events in mix panel.
  const cancelAppt = () => {
    track("Cancel appointment clicked", {
      deprecated: true,
      replaced_with: makeEventString(EVENT_TYPE.BUTTON_CLICKED, {
        page: window.location.pathname,
        type: "Cancel Appointment",
        to: null,
        appointment_type: getAppointmentKindForTracking(kind),
        appointment_medium: getAppointmentMediumForTracking(medium),
        time_to_appointment: getTimeToAppointment(time),
      }),
    });
    TRACK_EVENT.BUTTON_CLICKED(window.location.pathname, "Cancel Appointment", {
      spring_doc_id: "cnsched016",
      to: null,
      appointment_type: getAppointmentKindForTracking(kind),
      appointment_medium: getAppointmentMediumForTracking(medium),
      time_to_appointment: getTimeToAppointment(time),
    });
    openModal(modalIds.cancelAppointmentModal);
  };

  // Helper method opening the rescheduling modals and tracking events in mix panel.
  const rescheduleAppt = () => {
    track("Reschedule appointment clicked", {
      deprecated: true,
      replaced_with: makeEventString(EVENT_TYPE.BUTTON_CLICKED, {
        page: window.location.pathname,
        type: "Reschedule Appointment",
        to: null,
        appointment_type: getAppointmentKindForTracking(kind),
        appointment_medium: getAppointmentMediumForTracking(medium),
        time_to_appointment: getTimeToAppointment(time),
      }),
    });

    TRACK_EVENT.BUTTON_CLICKED(
      window.location.pathname,
      "Reschedule Appointment",
      {
        spring_doc_id: "cnsched005",
        to: null,
        appointment_type: getAppointmentKindForTracking(kind),
        appointment_medium: getAppointmentMediumForTracking(medium),
        time_to_appointment: getTimeToAppointment(time),
      },
    );

    if (isCareConsultant) {
      window.open(formLinkSDOH, "_blank", "noopener noreferrer");
    } else {
      openModal(
        allowModificationWithin24Hours
          ? modalIds.careProviderScheduleModal
          : checkIfWithin24Hours(time, modalIds.careProviderScheduleModal),
        {
          ...provider,
          kind,
          medium,
          buttonText: t(rescheduleButtonString),
          action: AppointmentAction.Reschedule,
          appointmentId,
          appointmentLocation,
          providerRole: getSchedulableProviderRole(provider),
          initialStartTime:
            firstAvailableSlot &&
            firstAvailableSlot?.appointment_slots?.available[0],
          reasonForScheduling: reasonForScheduling,
          isCoveredForMember: isCoveredForMember,
          isReschedule: true,
        },
      );
    }
  };

  const bookAnotherAppointment = () => {
    TRACK_EVENT.BUTTON_CLICKED(window.location.pathname, "Schedule Now");
    openModal(modalIds.careProviderScheduleModal, {
      ...provider,
      kind,
      medium,
      buttonText: t("appointmentDetails.scheduleNow"),
      action: AppointmentAction.Create,
      providerRole: getSchedulableProviderRole(provider),
      initialStartTime: firstAvailableSlot?.appointment_slots?.available[0],
    });
  };

  // Helper method to track downloading the appt's ics file in mix panel.
  function trackAddToCalendarEvent(calendarType) {
    TRACK_EVENT.BUTTON_CLICKED(Router?.router?.asPath, "Add to Calendar", {
      calendar_type: calendarType,
      appointment_id: appointmentId,
      appointment_type: getAppointmentKindForTracking(kind),
      appintment_time_utc: time,
      appointment_medium: getAppointmentMediumForTracking(medium),
      provider_id: provider.id,
    });
  }

  useEffect(() => {
    if (!cancelled) {
      if (
        router.query.appointment === "reschedule" &&
        medium === AppointmentMedium.Video
      ) {
        return openModal(modalIds.careProviderScheduleModal, {
          ...provider,
          appointmentId,
          kind,
          medium,
          buttonText: t("appointmentDetails.reschedule.rescheduleButtonText"),
          action: AppointmentAction.Reschedule,
          providerRole: getSchedulableProviderRole(provider),
          initialStartTime:
            firstAvailableSlot &&
            firstAvailableSlot?.appointment_slots?.available[0],
        });
      }
    }

    if (isWithin24Hours) {
      TRACK_EVENT.COMPONENT_VIEWED(
        window.location.pathname,
        "Late Cancelation/Reschedule Banner",
        {
          provider_id: provider.id,
          appointment_id: appointmentId,
          appointment_type: getAppointmentKindForTracking(kind),
          appointment_medium: getAppointmentMediumForTracking(medium),
          appointment_time_utc: time,
          time_to_appointment: getTimeToAppointment(time),
        },
      );
    }
  }, [
    appointmentId,
    cancelled,
    firstAvailableSlot,
    isWithin24Hours,
    kind,
    medium,
    openModal,
    provider,
    router.query.appointment,
    t,
    time,
  ]);
  const appointmentKindText = curriedGetAppointmentKind(t, customerId);

  const appointmentTypeText = curriedGetAppointmentType(t);

  const getHeadline = curriedGetHeadline(t);

  const getGoogleCalendarLink = () => {
    const startDateAndTime = new Date(time)
      .toISOString()
      .replace(/-|:|\.\d\d\d/g, "");

    // Overwrite duration based on specific coaching type
    if (kind === "COACHING") {
      if (sessionType === CoachingSessionType.FOLLOW_UP) {
        duration = 30;
      } else if (sessionType === CoachingSessionType.INTAKE) {
        duration = 45;
      }
    }

    const endDateAndTime = new Date(
      new Date(time).setMinutes(new Date(time).getMinutes() + duration),
    )
      .toISOString()
      .replace(/-|:|\.\d\d\d/g, "");
    const eventTitle = t(
      "appointmentDetails.appointReminders.meetingEventTitle",
    );
    const supportEmail = envUtils.isInternalEnv()
      ? "springinternal@springhealth.com"
      : "careteam@springhealth.com";
    const appointmentType = getHeadline(
      medium,
      isInHouseVideo,
      "",
      "",
      GOOGLE_CALENDAR_ENUM,
    );
    const description = t(appointmentType, {
      providerName: provider.name,
      email: supportEmail,
      appointmentDetailLink: window.location.origin + router.asPath,
      phone: userInfo?.user?.phone,
    });

    return `https://calendar.google.com/calendar/render?action=TEMPLATE&text=${eventTitle}&details=${description}&dates=${startDateAndTime}/${endDateAndTime}&location=${
      appointmentLocation ? appointmentLocation : ""
    }&ctz=${Intl.DateTimeFormat().resolvedOptions().timeZone}`;
  };

  const downloadCalendarInvite = (calendarType) => {
    trackAddToCalendarEvent(calendarType);
    toast({
      duration: null,
      containerStyle: {
        maxWidth: "100%",
        width: "96%",
      },
      // eslint-disable-next-line react/display-name
      render: () => (
        <SHNotification
          notification={{
            status: "success",
            text: (
              <DownloadMessage
                appointmentId={appointmentId}
                API_DOMAIN={API_DOMAIN}
              />
            ),
          }}
          setNotification={() => {}}
        />
      ),
    });
  };
  const conversationWithUser = useOpenConversationWithUser();

  return (
    <>
      <div>
        <Heading
          data-cy="appointment-details-headline"
          as="h1"
          size="heading-large"
          color="#292929"
          margin={"24px 0px"}
        >
          {getHeadline(
            medium,
            isInHouseVideo,
            isAMinor,
            memberFirstName,
            kind,
            appointmentKindText(kind),
            appointmentTypeText(medium),
          )}
        </Heading>
      </div>
      {/* TODO merge this and below */}
      {kind === AppointmentKind.InitialMedicationManagement && (
        <Banner
          text={t("appointmentDetails.medManagementDisclaimerText")}
          icon="information"
          color="info"
        />
      )}
      {kind === AppointmentKind.FollowUpMedicationManagement && (
        <Banner
          text={t("appointmentDetails.medManagementDisclaimerText")}
          icon="information"
          color="info"
        />
      )}
      {isWithin24Hours && (
        <Alert
          colorScheme="#F1FCF9"
          paddingY={20}
          marginY={24}
          paddingLeft={30}
          data-cy="appointment-within-24-hrs-banner"
        >
          <AlertIcon as={VCalendarIcon} height={30} width={30} />
          <AlertTitle>
            {t("appointmentDetails.appointmentWithin24Hours.banner")}
          </AlertTitle>
        </Alert>
      )}
      <Grid>
        <Col md={5} lg={4}>
          <div
            className={classnames({
              [styles.opaque]: cancelled,
            })}
          >
            <AppointmentDetailsCard
              kind={kind}
              time={time}
              provider={provider}
              customerId={customerId}
            />

            {!cancelled && (
              <VStack spacing={8} align="stretch" mt={12}>
                <Menu>
                  <MenuButton
                    as={Button}
                    ref={addToCalendarRef}
                    size="lg"
                    height={40}
                    padding="8px, 16px, 8px, 16px"
                    variant="solid"
                    colorScheme="primary"
                    _focusVisible={{ boxShadow: "0 0 0 3px black" }}
                  >
                    {t("appointmentDetails.addToCalendar")}
                  </MenuButton>
                  <MenuList
                    width="228px"
                    marginLeft={menuDimensions?.borderBox?.width - 228 || 0}
                  >
                    <MenuGroup>
                      <MenuItem
                        as={Link}
                        href={getGoogleCalendarLink()}
                        target="_blank"
                        _hover={{
                          bg: "tertiary.light",
                          textDecoration: "none",
                          outline: "none",
                          boxShadow: "none",
                          color: "inherit",
                        }}
                        _focus={{
                          bg: "tertiary.light",
                          outline: "none",
                          boxShadow: "none",
                        }}
                        h="56"
                        paddingLeft="24px"
                        onClick={() => trackAddToCalendarEvent("google")}
                      >
                        <GoogleSvgIcon />
                        <Text marginLeft="18">
                          {t("appointmentDetails.appointReminders.google")}
                        </Text>
                      </MenuItem>
                    </MenuGroup>
                    <MenuGroup>
                      <MenuItem
                        as={Link}
                        href={`${API_DOMAIN}/event/members/${appointmentId}`}
                        _hover={{
                          bg: "tertiary.light",
                          textDecoration: "none",
                          outline: "none",
                          boxShadow: "none",
                          color: "inherit",
                        }}
                        _focus={{
                          bg: "tertiary.light",
                          outline: "none",
                          boxShadow: "none",
                        }}
                        paddingLeft="24px"
                        h="56"
                        onClick={() => downloadCalendarInvite("outlook")}
                      >
                        <OutlookSvgIcon />
                        <Text marginLeft="18">
                          {" "}
                          {t("appointmentDetails.appointReminders.outlook")}
                        </Text>
                      </MenuItem>
                    </MenuGroup>
                    <MenuGroup>
                      <MenuItem
                        as={Link}
                        href={`${API_DOMAIN}/event/members/${appointmentId}`}
                        _hover={{
                          bg: "tertiary.light",
                          textDecoration: "none",
                          outline: "none",
                          boxShadow: "none",
                          color: "inherit",
                        }}
                        _focus={{
                          bg: "tertiary.light",
                          outline: "none",
                          boxShadow: "none",
                        }}
                        paddingLeft="24px"
                        h="56"
                        onClick={() => downloadCalendarInvite("other")}
                      >
                        <CalendarSvgIcon />
                        <Text marginLeft="18">
                          {" "}
                          {t("appointmentDetails.appointReminders.other")}{" "}
                        </Text>
                      </MenuItem>
                    </MenuGroup>
                  </MenuList>
                </Menu>
                {!isGlobalMember &&
                  providerMemberMessagingLDFlag &&
                  !isCareNavigation(kind) && (
                    <Button
                      as={Link}
                      size="md"
                      variant="outline"
                      w={{ base: "100%" }}
                      colorScheme="platform"
                      color="platform.dark"
                      data-cy="send-a-message"
                      _focusVisible={{ boxShadow: "0 0 0 3px black" }}
                      onClick={() => {
                        const existingConversation = conversationWithUser({
                          ...provider,
                          care_provider: provider,
                          id: provider?.user_id,
                        });
                        if (existingConversation) {
                          router.push(
                            {
                              pathname: routes.Messages.as,
                              query: {
                                conversation_id: existingConversation?.id,
                                from: router.asPath,
                              },
                            },
                            routes.Messages.as,
                          );
                        } else {
                          router.push(
                            {
                              pathname: routes.Messages.as,
                              query: {
                                provider_id: provider?.user_id,
                                from: router.asPath,
                              },
                            },
                            routes.Messages.as,
                          );
                        }

                        TRACK_EVENT.BUTTON_CLICKED(
                          window.location.pathname,
                          "Send a Message",
                          {
                            appointment_id: appointmentId,
                          },
                        );
                      }}
                    >
                      {t("appointmentDetails.sendAMessage")}
                    </Button>
                  )}
                <ButtonGroup
                  gap={8}
                  w={{ base: "100%" }}
                  style={{ marginTop: 8 }}
                >
                  <Button
                    size="md"
                    variant="outline"
                    w={{ base: "50%" }}
                    colorScheme="platform"
                    color="platform.dark"
                    data-cy="reschedule-session"
                    onClick={rescheduleAppt}
                    disabled={isAPastAppointment}
                    _focusVisible={{ boxShadow: "0 0 0 3px black" }}
                  >
                    {t(rescheduleButtonString)}
                  </Button>
                  <Button
                    size="md"
                    variant="outline"
                    w={{ base: "50%" }}
                    colorScheme="platform"
                    color="platform.dark"
                    style={{ marginLeft: 0 }}
                    data-cy="cancel-session-link"
                    onClick={cancelAppt}
                    disabled={isAPastAppointment}
                    _focusVisible={{ boxShadow: "0 0 0 3px black" }}
                  >
                    {t("appointmentDetails.cancelAppointment")}
                  </Button>
                </ButtonGroup>
              </VStack>
            )}
          </div>
        </Col>

        {isMobile && isInPersonSession && provider?.instructions && (
          <Col>
            <ProviderInstructions copy={provider?.instructions} />
          </Col>
        )}

        <Col md={1} />

        <Col md={6} lg={7}>
          {isAMinor && !cancelled && (
            <MinorAppointmentCallout
              isACareNavigator={
                kind === AppointmentKind.InitialCareNavigation ||
                kind === AppointmentKind.FollowUpCareNavigation
              }
              firstName={memberFirstName}
            />
          )}
          <ConfirmationCopy
            medium={medium}
            location={appointmentLocation}
            time={time}
            appointmentId={appointmentId}
            duration={duration}
            provider={provider}
            cancelled={cancelled}
            kind={kind}
            isAMinor={isAMinor}
            isInHouseVideo={isInHouseVideo}
            isCoveredForMember={isCoveredForMember}
            memberCohort=""
            bookAnotherAppointmentCb={bookAnotherAppointment}
            openCouplesTherapyModal={openCouplesTherapyModal}
          />
        </Col>
      </Grid>

      {!isMobile && isInPersonSession && provider?.instructions && (
        <ProviderInstructions copy={provider?.instructions} />
      )}

      <CareProviderScheduleModal />
      <AvailabilityRequestModal />
      <ChangeCareProviderModal />
      {isCouplesTherapy && showCouplesTherapy && (
        <CouplesTherapyFAQModal
          isModalOpen={isCouplesTherapyModalOpen}
          onModalClose={closeCouplesTherapyModal}
        />
      )}
    </>
  );
};

AppointmentDetails.propTypes = {
  appointmentId: PropTypes.string.isRequired,
  cancelled: PropTypes.bool.isRequired,
  duration: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  isAMinor: PropTypes.any,
  kind: PropTypes.string.isRequired,
  appointmentLocation: PropTypes.string,
  medium: PropTypes.string.isRequired,
  memberFirstName: PropTypes.any,
  openModal: PropTypes.func.isRequired,
  provider: PropTypes.shape({
    avatar: PropTypes.any,
    name: PropTypes.string,
    roles: PropTypes.any,
  }).isRequired,
  time: PropTypes.string.isRequired,
};

AppointmentDetails.defaultProps = {
  appointmentLocation: "",
};

const mapDispatchToProps = { openModal, addNotification };
export default connect(null, mapDispatchToProps)(AppointmentDetails);
