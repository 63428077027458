import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  Grid,
  Flex,
  Spacer,
  HStack,
  Box,
  SHLanguageSelect,
  SHFlyout,
  DIAL_CODES,
  useDisclosure,
  useMediaQuery,
} from "design-system/components/index";
import {
  BackBtn,
  LoginBtn,
  NavHelpBtn,
  LogoutBtn,
  CloseBtn,
} from "./HeaderButtons";
import { MemberHelpCard } from "components";
import { Link as SpringLink } from "@springcare/sh-component-library";
import { titleCase } from "components/templates/RegisterPage/analytics";
import { normalizeToISO2 } from "utils/global";
import { lookupLanguage, getLanguageFromCountry } from "utils/language";
import routes from "routes";
import { TRACK_EVENT } from "utils/mixpanel";
import { useDispatch } from "react-redux";
import { localeCountry } from "actions/global/actions";
import { SHLogoLink } from "./SHLogoLink";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";
import { feedbackFormUrl } from "components/templates/VirtualSessionView/constants";

export const SHHeader = ({
  sliderDismissed = true,
  country,
  currentView,
  desktopBg,
  mobileBg,
  setCurrentView,
  isDependentObj,
  global,
  setGlobalCtx,
  displayBackBtn,
  doGoBack,
  isLoggedIn,
  page,
  currLang,
  showSplitHeaderDesktop = true,
  showLanguageSelect = true,
  closeTo = undefined,
  user = undefined,
  showReportIssue = false,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t, i18n } = useTranslation([
    "memberHelpCard",
    "limitedLangMemberHelpCard",
  ]);
  const [isMobileTrue] = useMediaQuery("(max-width: 767px)");
  const [lang, setLang] = useState(currLang || i18n.language);
  const dispatch = useDispatch();
  const showFeedbackURL = useFeatureFlag(FLAGS.ENABLE_ZOOM_FEEDBACK_URL);

  function getUserLanguagePreference(iso2Country) {
    if (sessionStorage.getItem("langDropdownTriggered")) {
      return lookupLanguage(sessionStorage.getItem("langDropdownTriggered"));
    }
    if (user?.preferred_language) {
      return lookupLanguage(user.preferred_language);
    }
    return lookupLanguage(getLanguageFromCountry(iso2Country));
  }

  function updateGlobalCtx(coveredLifeCountry) {
    // convert to iso2 format
    const iso2Country = normalizeToISO2(coveredLifeCountry);

    const language = getUserLanguagePreference(iso2Country);

    // update local state language
    setLang(language.code);

    // update global state country && lang && showGlobalExperience
    if (iso2Country !== "US") {
      // get country dial code (country_code)
      const country_code = updateGlobalCtxCountryCode(iso2Country);
      // if a view needs global ctx, pass its setter to be used here
      if (setGlobalCtx) {
        setGlobalCtx({
          ...global,
          country: iso2Country,
          lang: lang || language.code,
          preferred_language:
            lookupLanguage(lang).englishName || language.englishName,
          showGlobalExperience: true,
          country_code,
        });
      }
    }
  }

  function updateGlobalCtxCountryCode(coveredLifeCountry) {
    let { value } = DIAL_CODES.find(
      (country) => country.code === coveredLifeCountry,
    );
    return value;
  }

  function helpClickBtnHandler() {
    if (currentView !== "LOGIN") {
      TRACK_EVENT.BUTTON_CLICKED(window.location.pathname, "Help", {
        location: "Header",
        page_version:
          window.location.pathname === routes.Register.as
            ? titleCase(currentView)
            : undefined,
      });
    } else {
      TRACK_EVENT.BUTTON_CLICKED(window.location.pathname, "Help", {
        location: "Header",
        spring_doc_id: "register003",
      });
    }
    onOpen();
  }

  useEffect(() => {
    if (country) {
      updateGlobalCtx(country);
    }
  }, [country, user]);

  useEffect(() => {
    let preferred_language = lookupLanguage(lang);
    preferred_language = preferred_language.englishName;
    // if a view needs global ctx, pass its setter to be used here
    if (setGlobalCtx) {
      setGlobalCtx({ ...global, preferred_language, lang });
    }
    i18n.changeLanguage(lang);
    // update redux state language
    dispatch(localeCountry({ lang }));
  }, [lang]);

  const mobileHeader = (
    <Box as="nav" alignItems="center" aria-label="Navigation">
      <Box h={95} pb={24} pr={24} pt={24} bg={mobileBg}>
        <Flex alignItems="center">
          <BackBtn
            currentView={currentView}
            setCurrentView={setCurrentView}
            displayBackBtn={displayBackBtn}
            doGoBack={doGoBack}
            isDependentObj={isDependentObj}
          />
          <Spacer />
          <HStack spacing={8} justifyContent="flex-end" maxW={"100vw"}>
            {showLanguageSelect && (
              <SHLanguageSelect
                lang={lang}
                setLang={setLang}
                currentView={currentView}
                isDependentObj={isDependentObj}
                page={page}
              />
            )}
            <NavHelpBtn onClick={() => helpClickBtnHandler()} />
            {!isLoggedIn && sliderDismissed && (
              <LoginBtn
                currentView={currentView}
                setCurrentView={setCurrentView}
              />
            )}
            {isLoggedIn && !routes[closeTo] && (
              <LogoutBtn currentView={currentView} />
            )}
            {routes[closeTo] && <CloseBtn closeTo={closeTo} />}
          </HStack>
        </Flex>
      </Box>
    </Box>
  );

  const desktopHeader = (
    <Box as="nav" aria-label="Navigation">
      <Grid
        templateColumns={
          showSplitHeaderDesktop ? "repeat(2, 1fr)" : "repeat(1, 1fr)"
        }
        gap={0}
        h={95}
      >
        {showSplitHeaderDesktop && (
          <SHLogoLink desktopBg={desktopBg} isLoggedIn={isLoggedIn} />
        )}
        <Flex
          w={[null, null, "calc(100% - 48px)", "80%"]}
          alignItems="center"
          m="0 auto"
          bg="white"
        >
          <BackBtn
            currentView={currentView}
            setCurrentView={setCurrentView}
            displayBackBtn={displayBackBtn}
            doGoBack={doGoBack}
          />
          <Spacer />
          <HStack spacing={8}>
            {showReportIssue && showFeedbackURL && (
              <SpringLink href={feedbackFormUrl} isExternal>
                {t("virtualSession:endSession.provideFeedback")}
              </SpringLink>
            )}
            {showLanguageSelect && (
              <SHLanguageSelect
                lang={lang}
                setLang={setLang}
                currentView={currentView}
                isDependentObj={isDependentObj}
                page={page}
              />
            )}
            <NavHelpBtn onClick={() => helpClickBtnHandler()} />
            {!isLoggedIn && (
              <LoginBtn
                currentView={currentView}
                setCurrentView={setCurrentView}
              />
            )}
            {isLoggedIn && !routes[closeTo] && (
              <LogoutBtn currentView={currentView} />
            )}
            {routes[closeTo] && <CloseBtn closeTo={closeTo} />}
          </HStack>
        </Flex>
      </Grid>
    </Box>
  );

  return (
    <>
      {isMobileTrue ? mobileHeader : desktopHeader}
      <SHFlyout
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
        size="md"
        headerText={t("limitedLangMemberHelpCard:footerHeader")}
        isMemberHelpFlyout={true}
      >
        <MemberHelpCard closeFlyout={onClose} />
      </SHFlyout>
    </>
  );
};
